import React from 'react';
import SplitButton from '../../../common/splitButton';
import InlineInputForm from '../../../common/inlineInputForm';
import InlineDropdown from '../../../common/inlineDropDown';
import InLineCheckbox from '../../../common/inLineCheckbox';
import RunReport from '../../../common/runReport';
import UnitSalesTable from './unitSalesTable';

export default function UnitSalesEnquiry() {
	return (
		<>
			<div className="comp-heading">
				<div className="row">
					<div className="col-lg-4">
						<label>Unit Sales Enquiry</label>
					</div>
					<div className="col-lg-2"></div>
					<div className="col-lg-6" style={{ textAlign: 'right' }}>
						<SplitButton
							label="Export"
							type="light"
							data={['Export as PDF', 'Export as CSV', 'Export as XLSX', 'Export as XLS']}
						/>
						<button className="btn btn-success btn-sm">Run</button>
					</div>
				</div>
			</div>
			<br />
			<div className="panel panel-default">
				<div className="panel-body">
					<div className="row panel-inputs" id="inline-input-forms">
						<div className="col-sm-3 padding-right">
							<InlineInputForm id="date-from" type="date" value="" name="Date From" />
							<InlineInputForm id="date-to" type="date" value="" name="Date To" />
							<InlineDropdown
								id="warehouse"
								name="Warehouse"
								values={['Select All', 'Warehouse', 'Main Office']}
							/>
						</div>
						<div className="col-sm-3 padding-0">
							<InLineCheckbox id="include-credits" name="Include Credits" />
							<InlineInputForm id="product-code" value="" name="Product Code" />
							<InlineInputForm id="product-description" value="" name="Product Description" />
						</div>
						<div className="col-sm-3 padding-0">
							<InlineInputForm id="supplier-code" value="" name="Supplier Code" />
							<InlineInputForm id="supplier-name" value="" name="Supplier Name" />
							<InlineDropdown id="sales-order-group" name="Sales Order Group" values={['']} />
						</div>
						<div className="col-sm-3 padding-0">
							<InlineDropdown id="customer-type" name="Customer Type" values={['', 'General']} />
							<InlineInputForm id="customer-code" value="" name="Customer Code" />
							<InlineInputForm id="customer-name" value="" name="Customer Name" />
						</div>
					</div>
				</div>
			</div>
			<div className="panel panel-default">
				<div className="panel-body">
					<UnitSalesTable />
					<br />
					<RunReport />
				</div>
			</div>

			<div className="row">
				<div className="col-md-9"></div>
				<div className="col-md-3 col-sm-6">
					<div className="panel panel-default" id="panel-margin-right">
						<div className="panel-body">
							<div className="border-bottom">
								<span className="receipt-stat-label">Total Unit Sales </span>
								<span className="pull-right receipt-stat-value"></span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
