import React from 'react';

export default function DisAssembliesTable() {
	return (
		<div className="table-view table-responsive">
			<table id="disassembies-table" className="table">
				<thead>
					<tr>
						<th>Image</th>
						<th>Product Code</th>
						<th>Product Description</th>
						<th>Quantity</th>
						<th>Unit</th>
						<th>Avaiable</th>
						<th>Stock On Hand</th>
						<th>Disassemble Cost</th>
						<th>Serial</th>
						<th>Batch</th>
						<th>Delete</th>
					</tr>
				</thead>
				<tbody></tbody>
			</table>
		</div>
	);
}
