import { settings } from '../../constants/actionTypes';
const initialState = {
	allShippingCompanies: null,
	selectedShippingCompany: null,
};

function shippingCompaniesReducer(state = initialState, action) {
	switch (action.type) {
		case settings.SHIPPING_COMPANIES:
			return { ...state, allShippingCompanies: action.payload };
		case settings.SELECTED_SHIPPING_COMPANY:
			return { ...state, selectedShippingCompany: action.payload };
		default:
			return state;
	}
}
export default shippingCompaniesReducer;
