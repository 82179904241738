import React, { Component } from 'react';
import { goto, initilizeDataTable, destroyDataTable, isEmptyOrNull } from '../../../helpers';
import {
	loaderState,
	getProducts,
	deleteProduct,
	onSelectProduct,
	updateProduct,
	addProduct,
	selectedNav,
} from '../../../actions';
import { connect } from 'react-redux';
import _ from 'lodash';
import $ from 'jquery';
import ViewProductComp from '../../../components/inventory/viewProduct';
import swal from 'sweetalert';

class ViewProduct extends Component {
	constructor(props) {
		super(props);
		this.state = {
			productCode: null,
			productDescription: null,
		};
	}
	componentDidMount() {
		this.props.loaderState(true);
		this.props.getProducts();
		initilizeDataTable('view-product-table');
		// window.$(`#view-product-table`).DataTable({
		// 	ordering: true,
		// 	select: true,
		// 	searching: true,
		// 	// dom: 'Bfrtip',
		// 	// lengthMenu: [[10, 25, 50, -1], ['10 rows', '25 rows', '50 rows', 'Show all']],
		// 	// buttons: ['excel', 'pdf', 'csv', 'print', 'pageLength'], //'copy'
		// });
	}

	componentDidUpdate() {
		initilizeDataTable('view-product-table');
		// window.$(`#view-product-table`).DataTable({
		// 	ordering: true,
		// 	select: true,
		// 	searching: true,
		// 	// dom: 'Bfrtip',
		// 	// lengthMenu: [[10, 25, 50, -1], ['10 rows', '25 rows', '50 rows', 'Show all']],
		// 	// buttons: ['excel', 'pdf', 'csv', 'print', 'pageLength'],
		// });
	}

	onChange = ({ target }) => {
		const { id, type } = target;
		if (type === 'checkbox') {
			this.setState({ [id]: target.checked ? 1 : -1 });
		} else {
			this.setState({ [id]: target.value });
		}
	};

	onClickAction = action => {
		const { selectedProduct } = this.props;

		if (action === 'Delete') {
			this.props.loaderState(true);
			this.props.deleteProduct(selectedProduct.id);
		} else if (action === 'Edit') {
			goto(`/inventory/products/update/${selectedProduct.id}`);
		} else if (action === 'Clone') {
			window.$('#cloneModal').modal();
		} else if (action === 'Obsolete') {
			let data = { obsolete: 1 };
			this.props.loaderState(true);
			this.props.updateProduct(data);
		}
	};

	onProductSelect = product => {
		this.props.onSelectProduct(product);
	};

	productGroupsData = () => {
		const { productGroups } = this.props;
		let data = [];
		_.forEach(productGroups, group => {
			data.push({ value: group.id, label: group.groupName });
		});

		return data;
	};

	handleChange = (newValue, actionMeta) => {
		const { name } = actionMeta;
		if (newValue) {
			this.setState({ [name]: newValue.value });
		} else {
			this.setState({ [name]: newValue });
		}
	};

	gotoProduct = async product => {
		await this.onProductSelect(product);
		await this.props.selectedNav('/inventory/products/add product');
		goto(`/inventory/products/update/${product.id}`);
	};

	onCloneProduct = () => {
		const { productCode, productDescription, barcode, clearSupplierData } = this.state;
		if (isEmptyOrNull(productCode) && isEmptyOrNull(productDescription)) {
			swal('', 'Please provide Product Code and Product Description', 'error');
		} else {
			window.$('.close').click();
			this.props.loaderState(true);
			const { selectedProduct } = this.props;
			let data = { ...selectedProduct };
			delete data.id;
			data.productCode = productCode;
			data.productDescription = productDescription;

			if (!isEmptyOrNull(barcode)) {
				data.barcode = barcode;
			}
			if (!isEmptyOrNull(clearSupplierData)) {
				delete data.supplierCode;
				delete data.supplierName;
				delete data.supplierProductCode;
			}
			this.props.addProduct(data);
		}
	};

	goto = path => {
		this.props.selectedNav(path);
		goto(path);
	};

	render() {
		destroyDataTable('view-product-table');

		const { products, selectedProduct } = this.props;
		let productGroups = this.productGroupsData();

		return (
			<ViewProductComp
				handleChange={(v, m) => this.handleChange(v, m)}
				onProductSelect={product => this.onProductSelect(product)}
				onClickAction={action => this.onClickAction(action)}
				products={products}
				productGroups={productGroups}
				goto={path => this.goto(path)}
				gotoProduct={product => this.gotoProduct(product)}
				selectedProduct={selectedProduct}
				onChange={e => this.onChange(e)}
				onCloneProduct={() => this.onCloneProduct()}
			/>
		);
	}
}

const mapStateToProps = state => {
	return {
		products: state.inventory.products.allProducts,
		selectedProduct: state.inventory.products.selectedProduct,
		productGroups: state.system.productGroups.allGroups,
	};
};

export default connect(mapStateToProps, {
	loaderState,
	getProducts,
	deleteProduct,
	onSelectProduct,
	updateProduct,
	addProduct,
	selectedNav,
})(ViewProduct);
