import React from 'react';

export default function QuantityPriceTable() {
	return (
		<div className="table-view table-responsive">
			<table id="quantity-prices-table" className="table">
				<thead>
					<tr>
						<th>Min Qty</th>
						<th>Valid From</th>
						<th>Valid To</th>
						<th>Price / Discount %</th>
						<th>Price Type</th>
						<th>Discount From</th>
						<th>Show Discount</th>
						<th>Customer Price</th>
						<th>Margin</th>
						<th>
							<i className="fa fa-comments-o" aria-hidden="true" />
						</th>
						<th>Actions</th>
					</tr>
				</thead>
				<tbody></tbody>
			</table>
		</div>
	);
}
