import React from 'react';
import InlineInputForm from '../../common/inlineInputForm';
import InlineDropDown from '../../common/inlineDropDown';
import Select from 'react-select';

export default function Batch({ data, handleChange, priceTiers, onChange, productGroups }) {
	return (
		<div id="Batch" className="tab-pane active">
			<div className="row" id="settings-field">
				<div className="col-md-2 padding-right">
					<div className="form-group">
						<label className="input-label" htmlFor="product-code">
							Product Code
						</label>
						<input className="form-control" id="product-code" />
					</div>
				</div>
				<div className="col-md-2 padding-0">
					<div className="form-group">
						<label className="input-label" htmlFor="product-description">
							Product Description
						</label>
						<input className="form-control" id="product-description" />
					</div>
				</div>
				<div className="col-md-2 padding-0">
					<div className="form-group">
						<label className="input-label" htmlFor="supplier-code">
							Supplier Code
						</label>
						<input className="form-control" id="supplier-code" />
					</div>
				</div>
				<div className="col-md-2 padding-0">
					<div className="form-group">
						<label className="input-label" htmlFor="supplier-name">
							Supplier Name
						</label>
						<input className="form-control" id="supplier-name" />
					</div>
				</div>
			</div>
			<div className="row">
				<div className="col-md-3 padding-right">
					<div className="form-group">
						<label className="input-label" htmlFor="product-groups">
							Product Group
						</label>
						<Select id="product-groups" isClearable onChange={handleChange} options={productGroups} />
					</div>
				</div>
				<div className="col-md-3 padding-0">
					<div className="form-group">
						<label className="input-label" htmlFor="price-tiers">
							Price Tiers
						</label>
						<Select id="price-tiers" isMulti isClearable onChange={handleChange} options={priceTiers} />
					</div>
				</div>
			</div>
			<br />
			<div className="table-view table-responsive">
				<table id="batch-table" className="table">
					<thead>
						<tr>
							<th>Code</th>
							<th>Description</th>
							<th>Purchase Price</th>
							<th>Sell Price</th>
							<th>Tier 1</th>
							<th>Tier 2</th>
							<th>Tier 3</th>
							<th>Tier 4</th>
							<th>Tier 5</th>
							<th>Tier 6</th>
							<th>Tier 7</th>
							<th>Tier 8</th>
							<th>Tier 9</th>
							<th>Tier 10</th>
						</tr>
					</thead>
					<tbody>
						{data
							? data.map((d, index) => {
									return (
										<tr key={index}>
											<td>
												<label className="input-label">
													<a href="#" target="_blank">
														DINNING
													</a>
												</label>
											</td>
											<td>Dining Table</td>
											<td>47.50</td>
											<td>99.99</td>
											<td>0.00</td>
											<td>0.00</td>
											<td>0.00</td>
											<td>0.00</td>
											<td>0.00</td>
											<td>0.00</td>
											<td>0.00</td>
											<td>0.00</td>
											<td>0.00</td>
											<td>0.00</td>
										</tr>
									);
							  })
							: null}
					</tbody>
				</table>
			</div>
		</div>
	);
}
