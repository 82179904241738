import React from 'react';

export default function SalesPersonTable({ allSalesPerson, onSalesPersonSelect }) {
	return (
		<div className="table-view table-responsive" id="setting-tables">
			<table id="sales-person-table" className="table">
				<thead>
					<tr>
						<th>Full Name</th>
						<th>Email</th>
						<th>Obsolete</th>
						<th>Actions</th>
					</tr>
				</thead>
				<tbody>
					{allSalesPerson
						? allSalesPerson.map(person => (
								<tr>
									<td>{person.fullName}</td>
									<td>{person.email}</td>
									<td>
										<div class="checkbox">
											<input
												disabled
												checked={person.obsolete === '1' ? 'checked' : ''}
												type="checkbox"
											/>
										</div>
									</td>
									<td>
										<i
											data-toggle="modal"
											data-target="#confirmationModal"
											className="fa fa-trash-o icon"
											onClick={() => onSalesPersonSelect(person)}
										/>
										<i
											data-toggle="modal"
											data-target="#twoInputEditModal"
											className="fa fa-pencil-square-o icon"
											onClick={() => onSalesPersonSelect(person)}
										/>
									</td>
								</tr>
						  ))
						: null}
				</tbody>
			</table>
		</div>
	);
}
