import React from 'react';
import InlineInputForm from '../../../common/inlineInputForm';
import InlineDropdown from '../../../common/inlineDropDown';
import AddCreditProductTable from './addCreditProductTable';
import Stats from './stats';

export default function AddCredit({ warehouses }) {
	return (
		<>
			<div className="comp-heading">
				<div className="row">
					<div className="col-lg-4">
						<label>Add Credit</label>
					</div>
					<div className="col-lg-3"></div>
					<div className="col-lg-5" style={{ textAlign: 'right' }}>
						<button className="btn btn-light btn-sm">Free Entry</button>
					</div>
				</div>
			</div>
			<br />
			<div className="panel panel-default">
				<div className="panel-body">
					<div className="row" id="inline-input-forms">
						<div className="col-sm-4">
							<InlineInputForm id="sales-invoice-number" value="" name="*Sales Invoice Number" />
							<InlineInputForm disable="disabled" id="customer-code" value="" name="Customer Code" />
							<InlineInputForm disable="disabled" id="customer-name" value="" name="Customer Name" />
							<InlineInputForm disable="disabled" id="email" value="" name="Email" />
						</div>
						<div className="col-sm-4">
							<InlineInputForm
								disable="disabled"
								id="customer-currency"
								value=""
								name="Customer Currency"
							/>
							<InlineInputForm id="exchange-rate" value="" name="Exchange Rate" />
							<InlineInputForm
								disable="disabled"
								id="reference-number"
								value=""
								name="Reference Number"
							/>
							<InlineInputForm disable="disabled" id="credit-status" value="" name="Credit Status" />
						</div>
						<div className="col-sm-4">
							<InlineInputForm disable="disabled" id="credit-number" value="" name="Credit Number" />
							<InlineInputForm id="sales-invoice-date" type="date" value="" name="Sales Invoice Date" />
							<InlineInputForm
								disable="disabled"
								id="required-depivery-date"
								type="date"
								value=""
								name="Required Delivery Date"
							/>
							<InlineInputForm
								disable="disabled"
								id="credit-date"
								type="date"
								value=""
								name="Credit Date"
							/>
							<InlineDropdown id="warehouse" name="Warehouse" values={warehouses} />
							<InlineDropdown
								id="template"
								name="Template"
								values={['Globus Default', 'Default Credit Note']}
							/>
						</div>
					</div>
				</div>
			</div>
			<div className="panel panel-default">
				<div className="panel-body">
					<AddCreditProductTable />
				</div>
			</div>
			<div className="row">
				<div className="col-md-6">
					<div className="panel panel-default">
						<div className="panel-body">
							<div className="table-view table-responsive">
								<table id="sales-quotes-charge-table" className="table">
									<thead>
										<tr>
											<th>Charge</th>
											<th>Price</th>
											<th>Tax Rate</th>
										</tr>
									</thead>
								</table>
							</div>
						</div>
					</div>
				</div>
				<div className="col-md-6">
					<div className="panel panel-default" id="panel-margin-right">
						<div className="panel-body">
							<Stats />
						</div>
					</div>
				</div>
			</div>
			<br />
			<div className="row">
				<div className="col-md-4">
					<label className="input-label" htmlFor="comments">
						Comments
					</label>
					<textarea disabled className="form-control" id="comments" rows="10" />
				</div>
			</div>
		</>
	);
}
