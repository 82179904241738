import React from 'react';

export default function BatchCompleteTable() {
	return (
		<div className="table-view table-responsive">
			<table id="batch-complete-sales-order-table" className="table">
				<thead>
					<tr>
						<th>Order No.</th>
						<th>Order Date</th>
						<th>Required Date </th>
						<th>Customer Code</th>
						<th>Customer Name</th>
						<th>Warehouse</th>
						<th>Status</th>
						<th>Currency</th>
						<th>Cost</th>
						<th>Margin</th>
						<th>Select</th>
						<th>Sub Total</th>
					</tr>
				</thead>
				<tbody></tbody>
			</table>
		</div>
	);
}
