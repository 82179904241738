import api from '../../api/api';
import { success, failed } from '../../constants/messages';
import { settings } from '../../constants/actionTypes';
import { loaderState } from '..';
import _ from 'lodash';
import swal from 'sweetalert';

export const getUnits = () => dispatch => {
	api.get('/unitOfMeasures')
		.then(res => {
			if (res.data.code === 200) {
				dispatch({ type: settings.ALL_UNITS, payload: res.data.data });
			} else {
				swal('', res.data.message, 'error');
			}
			dispatch(loaderState(false));
		})
		.catch(err => {
			swal('', err.message, 'error');
			dispatch(loaderState(false));
		});
};

export const addUnit = ({ unit_name, createdBy, organizationId }) => (dispatch, getState) => {
	var allUnits = getState().system.units.allUnits;
	api.post('/unitOfMeasures', { unit_name, createdBy, updatedBy: createdBy, organizationId })
		.then(res => {
			if (res.data.code === 200) {
				dispatch({ type: settings.ALL_UNITS, payload: [...allUnits, res.data.data] });
			} else {
				swal('', res.data.message, 'error');
			}
			dispatch(loaderState(false));
		})
		.catch(err => {
			swal('', err.message, 'error');
			dispatch(loaderState(false));
		});
};

export const deleteUnit = id => (dispatch, getState) => {
	const { allUnits } = getState().system.units;
	api.delete(`/unitOfMeasures/${id}`)
		.then(res => {
			if (Number(res.data.code) === 200) {
				_.remove(allUnits, unit => {
					return Number(id) == Number(unit.id);
				});
				dispatch(loaderState(false));
			} else {
				swal('', res.data.message, 'error');
				dispatch(loaderState(false));
			}
		})
		.catch(err => {
			swal('', err.message, 'error');
			dispatch(loaderState(false));
		});
};

export const updateUnit = ({ unit_name, id, updatedBy }) => (dispatch, getState) => {
	var allUnits = getState().system.units.allUnits;
	api.post(`/unitOfMeasures/update/${id}`, { unit_name, updatedBy })
		.then(res => {
			if (Number(res.data.code) === 200) {
				let updatedUnit = _.findLast(allUnits, unit => {
					return Number(unit.id) == Number(id);
				});
				updatedUnit.unit_name = unit_name;
				let updatedUnits = _.map(allUnits, unit => {
					return unit.id === id ? updatedUnit : unit;
				});

				dispatch({ type: settings.ALL_UNITS, payload: updatedUnits });
			} else {
				swal('', res.data.message, 'error');
			}
			dispatch(loaderState(false));
		})
		.catch(err => {
			swal('', err.message, 'error');
			dispatch(loaderState(false));
		});
};

export const onUpdateOrDeleteUnit = data => dispatch => {
	dispatch({ type: settings.SELECTED_UNIT, payload: data });
};
