import React from 'react';
import SelectedManus from './selectedManus';

export default function ShortcutsNav({ manus, onChange, SelectedLinks, onClickAddOrRemove }) {
	return (
		<>
			<div className="comp-heading">
				<div className="row">
					<div className="col-lg-4 col-md-7 col-8">
						<label>Customize Shortcuts</label>
					</div>
					{/* <div className="col-lg-5 col-md-2 col-1"></div>
					<div className="col-lg-3 col-md-3 col-3" style={{ textAlign: 'right' }}>
						<button className="btn btn-success btn-sm">Save Shortcuts</button>
					</div> */}
				</div>
			</div>
			<br />
			<div className="row">
				<div className="col-md-6 manus">
					<div className="row" id="settings-field">
						<div className="col-md-4 padding-right">
							<label className="input-label" htmlFor="manu">
								Manu
							</label>
							<select onChange={e => onChange(e)} className="form-control" id="manu">
								<option>All</option>
								<option>Purchases</option>
								<option>Inventory</option>
								<option>Productions</option>
								<option>Sales</option>
								<option>Customers</option>
								<option>Suppliers</option>
								<option>Integration</option>
								<option>Reports</option>
								<option>Settings</option>
							</select>
						</div>
					</div>
					<br />
					<div className="available-manus">
						<div className="manu-items">
							{manus
								? manus.map(manu =>
										manu.name !== 'Dashboard' ? (
											<div key={manu.name}>
												<label className="heading">{manu.name}</label>
												<hr />
												<div className="row">
													{manu.links
														? manu.links.map(link => (
																<div key={link.id} className="col-xs-6">
																	<div className="item">
																		<i className={link.icon} /> {link.name}
																		<div className="quickLinks-dropdown">
																			<i
																				className="fa fa-check"
																				onClick={() =>
																					onClickAddOrRemove(
																						link.id,
																						1,
																						'add'
																					)
																				}
																			/>
																		</div>
																	</div>
																</div>
														  ))
														: null}
												</div>
											</div>
										) : null
								  )
								: null}
						</div>
					</div>
				</div>
				<div className="col-md-6">
					<div className="manu">
						<SelectedManus
							onClickAddOrRemove={(id, status, op) => onClickAddOrRemove(id, status, op)}
							SelectedLinks={SelectedLinks}
						/>
					</div>
				</div>
			</div>
		</>
	);
}
