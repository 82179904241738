import React from 'react';
import SplitButton from '../../../common/splitButton';
import InlineInputForm from '../../../common/inlineInputForm';
import InlineDropdown from '../../../common/inlineDropDown';
import RunReport from '../../../common/runReport';
import ShipmenytEnquiryTable from './shipmenytEnquiryTable';

export default function ShipmentEnquiry() {
	return (
		<>
			<div className="comp-heading">
				<div className="row">
					<div className="col-lg-4">
						<label>Shipment Enquiry</label>
					</div>
					<div className="col-lg-2"></div>
					<div className="col-lg-6" style={{ textAlign: 'right' }}>
						<SplitButton
							label="Export"
							type="light"
							data={['Export as PDF', 'Export as CSV', 'Export as XLSX', 'Export as XLS']}
						/>
						<button className="btn btn-success btn-sm">Run</button>
					</div>
				</div>
			</div>
			<br />
			<div className="panel panel-default">
				<div className="panel-body">
					<div className="row" id="inline-input-forms">
						<div className="col-sm-4">
							<InlineDropdown
								id="transaction-date"
								name="Transaction Date"
								values={['', 'Required Date', 'Dispatched date']}
							/>
							<InlineInputForm id="date-from" type="date" value="" name="Date From" />
							<InlineInputForm id="date-to" type="date" value="" name="Date To" />
						</div>
						<div className="col-sm-4">
							<InlineInputForm id="sales-order-number" value="" name="Sales Order Number" />
							<InlineInputForm id="shipment-number" value="" name="Shipment Number" />
							<InlineDropdown
								id="shipment-status"
								name="Shipment Status"
								values={[
									'Open',
									'Parked',
									'Placed',
									'Picking',
									'Picked',
									'Packed',
									'Dispatched',
									'Deleted',
									'All',
								]}
							/>
							<InlineDropdown id="warehouse" name="Warehouse" values={['Select All', 'Warehouse']} />
							<InlineDropdown id="printed" name="Printed" values={['', 'Yes', 'No']} />
						</div>
						<div className="col-sm-4">
							<InlineDropdown id="shipping-company" name="Shipping Company" values={['']} />
							<InlineInputForm id="tracking-number" value="" name="Tracking Number" />
							<InlineInputForm id="customer-code" value="" name="Customer Code" />
							<InlineInputForm id="customer-name" value="" name="Customer Name" />
							<InlineInputForm id="customer-reference" value="" name="Customer Reference" />
						</div>
					</div>
				</div>
			</div>
			<div className="panel panel-default">
				<div className="panel-body">
					<ShipmenytEnquiryTable />
					<br />
					<RunReport />
				</div>
			</div>
		</>
	);
}
