import React from 'react';
import docDesignerHOC from '../docDesigner';

function CreditNotes() {
	return <div className="doc-designer">CreditNotes Design</div>;
}

const designerHOC = docDesignerHOC(CreditNotes);

export default designerHOC;
