import React from 'react';
import Tabs from '../../common/tabs';
import Details from './details';
import Address from './address';
import Contact from './contact';
import Purchases from './purchases';
import Returns from './returns';
import Constings from './costings';

export default function AddSupplier({ nav, goto }) {
	return (
		<>
			<div className="comp-heading">
				<div className="row">
					<div className="col-lg-4">
						<label>Add Supplier</label>
					</div>
					<div className="col-lg-5 col-md-2 col-1"></div>
					<div className="col-lg-3 col-md-3 col-3" style={{ textAlign: 'right' }}>
						<button
							className="btn btn-light btn-sm"
							onClick={() => goto('/purchases/purchase orders/add spurchase')}
						>
							Add Purchase
						</button>
						<button className="btn btn-success btn-sm">Save</button>
					</div>
				</div>
			</div>
			<br />
			<div className="panel panel-default">
				<div className="panel-body">
					<Tabs nav={nav} />
					<div className="tab-content">
						<Details />
						<Address />
						<Contact />
						<Purchases />
						<Returns />
						<Constings />
					</div>
				</div>
			</div>
		</>
	);
}
