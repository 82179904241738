import React from 'react';

export default function SplitButton({ label, data, type }) {
	return (
		<div className="btn-group">
			<button type="button" className={`btn btn-${type} btn-sm`}>
				{label}
			</button>
			<button type="button" className={`btn btn-${type} btn-sm dropdown-toggle`} data-toggle="dropdown">
				<span className="caret"></span>
			</button>
			<ul className="dropdown-menu dropdown-menu-right" role="menu">
				{data
					? data.map(d => (
							<li key={d}>
								<a href="#" key={d}>
									{d}
								</a>
							</li>
					  ))
					: null}
			</ul>
		</div>
	);
}
