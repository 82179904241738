import React, { Component } from 'react';
import { goto, initilizeDataTable, destroyDataTable } from '../../../helpers';
import StockCountComp from '../../../components/inventory/stockCount';

class StockCount extends Component {
	componentDidMount() {
		initilizeDataTable('stock-count-table');
	}

	componentDidUpdate() {
		initilizeDataTable('stock-count-table');
	}
	render() {
		destroyDataTable('stock-count-table');

		return <StockCountComp goto={path => goto(path)} />;
	}
}

export default StockCount;
