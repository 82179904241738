import React from 'react';
import SalesPersonTable from './salesPersonTable';
import ConfirmationModal from '../../../common/confirmationModal';
import TwoInputEditModal from '../../../common/twoInputEditModal';

export default function SalesPerson({
	fullName,
	email,
	editEmail,
	editFullname,
	obsolete,
	onUpdate,
	onDelete,
	onChange,
	allSalesPerson,
	onSalesPersonSelect,
	onAdd,
	onChangeEdit,
}) {
	return (
		<>
			<div className="comp-heading">
				<div className="row">
					<div className="col-lg-4">
						<label>Sales Person</label>
					</div>
				</div>
			</div>
			<br />
			<div className="panel panel-default">
				<div className="panel-body">
					<div className="row" id="settings-field">
						<div className="col-md-2 padding-right">
							<label className="input-label" htmlFor="sales-person-name">
								* Full Name
							</label>
							<input
								value={fullName}
								onChange={e => onChange(e)}
								name="fullName"
								className="form-control"
								id="sales-person-name"
							/>
						</div>
						<div className="col-md-2 col-xs-9 padding-0">
							<label className="input-label" htmlFor="sales-person-email">
								* Email
							</label>
							<input
								value={email}
								onChange={e => onChange(e)}
								type="email"
								name="email"
								className="form-control"
								id="sales-person-email"
							/>
						</div>
						<div className="col-xs-2 padding-0" id="system-add-btn">
							<button onClick={() => onAdd()} className="btn btn-success btn-sm">
								Add
							</button>
						</div>
					</div>
				</div>
			</div>
			<div className="panel panel-default">
				<div className="panel-body">
					<SalesPersonTable allSalesPerson={allSalesPerson} onSalesPersonSelect={onSalesPersonSelect} />
				</div>
			</div>
			<ConfirmationModal onDelete={() => onDelete()} item="Sales Person" />
			<TwoInputEditModal
				value={editFullname}
				value1={editEmail}
				label="Full Name"
				label1="Email"
				name="editFullname"
				name1="editEmail"
				onChange={e => onChangeEdit(e)}
				onUpdate={() => onUpdate()}
				checkbox={true}
				checkboxLabel="Obsolete"
				checkboxValue={obsolete}
			/>
		</>
	);
}
