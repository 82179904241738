import React from 'react';
import docDesignerHOC from '../docDesigner';

function SupplierReturns() {
	return <div className="doc-designer">SupplierReturns Design</div>;
}

const designerHOC = docDesignerHOC(SupplierReturns);

export default designerHOC;
