import React from 'react';
import AssembliesTable from './assembliesTable';
import ExportButton from '../../common/exportButton';

export default function ViewAssemblies({ goto }) {
	return (
		<>
			<div className="comp-heading">
				<div className="row">
					<div className="col-lg-4">
						<label>View Assemblies</label>
					</div>
					<div className="col-lg-3"></div>
					<div className="col-lg-5" style={{ textAlign: 'right' }}>
						<ExportButton />
						<button
							onClick={() => goto('/productions/assemblies/add disassembliy')}
							className="btn btn-success btn-sm"
						>
							Add DisAssembly
						</button>
						<button
							onClick={() => goto('/productions/assemblies/add assembly')}
							className="btn btn-success btn-sm"
						>
							Add Assembly
						</button>
					</div>
				</div>
			</div>
			<br />
			<div className="panel panel-default">
				<div className="panel-body">
					<div className="row" id="settings-field">
						<div className="col-md-2 padding-right">
							<label className="input-label" htmlFor="status">
								Status
							</label>
							<select className="form-control" id="status">
								<option>All</option>
								<option>Parked</option>
								<option>Completed</option>
							</select>
						</div>
						<div className="col-md-2 padding-0">
							<label className="input-label" htmlFor="assembly_number">
								Assembly Number
							</label>
							<input type="text" className="form-control" id="assembly_number" name="assembly_number" />
						</div>
						<div className="col-md-2 padding-0">
							<label className="input-label" htmlFor="product">
								Product
							</label>
							<input type="text" className="form-control" id="product" name="product" />
						</div>
						<div className="col-md-2 padding-0">
							<label className="input-label" htmlFor="assemble_by_from">
								Assemble By From
							</label>
							<input type="date" className="form-control" id="assemble_by_from" name="assemble_by_from" />
						</div>
						<div className="col-md-2 padding-0">
							<label className="input-label" htmlFor="assembly_type">
								Assembly Type
							</label>
							<select className="form-control" id="assembly_type">
								<option>All</option>
								<option>Assembly</option>
								<option>DisAssembly</option>
							</select>
						</div>
						<div className="col-md-1 padding-0">
							<label className="input-label" htmlFor="auto_assembly">
								Auto Assembly
							</label>
							<select className="form-control" id="auto_assembly">
								<option>All</option>
								<option>Manual</option>
								<option>Auto</option>
							</select>
						</div>
						<div className="col-md-1 padding-0">
							<label className="input-label" htmlFor="printed">
								Printed
							</label>
							<select className="form-control" id="printed">
								<option></option>
								<option>Yes</option>
								<option>No</option>
							</select>
						</div>
					</div>
				</div>
			</div>

			<div className="panel panel-default">
				<div className="panel-body">
					<AssembliesTable />
				</div>
			</div>
		</>
	);
}
