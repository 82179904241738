import React from 'react';

export default function TwoInputEditModal({
	label,
	label1,
	onUpdate,
	value,
	value1,
	onChange,
	checkbox,
	checkboxValue,
	checkboxLabel,
	name,
	name1,
}) {
	return (
		<div className="modal fade" id="twoInputEditModal" role="dialog">
			<div className="modal-dialog">
				<div className="modal-content">
					<div className="modal-header">
						<button type="button" className="close" data-dismiss="modal">
							&times;
						</button>
						<h4 className="modal-title">Update Data</h4>
					</div>
					<div className="modal-body">
						<div className="form-group">
							<label htmlFor="text">{label}</label>
							<input
								name={name}
								type="text"
								value={value}
								onChange={e => onChange(e)}
								className="form-control"
								id={label.toLowerCase().replace(/\s/g, '')}
							/>
						</div>
						<div className="form-group">
							<label htmlFor="text">{label1}</label>
							<input
								name={name1}
								type="text"
								value={value1}
								onChange={e => onChange(e)}
								className="form-control"
								id={label1.toLowerCase().replace(/\s/g, '')}
							/>
						</div>
						{checkbox ? (
							<div className="form-group">
								<label class="checkbox-inline">
									<input
										onChange={e => onChange(e)}
										type="checkbox"
										checked={checkboxValue === '1' ? 'checked' : ''}
									/>
									{checkboxLabel}
								</label>
							</div>
						) : null}
					</div>
					<div className="modal-footer">
						<button
							type="button"
							onClick={() => onUpdate()}
							className="btn btn-success"
							data-dismiss="modal"
						>
							Update
						</button>
						<button type="button" className="btn btn-default" data-dismiss="modal">
							Cancel
						</button>
					</div>
				</div>
			</div>
		</div>
	);
}
