import { settings } from '../../constants/actionTypes';
const initialState = {
	allAdjustments: null,
	selectedReason: null,
};

function adjustmentsReducer(state = initialState, action) {
	switch (action.type) {
		case settings.ADJUSTMENT_REASONS:
			return { ...state, allAdjustments: action.payload };
		case settings.UPDATE_OR_DELETE_ADJUSTMENT_REASON:
			return { ...state, selectedReason: action.payload };
		default:
			return state;
	}
}
export default adjustmentsReducer;
