import React from 'react';
import TableActions from '../../common/tableActions';

export default function CustomersTable() {
	var arr = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
	return (
		<div className="table-view table-responsive">
			<table id="view-customers-table" className="table">
				<thead>
					<tr>
						<th>Customer Code</th>
						<th>Customer Name</th>
						<th>Type</th>
						<th>Currency</th>
						<th>Website</th>
						<th>Phone</th>
						<th>Mobile</th>
						<th>Email Address</th>
						<th>Actions</th>
					</tr>
				</thead>
				<tbody>
					{arr.map(data => (
						<tr key={data}>
							<td>
								<label className="input-label">
									<a href="#">Code {data}</a>
								</label>
							</td>
							<td>
								<label className="input-label">
									<a href="#">Name {data}</a>
								</label>
							</td>
							<td>Type {data}</td>
							<td>Currency {data}</td>
							<td>Webiste {data}</td>
							<td>Phone {data}</td>
							<td>Mobile {data}</td>
							<td>Email Address {data}</td>
							<td>
								<TableActions
									icon="fa fa-cog icon"
									data={[
										'Edit',
										'Add Quote',
										'Add Order',
										'Add Credit',
										'View Transaction',
										'Obsolete',
										'Delete',
									]}
								/>
							</td>
						</tr>
					))}
				</tbody>
			</table>
		</div>
	);
}
