import React, { Component } from 'react';
import AddsupplierComp from '../../../components/suppliers/Add';
import { goto, initilizeDataTable, destroyDataTable } from '../../../helpers';

export default class Addsupplier extends Component {
	constructor(props) {
		super(props);
		this.state = {
			nav: ['Details', 'Address', 'Contact', 'Purchases', 'Returns', 'Constings'],
		};
	}

	componentDidMount() {
		initilizeDataTable('purchases-table');
		initilizeDataTable('returns-table');
		initilizeDataTable('costings-table');
	}

	componentDidUpdate() {
		initilizeDataTable('purchases-table');
		initilizeDataTable('returns-table');
		initilizeDataTable('costings-table');
	}

	render() {
		destroyDataTable('purchases-table');
		destroyDataTable('returns-table');
		destroyDataTable('costings-table');

		const { nav } = this.state;
		return <AddsupplierComp nav={nav} goto={path => goto(path)} />;
	}
}
