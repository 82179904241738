import { settings } from '../../constants/actionTypes';
const initialState = {
	allCustomerTypes: null,
	selectedCustomerTypes: null,
};

function customerTypesReducer(state = initialState, action) {
	switch (action.type) {
		case settings.CUSTOMER_TYPES:
			return { ...state, allCustomerTypes: action.payload };
		case settings.SELECTED_CUSTOMER_TYPE:
			return { ...state, selectedCustomerTypes: action.payload };
		default:
			return state;
	}
}
export default customerTypesReducer;
