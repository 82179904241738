import React from 'react';

export default function ProductEnquiryTable() {
	return (
		<div className="table-view table-responsive">
			<table id="sales-quotes-charge-table" className="table">
				<thead>
					<tr>
						<th>Assembly Number</th>
						<th>Assembly Date</th>
						<th>Assemble By</th>
						<th>Product Code</th>
						<th>Product Description</th>
						<th>Product Group</th>
						<th>Warehouse</th>
						<th>Qty</th>
						<th>Total Cost</th>
					</tr>
				</thead>
				<tbody></tbody>
			</table>
		</div>
	);
}
