import React from 'react';

export default function CreateStockCountTable() {
	return (
		<div className="table-view table-responsive">
			<table id="create-stock-count-table" className="table">
				<thead>
					<tr>
						<th>Line</th>
						<th>Product Code</th>
						<th>Product Description</th>
						<th>Bin Location</th>
						<th>Unit Of Measure</th>
						<th>Pack Size</th>
						<th>Count Quantity</th>
						<th>Delete</th>
					</tr>
				</thead>
				<tbody></tbody>
			</table>
		</div>
	);
}
