import React from 'react';

export default function Purchase() {
	return (
		<div id="Purchase" className="tab-pane fade">
			<form>
				<div className="panel panel-default">
					<div className="panel-body">
						<div className="">
							<div className="row" id="settings-field">
								<div className="col-md-2 padding-right">
									<label className="input-label" htmlFor="purchase-price">
										Default Purchase Price
									</label>
									<input
										type="text"
										className="form-control"
										id="purchase-price"
										name="purchase-price"
									/>
								</div>
								<div className="col-md-2 padding-0">
									<label className="input-label" htmlFor="purchase-tax-rate">
										<a href="#" target="_blank">
											Purchase Tax Rate
										</a>
									</label>
									<select className="form-control" id="purchase-tax-rate">
										<option></option>
										<option>No Tax (0%)</option>
									</select>
								</div>
								<div className="col-md-2 padding-0">
									<label className="input-label" htmlFor="minimum-order-quantity">
										Minimum Order Quantity
									</label>
									<input
										type="text"
										className="form-control"
										id="minimum-order-quantity"
										name="minimum-order-quantity"
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="panel panel-default">
					<div className="panel-body">
						<h6>Supplier</h6>
						<hr className="purchase-hr" />
						<div className="row" id="settings-field">
							<div className="col-md-2 padding-right">
								<label className="input-label" htmlFor="supplier-code">
									<a href="#" target="_blank">
										Supplier Code
									</a>
								</label>
								<input
									disabled
									type="text"
									className="form-control"
									id="supplier-code"
									name="supplier-code"
								/>
							</div>
							<div className="col-md-2 padding-0">
								<label className="input-label" htmlFor="supplier-name">
									<a href="#" target="_blank">
										Supplier Name
									</a>
								</label>
								<input
									disabled
									type="text"
									className="form-control"
									id="supplier-name"
									name="supplier-name"
								/>
							</div>
							<div className="col-md-2 padding-0">
								<label className="input-label" htmlFor="supplier-product-code">
									Supplier Product Code
								</label>
								<input
									disabled
									type="text"
									className="form-control"
									id="supplier-product-code"
									name="supplier-product-code"
								/>
							</div>
							<div className="col-md-2 padding-0">
								<label className="input-label" htmlFor="supplier-product-description">
									Supplier Product Description
								</label>
								<input
									disabled
									type="text"
									className="form-control"
									id="supplier-product-description"
									name="supplier-product-description"
								/>
							</div>
							<div className="col-md-2 padding-0">
								<label className="input-label" htmlFor="supplier-product-description">
									Purchase Price
								</label>
								<input
									disabled
									type="text"
									className="form-control"
									id="supplier-product-description"
									name="supplier-product-description"
								/>
							</div>
							<div className="col-md-1 padding-0">
								<label className="input-label" htmlFor="min-order-qty">
									Min. Order Quantity
								</label>
								<input
									disabled
									type="text"
									className="form-control"
									id="min-order-qty"
									name="min-order-qty"
								/>
							</div>
							<div className="col-md-1 padding-0" id="add-supplier-btn">
								<label className="input-label">Add button</label>
								<button type="button" className="btn btn-success btn-sm">
									Add
								</button>
							</div>
						</div>
					</div>
				</div>
			</form>
			<div className="panel panel-default">
				<div className="panel-body">
					<div className="table-view table-responsive">
						<table id="purchases-table" className="table">
							<thead>
								<tr>
									<th>Default</th>
									<th>Supplier Code</th>
									<th>Supplier Name</th>
									<th>Supplier Product Code</th>
									<th>Supplier Product Description</th>
									<th>Purchase Price</th>
									<th>Min. Order Currency</th>
									<th>Supplier Currency</th>
									<th>Lead Time (days)</th>
									<th>Action</th>
								</tr>
							</thead>
							<tbody></tbody>
						</table>
						<small>Please Save your Product before adding any Suppliers</small>
					</div>
				</div>
			</div>
		</div>
	);
}
