import React, { Component } from 'react';
import { connect } from 'react-redux';
import { loaderState, getWarehouses } from '../../../actions';
import {
	initilizeDataTable,
	destroyDataTable,
	isEmptyOrNull,
	removeNullValues,
	goto,
	warehousesData,
} from '../../../helpers';
import CreateStockCountComp from '../../../components/inventory/stockCount/createStockCount';

class CreateStockCount extends Component {
	componentDidMount() {
		const { warehouses } = this.props;
		if (warehouses.length === 0) {
			this.props.loaderState(true);
			this.props.getWarehouses();
		}
		initilizeDataTable('create-stock-count-table');
	}

	componentDidUpdate() {
		initilizeDataTable('create-stock-count-table');
	}

	render() {
		destroyDataTable('create-stock-count-table');

		const { warehouses } = this.props;
		return <CreateStockCountComp goto={path => goto(path)} warehouses={warehousesData(warehouses)} />;
	}
}

const mapStateToProps = state => {
	return {
		user: state.users.loggedInUser,
		warehouses: state.system.warehouses.allWarehouses,
	};
};

export default connect(mapStateToProps, { loaderState, getWarehouses })(CreateStockCount);
