import React from 'react';

export default function TableActions({ data, onClick, icon }) {
	return (
		// <div className="dropdown" id="table-action-dropdown">
		// 	<i style={{ fontSize: '1.2em' }} className={`${icon} dropdown-toggle`} data-toggle="dropdown" />
		// 	<div className="dropdown-menu dropdown-menu-right">
		// 		{data
		// 			? data.map(d => (
		// 					<a className="dropdown-item" key={d} onClick={() => onClick(d)}>
		// 						<span>{d}</span>
		// 					</a>
		// 					// <a key={d} onClick={() => onClick(d)}>
		// 					// 	{d}
		// 					// </a>
		// 			  ))
		// 			: null}
		// 	</div>
		// </div>
		<div className="my-dropdown">
			<i style={{ fontSize: '1.2em' }} className={icon} />
			<div className="my-dropdown-content">
				{data
					? data.map(d => (
							<a key={d} onClick={() => onClick(d)}>
								{d}
							</a>
					  ))
					: null}
			</div>
		</div>
	);
}
