import React from 'react';
import ShipmentsTable from './shipmentsTable';

export default function ViewShipments({ warehouses }) {
	return (
		<>
			<div className="comp-heading">
				<div className="row">
					<div className="col-lg-4">
						<label>View Shipments</label>
					</div>
				</div>
			</div>
			<br />
			<div className="panel panel-default">
				<div className="panel-body">
					<div className="row" id="settings-field">
						<div className="col-md-2 padding-right">
							<label className="input-label" htmlFor="status">
								Status
							</label>
							<select className="form-control" id="status">
								<option>All</option>
								<option>Open</option>
								<option>Parked</option>
								<option>Placed</option>
								<option>Picking</option>
								<option>Picked</option>
								<option>Packed</option>
								<option>Dispatched</option>
								<option>Deleted</option>
							</select>
						</div>
						<div className="col-md-2 padding-0">
							<label className="input-label" htmlFor="shipment-number">
								Shipment Number
							</label>
							<input type="text" className="form-control" id="shipment-number" name="shipment-number" />
						</div>
						<div className="col-md-2 padding-0">
							<label className="input-label" htmlFor="customer">
								Customer
							</label>
							<input type="text" className="form-control" id="customer" name="customer" />
						</div>
						<div className="col-md-2 padding-0">
							<label className="input-label" htmlFor="warehouse">
								Warehouse
							</label>
							<select className="form-control" id="warehouse">
								<option></option>
								{warehouses
									? warehouses.map(warehouse => <option key={warehouse}>{warehouse}</option>)
									: null}
							</select>
						</div>
						<div className="col-md-2 padding-0">
							<label className="input-label" htmlFor="transaction-date">
								Trasaction Date
							</label>
							<select className="form-control" id="transaction-date">
								<option></option>
								<option>Required Date</option>
								<option>Dispatch Date</option>
							</select>
						</div>
						<div className="col-md-2 padding-0">
							<label className="input-label" htmlFor="date-range">
								Date Range
							</label>
							<input type="date" className="form-control" id="date-range" name="date-range" />
						</div>
					</div>
					<div className="row" id="settings-field">
						<div className="col-md-2 padding-right">
							<label className="input-label" htmlFor="customer-reference">
								Customer Reference
							</label>
							<input
								type="text"
								className="form-control"
								id="customer-reference"
								name="customer-reference"
							/>
						</div>
						<div className="col-md-2 padding-0">
							<label className="input-label" htmlFor="sales-order-number">
								Sales Order Number
							</label>
							<input
								type="text"
								className="form-control"
								id="sales-order-number"
								name="sales-order-number"
							/>
						</div>
						<div className="col-md-2 padding-0">
							<label className="input-label" htmlFor="shipping-company">
								Shipping Company
							</label>
							<select className="form-control" id="shipping-company">
								<option></option>
							</select>
						</div>
						<div className="col-md-2 padding-0">
							<label className="input-label" htmlFor="tracking-number">
								Tracking Number
							</label>
							<input type="text" className="form-control" id="tracking-number" name="tracking-number" />
						</div>
						<div className="col-md-2 padding-0">
							<label className="input-label" htmlFor="printed">
								Printed
							</label>
							<select className="form-control" id="printed">
								<option></option>
								<option>Yes</option>
								<option>No</option>
							</select>
						</div>
					</div>
				</div>
			</div>
			<div className="panel panel-default">
				<div className="panel-body">
					<ShipmentsTable />
				</div>
			</div>
		</>
	);
}
