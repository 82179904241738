import React from 'react';
import OrdersTable from './ordersTable';

export default function Orders() {
	return (
		<div id="Orders" className="tab-pane fade">
			<div className="row" id="settings-field">
				<div className="col-md-2 padding-right">
					<label className="input-label" htmlFor="order_status">
						Order Status
					</label>
					<select className="form-control" id="order_status">
						<option>Open</option>
						<option>Parked</option>
						<option>Accepted</option>
						<option>All</option>
					</select>
				</div>
				<div className="col-md-2 padding-0">
					<label className="input-label" htmlFor="warehouse">
						Warehouse
					</label>
					<select className="form-control" id="warehouse">
						<option></option>
						<option>Head Office</option>
						<option>Main Warehouse</option>
					</select>
				</div>
			</div>
			<br />
			{/* <div className="panel panel-default">
				<div className="panel-body"> */}
			<OrdersTable />
			{/* </div>
			</div> */}
		</div>
	);
}
