import React, { Component } from 'react';
import { userSignIn, loaderState } from '../../actions';
import { connect } from 'react-redux';
import swal from 'sweetalert';
import { isEmptyOrNull, goto } from '../../helpers';
import LoginComp from '../../components/registration/login';

class Login extends Component {
	constructor(props) {
		super(props);
		this.state = {
			email: '',
			password: '',
		};
	}

	onChange = ({ target }) => {
		const { id, value } = target;
		this.setState({ [id]: value });
	};

	onLogin = () => {
		const { email, password } = this.state;
		if (isEmptyOrNull(email) && isEmptyOrNull(password)) {
			swal('', 'username and password are empty!', 'info');
		} else if (isEmptyOrNull(password)) {
			swal('", "password is empty', 'info');
		} else if (isEmptyOrNull(email)) {
			swal('', 'username are empty', 'info');
		} else {
			this.props.loaderState(true);
			this.props.userSignIn({ email, password });
		}
	};

	render() {
		const { email, password } = this.state;
		return (
			<div>
				<LoginComp
					email={email}
					password={password}
					onChange={e => this.onChange(e)}
					onLogin={() => this.onLogin()}
					goto={path => goto(path)}
				/>
			</div>
		);
	}
}

export default connect(null, { userSignIn, loaderState })(Login);
