import React from 'react';
import CustomerTypesTable from './customerTypesTable';
import ConfirmationModal from '../../../common/confirmationModal';
import OneInputEditModal from '../../../common/OneInputEditModal';

export default function CustomerTypes({
	addValue,
	editValue,
	onChange,
	onUpdate,
	types,
	onUpdateOrDelete,
	onChangeEdit,
	onDelete,
	onAdd,
}) {
	return (
		<>
			<div className="comp-heading">
				<div className="row">
					<div className="col-lg-4">
						<label>Customer Type</label>
					</div>
				</div>
			</div>
			<br />
			<div className="panel panel-default">
				<div className="panel-body">
					<div className="row" id="settings-field">
						<div className="col-md-2 col-xs-9">
							<label className="input-label" htmlFor="customer-type">
								* Customer Type
							</label>
							<input
								value={addValue}
								onChange={e => onChange(e)}
								className="form-control"
								id="customer-type"
							/>
						</div>
						<div className="col-xs-2" id="system-add-btn">
							<button onClick={() => onAdd()} className="btn btn-success btn-sm">
								Add
							</button>
						</div>
					</div>
				</div>
			</div>
			<div className="panel panel-default">
				<div className="panel-body">
					<CustomerTypesTable onUpdateOrDelete={data => onUpdateOrDelete(data)} types={types} />
				</div>
			</div>
			<ConfirmationModal onDelete={() => onDelete()} item={editValue + ' Customer Type'} />
			<OneInputEditModal
				label="Customer Type"
				value={editValue}
				onChange={e => onChangeEdit(e)}
				onUpdate={() => onUpdate()}
			/>
		</>
	);
}
