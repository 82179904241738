import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { getWarehouses, loaderState } from '../../../actions';
import { initilizeDataTable, destroyDataTable, warehousesData } from '../../../helpers';
import AddAssemblyComp from '../../../components/production/addAssemblies';

class AddAssembly extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	componentDidMount() {
		const { warehouses } = this.props;
		if (warehouses.length === 0) {
			this.props.loaderState(true);
			this.props.getWarehouses();
		}
		initilizeDataTable('assemblies-table');
	}

	componentDidUpdate() {
		initilizeDataTable('assemblies-table');
	}

	render() {
		destroyDataTable('assemblies-table');

		const { warehouses } = this.props;
		return <AddAssemblyComp warehouses={warehousesData(warehouses)} />;
	}
}

const mapStateToProps = state => {
	return {
		warehouses: state.system.warehouses.allWarehouses,
	};
};

export default connect(mapStateToProps, { loaderState, getWarehouses })(AddAssembly);
