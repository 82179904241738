import React, { Component } from 'react';
import { connect } from 'react-redux';
import { selectedNav, loaderState } from '../../actions';
import { goto } from '../../helpers';
import SidebarComp from '../../components/common/sidebar';
import data from '../../data';

class Sidebar extends Component {
	onNavClick = async nav => {
		await this.props.selectedNav(nav);
		goto(nav);
	};

	render() {
		const { activeNav } = this.props;
		return (
			<div>
				<SidebarComp manus={data.sideManu} onNavClick={nav => this.onNavClick(nav)} nav={activeNav} />
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		isLoading: state.common.isLoading,
		activeNav: state.common.url,
	};
};

export default connect(mapStateToProps, { selectedNav, loaderState })(Sidebar);
