import React from 'react';

export default function TaxTable({ taxes, onTaxSelect }) {
	return (
		<div className="table-view table-responsive" id="setting-tables">
			<table id="tax-table" className="table">
				<thead>
					<tr>
						<th>Tax Description/Name</th>
						<th>Tax Code</th>
						<th>Tax Rate</th>
						<th>Sales Tax</th>
						<th>Purchase Tax</th>
						<th>Obsolete</th>
						<th>Actions</th>
					</tr>
				</thead>
				<tbody>
					{taxes
						? taxes.map(tax => (
								<tr key={tax.id}>
									<td>{tax.taxName}</td>
									<td>{tax.taxCode}</td>
									<td>{tax.taxRate}</td>
									<td>
										<input
											disabled
											checked={Number(tax.salesTax) === 1 ? 'checked' : ''}
											type="checkbox"
										/>
									</td>
									<td>
										<input
											disabled
											checked={Number(tax.purchaseTax) === 1 ? 'checked' : ''}
											type="checkbox"
										/>
									</td>
									<td>
										<input
											disabled
											checked={Number(tax.obsolete) === 1 ? 'checked' : ''}
											type="checkbox"
										/>
									</td>
									<td>
										<i
											data-toggle="modal"
											data-target="#confirmationModal"
											className="fa fa-trash-o icon"
											onClick={() => onTaxSelect(tax)}
										/>
										<i
											data-toggle="modal"
											data-target="#taxEditModal"
											className="fa fa-pencil-square-o icon"
											onClick={() => onTaxSelect(tax)}
										/>
									</td>
								</tr>
						  ))
						: null}
				</tbody>
			</table>
		</div>
	);
}
