import React from 'react';
import TableActions from '../../../common/tableActions';

export default function ShipmentsTable() {
	return (
		<div className="table-view table-responsive">
			<table id="view-shipments-table" className="table">
				<thead>
					<tr>
						{/* <th>
							<i style={{ fontSize: '1.5em' }} className="fa fa-cog icon" />
						</th> */}
						<th>Shipment No.</th>
						<th>Sales Order No.</th>
						<th>Required Date </th>
						<th>Dispatch Date</th>
						<th>Status</th>
						<th>Warehouse Name</th>
						<th>Shipping Company</th>
						<th>Tracking Number</th>
						<th>Customer Code</th>
						<th>Customer Name</th>
						<th>Customer Ref</th>
						<th>Action</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>asd</td>
						<td>asd</td>
						<td>asd</td>
						<td>asd</td>
						<td>asd</td>
						<td>asd</td>
						<td>asd</td>
						<td>asd</td>
						<td>asd</td>
						<td>asd</td>
						<td>asd</td>
						<td>
							<TableActions
								icon="fa fa-cog icon"
								data={[
									'Edit',
									'Print Picklist',
									'Print Ship Note',
									'Email Picklist',
									'Email Ship Note',
									'Create Invoice',
									'Dispatch',
									'Delete',
								]}
							/>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	);
}
