import React from 'react';
import UploadFiles from '../../../common/uploadFiles';
import ImagesTable from './imagesTable';

export default function Images() {
	return (
		<div id="Images" className="tab-pane fade">
			<UploadFiles
				id="Organization Images"
				image="size limit is 250KB Supported image formats are: jpg, jpeg, png, gif, bmp."
				noBtn={true}
			/>
			<ImagesTable />
		</div>
	);
}
