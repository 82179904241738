import api from '../../api/api';
import { success, failed } from '../../constants/messages';
import { settings } from '../../constants/actionTypes';
import { loaderState } from '..';
import _ from 'lodash';
import swal from 'sweetalert';

export const getRolls = () => dispatch => {
	api.get('/roles')
		.then(res => {
			if (res.data.code === 200) {
				dispatch({ type: settings.ROLES, payload: res.data.data });
			} else {
				swal('', res.data.message, 'error');
			}
			dispatch(loaderState(false));
		})
		.catch(err => {
			swal('', err.message, 'error');
			dispatch(loaderState(false));
		});
};

export const addRole = ({ roleName, createdBy }) => (dispatch, getState) => {
	var roles = getState().system.roles.allRoles;
	api.post('/roles', { roleName, createdBy, updatedBy: createdBy })
		.then(res => {
			if (res.data.code === 200) {
				dispatch({ type: settings.ROLES, payload: [...roles, res.data.data] });
			} else {
				swal('', res.data.message, 'error');
			}
			dispatch(loaderState(false));
		})
		.catch(err => {
			swal('', err.message, 'error');
			dispatch(loaderState(false));
		});
};

export const deleteRole = id => (dispatch, getState) => {
	const { allRoles } = getState().system.roles;
	api.delete(`/roles/${id}`)
		.then(res => {
			if (Number(res.data.code) === 200) {
				_.remove(allRoles, role => {
					return Number(id) == Number(role.id);
				});
				dispatch(loaderState(false));
			} else {
				swal('', res.data.message, 'error');
				dispatch(loaderState(false));
			}
		})
		.catch(err => {
			swal('', err.message, 'error');
			dispatch(loaderState(false));
		});
};

export const updateRole = ({ roleName, id, updatedBy }) => (dispatch, getState) => {
	var roles = getState().system.roles.allRoles;
	api.post(`/roles/update/${id}`, { roleName, updatedBy })
		.then(res => {
			if (Number(res.data.code) === 200) {
				let updatedRole = _.findLast(roles, role => {
					return Number(role.id) == Number(id);
				});
				updatedRole.roleName = roleName;
				let updatedRoles = _.map(roles, role => {
					return role.id === id ? updatedRole : role;
				});

				dispatch({ type: settings.ROLES, payload: updatedRoles });
				dispatch(loaderState(false));
			} else {
				swal('', res.data.message, 'error');
				dispatch(loaderState(false));
			}
		})
		.catch(err => {
			swal('', err.message, 'error');
			dispatch(loaderState(false));
		});
};

export const onUpdateOrDeleteRole = data => dispatch => {
	dispatch({ type: settings.SELECTED_ROLE, payload: data });
};
