import React, { Component } from 'react';
import CreditNotesComp from '../../../../components/setting/docDesigner/creditNotes/index';

export default class CreditNotes extends Component {
	render() {
		return <CreditNotesComp />;
	}
	state = {
		templates: ['Default Credit Note'],
	};
	render() {
		const { templates } = this.state;
		return <CreditNotesComp templates={templates} name="Credit Note" />;
	}
}
