import React from 'react';
import Select from 'react-select';

export default function Company({ goto, onChange, onCreateAccount, timezones, handleChange, currencies, industries }) {
	return (
		<div className="container register">
			<div className="row">
				<div className="col-md-3 register-left">
					<h3>Welcome To</h3>
					{/* <img src="images/logo.png" alt="" />
			<h2>Globuss</h2> */}
					<a onClick={() => goto('/')}>already have an account</a>
					<br />
					<a onClick={() => goto('/signup')}>go back</a>
					<br />
				</div>
				<div className="col-md-9 register-right">
					<div className="tab-content" id="myTabContent">
						<div className="tab-pane show active" id="home" aria-labelledby="home-tab">
							<h3 className="register-heading">Organization Infomation</h3>
							<div className="row register-form">
								<div className="col-md-6">
									<div className="form-group">
										<input
											onChange={e => onChange(e)}
											type="text"
											id="companyName"
											className="form-control"
											placeholder="Company Name"
										/>
									</div>
									<div className="form-group">
										<input
											style={{ marginTop: '-5px' }}
											onChange={e => onChange(e)}
											type="text"
											id="tradingName"
											className="form-control"
											placeholder="Trading Name"
										/>
									</div>
								</div>
								<div className="col-md-6">
									<div className="form-group">
										<Select
											placeholder="Industry"
											isClearable
											options={industries}
											name="Industry"
											onChange={handleChange}
										/>
									</div>
									<div className="form-group">
										<Select
											placeholder="Timezone"
											isClearable
											options={timezones}
											name="timezone"
											onChange={handleChange}
										/>
									</div>
								</div>
								<div className="col-md-12">
									<div className="form-group">
										<Select
											placeholder="Base Currency"
											isClearable
											options={currencies}
											name="baseCurrency"
											onChange={handleChange}
										/>
									</div>
								</div>

								<input
									onClick={() => onCreateAccount()}
									type="submit"
									className="btnLogin"
									value="Register"
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
