import React from 'react';
import docDesignerHOC from '../docDesigner';

function PickList() {
	return <div className="doc-designer">PickList Design</div>;
}

const designerHOC = docDesignerHOC(PickList);

export default designerHOC;
