import React from 'react';

export default function UploadFiles({ id, onFileDivClick, image, noBtn }) {
	return (
		<div className="uploadFiles">
			<br />
			<div className="file-drop-area" id={id.replace(/\s/g, '')} onClick={() => onFileDivClick()}>
				<input id={`inputFile${id.replace(/\s/g, '')}`} type="file" />
				<i className="fa fa-cloud-upload" aria-hidden="true"></i>
				<p className="file-name">click here to upload file.</p>
			</div>
			<br />
			<div className="attachments-upload">
				<p className="help center-align" style={{ marginBottom: '20px' }}>
					{image ? (
						<>
							<i>
								<a href="#" id="uploadFileLink">
									Upload Image
								</a>
								{image}
							</i>
							<br />
							<br />
						</>
					) : (
						<>
							<i>
								The file you upload must be a
								<a href="#" id="uploadFileLink">
									valid
								</a>
								Excel file in .CSV format
							</i>
							<br />
							<br />
							<label>All imports must be uploaded using the correct template file.</label>{' '}
						</>
					)}
					{noBtn ? null : (
						<>
							<br />
							<button className="btn btn-light">Download {id} Template File</button>
						</>
					)}
				</p>
			</div>
		</div>
	);
}
