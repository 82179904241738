import React from 'react';

export default function ExportBillOfMaterialProduction() {
	return (
		<>
			<div className="comp-heading">
				<div className="row">
					<div className="col-lg-4">
						<label>Export Bill Of Material </label>
					</div>
				</div>
			</div>
			<br />
			exportBillOfMaterialProduction
		</>
	);
}
