import React, { Component } from 'react';
import { initilizeDataTable, destroyDataTable } from '../../../../helpers';
import BatchCompleteSalesOrderComp from '../../../../components/sales/order/batchComplete';

export default class BatchCompleteSalesOrder extends Component {
	componentDidMount() {
		initilizeDataTable('batch-complete-sales-order-table');
	}

	componentDidUpdate() {
		initilizeDataTable('batch-complete-sales-order-table');
	}

	render() {
		destroyDataTable('batch-complete-sales-order-table');
		return <BatchCompleteSalesOrderComp />;
	}
}
