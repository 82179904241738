import React from 'react';
import ExportBtn from '../../common/exportButton';
import SuppplierTable from './supppliersTable';

export default function ViewSuppliers({ goto }) {
	return (
		<>
			<div className="comp-heading">
				<div className="row">
					<div className="col-lg-4">
						<label>View Suppliers</label>
					</div>
					<div className="col-lg-3 col-1"></div>
					<div className="col-lg-5 col-md-5 col-3" style={{ textAlign: 'right' }}>
						<button className="btn btn-light btn-sm">Import</button>
						<ExportBtn />
						<button onClick={() => goto('/suppliers/add suppliers')} className="btn btn-success btn-sm">
							Add Supplier
						</button>
					</div>
				</div>
			</div>
			<br />
			<div className="panel panel-default">
				<div className="panel-body">
					<div className="row" id="settings-field">
						<div className="col-md-2 padding-right">
							<label className="input-label" htmlFor="supplier">
								Supplier
							</label>
							<input className="form-control" id="supplier" />
						</div>
						<div className="col-md-2 padding-0">
							<label className="input-label" htmlFor="obsolete">
								Obsolete
							</label>
							<select className="form-control" id="obsolete">
								<option>All</option>
								<option>Yes</option>
								<option>No</option>
							</select>
						</div>
					</div>
				</div>
			</div>
			<div className="panel panel-default">
				<div className="panel-body">
					<SuppplierTable />
				</div>
			</div>
		</>
	);
}
