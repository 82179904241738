import React from 'react';

export default function Single() {
	return (
		<div id="Single" className="tab-pane active">
			<div className="panel panel-default">
				<div className="panel-body">
					<div className="row" id="settings-field">
						<div className="col-sm-2 padding-right">
							<label className="input-label" htmlFor="customer">
								*Customer
							</label>
							<input type="text" className="form-control" id="customer" name="customer" />
						</div>
						<div className="col-sm-2 padding-0">
							<label className="input-label" htmlFor="new-code">
								*New Customer Code
							</label>
							<input type="text" className="form-control" id="new-code" name="new-code" />
						</div>
						<div className="col-lg-3 col-md-3 col-sm-3 col-2 padding-0" id="system-add-btn">
							<button className="btn btn-success btn-sm">Save</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
