import React, { Component } from 'react';
import { connect } from 'react-redux';
import { goto, initilizeDataTable, destroyDataTable } from '../../../helpers';
import ViewAssemblyComp from '../../../components/production/viewAssembly';

class ViewAssembly extends Component {
	componentDidMount() {
		initilizeDataTable('view-assemblies-table');
	}
	componentDidUpdate() {
		initilizeDataTable('view-assemblies-table');
	}
	render() {
		destroyDataTable('view-assemblies-table');
		return <ViewAssemblyComp goto={path => goto(path)} />;
	}
}

export default connect(null, {})(ViewAssembly);
