import React from 'react';
import Tabs from '../../common/tabs';
import Batch from './batch';
import Log from './log';
export default function MarkupPriceUpdate({ nav, data, priceTiers }) {
	return (
		<>
			<div className="comp-heading">
				<div className="row">
					<div className="col-lg-4 col-md-7 col-8">
						<label>Markup Price Update</label>
					</div>
					<div className="col-lg-5 col-md-2 col-1"></div>
					<div className="col-lg-3 col-md-3 col-3" style={{ textAlign: 'right' }}>
						<button className="btn btn-default btn-sm">Import</button>
						<button className="btn btn-default btn-sm">Export</button>
						<button className="btn btn-success btn-sm">Update</button>
					</div>
				</div>
			</div>
			<br />
			<div className="panel panel-default">
				<div className="panel-body">
					<Tabs nav={nav} />
					<br />
					<div className="tab-content">
						<Batch priceTiers={priceTiers} data={data} />
						<Log />
					</div>
				</div>
			</div>
		</>
	);
}
