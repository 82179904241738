import React from 'react';
import InlineInputForm from '../../common/inlineInputForm';
import InlineDropdown from '../../common/inlineDropDown';
import InLineCheckbox from '../../common/inLineCheckbox';
import Switch from '../../common/switch';

export default function Details() {
	return (
		<div id="Details" className="tab-pane active">
			<div className="row" id="inline-input-forms">
				<div className="col-sm-4">
					<InlineInputForm id="customer-code" value="" name="*Cutomer Code" />
					<InlineInputForm id="customer-name" value="" name="*Cutomer Name" />
					<InlineDropdown
						id="customer-type"
						name="Customer Type"
						values={['', 'Cash', 'General', 'OnAccount', 'WholeSale']}
					/>
					<InlineDropdown
						id="sales-person"
						name="Sales Person"
						values={['', 'John Smith:john.smith@gmail.com']}
					/>
					<InlineDropdown
						id="sales-order-group"
						name="Sales Order Group"
						values={['', 'Website', 'Catalogue', 'Shop']}
					/>
					<InlineDropdown id="default-delivery-address" name="Default Delivery Address" values={['']} />
					<InlineDropdown id="delivery-method" name="Delivery Method" values={['', 'Delivery', 'Pickup']} />
					<InlineDropdown
						id="default-warehouse"
						name="Default Warehouse"
						values={['', 'Head Office', 'Main Warehouse']}
					/>
					<div className="form-inline">
						<div className="form-group">
							<label htmlFor="notes">Notes</label>
							<textarea rows="7" type="text" className="form-control" id="notes" />
						</div>
					</div>
					<Switch id="obsolete" name="Obsolete" value="" margin="yes" />
				</div>
				<div className="col-sm-4">
					<InlineInputForm id="gst-vat-number" value="" name="GST/VAT Number" />
					<InlineDropdown id="default-currency" name="Default Currency" values={['', 'PKR']} />
					<Switch id="taxable" name="Taxable" value="" margin="yes" />
					<InlineDropdown
						disable="disabled"
						id="tax-rate"
						name="Tax Rate"
						values={['', 'Head Office', 'Main Warehouse']}
					/>
					<InlineInputForm id="bank-account-name" value="" name="Bank Account Name" />
					<InlineInputForm id="back-account-number" value="" name="Bank Account Number" />
					<InlineDropdown
						id="payment-terms"
						name="Payment Terms"
						values={[
							'',
							'120 Days',
							'14 Days',
							'1st of the month following',
							'20th months following',
							'21 Days',
						]}
					/>
					<InlineDropdown
						id="sell-price-tier"
						name="Sell Price Tier"
						values={[
							'',
							'Sell Price Tier 1',
							'Sell Price Tier 2',
							'Sell Price Tier 3',
							'Sell Price Tier 4',
							'Sell Price Tier 5',
						]}
					/>
					<InlineInputForm id="discount" value="0.00" name="Discount (%)" />
					<div className="form-inline">
						<div className="form-group">
							<label htmlFor="reminder">Reminder</label>
							<textarea rows="7" type="text" className="form-control" id="reminder" />
						</div>
					</div>
				</div>
				<div className="col-sm-4">
					<InLineCheckbox
						id="price-quote-accepted-on-sale"
						name="Print Quote when accepted on Sales Quote page"
					/>
					<InLineCheckbox
						id="price-quote-completed-on-sale"
						name="Print Order when completed on Sales Order page"
					/>
					<InLineCheckbox id="printing-packing-slip" name="Print Packing Slip" />
					<InlineDropdown
						id="quotes-template"
						name="Quote template"
						values={['', 'Globuss Default', 'Default Sales Quote']}
					/>
					<InlineDropdown
						id="order-template"
						name="Order template"
						values={['', 'Globuss Default', 'Formal Order', 'Classic Order']}
					/>
					<InlineDropdown
						id="invoice-template"
						name="Invoice template"
						values={['', 'Globuss Default', 'Format Invoice', 'Classic Invoice']}
					/>
					<InlineDropdown
						id="packing-slip-template"
						name="Packing Slip template"
						values={['', 'Globuss Deafult', 'Formal Slip Template', 'Classic Slip Template']}
					/>
				</div>
			</div>
		</div>
	);
}
