import React from 'react';
import docDesignerHOC from '../docDesigner';

function Assemblies() {
	return <div className="doc-designer">Assemblies Design</div>;
}

const designerHOC = docDesignerHOC(Assemblies);

export default designerHOC;
