import api from '../../api/api';
import { success, failed } from '../../constants/messages';
import { settings } from '../../constants/actionTypes';
import { loaderState } from '..';
import _ from 'lodash';
import swal from 'sweetalert';

export const getSalesPerson = () => dispatch => {
	api.get('/salesPerson')
		.then(res => {
			if (res.data.code === 200) {
				dispatch({ type: settings.SALES_PERSON, payload: res.data.data });
			} else {
				swal('', res.data.message, 'error');
			}
			dispatch(loaderState(false));
		})
		.catch(err => {
			swal('', err.message, 'error');
			dispatch(loaderState(false));
		});
};

export const addSalesPerson = data => (dispatch, getState) => {
	var salesPerson = getState().system.salesPerson.allSalesPerson;
	api.post('/salesPerson', { ...data })
		.then(res => {
			if (res.data.code === 200) {
				dispatch({ type: settings.SALES_PERSON, payload: [...salesPerson, res.data.data] });
			} else {
				swal('', res.data.message, 'error');
			}
			dispatch(loaderState(false));
		})
		.catch(err => {
			swal('', err.message, 'error');
			dispatch(loaderState(false));
		});
};

export const deleteSalesPerson = id => (dispatch, getState) => {
	const { allSalesPerson } = getState().system.salesPerson;
	api.delete(`/salesPerson/${id}`)
		.then(res => {
			if (Number(res.data.code) === 200) {
				_.remove(allSalesPerson, person => {
					return Number(id) == Number(person.id);
				});
				dispatch(loaderState(false));
			} else {
				swal('', res.data.message, 'error');
				dispatch(loaderState(false));
			}
		})
		.catch(err => {
			swal('', err.message, 'error');
			dispatch(loaderState(false));
		});
};

export const updateSalesPerson = (data, id) => (dispatch, getState) => {
	const { allSalesPerson, selectedSalesPerson } = getState().system.salesPerson;
	api.post(`/salesPerson/update/${id}`, { ...data })
		.then(res => {
			if (Number(res.data.code) === 200) {
				let updatedPerson = _.findLast(allSalesPerson, person => {
					return Number(person.id) == Number(id);
				});
				updatedPerson.fullName = data.fullName ? data.fullName : selectedSalesPerson.fullName;
				updatedPerson.email = data.email ? data.email : selectedSalesPerson.email;
				updatedPerson.obsolete = data.obsolete;
				let updatedPersons = _.map(allSalesPerson, person => {
					return person.id === id ? updatedPerson : person;
				});

				dispatch({ type: settings.SALES_PERSON, payload: updatedPersons });
				dispatch(loaderState(false));
			} else {
				swal('', res.data.message, 'error');
				dispatch(loaderState(false));
			}
		})
		.catch(err => {
			swal('', err.message, 'error');
			dispatch(loaderState(false));
		});
};

export const onSelectSalesPerson = data => dispatch => {
	dispatch({ type: settings.SELECTED_SALES_PERSON, payload: data });
};
