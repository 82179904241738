import React from 'react';

export default function BillOfMaterialTable() {
	return (
		<div className="table-view table-responsive">
			<table id="bill-of-material-table" className="table">
				<thead>
					<tr>
						<th>Component Product</th>
						<th>Image</th>
						<th>Quantity</th>
						<th>Wastage Quantity </th>
						<th>Unit</th>
						<th>Unit Cost</th>
						<th>Total Cost</th>
						<th>Delete</th>
					</tr>
				</thead>
				<tbody></tbody>
			</table>
		</div>
	);
}
