import React from 'react';
import InlineInputForm from '../../common/inlineInputForm';

export default function Details() {
	return (
		<div id="Details" className="tab-pane active" >
			<br />
			<div className="row" id="prefix-divs">
				<div className="col-sm-4">
					<InlineInputForm id="supplier-code" value="" name="*Supplier Code" />
				</div>
				<div className="col-sm-2"></div>
				<div className="col-sm-4">
					<div className="form-inline">
						<div className="form-group">
							<label htmlFor="currency" className="input-label">
								<a href="#">Currency</a>
							</label>
							<select className="form-control" id="currency">
								<option>GBP</option>
							</select>
						</div>
					</div>
				</div>
			</div>
			<div className="row" id="prefix-divs">
				<div className="col-sm-4">
					<InlineInputForm id="supplier-name" value="" name="*Supplier Name" />
				</div>
				<div className="col-sm-2"></div>
				<div className="col-sm-4">
					<InlineInputForm id="bank-name" value="" name="bank-name" />
				</div>
			</div>
			<div className="row" id="prefix-divs">
				<div className="col-sm-4">
					<InlineInputForm id="gst-vat-number" value="" name="GST/VAT Number" />
				</div>
				<div className="col-sm-2"></div>
				<div className="col-sm-4">
					<InlineInputForm id="bank-branch" value="" name="Bank Branch" />
				</div>
			</div>
			<div className="row" id="prefix-divs">
				<div className="col-sm-4">
					<div className="form-inline">
						<div className="form-group">
							<label htmlFor="notes">Notes</label>
							<textarea rows="7" type="text" className="form-control" id="notes" />
						</div>
					</div>
				</div>
				<div className="col-sm-2"></div>
				<div className="col-sm-4">
					<div className="form-inline" style={{ marginBottom: '10px' }}>
						<div className="form-group">
							<label htmlFor="bank-account">Bank Account</label>
							<input type="text" className="form-control" id="bank-account" />
						</div>
					</div>
					<div className="form-inline" style={{ marginBottom: '10px' }}>
						<div className="form-group">
							<label htmlFor="payment-term-description">Payment Term Description</label>
							<select className="form-control" id="payment-term-description">
								<option>120 days</option>
								<option>14 days</option>
								<option>1st of the month following</option>
								<option>20th Month following</option>
								<option>20 days</option>
							</select>
						</div>
					</div>
					<div className="form-inline">
						<div className="form-group">
							<label htmlFor="purchase-order-print-template">Purchase Order Print Template</label>
							<select className="form-control" id="purchase-order-print-template">
								<option>Globuss Default</option>
								<option>Formal Purchase</option>
								<option>Classic Purchase</option>
								<option>test</option>
							</select>
						</div>
					</div>
				</div>
			</div>
			<div className="row" id="prefix-divs">
				<div className="col-sm-4">
					<div className="form-inline" style={{ textAlign: 'center' }}>
						<div className="form-group">
							<label htmlFor="taxable">Taxable</label>
							<label className="switch">
								<input type="checkbox" name="taxable" id="taxable" />
								<span className="slider round"></span>
							</label>
						</div>
					</div>
				</div>
				<div className="col-sm-2"></div>
				<div className="col-sm-4">
					<div className="form-inline">
						<div className="form-group">
							<label htmlFor="supplier-return-print-template">Supplier Return Print Template</label>
							<select className="form-control" id="supplier-return-print-template">
								<option>Globuss Default</option>
								<option>Default Supplier Return</option>
							</select>
						</div>
					</div>
				</div>
			</div>
			<div className="row" id="prefix-divs">
				<div className="col-sm-4">
					<div className="form-inline">
						<div className="form-group">
							<label htmlFor="tax-rate" className="input-label">
								<a href="#">Tax Rate</a>
							</label>
							<input type="text" className="form-control" id="tax-rate" />
						</div>
					</div>
				</div>
				<div className="col-sm-2"></div>
				<div className="col-sm-4">
					<InlineInputForm id="delivery-lead-time" value="" name="Delivery Lead Time (days)" />
				</div>
			</div>
		</div>
	);
}
