import { settings } from '../../constants/actionTypes';
const initialState = {
	allCurrencies: null,
	selectedCurrency: null,
};

function currencyRatesReducer(state = initialState, action) {
	switch (action.type) {
		case settings.CURRENCY_RATE:
			return { ...state, allCurrencies: action.payload };
		case settings.SELECTED_CURRENCY_RATE:
			return { ...state, selectedCurrency: action.payload };
		default:
			return state;
	}
}
export default currencyRatesReducer;
