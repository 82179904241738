import React from 'react';
import Product from './product';
import AdjustmentTable from './adjustmentTable';

export default function AddAdjustment() {
	return (
		<>
			<div className="comp-heading">
				<div className="row">
					<div className="col-lg-4 col-md-7 col-8">
						<label>Add Stock Adjustment</label>
					</div>
					<div className="col-lg-3 col-md-2 col-1"></div>
					<div className="col-lg-5 col-md-3 col-3" style={{ textAlign: 'right' }}>
						<button className="btn btn-light btn-sm">Add New Adjustment</button>
						<button className="btn btn-light btn-sm">Save</button>
						<button className="btn btn-success btn-sm">Complete</button>
					</div>
				</div>
			</div>
			<br />
			<div className="panel panel-default">
				<div className="panel-body">
					<div className="row" id="settings-field">
						<div className="col-md-2 padding-right">
							<label className="input-label" htmlFor="reason">
								Reason
							</label>
							<select className="form-control" id="reason">
								<option>All</option>
								<option>Parked</option>
								<option>Completed</option>
							</select>
						</div>
					</div>
				</div>
			</div>
			<div className="panel panel-default">
				<div className="panel-body">
					<Product />
					<br />
					<AdjustmentTable />
				</div>
			</div>
			<div className="row">
				<div className="col-md-9 col-xs-6"></div>
				<div className="col-md-3 col-xs-6 stat-div">
					<small className="stat-label">TOTAL ADJUST QUANTITY</small>
					<div className="stat-value">16</div>
					<hr />
					<small className="stat-label">TOTAL ADJUST VALUE</small>
					<div className="stat-value">1612312</div>
				</div>
			</div>
			<div className="row">
				<div className="col-xs-12" style={{ textAlign: 'right' }}>
					<button style={{ marginRight: '5px' }} className="btn btn-light btn-sm">
						Print
					</button>
					<button className="btn btn-danger btn-sm">Delete</button>
				</div>
			</div>
		</>
	);
}
