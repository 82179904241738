import { purchases } from '../../constants/actionTypes';
const initialState = {
	allPurchases: null,
	selectedPurchase: null,
	supplierReturns: null,
	selectedSupplierRerurn: null,
};

function purchasesReducer(state = initialState, action) {
	switch (action.type) {
		case purchases.PURCHASES:
			return { ...state, allPurchases: action.payload };
		case purchases.SELECTED_PURCHASE:
			return { ...state, selectedPurchase: action.payload };
		case purchases.SUPPLIER_RETURNS:
			return { ...state, supplierReturns: action.payload };
		case purchases.SELECTED_SUPPLIER_RETURN:
			return { ...state, selectedSupplierRerurn: action.payload };
		default:
			return state;
	}
}
export default purchasesReducer;
