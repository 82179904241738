import React from 'react';
import InlineInputForm from '../../../common/inlineInputForm';
import InlineDropdown from '../../../common/inlineDropDown';
import moment from 'moment';

export default function Details({ organization }) {
	return (
		<div id="Details" className="tab-pane active" >
			<div className="row" id="inline-input-forms">
				<div className="col-sm-4">
					<InlineInputForm
						id="company-name"
						value={organization ? organization.companyName : ''}
						name="*Company Name"
					/>
					<InlineInputForm
						id="trading-name"
						value={organization ? organization.tradingName : ''}
						name="*Trading Name"
					/>
					<InlineInputForm id="trading-name-purchasing" value="" name="Trading Name (for Purchasing)" />
					<InlineDropdown
						id="industry"
						value={organization ? organization.industry : ''}
						name="*Industry"
						values={['Food', 'Mining', 'Other', 'test']}
					/>
					<InlineDropdown
						id="organisation-type"
						name="*Organisation Type"
						values={['Company', 'Persom', 'Other', 'etc']}
					/>
					<InlineInputForm
						disable="disabled"
						id="base-currency"
						value={organization ? organization.baseCurrency : ''}
						name="Base Currency"
					/>
				</div>
				<div className="col-sm-1"></div>
				<div className="col-sm-4">
					<InlineDropdown
						id="dashboard-data-source"
						name="Dashboard Data Source"
						values={['', 'Sales Invoice', 'Sales Order']}
					/>
					<InlineInputForm id="gst-vat-number" value="" name="GST / VAT Number" />
					<InlineInputForm id="website" value="" name="Website" />
					<InlineDropdown id="timezone" name="*Timezone" values={['']} />
					<InlineDropdown
						id=""
						name="Financial Year End"
						values={[
							'31 JAN',
							'28/29 FEB',
							'31 MAR',
							'30 APR',
							'31 MAY',
							'30 JUN',
							'31 JUL',
							'30 AUG',
							'30 SEP',
							'31 OCT',
							'30 NOV',
							'31 DEC',
						]}
					/>
					<InlineDropdown id="measurement-units" name="Measurement Units" values={['Metric', 'Imperial']} />
					<InlineDropdown
						id="date-format"
						name="Date Format"
						values={['dd/MM/yyyy', 'MM/dd/yyyy', 'dd/MMM/yyyy', 'MMM/dd/yyyy']}
					/>
					<small>Today is {moment().format('DD/MM/YYYY')}</small>
				</div>
			</div>
		</div>
	);
}
