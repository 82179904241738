import { settings } from '../../constants/actionTypes';
const initialState = {
	allTerms: null,
	selectedTerm: null,
};

function paymentTermsReducer(state = initialState, action) {
	switch (action.type) {
		case settings.PAYMENT_TERMS:
			return { ...state, allTerms: action.payload };
		case settings.SELECTED_PAYMENT_TERM:
			return { ...state, selectedTerm: action.payload };
		default:
			return state;
	}
}
export default paymentTermsReducer;
