import React from 'react';

export default function Log() {
	return (
		<div id="Log" className="tab-pane fade">
			{/* <div className="panel panel-default">
				<div className="panel-body"> */}
			<div className="table-view table-responsive">
				<table id="log-table" className="table">
					<thead>
						<tr>
							<th>Date</th>
							<th>Product Code</th>
							<th>Sell Price Tier</th>
							<th>Old Price</th>
							<th>New Price</th>
							<th>Created By</th>
						</tr>
					</thead>
					<tbody></tbody>
				</table>
			</div>
		</div>
		// 	</div>
		// </div>
	);
}
