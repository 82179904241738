import React from 'react';
import InlineDropdown from '../../../components/common/inlineDropDown';

export default function Template() {
	return (
		<div id="Template" className="tab-pane fade">
			<div className="row" id="inline-input-forms">
				<div className="col-sm-6">
					<InlineDropdown
						noEmpty={true}
						id="fontName"
						name="Font Name"
						values={['Arial Unicode MS', 'Cambria', 'Time New Roman', 'Verdana']}
					/>
				</div>
				<div className="col-sm-6">
					<InlineDropdown
						noEmpty={true}
						id="orientation"
						name="Orientation"
						values={['Portrait', 'Landscape']}
					/>
				</div>
				<div className="col-sm-6">
					<div style={{ marginLeft: '7px' }}>
						<InlineDropdown
							noEmpty={true}
							id="fontSize"
							name="Font Size"
							values={[
								'6',
								'7',
								'8',
								'9',
								'10',
								'11',
								'12',
								'13',
								'14',
								'15',
								'16',
								'17',
								'18',
								'19',
								'20',
							]}
						/>
					</div>
				</div>
				<div className="col-sm-6">
					<div style={{ marginLeft: '4px' }}>
						<InlineDropdown noEmpty={true} id="pageSize" name="Page Size" values={['A4', 'Letter']} />
					</div>
				</div>
			</div>
			<hr />
			<div className="doc-info">
				<i className="fa fa-info-circle" />
				<span>These settings will define a default style for all components in the template.</span>
			</div>
		</div>
	);
}
