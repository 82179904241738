import React from 'react';
import AdjustmentReasonsTable from './adjustmentReasonsTable';
import ConfirmationModal from '../../../common/confirmationModal';
import OneInputEditModal from '../../../common/OneInputEditModal';

export default function AdjustmentReasons({
	editValue,
	addValue,
	onChange,
	onUpdate,
	adjustments,
	onAdd,
	onDelete,
	onReasonUpdateOrDelete,
	onChangeEdit,
}) {
	return (
		<>
			<div className="comp-heading">
				<div className="row">
					<div className="col-lg-4">
						<label>Adjustment Reasons</label>
					</div>
				</div>
			</div>
			<br />
			<div className="panel panel-default">
				<div className="panel-body">
					<div className="row" id="settings-field">
						<div className="col-md-2 col-xs-9">
							<label className="input-label" htmlFor="adjustment-reason">
								* Adjustment Reason
							</label>
							<input
								className="form-control"
								id="adjustment-reason"
								onChange={e => onChange(e)}
								value={addValue}
							/>
						</div>
						<div className="col-xs-2" id="system-add-btn">
							<button className="btn btn-success btn-sm" onClick={() => onAdd()}>
								Add
							</button>
						</div>
					</div>
				</div>
			</div>
			<div className="panel panel-default">
				<div className="panel-body">
					<AdjustmentReasonsTable
						onReasonUpdateOrDelete={data => onReasonUpdateOrDelete(data)}
						adjustments={adjustments}
					/>
				</div>
			</div>
			<ConfirmationModal onDelete={() => onDelete()} item="Adjustment Reason" />
			<OneInputEditModal
				value={editValue}
				label="Adjustment Reason"
				onChange={e => onChangeEdit(e)}
				onUpdate={() => onUpdate()}
			/>
		</>
	);
}
