import React from 'react';

export default function BillOfMaterialTable() {
	return (
		<div className="table-view table-responsive">
			<table id="view-bill-of-material-table" className="table">
				<thead>
					<tr>
						<th>Bill Number</th>
						<th>Product Code</th>
						<th>Product Description</th>
						<th>Sub Bom Count </th>
						<th>Can Auto Assemble</th>
						<th>Can Auto Disassemble</th>
						<th>Obsolete</th>
						<th>Action</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>
							<label className="input-label">
								<a href="#" target="_blank">
									BOM-00001
								</a>
							</label>
						</td>
						<td>
							<label className="input-label">
								<a href="#" target="_blank">
									BookShelf
								</a>
							</label>
						</td>
						<td>
							<label className="input-label">
								<a href="#" target="_blank">
									Wooden BookShelf
								</a>
							</label>
						</td>
						<td>0</td>
						<td>
							<i style={{ fontSize: '1.7em', color: 'green' }} className="fa fa-check icon" />
						</td>
						<td>
							<i style={{ fontSize: '1.7em', color: 'red' }} className="fa fa-times icon" />
						</td>
						<td>
							<i style={{ fontSize: '1.7em', color: 'red' }} className="fa fa-times icon" />
						</td>
						<td>
							<i style={{ fontSize: '1.7em' }} className="fa fa-cog icon" />
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	);
}
