import { users } from '../../constants/actionTypes';
const initialState = {
	superUserInfo: null,
	loggedInUser: null,
	allUsers: null,
	selectedUser: null,
	organizationInfo: null,
};

function usersReducer(state = initialState, action) {
	switch (action.type) {
		case users.USER_INFO:
			return { ...state, superUserInfo: action.payload };
		case users.ORGANIZATION_INFO:
			return { ...state, organizationInfo: action.payload };
		case users.LOGGED_IN_USER_DATA:
			return { ...state, loggedInUser: action.payload };
		case users.USERS:
			return { ...state, allUsers: action.payload };
		case users.DELETE_USER:
			return { ...state, selectedUser: action.payload };
		default:
			return state;
	}
}
export default usersReducer;
