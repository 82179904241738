import React from 'react';

export default function Tabs({ nav }) {
	return (
		<div>
			<ul className="nav nav-tabs" role="tablist" id="comp-tab">
				{nav.map((nav, index) => {
					return (
						<li className={`nav-item ${index === 0 ? 'active' : ''}`} key={nav}>
							<a className="nav-link" data-toggle="tab" href={`#${nav.split(' ').join('')}`}>
								{nav}
							</a>
						</li>
					);
				})}
			</ul>
		</div>
	);
}
