import React from 'react';

export default function ReOrderTable() {
	return (
		<div className="table-view table-responsive">
			<table id="sales-quotes-charge-table" className="table">
				<thead>
					<tr>
						<th>Code</th>
						<th>Description</th>
						<th>Pref Supplier</th>
						<th>Min Stock</th>
						<th>Max Stock</th>
						<th>On Hand</th>
						<th>On Order</th>
						<th>In Assembly</th>
						<th>On Assembly</th>
						<th>On Transfer</th>
						<th>On Purchase</th>
						<th>Next Delivery</th>
						<th>Available</th>
						<th>Suggested Reorder</th>
						<th>Alter</th>
						<th>Stock Turns</th>
					</tr>
				</thead>
				<tbody></tbody>
			</table>
		</div>
	);
}
