import React from 'react';

export default function ReceiptPurchaseTable() {
	return (
		<div className="table-view table-responsive">
			<table id="receipt-purchases-table" className="table">
				<thead>
					<tr>
						<th>Line</th>
						<th>Product Code</th>
						<th>Product Description</th>
						<th>Unit</th>
						<th>Price</th>
						<th>Discount</th>
						<th>Dics. Price</th>
						<th>Line Total</th>
						<th>Tax Rate</th>
						<th>Comments</th>
						<th>Order Qaunatity</th>
						<th>
							<i style={{ fontSize: '1.5em' }} className="fa fa-cog icon" />
							Receipt Qaunatity
						</th>
					</tr>
				</thead>
				<tbody></tbody>
			</table>
		</div>
	);
}
