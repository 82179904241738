import React from 'react';

export default function EmailsTable() {
	return (
		<div className="table-view table-responsive">
			<table id="email-log-table" className="table">
				<thead>
					<tr>
						<th>Sender</th>
						<th>Recipient</th>
						<th>CC</th>
						<th>Subject</th>
						<th>Transaction Type</th>
						<th>Transaction Ref</th>
						<th>Time</th>
					</tr>
				</thead>
				<tbody></tbody>
			</table>
		</div>
	);
}
