import React from 'react';
import StockTable from './stockTable';
import ExportButton from '../../common/exportButton';

export default function StockAdjustments({ warehouses, goto }) {
	return (
		<>
			<div className="comp-heading">
				<div className="row">
					<div className="col-lg-4 col-md-7 col-8">
						<label>Stock Adjustments</label>
					</div>
					<div className="col-lg-5 col-md-2 col-1"></div>
					<div className="col-lg-3 col-md-3 col-3" style={{ textAlign: 'right' }}>
						<ExportButton />
						<button onClick={() => goto('/stock adjustment/add')} className="btn btn-success btn-sm">
							Add Adjustments
						</button>
					</div>
				</div>
			</div>
			<br />
			<div className="panel panel-default">
				<div className="panel-body">
					<div className="row" id="stock-adjustments">
						<div className="col-md-2 padding-right">
							<label className="input-label" htmlFor="adjustments-status">
								Status
							</label>
							<select className="form-control" id="adjustments-status">
								<option>All</option>
								<option>Parked</option>
								<option>Completed</option>
								<option>Deleted</option>
							</select>
						</div>
						<div className="col-md-2 padding-0">
							<label className="input-label" htmlFor="adjustments-reason">
								Reason
							</label>
							<select className="form-control" id="adjustments-reason">
								<option></option>
								<option>End Of Season</option>
								<option>Sample</option>
								<option>Stolen</option>
							</select>
						</div>
						<div className="col-md-2 padding-0">
							<label className="input-label" htmlFor="adjustments-warehouse">
								Warehouse
							</label>
							<select className="form-control" id="adjustments-warehouse">
								<option></option>
								{warehouses
									? warehouses.map(warehouse => <option key={warehouse}>{warehouse}</option>)
									: null}
							</select>
						</div>
					</div>
				</div>
			</div>
			<div className="panel panel-default">
				<div className="panel-body">
					<StockTable />
				</div>
			</div>
		</>
	);
}
