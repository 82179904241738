import React, { Component } from 'react';
import PackingSlipsComp from '../../../../components/setting/docDesigner/packingSlips/index';

export default class PackingSlips extends Component {
	state = {
		templates: ['Formal Packing Slip', 'Classic Packing Slip'],
	};
	render() {
		const { templates } = this.state;
		return <PackingSlipsComp templates={templates} name="Packing Slip" />;
	}
}
