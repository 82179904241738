import React from 'react';

export default function ProductAllocationTable() {
	return (
		<div className="table-view table-responsive">
			<table id="sales-quotes-charge-table" className="table">
				<thead>
					<tr>
						<th>Product Code</th>
						<th>Product Description</th>
						<th>Warehouse</th>
						<th>Allocated To</th>
						<th>Order Number</th>
						<th>Order Date</th>
						<th>Allocated Quantity</th>
					</tr>
				</thead>
				<tbody></tbody>
			</table>
		</div>
	);
}
