import React from 'react';

export default function Info({ heading, message, link, path }) {
	return (
		<div className="info">
			<label>
				<i className="fa fa-info-circle" aria-hidden="true" />
				{heading}
			</label>
			<span>
				{message}
				<a href={path}>{link}</a>
			</span>
		</div>
	);
}
