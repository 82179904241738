import React, { Component } from 'react';
import permissions from './permissionsData';

export class Permissions extends Component {
	render() {
		return (
			<div className="role-permissions">
				{/* <div className="row">
					<div className="col-md-12"> */}
				{/* <table className="table table-condensed"> */}
				{/* <thead>
								<tr>
									<th></th>
									<th>Permission</th>
									<th>Status</th>
									<th>Can Edit</th>
								</tr>
							</thead> */}
				<div className="row" id="heading">
					<div className="col-xs-1"></div>
					<div className="col-xs-5">Permission</div>
					<div className="col-xs-3">Status</div>
					<div className="col-xs-3">Can Edit</div>
				</div>
				{permissions.map((permission, index) => {
					let id = permission.main.replace(/\s/g, '');
					return (
						<React.Fragment key={index}>
							<div className="row">
								<div
									data-toggle="collapse"
									data-target={`#${id}`}
									className="col-xs-1 accordion-toggle"
								>
									{permission.subPermissions ? (
										<i className="fa fa-chevron-right" aria-hidden="true" />
									) : null}
								</div>
								<div className="col-xs-5">{permission.main}</div>
								<div className="col-xs-3">
									<i className="fa fa-lock" />
								</div>
								<div className="col-xs-3">
									<input type="checkbox" />
								</div>
							</div>
							{permission.subPermissions ? (
								<div id={id} className="accordian-body collapse">
									{permission.subPermissions.map(sub => (
										<div key={sub} className="row" id="sub-permissions">
											<div className="col-xs-1"></div>
											<div className="col-xs-5">{sub}</div>
											<div className="col-xs-3">
												<i className="fa fa-lock" />
											</div>
											<div className="col-xs-3">
												<input type="checkbox" />
											</div>
										</div>
									))}
								</div>
							) : null}
						</React.Fragment>
					);
				})}
			</div>
		);
	}
}
