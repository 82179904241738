import React from 'react';

export default function Stats() {
	return (
		<div className="row">
			<div className="col-md-1"></div>
			<div className="col-md-6 col-xs-6">
				<div className="border-bottom">
					<span className="receipt-stat-label">Total Turnover</span>
					<span className="pull-right receipt-stat-value">76888.98</span>
				</div>
				<div className="border-bottom">
					<span className="receipt-stat-label">Total Profit</span>
					<span className="pull-right receipt-stat-value">31232.23</span>
				</div>
				<div className="border-bottom">
					<span className="receipt-stat-label">Total Sales Order value</span>
					<span className="pull-right receipt-stat-value">3123431.00</span>
				</div>
			</div>
			<div className="col-md-5 col-xs-6">
				<div className="border-bottom">
					<span className="receipt-stat-label">Sales Order Count</span>
					<span className="pull-right receipt-stat-value">23</span>
				</div>
				<div className="border-bottom">
					<span className="receipt-stat-label">Average Profit</span>
					<span className="pull-right receipt-stat-value">3.44%</span>
				</div>
			</div>
		</div>
	);
}
