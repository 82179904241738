import React from 'react';
import SplitButton from '../../../common/splitButton';
import InlineInputForm from '../../../common/inlineInputForm';
import InlineDropdown from '../../../common/inlineDropDown';
import RunReport from '../../../common/runReport';
import QuotesEnquiryTable from './quotesEnquiryTable';

export default function QuotesEnquiry() {
	return (
		<>
			<div className="comp-heading">
				<div className="row">
					<div className="col-lg-4">
						<label>Quotes Enquiry</label>
					</div>
					<div className="col-lg-2"></div>
					<div className="col-lg-6" style={{ textAlign: 'right' }}>
						<SplitButton
							label="Export"
							type="light"
							data={['Export as PDF', 'Export as CSV', 'Export as XLSX', 'Export as XLS']}
						/>
						<button className="btn btn-success btn-sm">Run</button>
					</div>
				</div>
			</div>
			<br />
			<div className="panel panel-default">
				<div className="panel-body">
					<div className="row" id="inline-input-forms">
						<div className="col-sm-4">
							<InlineInputForm id="date-from" type="date" value="" name="Date From" />
							<InlineInputForm id="date-to" type="date" value="" name="Date To" />
							<InlineDropdown
								id="transaction-date"
								name="Transaction Date"
								values={['Quote Date', 'Expiry Date', 'Accept Date']}
							/>
							<InlineInputForm id="sales-quote-number" value="" name="Sales Quote Number" />
							<InlineDropdown
								id="sales-quote-status"
								name="Sales Quote Status"
								values={['', 'Draft', 'Pending', 'Accepted', 'Cancelled', 'Deleted']}
							/>
						</div>
						<div className="col-sm-4">
							<InlineDropdown
								id="product-group"
								name="Product Group"
								values={['', 'Product Group 1', 'Product Group 2', 'Product Group 3']}
							/>
							<InlineInputForm id="product-code" value="" name="Product Code" />
							<InlineInputForm id="product-description" value="" name="Product Description" />
							<InlineDropdown
								id="warehouse"
								name="Warehouse"
								values={['Select All', 'Warehouse', 'Main Office']}
							/>
							<InlineDropdown id="sales-order-group" name="Sales Order Group" values={['']} />
						</div>
						<div className="col-sm-4">
							<InlineDropdown id="customer-type" name="Customer Type" values={['', 'General']} />
							<InlineInputForm id="customer-code" value="" name="Customer Code" />
							<InlineInputForm id="customer-name" value="" name="Customer Name" />
							<InlineInputForm id="customer-reference" value="" name="Customer Reference" />
							<InlineInputForm id="delivery-address" value="" name="Delivery Address" />
							<InlineDropdown id="sales-person" name="Sales Persom" values={['', 'name: email']} />
						</div>
					</div>
				</div>
			</div>
			<div className="panel panel-default">
				<div className="panel-body">
					<QuotesEnquiryTable />
					<br />
					<RunReport />
				</div>
			</div>
			<div className="row">
				<div className="col-md-6"></div>
				<div className="col-md-6">
					<div className="panel panel-default" id="panel-margin-right">
						<div className="panel-body">
							<div className="grid-container">
								<div className="grid-item">
									<div className="border-bottom">
										<span className="receipt-stat-label">Sales Quote Line Count </span>
										<span className="pull-right receipt-stat-value"></span>
									</div>
								</div>
								<div className="grid-item">
									<div className="border-bottom">
										<span className="receipt-stat-label">Total Sales Quote Value </span>
										<span className="pull-right receipt-stat-value"></span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
