import React, { Component } from 'react';
import SellPriceTiers from './sellPriceTiers';
import QuantityPrices from './quantityPrices';

export default function Sale({ onClickHideOrView, hideOrShow }) {
	return (
		<div id="Sale" className="tab-pane fade">
			<div className="panel panel-default">
				<div className="panel-body">
					<form>
						<div className="row" id="settings-field">
							<div className="col-md-2 padding-right">
								<label className="input-label" htmlFor="default-sell-price">
									Default Sell Price
								</label>
								<input
									type="text"
									className="form-control"
									id="default-sell-price"
									name="default-sell-price"
								/>
							</div>
							<div className="col-md-2 padding-0">
								<label className="input-label" htmlFor="minimum-sell-price">
									Minimum Sell Price
								</label>
								<input
									type="text"
									className="form-control"
									id="minimum-sell-price"
									name="minimum-sell-price"
								/>
							</div>
							<div className="col-md-1 padding-0">
								<label className="input-label" htmlFor="sale-tax-rate">
									<a href="#" target="_blank">
										Sales Tax Rate
									</a>
								</label>
								<select className="form-control" id="sale-tax-rate">
									<option></option>
									<option>No Tax (0%)</option>
								</select>
							</div>
							<div className="col-md-2 padding-0">
								<label className="input-label" htmlFor="average-land-cost">
									Average Land Cost
								</label>
								<input
									disabled
									type="text"
									className="form-control"
									value="0"
									id="average-land-cost"
									name="average-land-cost"
								/>
							</div>
							<div className="col-md-1 padding-0">
								<label className="input-label" htmlFor="last-cost">
									Last Cost
								</label>
								<input
									disabled
									type="text"
									className="form-control"
									value="0"
									id="last-cost"
									name="last-cost"
								/>
							</div>
							<div className="col-md-2 padding-0">
								<label className="input-label" htmlFor="default-purchase-price">
									Default Purchase Price
								</label>
								<input
									disabled
									type="text"
									className="form-control"
									value="0"
									id="default-purchase-price"
									name="default-purchase-price"
								/>
							</div>
							<div className="col-md-2 padding-0">
								<label className="input-label" htmlFor="minimum-sale-quality">
									Minimum Sale Quantity
								</label>
								<input
									disabled
									type="text"
									className="form-control"
									value="0"
									id="minimum-sale-quality"
									name="minimum-sale-quality"
								/>
							</div>
						</div>
					</form>
				</div>
			</div>
			<div className="panel panel-default">
				<div className="panel-body">
					<ul className="nav nav-tabs" role="tablist" id="comp-tab">
						<li className="nav-item active">
							<a className="nav-link active" data-toggle="tab" href="#sellpricetiers">
								Sell Price Tiers
							</a>
						</li>
						<li className="nav-item">
							<a className="nav-link" data-toggle="tab" href="#qualityprices">
								Quality Prices
							</a>
						</li>
					</ul>
					<div className="tab-content">
						<SellPriceTiers onClickHideOrView={() => onClickHideOrView()} hideOrShow={hideOrShow} />
						<QuantityPrices />
					</div>
				</div>
			</div>
		</div>
	);
}
