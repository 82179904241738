import React from 'react';
import InlineDropdown from '../../../common/inlineDropDown';

export default function Invoicing() {
	return (
		<div id="Invoicing" className="tab-pane fade">
			<div className="row" id="inline-input-forms">
				<div className="col-sm-4">
					<div className="form-inline">
						<div className="form-group">
							<label htmlFor="footer-details">Footer Details</label>
							<textarea rows="7" type="text" className="form-control" id="footer-details" />
						</div>
					</div>
					<InlineDropdown
						id="default-invoice-date"
						name="Default Invoice Date"
						values={['Created Date', 'Order Date']}
					/>
				</div>
			</div>
		</div>
	);
}
