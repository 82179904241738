import React, { Component } from 'react';
import InvoicesComp from '../../../../components/setting/docDesigner/invoices/index';

export default class Invoices extends Component {
	state = {
		templates: ['Formal Invoice', 'Classic Invoice'],
	};
	render() {
		const { templates } = this.state;
		return <InvoicesComp templates={templates} name="Invoices" />;
	}
}
