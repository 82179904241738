import React from 'react';
import docDesignerHOC from '../docDesigner';

function PurchaseOrders() {
	return <div className="doc-designer">PurchaseOrders Design</div>;
}

const designerHOC = docDesignerHOC(PurchaseOrders);

export default designerHOC;
