import React from 'react';

export default function Stats() {
	return (
		<div className="row">
			<div className="col-md-6"></div>
			<div className="col-md-6">
				<div className="panel panel-default" id="panel-margin-right">
					<div className="panel-body">
						<div className="grid-container">
							<div className="grid-item">
								<div className="border-bottom">
									<span className="receipt-stat-label">Transaction Count</span>
									<span className="pull-right receipt-stat-value"></span>
								</div>
								<div className="border-bottom">
									<span className="receipt-stat-label">Total Quantity </span>
									<span className="pull-right receipt-stat-value"></span>
								</div>
								<div className="border-bottom">
									<span className="receipt-stat-label">Total Profit </span>
									<span className="pull-right receipt-stat-value"></span>
								</div>
								<div className="border-bottom">
									<span className="receipt-stat-label">Overall Margin</span>
									<span className="pull-right receipt-stat-value"></span>
								</div>
							</div>
							<div className="grid-item">
								<div className="border-bottom">
									<span className="receipt-stat-label">Total Sell</span>
									<span className="pull-right receipt-stat-value"></span>
								</div>
								<div className="border-bottom">
									<span className="receipt-stat-label">Total Charge </span>
									<span className="pull-right receipt-stat-value"></span>
								</div>
								<div className="border-bottom">
									<span className="receipt-stat-label">Total Turnover </span>
									<span className="pull-right receipt-stat-value"></span>
								</div>
								<div className="border-bottom">
									<span className="receipt-stat-label">Total Cost</span>
									<span className="pull-right receipt-stat-value"></span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
