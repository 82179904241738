import React from 'react';

export default function WarehousesTable({ goto, warehouses, onWarehouseUpdateOrDelete }) {
	return (
		<div className="table-view table-responsive" id="setting-tables">
			<table id="warehouses-table" className="table">
				<thead>
					<tr>
						<th>Warehouse Code</th>
						<th>Warehouse Name</th>
						<th>Default</th>
						<th>Obsolete</th>
						<th>Actions</th>
					</tr>
				</thead>
				<tbody>
					{warehouses
						? warehouses.map(warehouse => (
								<tr key={warehouse.code}>
									<td>
										<label
											className="input-label"
											onClick={() => goto(`/settings/system/warehouse/update/${warehouse.id}`)}
										>
											<a>{warehouse.code}</a>
										</label>
									</td>
									<td>
										<label
											className="input-label"
											onClick={() => goto(`/settings/system/warehouse/update/${warehouse.id}`)}
										>
											<a>{warehouse.warehouseName}</a>
										</label>
									</td>
									<td>
										<input
											checked={`${Number(warehouse.defaultWarehouse) === 1 ? 'checked' : ''}`}
											disabled
											type="checkbox"
										/>
									</td>
									<td>
										<input
											checked={`${Number(warehouse.obsolete) === 1 ? 'checked' : ''}`}
											disabled
											type="checkbox"
										/>
									</td>
									<td>
										<i
											data-toggle="modal"
											data-target="#confirmationModal"
											className="fa fa-trash-o icon"
											onClick={() => onWarehouseUpdateOrDelete(warehouse)}
										/>
										{/* <i className="fa fa-pencil-square-o icon" /> */}
									</td>
								</tr>
						  ))
						: null}
				</tbody>
			</table>
		</div>
	);
}
