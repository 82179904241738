import React from 'react';
import Select from 'react-select';

export default function EditModal({ onUpdate, editType, editName, editDays, onChange, types, handleEditChange }) {
	return (
		<div className="modal fade" id="paymentEditModal" role="dialog">
			<div className="modal-dialog">
				<div className="modal-content">
					<div className="modal-header">
						<button type="button" className="close" data-dismiss="modal">
							&times;
						</button>
						<h4 className="modal-title">Update Data</h4>
					</div>
					<div className="modal-body">
						<div className="form-group">
							<label htmlFor="text"> Name </label>
							<input
								type="text"
								value={editName}
								onChange={e => onChange(e)}
								className="form-control"
								id="editName"
							/>
						</div>
						<div className="form-group">
							<label htmlFor="text">Days</label>
							<input
								type="text"
								value={editDays}
								onChange={e => onChange(e)}
								className="form-control"
								id="editDays"
							/>
						</div>
						<div className="form-group">
							<label htmlFor="default-attribute-set">Type</label>
							<Select value={editType} isClearable onChange={handleEditChange} options={types} />
						</div>
					</div>
					<div className="modal-footer">
						<button
							type="button"
							onClick={() => onUpdate()}
							className="btn btn-success"
							data-dismiss="modal"
						>
							Update
						</button>
						<button type="button" className="btn btn-default" data-dismiss="modal">
							Cancel
						</button>
					</div>
				</div>
			</div>
		</div>
	);
}
