import React from 'react';
import SplitButton from '../../../common/splitButton';
import InlineInputForm from '../../../common/inlineInputForm';
import InlineDropdown from '../../../common/inlineDropDown';
import RunReport from '../../../common/runReport';
import PriceListTable from './priceListTable';

export default function PriceListEnquiry() {
	return (
		<>
			<div className="comp-heading">
				<div className="row">
					<div className="col-lg-4">
						<label>Price List Enquiry</label>
					</div>
					<div className="col-lg-2"></div>
					<div className="col-lg-6" style={{ textAlign: 'right' }}>
						<SplitButton
							label="Export"
							type="light"
							data={['Export as PDF', 'Export as CSV', 'Export as XLSX', 'Export as XLS']}
						/>
						<button className="btn btn-success btn-sm">Run</button>
					</div>
				</div>
			</div>
			<br />
			<div className="panel panel-default">
				<div className="panel-body">
					<div className="row" id="inline-input-forms">
						<div className="col-sm-4">
							<InlineInputForm id="product-code" value="" name="Product Code" />
							<InlineInputForm id="product-description" value="" name="Product Description" />
							<InlineDropdown
								id="product-group"
								name="Product Group"
								values={[
									'',
									'Product Group 1',
									'Product Group 2',
									'Product Group 3',
									'Product Group 4',
								]}
							/>
						</div>
						<div className="col-sm-2"></div>
						<div className="col-sm-4">
							<InlineInputForm id="supplier-code" value="" name="Supplier  Code" />
							<InlineInputForm id="supplier-name" value="" name="Supplier Name" />
						</div>
					</div>
				</div>
			</div>
			<div className="panel panel-default">
				<div className="panel-body">
					<PriceListTable />
					<br />
					<RunReport />
				</div>
			</div>
		</>
	);
}
