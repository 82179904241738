import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { getWarehouses, loaderState } from '../../../actions';
import { initilizeDataTable, destroyDataTable, warehousesData, goto } from '../../../helpers';
import ViewPurchasesComp from '../../../components/purchases/viewPurchases';

class ViewPurchases extends Component {
	componentDidMount() {
		const { warehouses } = this.props;
		if (warehouses.length === 0) {
			this.props.loaderState(true);
			this.props.getWarehouses();
		}
		initilizeDataTable('view-purchases-table');
	}
	componentDidUpdate() {
		initilizeDataTable('view-purchases-table');
	}
	render() {
		destroyDataTable('view-purchases-table');

		const { warehouses } = this.props;
		return <ViewPurchasesComp warehouses={warehousesData(warehouses)} goto={path => goto(path)} />;
	}
}

const mapStateToProps = state => {
	return {
		warehouses: state.system.warehouses.allWarehouses,
	};
};

export default connect(mapStateToProps, { loaderState, getWarehouses })(ViewPurchases);
