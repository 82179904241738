import React from 'react';
import InlineInputForm from '../../common/inlineInputForm';
import InLineCheckbox from '../../common/inLineCheckbox';

export default function CloneModal({ product, onCloneProduct, onChange }) {
	return (
		<div className="modal fade" id="cloneModal" role="dialog">
			<div className="modal-dialog">
				<div className="modal-content">
					<div className="modal-header">
						<button type="button" className="close" data-dismiss="modal">
							&times;
						</button>
						<h4 className="modal-title">Enter Code and Description for the new Product</h4>
					</div>
					<div className="modal-body">
						<p>
							Cloning from Product
							<strong>
								{product ? ` [${product.productCode}] ${product.productDescription}` : null}
							</strong>
						</p>
						<div className="row" id="inline-input-forms">
							<div className="col-xs-12">
								<InlineInputForm id="productCode" name="* Product Code" onChange={e => onChange(e)} />
							</div>
							<div className="col-xs-12">
								<InlineInputForm
									id="productDescription"
									name="* Product Description"
									onChange={e => onChange(e)}
								/>
							</div>
							<div className="col-xs-12">
								<InlineInputForm id="barcode" name="Barcode" onChange={e => onChange(e)} />
							</div>
							<div className="col-xs-12">
								<InLineCheckbox
									id="clearSupplierData"
									name="Clear Supplier Code and Description"
									onChange={e => onChange(e)}
								/>
							</div>
						</div>
					</div>
					<div className="modal-footer">
						<button
							type="button"
							className="btn btn-success"
							onClick={() => onCloneProduct()}
							// data-dismiss="modal"
						>
							Clone Product
						</button>
						<button type="button" className="btn btn-default" data-dismiss="modal">
							Cancel
						</button>
					</div>
				</div>
			</div>
		</div>
	);
}
