import { settings } from '../../constants/actionTypes';
const initialState = {
	allPrefixes: null,
};

function prefixesReducer(state = initialState, action) {
	switch (action.type) {
		case settings.PREFIXES:
			return { ...state, allPrefixes: action.payload };
		default:
			return state;
	}
}
export default prefixesReducer;
