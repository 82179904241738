import React, { Component } from 'react';
import { initilizeDataTable, destroyDataTable } from '../../../../helpers';
import PurchaseEnquiryComp from '../../../../components/reports/purchase/purchaseEnquiry';

export default class PurchaseEnquiry extends Component {
	componentDidMount() {
		initilizeDataTable('sales-quotes-charge-table');
	}

	componentDidUpdate() {
		initilizeDataTable('sales-quotes-charge-table');
	}

	render() {
		destroyDataTable('sales-quotes-charge-table');
		return <PurchaseEnquiryComp />;
	}
}
