import React from 'react';
import SalesGroupTable from './salesGroupTable';
import ConfirmationModal from '../../../common/confirmationModal';
import OneInputEditModal from '../../../common/OneInputEditModal';

export default function SalesGroups({
	salesGroups,
	addValue,
	editValue,
	onChange,
	onUpdate,
	onAdd,
	onGroupUpdateOrDelete,
	onDelete,
	onChangeEdit,
	obsolete,
}) {
	return (
		<>
			<div className="comp-heading">
				<div className="row">
					<div className="col-lg-4">
						<label>Sales Groups</label>
					</div>
				</div>
			</div>
			<br />
			<div className="panel panel-default">
				<div className="panel-body">
					<div className="row" id="settings-field">
						<div className="col-md-2 col-xs-8">
							<label className="input-label" htmlFor="sales-group-field-name">
								Sales Group Field Label
							</label>
							<input className="form-control" id="sales-group-field-name" />
						</div>
						<div className="col-xs-2" id="system-add-btn">
							<button className="btn btn-success btn-sm">Update</button>
						</div>
					</div>
					<br />
					<div className="row" id="settings-field">
						<div className="col-md-2 col-xs-8">
							<label className="input-label" htmlFor="sales-group-name">
								* Sales Group Name
							</label>
							<input
								value={addValue}
								onChange={e => onChange(e)}
								className="form-control"
								id="sales-group-name"
							/>
						</div>
						<div className="col-xs-2" id="system-add-btn">
							<button onClick={() => onAdd()} className="btn btn-success btn-sm">
								Add
							</button>
						</div>
					</div>
				</div>
			</div>
			<div className="panel panel-default">
				<div className="panel-body">
					<SalesGroupTable salesGroups={salesGroups} onGroupUpdateOrDelete={onGroupUpdateOrDelete} />
				</div>
			</div>
			<ConfirmationModal onDelete={() => onDelete()} item="Sales Group" />
			<OneInputEditModal
				label="Sales Group Name"
				value={editValue}
				onChange={e => onChangeEdit(e)}
				onUpdate={() => onUpdate()}
				checkbox={true}
				checkboxLabel="Obsolete"
				checkboxValue={obsolete}
			/>
		</>
	);
}
