import React from 'react';
import SplitButton from '../../../common/splitButton';
import InlineInputForm from '../../../common/inlineInputForm';
import InlineDropdown from '../../../common/inlineDropDown';
import ProductEnquiryTable from './productEnquiryTable';
import RunReport from '../../../common/runReport';

export default function ProductionEnquiry() {
	return (
		<>
			<div className="comp-heading">
				<div className="row">
					<div className="col-lg-4">
						<label>Production Enquiry</label>
					</div>
					<div className="col-lg-2"></div>
					<div className="col-lg-6" style={{ textAlign: 'right' }}>
						<SplitButton
							label="Export"
							type="light"
							data={['Export as PDF', 'Export as CSV', 'Export as XLSX', 'Export as XLS']}
						/>
						<button className="btn btn-success btn-sm">Run</button>
					</div>
				</div>
			</div>
			<br />
			<div className="panel panel-default">
				<div className="panel-body">
					<div className="row" id="inline-input-forms">
						<div className="col-sm-4">
							<InlineInputForm id="date-from" type="date" value="" name="Date From" />
							<InlineInputForm id="date-to" type="date" value="" name="Date To" />
							<InlineDropdown id="warehouse" name="Warehouse" values={['Warehouse']} />
							<InlineInputForm id="assembly" value="" name="Assembly" />
						</div>
						<div className="col-sm-4">
							<InlineInputForm id="product-code" value="" name="Product Code" />
							<InlineInputForm id="product-description" value="" name="Product Description" />
							<InlineDropdown
								id="product-group"
								name="Product Group"
								values={['', 'product 1', 'product 2']}
							/>
							<InlineDropdown id="status" name="Status" values={['All', 'Parked', 'Completed']} />
						</div>
						<div className="col-sm-4">
							<InlineDropdown
								id="assembly-type"
								name="Assembly Type"
								values={['All', 'Assembly', 'Disassembly']}
							/>
							<InlineDropdown id="status" name="Status" values={['All', 'Manual', 'Auto']} />
							<InlineInputForm id="assembly-by-from" type="date" value="" name="Assembly By From" />
							<InlineInputForm id="assembly-by-to" type="date" value="" name="Assembly By To" />
						</div>
					</div>
				</div>
			</div>
			<div className="panel panel-default">
				<div className="panel-body">
					<ProductEnquiryTable />
					<br />
					<RunReport />
				</div>
			</div>
		</>
	);
}
