import React from 'react';
import docDesignerHOC from '../docDesigner';

function Labels() {
	return <div className="doc-designer">Labels Design</div>;
}

const designerHOC = docDesignerHOC(Labels);

export default designerHOC;
