import React from 'react';

export default function Stats() {
	return (
		<div className="row">
			<div className="col-md-1"></div>
			<div className="col-md-6 col-xs-6">
				<div className="border-bottom">
					<span className="receipt-stat-label">Line Count</span>
					<span className="pull-right receipt-stat-value">0</span>
				</div>
				<div className="border-bottom">
					<span className="receipt-stat-label">Total Volume</span>
					<span className="pull-right receipt-stat-value">0.00</span>
				</div>
				<div className="border-bottom">
					<span className="receipt-stat-label">Total Weight</span>
					<span className="pull-right receipt-stat-value">0.00</span>
				</div>
				<div className="border-bottom">
					<span className="receipt-stat-label">Sell Price Tier</span>
					<span className="pull-right receipt-stat-value"></span>
				</div>
				<div className="border-bottom">
					<span className="receipt-stat-label">Currency Rate</span>
					<span className="pull-right receipt-stat-value">PKR</span>
				</div>
			</div>
			<div className="col-md-5 col-xs-6">
				<div className="border-bottom">
					<span className="receipt-stat-label">Item Count</span>
					<span className="pull-right receipt-stat-value">0</span>
				</div>
				<div className="border-bottom">
					<span className="receipt-stat-label">Sub Total</span>
					<span className="pull-right receipt-stat-value">0.00</span>
				</div>
				<div className="border-bottom">
					<span className="receipt-stat-label">Charge Sub Total</span>
					<span className="pull-right receipt-stat-value">0.00</span>
				</div>
				<div className="border-bottom">
					<span className="receipt-stat-label">Tax Total</span>
					<span className="pull-right receipt-stat-value">0.00</span>
				</div>
				<div className="border-bottom">
					<span className="receipt-stat-label">Total</span>
					<span className="pull-right receipt-stat-value">0.00</span>
				</div>
				<div className="border-bottom">
					<span className="receipt-stat-label">Margin</span>
					<span className="pull-right receipt-stat-value">0.00%</span>
				</div>
				<div className="border-bottom">
					<span className="receipt-stat-label">Profit</span>
					<span className="pull-right receipt-stat-value">0.00</span>
				</div>
			</div>
		</div>
	);
}
