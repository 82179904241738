import React, { Component } from 'react';
import PurchaseOrdersComp from '../../../../components/setting/docDesigner/purchaseOrders/index';

export default class PurchaseOrders extends Component {
	state = {
		templates: ['Formal Purchase', 'Classic Purchase'],
	};

	render() {
		const { templates, newOrDuplicate } = this.state;
		return <PurchaseOrdersComp templates={templates} name="Purchase Orders" />;
	}
}
