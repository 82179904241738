import React from 'react';
import QuickLinksDropdown from './quickLinksDropdown';

export default function header({ goto, user, quicklinks }) {
	return (
		<nav className="navbar navbar-expand-lg navbar-light bg-light app-header">
			<div className="container-fluid">
				<div className="header-btns">
					<button type="button" id="sidebarCollapse" className="navbar-btn">
						<span></span>
						<span></span>
						<span></span>
					</button>

					<button
						id="header-btn"
						className="navbar-btn"
						type="button"
						data-toggle="collapse"
						data-target="#navbarSupportedContent"
						aria-controls="navbarSupportedContent"
						aria-expanded="false"
						aria-label="Toggle navigation"
					>
						<span></span>
						<span></span>
						<span></span>
					</button>
					{/* <button
						id="header-btn"
						className="btn"
						type="button"
						data-toggle="collapse"
						data-target="#navbarSupportedContent"
						aria-controls="navbarSupportedContent"
						aria-expanded="false"
						aria-label="Toggle navigation"
					>
						<i className="fa fa-align-justify"></i>
					</button> */}
				</div>
				<div className="customize-quicklinks">
					<QuickLinksDropdown goto={path => goto(path)} quicklinks={quicklinks} />
				</div>
				<div className="collapse navbar-collapse" id="navbarSupportedContent" style={{ float: 'right' }}>
					<ul className="nav navbar-nav ml-auto">
						<li className="nav-item dropdown" id="header-nav-Item">
							<a className="nav-link" href="#" data-toggle="dropdown">
								{user ? user.first_name + ' ' + user.last_name : 'Profile'}
							</a>
							<div className="dropdown-menu dropdown-menu-right" id="header-dropdown">
								<a className="dropdown-item" href="#">
									<i className="fa fa-user" id="header-account-icon" aria-hidden="true" />
									<span>My Account</span>
								</a>
								<a className="dropdown-item" href="#">
									<i className="fa fa-graduation-cap" id="header-account-icon" aria-hidden="true" />
									<span>Globuss University</span>
								</a>
								<div className="dropdown-divider" id="header-divider"></div>
								<a className="dropdown-item" href="#">
									<i className="fa fa-pencil-square-o" id="header-account-icon" aria-hidden="true" />
									<span>Change Password</span>
								</a>
								<a className="dropdown-item" href="#" onClick={() => goto('/')}>
									<i className="fa fa-sign-out" id="header-account-icon" aria-hidden="true" />
									<span>Log Out</span>
								</a>
							</div>
						</li>
						<li className="nav-item dropdown" id="header-nav-Item">
							<a className="nav-link" href="#" data-toggle="dropdown">
								<i className="fa fa-bell-o"></i>
							</a>
							<div className="dropdown-menu dropdown-menu-right" id="header-dropdown">
								<a className="dropdown-item" href="#">
									<span>Notification 1</span>
								</a>
								<a className="dropdown-item" href="#">
									<span>Notification 2</span>
								</a>
								<a className="dropdown-item" href="#">
									<span>Notification 3</span>
								</a>
								<a className="dropdown-item" href="#">
									<span>Notification 4</span>
								</a>
							</div>
						</li>
						<li className="nav-item dropdown" id="header-nav-Item">
							<a className="nav-link" href="#" data-toggle="dropdown">
								<i className="fa fa-question-circle-o"></i>
							</a>
							<div className="dropdown-menu dropdown-menu-right" id="header-dropdown">
								<a className="dropdown-item" href="#">
									<span>Get help from this page</span>
								</a>
								<a className="dropdown-item" href="#">
									<span>Submit a request</span>
								</a>
								<a className="dropdown-item" href="#">
									<span>Globuss accademy</span>
								</a>
								<div className="dropdown-divider" id="header-divider"></div>
								<a className="dropdown-item" href="#">
									<span>Take the tour</span>
								</a>
							</div>
						</li>
						{/* <li className="nav-item" id="header-nav-Item">
							<a className="nav-link" href="#">
								<i className="fa fa-search" />
							</a>
						</li> */}
					</ul>
				</div>
			</div>
		</nav>
	);
}
