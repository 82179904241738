import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
	loaderState,
	getCustomerTypes,
	deleteCustomerType,
	onUpdateOrDeleteCustomerType,
	addCustomerType,
	updateCustomerType,
} from '../../../../actions';
import swal from 'sweetalert';
import { isEmptyOrNull, initilizeDataTable, destroyDataTable } from '../../../../helpers';
import CustomerTypesComp from '../../../../components/setting/system/customerTypes';

class CustomerTypes extends Component {
	constructor(props) {
		super(props);
		this.state = {
			addValue: null,
			editValue: null,
		};
	}

	componentDidMount() {
		initilizeDataTable('customer-type-table');
		this.props.loaderState(true);
		this.props.getCustomerTypes();
	}

	componentDidUpdate() {
		initilizeDataTable('customer-type-table');
	}

	resetState = () => {
		this.setState({ addValue: null, editValue: null });
	};

	onChange = ({ target }) => {
		this.setState({ addValue: target.value });
	};

	onChangeEdit = ({ target }) => {
		this.setState({ editValue: target.value });
	};

	onUpdate = () => {
		const { selectType, user } = this.props;
		const { editValue } = this.state;

		if (!isEmptyOrNull(editValue)) {
			this.props.loaderState(true);
			this.props.updateCustomerType({ typeName: editValue, id: selectType.id, updatedBy: user.id });
		}
		this.resetState();
	};

	onDelete = () => {
		const { selectType } = this.props;
		this.props.loaderState(true);
		this.props.deleteCustomerType(selectType.id);
		this.resetState();
	};

	onAdd = () => {
		const { user, organization } = this.props;
		const { addValue } = this.state;

		if (!isEmptyOrNull(addValue)) {
			this.props.loaderState(true);
			this.props.addCustomerType({ typeName: addValue, createdBy: user.id, organizationId: organization.id });
		} else {
			swal('', 'Please enter a type value', 'info');
		}
		this.resetState();
	};

	onUpdateOrDelete = data => {
		this.props.onUpdateOrDeleteCustomerType(data);
	};

	render() {
		destroyDataTable('customer-type-table');

		const { addValue, editValue } = this.state;
		const { types, selectType } = this.props;
		return (
			<CustomerTypesComp
				types={types}
				editValue={editValue != null ? editValue : selectType ? selectType.typeName : null}
				addValue={addValue}
				onAdd={() => this.onAdd()}
				onChange={e => this.onChange(e)}
				onUpdate={() => this.onUpdate()}
				onChangeEdit={e => this.onChangeEdit(e)}
				onDelete={() => this.onDelete()}
				onUpdateOrDelete={data => this.onUpdateOrDelete(data)}
			/>
		);
	}
}

const mapStateToProps = state => {
	return {
		types: state.system.customerTypes.allCustomerTypes,
		selectType: state.system.customerTypes.selectedCustomerTypes,
		user: state.users.loggedInUser,
		organization: state.users.organizationInfo,
	};
};

export default connect(mapStateToProps, {
	loaderState,
	getCustomerTypes,
	deleteCustomerType,
	onUpdateOrDeleteCustomerType,
	updateCustomerType,
	addCustomerType,
})(CustomerTypes);
