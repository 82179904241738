import React from 'react';

export default function ExportButton() {
	return (
		<div className="btn-group">
			<button type="button" className="btn btn-light btn-sm">
				Export
			</button>
			<button type="button" className="btn btn-light btn-sm dropdown-toggle" data-toggle="dropdown">
				<span className="caret"></span>
			</button>
			<ul className="dropdown-menu dropdown-menu-right" role="menu">
				<li>
					<a href="#">Export as PDF</a>
				</li>
				<li>
					<a href="#">Export as CSV</a>
				</li>
				<li>
					<a href="#">Export as XLSX</a>
				</li>
				<li>
					<a href="#">Export as XLS</a>
				</li>
			</ul>
		</div>
	);
}
