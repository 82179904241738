import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { getWarehouses, loaderState } from '../../../actions';
import { initilizeDataTable, destroyDataTable, warehousesData, goto } from '../../../helpers';
import AddPurchaseComp from '../../../components/purchases/addPurchase';

class AddPurchase extends Component {
	componentDidMount() {
		const { warehouses } = this.props;
		if (warehouses.length === 0) {
			this.props.loaderState(true);
			this.props.getWarehouses();
		}
		initilizeDataTable('add-purchases-table');
		initilizeDataTable('purchases-cost-lines-table');
	}
	componentDidUpdate() {
		initilizeDataTable('add-purchases-table');
		initilizeDataTable('purchases-cost-lines-table');
	}
	render() {
		destroyDataTable('purchases-cost-lines-table');
		destroyDataTable('add-purchases-table');

		const { warehouses } = this.props;
		return <AddPurchaseComp goto={path => goto(path)} warehouses={warehousesData(warehouses)} />;
	}
}

const mapStateToProps = state => {
	return {
		warehouses: state.system.warehouses.allWarehouses,
	};
};

export default connect(mapStateToProps, { loaderState, getWarehouses })(AddPurchase);
