import React from 'react';

export default function PurchasesTable() {
	return (
		<div className="table-view table-responsive">
			<table id="add-purchases-table" className="table">
				<thead>
					<tr>
						<th>
							<i style={{ fontSize: '1.5em' }} className="fa fa-cog icon" />
						</th>
						<th>Ln</th>
						<th>Image</th>
						<th>Supplier Product Code</th>
						<th>Product Code </th>
						<th>Product Description</th>
						<th>Unit</th>
						<th>Delivery Date</th>
						<th>Quantity</th>
						<th>Price</th>
						<th>Discount</th>
						<th>Disc. Price</th>
						<th>Tax Rate</th>
						<th>Line Total</th>
						<th>Unit Cost</th>
						<th>Costing Total</th>
						<th>Total Unit Cost</th>
						<th>Landed Code</th>
						<th>
							<i style={{ fontSize: '1.5em' }} className="fa fa-comments-o" aria-hidden="true" />
						</th>
						<th>Delete</th>
					</tr>
				</thead>
				<tbody></tbody>
			</table>
		</div>
	);
}
