import React from 'react';

export default function Stats() {
	return (
		<>
			<div className="row">
				<div className="col-xs-6" style={{ textAlign: 'right' }}>
					<small className="stat-label">
						TOTAL VOLUME(M <sup>3</sup>)
					</small>
					<div className="stat-value">0.000</div>
					<small className="stat-label">TOTAL WEIGHT</small>
					<div className="stat-value">0.000</div>
					<small className="stat-label">PAYMENT TERMS</small>
					<div className="stat-value"></div>
					<small className="stat-label">CURRENCY RATE</small>
					<div className="stat-value">PKR</div>
				</div>
				<div className="col-xs-6" style={{ textAlign: 'right' }}>
					<small className="stat-label">ITEM COUNT</small>
					<div className="stat-value">0</div>
					<small className="stat-label">SUB TOTAL</small>
					<div className="stat-value">0.00</div>
					<small className="stat-label">TAX TOTAL</small>
					<div className="stat-value">0.00</div>
					<small className="stat-label">COSTING SUB TOTAL</small>
					<div className="stat-value">0.00</div>
					<small className="stat-label">COSTING TAX TOTAL</small>
					<div className="stat-value">0.00</div>
				</div>
			</div>
			<div className="row" style={{ borderTop: '1px solid #c6ced6' }}>
				<div className="col-xs-6"></div>
				<div className="col-xs-6" style={{ textAlign: 'right' }}>
					<small className="stat-label">TOTAL</small>
					<div className="stat-value">0.00</div>
				</div>
			</div>
		</>
	);
}
