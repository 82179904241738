import { common } from '../../constants/actionTypes';
import axios from 'axios';
import swal from 'sweetalert';
import _ from 'lodash';
import api from '../../api/api';
import data from '../../data';

export const loaderState = state => dispatch => {
	dispatch({ type: common.LOADER, payload: state });
};

export const selectedNav = nav => dispatch => {
	dispatch({ type: common.SELECTED_URL, payload: nav });
};

export const getCountriesData = () => dispatch => {
	axios
		.get('https://restcountries.eu/rest/v2/all')
		.then(res => {
			// let data = [];
			// _.forEach(res.data, country => {
			// 	let exist = _.findLast(data, d => {
			// 		return d.currencies[0].code == country.currencies[0].code;
			// 	});
			// 	if (!exist) {
			// 		data.push(country);
			// 	}
			// });
			dispatch({ type: common.COUNTRIES_DATA, payload: res.data });
			dispatch(loaderState(false));
		})
		.catch(err => {
			swal('', err.message, 'error');
			dispatch(loaderState(false));
		});
};

export const addQuicklLinks = () => dispatch => {
	let dattta = data.quicklinks;
	dattta.forEach(d => {
		let datta = { ...d, status: -1 };
		api.post('/quickLinks', datta)
			.then(res => {
				// if (res.data.code === 200) {
				// 	dispatch({ type: common.QUICK_LINKS, payload: res.data.data });
				// }
				dispatch(loaderState(false));
			})
			.catch(err => {
				dispatch(loaderState(false));
				swal('', err.message, 'error');
			});
	});
};

export const getQuickLinks = () => dispatch => {
	api.get('/quickLinks')
		.then(res => {
			if (res.data.code === 200) {
				dispatch({ type: common.QUICK_LINKS, payload: res.data.data });
			} else {
				swal('', res.data.message, 'error');
			}
			dispatch(loaderState(false));
		})
		.catch(err => {
			dispatch(loaderState(false));
			swal('', err.message, 'error');
		});
};

export const updateQuickLinkStatus = ({ id, status }) => dispatch => {
	api.post(`/quickLinks/update/${id}`, { status })
		.then(res => {
			if (res.data.code === 200) {
				dispatch(getQuickLinks());
			} else {
				swal('', res.data.message, 'error');
				dispatch(getQuickLinks());
				dispatch(loaderState(false));
			}
		})
		.catch(err => {
			swal('', err.message, 'error');
			dispatch(loaderState(false));
		});
};
