import React from 'react';

export default function Attachments(props) {
	var data = [];
	const { onFileDivClick } = props;
	return (
		<div id="Attachments" className="tab-pane fade">
			<div className="panel panel-default">
				<div className="panel-body">
					<div className="file-drop-area" id="attachments" onClick={() => onFileDivClick()}>
						<input id="inputFile" type="file" multiple />
						<i className="fa fa-cloud-upload" aria-hidden="true"></i>
						<p className="file-count">click here to upload files.</p>
					</div>
					<div className="attachments-upload">
						<p className="help center-align" style={{ marginBottom: '20px' }}>
							<i>
								<a href="#" id="uploadFileLink">
									Upload file
								</a>
								size limit is
							</i>
							<strong>
								<span id="uploadFileSizeLimit">20MB</span>
							</strong>
							<br />
							<i>Image and document formats are supported.</i>
						</p>
					</div>
				</div>
			</div>
			<div className="panel panel-default">
				<div className="panel-body">
					<div className="table-view table-responsive">
						<table id="attachments-table" className="table">
							<thead>
								<tr>
									<th style={{ width: '20px' }}></th>
									<th>File Name</th>
									<th>File Size</th>
									<th>Date Upload</th>
									<th>Uploaded By</th>
									<th>Default Product Image</th>
									<th>Default Label Image</th>
									<th>Actions</th>
								</tr>
							</thead>
							<tbody></tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	);
}
