import React from 'react';
import CreditsTable from './creditsTable';

export default function Credits() {
	return (
		<div id="Credits" className="tab-pane fade">
			<div className="row" id="settings-field">
				<div className="col-md-2">
					<label className="input-label" htmlFor="credit_status">
						Credit Status
					</label>
					<select className="form-control" id="credit_status">
						<option>Parked</option>
						<option>Accepted</option>
						<option>All</option>
					</select>
				</div>
			</div>
			<br />
			{/* <div className="panel panel-default">
				<div className="panel-body"> */}
			<CreditsTable />
			{/* </div>
			</div> */}
		</div>
	);
}
