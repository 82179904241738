import React, { Component } from 'react';
import swal from 'sweetalert';
import { getTaxes, loaderState, addTax, onSelectTax, deleteTax, updateTax } from '../../../../actions';
import { connect } from 'react-redux';
import { isEmptyOrNull, initilizeDataTable, destroyDataTable, removeNullValues } from '../../../../helpers';
import TaxesComp from '../../../../components/setting/system/taxes';

var initialState = {
	default_sales_tax: null,
	default_purchase_tax: null,
	taxName: '',
	taxCode: '',
	taxRate: '',
	salesTax: '',
	purchaseTax: '',
	editTaxName: null,
	editTaxCode: null,
	editTaxRate: null,
	editSalesTax: null,
	editPurchaseTax: null,
	obsolete: null,
};
class Taxes extends Component {
	constructor(props) {
		super(props);
		this.state = { ...initialState };
	}

	componentDidMount() {
		this.props.loaderState(true);
		this.props.getTaxes();
		initilizeDataTable('tax-table');
	}

	componentDidUpdate() {
		initilizeDataTable('tax-table');
	}

	onChange = ({ target }) => {
		const { id, type } = target;
		if (type === 'checkbox') {
			this.setState({ [id]: target.checked ? '1' : '-1' });
		} else {
			this.setState({ [id]: target.value });
		}
	};

	onUpdate = () => {
		const { editTaxName, editTaxCode, editTaxRate, editSalesTax, editPurchaseTax, obsolete } = this.state;
		const { selectedTax, user } = this.props;
		let data = { id: selectedTax.id, updatedBy: user.id };
		if (
			isEmptyOrNull(editTaxName) &&
			isEmptyOrNull(editTaxCode) &&
			isEmptyOrNull(editTaxRate) &&
			isEmptyOrNull(editSalesTax) &&
			isEmptyOrNull(editPurchaseTax) &&
			isEmptyOrNull(obsolete)
		) {
		} else {
			if (!isEmptyOrNull(editTaxName)) {
				data.taxName = editTaxName;
			}
			if (!isEmptyOrNull(editTaxCode)) {
				data.taxCode = editTaxCode;
			}
			if (!isEmptyOrNull(editTaxRate)) {
				data.taxRate = editTaxRate;
			}
			if (!isEmptyOrNull(editSalesTax)) {
				data.salesTax = editSalesTax;
			}
			if (!isEmptyOrNull(editPurchaseTax)) {
				data.purchaseTax = editPurchaseTax;
			}
			if (!isEmptyOrNull(obsolete)) {
				data.obsolete = obsolete;
			}
			this.props.loaderState(true);
			this.props.updateTax(data);
		}
	};

	onAdd = () => {
		const { user, organization } = this.props;
		const { taxName, taxCode, taxRate, salesTax, purchaseTax } = this.state;
		if (!salesTax && !purchaseTax) {
			swal('', 'Please choose whether tax applies to sales or purchases or both', 'info');
		} else if (!isEmptyOrNull(taxName) && !isEmptyOrNull(taxCode) && !isEmptyOrNull(taxRate)) {
			if (Number(taxRate) > 100) {
				swal('', 'Tax Rate should not greater then 100', 'error');
			} else {
				let data = {
					taxName,
					taxCode,
					taxRate,
					createdBy: user.id,
					updatedBy: user.id,
					organizationId: organization.id,
				};
				if (salesTax === true && purchaseTax === true) {
					data.salesTax = 1;
					data.purchaseTax = 1;
				} else if (salesTax === true) {
					data.salesTax = 1;
					data.purchaseTax = -1;
				} else {
					data.salesTax = -1;
					data.purchaseTax = 1;
				}
				this.setState({ ...initialState });
				this.props.loaderState(true);
				this.props.addTax(data);
			}
		} else {
			swal('', 'Tax Name, Tax Code and Tax Rate are required fields', 'info');
		}
	};

	onDelete = () => {
		const { selectedTax } = this.props;
		this.props.loaderState(true);
		this.props.deleteTax(selectedTax.id);
	};

	onTaxSelect = data => {
		this.props.onSelectTax(data);
	};

	render() {
		destroyDataTable('tax-table');

		const { taxes, selectedTax } = this.props;

		return (
			<TaxesComp
				onAdd={() => this.onAdd()}
				stateData={this.state}
				taxes={taxes}
				selectedTax={selectedTax}
				onChange={e => this.onChange(e)}
				onUpdate={() => this.onUpdate()}
				onDelete={() => this.onDelete()}
				onTaxSelect={data => this.onTaxSelect(data)}
			/>
		);
	}
}

const mapStateToProps = state => {
	return {
		user: state.users.loggedInUser,
		taxes: state.system.taxes.allTaxes,
		selectedTax: state.system.taxes.selectedTax,
		organization: state.users.organizationInfo,
	};
};

export default connect(mapStateToProps, { getTaxes, loaderState, addTax, onSelectTax, deleteTax, updateTax })(Taxes);
