import React from 'react';

export default function InviteUserModal() {
	return (
		<div className="modal fade" id="invite-user-modal" role="dialog">
			<div className="modal-dialog">
				<div className="modal-content">
					<div className="modal-header">
						<button type="button" className="close" data-dismiss="modal">
							&times;
						</button>
						<h4 className="modal-title">Enter details of the person to invite to your Globuss account.</h4>
					</div>
					<div className="modal-body" style={{ margin: '0 10% 0 10%' }}>
						<div className="form-group">
							<label htmlFor="first-name">* First Name</label>
							<input type="text" className="form-control" id="first-name" />
						</div>
						<div className="form-group">
							<label htmlFor="last-name">* Last Name</label>
							<input type="text" className="form-control" id="last-name" />
						</div>
						<div className="form-group">
							<label htmlFor="email">* Email</label>
							<input type="email" className="form-control" id="email" />
						</div>
						<div className="form-group">
							<label htmlFor="user-role">Select list:</label>
							<select className="form-control" id="user-role">
								<option>Admin</option>
								<option>Super User</option>
							</select>
						</div>
						<div className="form-group">
							<label htmlFor="initation-message">Invitation Message Preview</label>
							<textarea
								disabled
								rows="5"
								value="test would like you to access their account at Globuss Software. test uses Globuss Software to help manage their business."
								type="text"
								className="form-control"
								id="initation-message"
							/>
						</div>
					</div>
					<div className="modal-footer">
						<button type="button" className="btn btn-success" data-dismiss="modal">
							Invite
						</button>
						<button type="button" className="btn btn-default" data-dismiss="modal">
							Close
						</button>
					</div>
				</div>
			</div>
		</div>
	);
}
