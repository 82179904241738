import React from 'react';

export default function DeliveryAddressesTable() {
	return (
		<div className="table-view table-responsive">
			<table id="delivery-address-table" className="table">
				<thead>
					<tr>
						<th>Address Name</th>
						<th>Address Line 1</th>
						<th>Address Line 2</th>
						<th>Suburb</th>
						<th>City</th>
						<th>Region</th>
						<th>Country</th>
						<th>Postal Code</th>
						<th>Delivery Instruction</th>
						<th>Obsolete</th>
					</tr>
				</thead>
				<tbody></tbody>
			</table>
		</div>
	);
}
