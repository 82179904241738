import React, { Component } from 'react';
import { initilizeDataTable, destroyDataTable, goto } from '../../../helpers';
import ViewCustomersComp from '../../../components/customers/view';

export default class ViewCustomers extends Component {
	componentDidMount() {
		initilizeDataTable('view-customers-table');
	}
	componentDidUpdate() {
		initilizeDataTable('view-customers-table');
	}

	render() {
		destroyDataTable('view-customers-table');

		return <ViewCustomersComp goto={path => goto(path)} />;
	}
}
