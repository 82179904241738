import React from 'react';

import Info from '../../common/info';
import UploadFiles from '../../common/uploadFiles';

export default function ProductAttributes({ onProductAttributesUpload }) {
	return (
		<div id="ProductAttributes" className="tab-pane fade">
			<div className="panel panel-default">
				<div className="panel-body">
					<Info
						heading="Importing Product Attributes"
						message="Use this template to update your Product Attributes. For further information on importing data, including required field definitions, please"
						link="visit our help pages."
					/>
					<UploadFiles onFileDivClick={() => onProductAttributesUpload()} id="Product Attributes" />
				</div>
			</div>
		</div>
	);
}
