import React from 'react';
import SplitButton from '../../../common/splitButton';
import InlineInputForm from '../../../common/inlineInputForm';
import InlineDropdown from '../../../common/inlineDropDown';
import Tabs from '../../../common/tabs';
import SalesOrdersTable from './salesOrdersTable';
import AllTable from './allTable';
import RunReport from '../../../common/runReport';

export default function BackOrderEnquiry({ nav }) {
	return (
		<>
			<div className="comp-heading">
				<div className="row">
					<div className="col-lg-4">
						<label>Back Order Enquiry</label>
					</div>
					<div className="col-lg-2"></div>
					<div className="col-lg-6" style={{ textAlign: 'right' }}>
						<SplitButton
							label="Export"
							type="light"
							data={['Export as PDF', 'Export as CSV', 'Export as XLSX', 'Export as XLS']}
						/>
						<button className="btn btn-success btn-sm">Run</button>
					</div>
				</div>
			</div>
			<br />
			<div className="panel panel-default">
				<div className="panel-body">
					<div className="row" id="inline-input-forms">
						<div className="col-sm-4">
							<InlineInputForm id="date-from" type="date" value="" name="Date From" />
							<InlineInputForm id="date-to" type="date" value="" name="Date To" />
							<InlineDropdown
								id="order-status"
								name="Order Status"
								values={['Open', 'Parked', 'Placed', 'Backorder']}
							/>
						</div>
						<div className="col-sm-4">
							<InlineDropdown id="warehouse" name="Warehouse" values={['Select All', 'Warehouse']} />
							<InlineInputForm id="product-code" value="" name="Product Code" />
							<InlineInputForm id="product-description" value="" name="Product Description" />
						</div>
						<div className="col-sm-4">
							<InlineDropdown id="customer-type" name="Customer Type" values={['', 'General']} />
							<InlineInputForm id="customer-code" value="" name="Customer Code" />
							<InlineInputForm id="customer-name" value="" name="Customer Name" />
						</div>
					</div>
				</div>
			</div>
			<div className="panel panel-default">
				<div className="panel-body">
					<Tabs nav={nav} />
					<div className="tab-content">
						<SalesOrdersTable />
						<AllTable />
					</div>
					<br />
					<RunReport />
				</div>
			</div>
		</>
	);
}
