import React from 'react';
import InlineInputForm from '../../../common/inlineInputForm';

export default function Prefixes({ onChange, onUpdate, prefixes, stateData }) {
	return (
		<>
			<div className="comp-heading">
				<div className="row">
					<div className="col-lg-4">
						<label>Prefixes</label>
					</div>
				</div>
			</div>
			<br />
			<div className="panel panel-default">
				<div className="panel-body">
					<div className="row" id="inline-input-forms">
						<div className="col-sm-4">
							<InlineInputForm
								value={prefixes ? prefixes.purchaseOrderPrefix : null}
								disable="disabled"
								onChange={e => onChange(e)}
								name="Purchase Order Prefix"
								id="purchaseOrderPrefix"
							/>
							<InlineInputForm
								disable="disabled"
								value={prefixes ? prefixes.nextPurchaseOrderNumber : null}
								onChange={e => onChange(e)}
								name="Next Purchase Order Number"
								id="nextPurchaseOrderNumber"
							/>
							<InlineInputForm
								disable="disabled"
								value={prefixes ? prefixes.supplierReturnPrefix : null}
								onChange={e => onChange(e)}
								name="Supplier Return Prefix"
								id="supplierReturnPrefix"
							/>
							<InlineInputForm
								disable="disabled"
								value={prefixes ? prefixes.nextSupplierReturnNumber : null}
								onChange={e => onChange(e)}
								name="Next Supplier Return Number"
								id="nextSupplierReturnNumber"
							/>
						</div>
						<div className="col-sm-4">
							<InlineInputForm
								value={prefixes ? prefixes.stockAdjustmentPrefix : null}
								disable="disabled"
								onChange={e => onChange(e)}
								name="Stock Adjustment Prefix"
								id="stockAdjustmentPrefix"
							/>
							<InlineInputForm
								disable="disabled"
								onChange={e => onChange(e)}
								value={prefixes ? prefixes.nextStockAdjustmentNumber : null}
								name="Next Stock Adjustment Number"
								id="nextStockAdjustmentNumber"
							/>
							<InlineInputForm
								disable="disabled"
								onChange={e => onChange(e)}
								value={prefixes ? prefixes.stockTakePrefix : null}
								name="Stock Take Prefix"
								id="stockTakePrefix"
							/>
							<InlineInputForm
								disable="disabled"
								onChange={e => onChange(e)}
								value={prefixes ? prefixes.nextStockTakeNumber : null}
								name="Next Stock Take Number"
								id="nextStockTakeNumber"
							/>
							<InlineInputForm
								value={
									stateData
										? stateData.transferOrderPrefix
											? stateData.transferOrderPrefix
											: prefixes
											? prefixes.transferOrderPrefix
											: null
										: prefixes
										? prefixes.transferOrderPrefix
										: null
								}
								onChange={e => onChange(e)}
								name="Transfer Order Prefix"
								id="transferOrderPrefix"
							/>
							<InlineInputForm
								value={
									stateData
										? stateData.nextTransferOrderNumber
											? stateData.nextTransferOrderNumber
											: prefixes
											? prefixes.nextTransferOrderNumber
											: null
										: prefixes
										? prefixes.nextTransferOrderNumber
										: null
								}
								onChange={e => onChange(e)}
								name="Next Transfer Order Number"
								id="nextTransferOrderNumber"
							/>
							<InlineInputForm
								value={
									stateData
										? stateData.assemblyPrefix
											? stateData.assemblyPrefix
											: prefixes
											? prefixes.assemblyPrefix
											: null
										: prefixes
										? prefixes.assemblyPrefix
										: null
								}
								onChange={e => onChange(e)}
								name="Assembly Prefix"
								id="assemblyPrefix"
							/>
							<InlineInputForm
								value={
									stateData
										? stateData.nextAssemblyNumber
											? stateData.nextAssemblyNumber
											: prefixes
											? prefixes.nextAssemblyNumber
											: null
										: prefixes
										? prefixes.nextAssemblyNumber
										: null
								}
								onChange={e => onChange(e)}
								name="Next Assembly Number"
								id="nextAssemblyNumber"
							/>
							<InlineInputForm
								value={
									stateData
										? stateData.disassemblyPrefix
											? stateData.disassemblyPrefix
											: prefixes
											? prefixes.disassemblyPrefix
											: null
										: prefixes
										? prefixes.disassemblyPrefix
										: null
								}
								onChange={e => onChange(e)}
								name="Disassembly Prefix"
								id="disassemblyPrefix"
							/>
							<InlineInputForm
								value={
									stateData
										? stateData.nextDisassemblyNumber
											? stateData.nextDisassemblyNumber
											: prefixes
											? prefixes.nextDisassemblyNumber
											: null
										: prefixes
										? prefixes.nextDisassemblyNumber
										: null
								}
								onChange={e => onChange(e)}
								name="Next Disassembly Number"
								id="nextDisassemblyNumber"
							/>
						</div>
						<div className="col-sm-4">
							<InlineInputForm
								onChange={e => onChange(e)}
								value={prefixes ? prefixes.salesQuotePrefix : null}
								disable="disabled"
								name="Sales Quote Prefix"
								id="salesQuotePrefix"
							/>
							<InlineInputForm
								onChange={e => onChange(e)}
								value={prefixes ? prefixes.nextSalesQuoteNumber : null}
								disable="disabled"
								name="Next Sales Quote Number"
								id="nextSalesQuoteNumber"
							/>
							<InlineInputForm
								onChange={e => onChange(e)}
								value={prefixes ? prefixes.salesOrderPrefix : null}
								disable="disabled"
								name="Sales Order Prefix"
								id="salesOrderPrefix"
							/>
							<InlineInputForm
								onChange={e => onChange(e)}
								value={prefixes ? prefixes.nextSalesOrderNumber : null}
								disable="disabled"
								name="Next Sales Order Number"
								id="nextSalesOrderNumber"
							/>
							<InlineInputForm
								onChange={e => onChange(e)}
								value={prefixes ? prefixes.salesShipmentPrefix : null}
								disable="disabled"
								name="Sales Shipment Prefix"
								id="salesShipmentPrefix"
							/>
							<InlineInputForm
								onChange={e => onChange(e)}
								value={prefixes ? prefixes.salesInvoicePrefix : null}
								disable="disabled"
								name="Sales Invoice Prefix"
								id="salesInvoicePrefix"
							/>
							<InlineInputForm
								value={prefixes ? prefixes.creditNotePrefix : null}
								disable="disabled"
								onChange={e => onChange(e)}
								name="Credit Note Prefix"
								id="creditNotePrefix"
							/>
							<InlineInputForm
								onChange={e => onChange(e)}
								value={prefixes ? prefixes.nextCreditNoteNumber : null}
								disable="disabled"
								name="Next Credit Note Number"
								id="nextCreditNoteNumber"
							/>
							<InlineInputForm
								onChange={e => onChange(e)}
								value={prefixes ? prefixes.productBatchPrefix : null}
								disable="disabled"
								name="Product Batch Prefix"
								id="productBatchPrefix"
							/>
						</div>
					</div>
					<div className="row" id="prefix-divs">
						<div className="col-md-12">
							<button
								onClick={() => onUpdate()}
								className="btn btn-success btn-sm"
								style={{ float: 'right', marginTop: '5px', width: '100px' }}
							>
								Save
							</button>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
