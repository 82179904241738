import React from 'react';

export default function Single() {
	return (
		<div id="Single" className="tab-pane active">
			<div className="row" id="settings-field">
				<div className="col-sm-2 padding-right">
					<label className="input-label" htmlFor="product">
						*Product
					</label>
					<input type="text" className="form-control" id="product" name="product" />
				</div>
				<div className="col-sm-2 padding-0">
					<label className="input-label" htmlFor="new-code">
						*New Product Code
					</label>
					<input type="text" className="form-control" id="new-code" name="new-code" />
				</div>
				<div className="col-md-3 padding-right" id="system-add-btn">
					<button className="btn btn-success btn-sm">Save</button>
				</div>
			</div>
		</div>
	);
}
