import React from 'react';

export default function ReturnTable() {
	return (
		<div className="table-view table-responsive">
			<table id="returns-table" className="table">
				<thead>
					<tr>
						<th>Supplier Return No</th>
						<th>Return Date</th>
						<th>Supplier</th>
						<th>Order Number</th>
						<th>Warehouse</th>
						<th>Status</th>
						<th>Currency</th>
						<th>Total</th>
						<th>Actions</th>
					</tr>
				</thead>
				<tbody></tbody>
			</table>
		</div>
	);
}
