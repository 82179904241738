import React, { Component } from 'react';
import { initilizeDataTable, destroyDataTable } from '../../../helpers';
import AddWarehouseTransferComp from '../../../components/inventory/warehouseTransfers/addWarehouseTransfer';

export default class AddWarehouseTransfer extends Component {
	componentDidMount() {
		initilizeDataTable('add-warehouse-transfers-table');
	}
	componentDidUpdate() {
		initilizeDataTable('add-warehouse-transfers-table');
	}
	render() {
		destroyDataTable('add-warehouse-transfers-table');
		return <AddWarehouseTransferComp />;
	}
}
