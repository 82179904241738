import React from 'react';

import Info from '../../common/info';
import UploadFiles from '../../common/uploadFiles';
export default function InventoryDetails({ onInventoryDetailsUpload }) {
	return (
		<div id="InventoryDetails" className="tab-pane fade">
			<div className="panel panel-default">
				<div className="panel-body">
					<Info
						heading="Importing Inventory Details"
						message="Use this template to update your Bin Location, Minimum Stock Level or Maximum Stock Level per warehouse. For further information on importing data, including required field definitions, please"
						link="visit our help pages."
					/>
					<UploadFiles onFileDivClick={() => onInventoryDetailsUpload()} id="Inventory Details" />
				</div>
			</div>
		</div>
	);
}
