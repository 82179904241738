import React from 'react';

export default function Transaction() {
	var arr = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
	return (
		<div id="Trasactions" className="tab-pane fade">
			{/* <div className="panel panel-default">
				<div className="panel-body"> */}
			<div className="table-view table-responsive">
				<table id="transaction-table" className="table">
					<thead>
						<tr>
							<th>Transaction Date</th>
							<th>Transaction Ref</th>
							<th>Warehouse</th>
							<th>Transaction Type</th>
							<th>Product Code</th>
							<th>Product Description</th>
							<th>Value</th>
							<th>Quantity</th>
							<th>Running Total</th>
						</tr>
					</thead>
					<tbody>
						{arr.map(a => {
							return (
								<tr key={a}>
									<td>13/09/2017</td>
									<td>
										<label className="input-label">
											<a href="#" target="_blank">
												PO-000001
											</a>
										</label>
									</td>
									<td>
										<label className="input-label">
											<a href="#" target="_blank">
												Head Office
											</a>
										</label>
									</td>
									<td>Stock Import</td>
									<td>
										<label className="input-label">
											<a href="#" target="_blank">
												DINING
											</a>
										</label>
									</td>
									<td>
										<label className="input-label">
											<a href="#" target="_blank">
												Dining Table
											</a>
										</label>
									</td>
									<td align="right">285.00</td>
									<td align="right">6.00</td>
									<td align="right">6.00</td>
								</tr>
							);
						})}
					</tbody>
				</table>
			</div>
		</div>
		// 	</div>
		// </div>
	);
}
