import React, { Component } from 'react';
import { initilizeDataTable, destroyDataTable } from '../../../../helpers';
import ShipmentComp from '../../../../components/reports/sales/shipment';

export default class Shipment extends Component {
	componentDidMount() {
		initilizeDataTable('sales-quotes-charge-table');
	}

	componentDidUpdate() {
		initilizeDataTable('sales-quotes-charge-table');
	}

	render() {
		destroyDataTable('sales-quotes-charge-table');
		return <ShipmentComp />;
	}
}
