import api from '../../api/api';
import { success, failed } from '../../constants/messages';
import { settings } from '../../constants/actionTypes';
import { loaderState } from '..';
import _ from 'lodash';
import swal from 'sweetalert';

export const getDeliveryMethods = () => dispatch => {
	api.get('/deliveryMethods')
		.then(res => {
			if (res.data.code === 200) {
				dispatch({ type: settings.DELIVERY_METHODS, payload: res.data.data });
			} else {
				swal('', res.data.message, 'error');
			}
			dispatch(loaderState(false));
		})
		.catch(err => {
			swal('', err.message, 'error');
			dispatch(loaderState(false));
		});
};

export const addDeliveryMethods = ({ methodName, createdBy, organizationId }) => (dispatch, getState) => {
	var methods = getState().system.deliveryMethods.allMethods;
	api.post('/deliveryMethods', { methodName, createdBy, updatedBy: createdBy, organizationId })
		.then(res => {
			if (res.data.code === 200) {
				dispatch({ type: settings.DELIVERY_METHODS, payload: [...methods, res.data.data] });
			} else {
				swal('', res.data.message, 'error');
			}
			dispatch(loaderState(false));
		})
		.catch(err => {
			swal('', err.message, 'error');
			dispatch(loaderState(false));
		});
};

export const deleteDeliveryMethods = id => (dispatch, getState) => {
	const { allMethods } = getState().system.deliveryMethods;
	api.delete(`/deliveryMethods/${id}`)
		.then(res => {
			if (Number(res.data.code) === 200) {
				_.remove(allMethods, method => {
					return Number(id) == Number(method.id);
				});
				dispatch(loaderState(false));
			} else {
				swal('', res.data.message, 'error');
				dispatch(loaderState(false));
			}
		})
		.catch(err => {
			swal('', err.message, 'error');
			dispatch(loaderState(false));
		});
};

export const updateDeliveryMethods = ({ methodName, id, updatedBy }) => (dispatch, getState) => {
	var methods = getState().system.deliveryMethods.allMethods;
	api.post(`/deliveryMethods/update/${id}`, { methodName, updatedBy })
		.then(res => {
			if (Number(res.data.code)) {
				let updatedMethod = _.findLast(methods, method => {
					return Number(method.id) == Number(id);
				});
				updatedMethod.methodName = methodName;
				let updatedMethods = _.map(methods, method => {
					return method.id === id ? updatedMethod : method;
				});

				dispatch({ type: settings.DELIVERY_METHODS, payload: updatedMethods });
				dispatch(loaderState(false));
			} else {
				swal('', res.data.message, 'error');
				dispatch(loaderState(false));
			}
		})
		.catch(err => {
			swal('', err.message, 'error');
			dispatch(loaderState(false));
		});
};

export const onUpdateOrDeleteDeliveryMethods = data => dispatch => {
	dispatch({ type: settings.SELECTED_DELIVERY_METHODS, payload: data });
};
