import React from 'react';

export default function StockTable() {
	return (
		<div className="table-view table-responsive">
			<table id="stock-adjustments-table" className="table">
				<thead>
					<tr>
						<th>Adjustment No</th>
						<th>Date</th>
						<th>Warehouse</th>
						<th>Status</th>
						<th>Reason</th>
						<th>Lines</th>
						<th>Total Adjustment Quantity</th>
						<th>Total Adjustment Value</th>
						<th>Actions</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>
							<label className="input-label">
								<a href="#">SA-000001</a>
							</label>
						</td>
						<td>12/12/2019</td>
						<td>
							<label className="input-label">
								<a href="#">Main Warehouse</a>
							</label>
						</td>
						<td>
							<span
								className="stock-adjustment-status"
								style={{ border: '2px solid #5BA55E', color: '#5BA55E' }}
							>
								Completed
							</span>
						</td>
						<td>End Of Season</td>
						<td>9</td>
						<td>1300.00</td>
						<td>2345678.00</td>
						<td>
							<i data-toggle="modal" data-target="#confirmationModal" className="fa fa-trash-o icon" />
							<i className="fa fa-pencil-square-o icon" />
						</td>
					</tr>
					<tr>
						<td>
							<label className="input-label">
								<a href="#" target="_blank">
									SA-000001
								</a>
							</label>
						</td>
						<td>12/12/2019</td>
						<td>
							<label className="input-label">
								<a href="#" target="_blank">
									Main Warehouse
								</a>
							</label>
						</td>
						<td>
							<span className="stock-adjustment-status" style={{ border: '2px solid red', color: 'red' }}>
								Deleted
							</span>
						</td>
						<td>End Of Season</td>
						<td>9</td>
						<td>1300.00</td>
						<td>2345678.00</td>
						<td>
							<i data-toggle="modal" data-target="#confirmationModal" className="fa fa-trash-o icon" />
							<i className="fa fa-pencil-square-o icon" />
						</td>
					</tr>
					<tr>
						<td>
							<label className="input-label">
								<a href="#" target="_blank">
									SA-000001
								</a>
							</label>
						</td>
						<td>12/12/2019</td>
						<td>
							<label className="input-label">
								<a href="#">Main Warehouse</a>
							</label>
						</td>
						<td>
							<span
								className="stock-adjustment-status"
								style={{ border: '2px solid #f06e23', color: '#f06e23' }}
							>
								Parked
							</span>
						</td>
						<td>End Of Season</td>
						<td>9</td>
						<td>1300.00</td>
						<td>2345678.00</td>
						<td>
							<i data-toggle="modal" data-target="#confirmationModal" className="fa fa-trash-o icon" />
							<i className="fa fa-pencil-square-o icon" />
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	);
}
