import React, { Component } from 'react';

import BillingComp from '../../../../components/setting/organization/billing';

export default class Billing extends Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedPlan: '1',
		};
	}

	onChangePlan = selectedPlan => {
		this.setState({ selectedPlan });
	};

	render() {
		const { selectedPlan } = this.state;
		console.log(selectedPlan);
		return <BillingComp selectedPlan={selectedPlan} onChangePlan={plan => this.onChangePlan(plan)} />;
	}
}
