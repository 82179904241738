import React from 'react';
import InlineInputForm from '../../common/inlineInputForm';
import InlineDropdown from '../../common/inlineDropDown';
import DisAssembliesTable from './disAssembliesTable';

export default function AddDisAssembly({ warehouses }) {
	return (
		<>
			<div className="comp-heading">
				<div className="row">
					<div className="col-lg-4">
						<label>Add DisAssembly</label>
					</div>
				</div>
			</div>
			<br />
			<div className="panel panel-default">
				<div className="panel-body">
					<div className="row" id="inline-input-forms">
						<div className="col-sm-4">
							<InlineDropdown id="source_warehouse" name="Source Warehouse" values={warehouses} />
							<InlineDropdown
								id="destination_warehouse"
								name="Destination Warehouse"
								values={warehouses}
							/>
							<InlineInputForm id="product_code" value="" name="*Product Code" />
							<InlineInputForm id="product_description" value="" name="*Product Description" />
							<InlineInputForm
								disable="disabled"
								id="disassembled_quantity"
								value="0"
								name="DisAssembled Quantity"
							/>
							<InlineInputForm
								disable="disabled"
								id="can_disassemble_quantity"
								value="0"
								name="Can DisAssemble Quantity"
							/>
						</div>
						<div className="col-sm-4">
							<InlineInputForm
								id="assemble_by"
								disable="disabled"
								type="date"
								value=""
								name="Assemble By"
							/>
							<div className="form-inline">
								<div className="form-group">
									<label htmlFor="comments">Comments</label>
									<textarea rows="7" type="text" className="form-control" id="comments" />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="panel panel-default">
				<div className="panel-body">
					<div className="row" id="settings-field">
						<div className="col-md-6 padding-right">
							<label className="input-label" htmlFor="component_product">
								*Component Product
							</label>
							<input
								disabled
								type="text"
								className="form-control"
								id="component_product"
								name="component_product"
							/>
						</div>
						<div className="col-md-2 padding-0">
							<label className="input-label" htmlFor="quantity">
								*Quantity
							</label>
							<input disabled type="text" className="form-control" id="quantity" name="quantity" />
						</div>
						<div className="col-md-2" id="add-production-btn">
							<label className="input-label">Add</label>
							<button disabled type="button" className="btn btn-success btn-sm">
								Add
							</button>
						</div>
					</div>
					<br />
					<DisAssembliesTable />
				</div>
			</div>
		</>
	);
}
