import React from 'react';
import Tabs from '../../common/tabs';
import Details from './details';
import Address from './address';
import Contacts from './contacts';
import Delivery from './delivery';
import Quotes from './quotes';
import CustomerPricing from './customerPricing';
import Orders from './orders';
import Credits from './credits';

export default function AddCustomer({ nav }) {
	return (
		<>
			<div className="comp-heading">
				<div className="row">
					<div className="col-lg-4">
						<label>Add Customer</label>
					</div>
					<div className="col-lg-5 col-md-2 col-1"></div>
					<div className="col-lg-3 col-md-3 col-3" style={{ textAlign: 'right' }}>
						<button className="btn btn-success btn-sm">Save</button>
					</div>
				</div>
			</div>
			<br />
			<div className="panel panel-default">
				<div className="panel-body">
					<Tabs nav={nav} />
					<div className="tab-content">
						<Details />
						<Address />
						<Contacts />
						<Delivery />
						<Quotes />
						<Orders />
						<Credits />
						<CustomerPricing />
					</div>
				</div>
			</div>
		</>
	);
}
