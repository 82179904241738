import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class sidebar extends Component {
	haveSubManu = (manu, haveSubManu, reportsId) => {
		const { onNavClick, nav } = this.props;
		var res = manu.name.replace(/\s/g, '');
		let openManuArr = nav.split('/');
		let openManu =
			openManuArr
				.map(function(item) {
					return item.toLowerCase();
				})
				.indexOf(manu.name.toLowerCase()) != -1;
		return haveSubManu ? (
			<a
				href={`#${res}Submanu${reportsId}`}
				data-toggle="collapse"
				aria-expanded={openManu ? 'true' : 'false'}
				id="sideBar-a"
				className={openManu ? '' : 'collapsed'}
			>
				<i className={`${manu.icon} icon`}></i>
				{manu.name}
				{/* <i id="sidebar-i-tag" className="fa fa-angle-right" aria-expanded={openManu ? 'true' : 'false'}></i> */}
			</a>
		) : (
			<a
				id="sideBar-a"
				className={nav === manu.url ? 'active-nav' : ''}
				// to={`${manu.url}`}
				onClick={() => {
					onNavClick(manu.url);
				}}
			>
				<i className={`${manu.icon} icon`}></i>
				{manu.name}
			</a>
		);
	};
	render() {
		const { manus, nav } = this.props;
		return (
			<>
				<div className="logo">Globuss</div>
				<div id="sidebar">
					<ul className="list-unstyled components" id="sidebar-ul">
						{manus.map(manu => {
							let subManu = manu.subManu ? true : false;
							let parentManu = manu.name.replace(/\s/g, '');
							let reportsId = parentManu === 'Reports' ? '1' : '';
							let openManuArr = nav.split('/');
							let openManu =
								openManuArr
									.map(function(item) {
										return item.toLowerCase();
									})
									.indexOf(manu.name.toLowerCase()) != -1;
							return (
								<React.Fragment key={manu.name}>
									{manu.name === 'Settings' ? <hr /> : null}
									<li key={manu.name}>
										{this.haveSubManu(manu, subManu, reportsId)}
										{subManu ? (
											<ul
												className={`list-unstyled collapse ${openManu ? 'in' : ''}`}
												id={`${parentManu}Submanu${reportsId}`}
												aria-expanded={openManu ? 'true' : 'false'}
											>
												{manu.subManu.map(manu1 => {
													let subSubManu = manu1.subSubManu ? true : false;
													let childManu = manu1.name.replace(/\s/g, '');
													let openManu1Arr = nav.split('/');
													let openManu1 =
														openManu1Arr
															.map(function(item) {
																return item.toLowerCase();
															})
															.indexOf(manu1.name.toLowerCase()) != -1;
													return (
														<li key={manu1.name}>
															{this.haveSubManu(manu1, subSubManu, reportsId)}
															{subSubManu ? (
																<ul
																	className={`list-unstyled collapse ${
																		openManu1 ? 'in' : ''
																	}`}
																	id={`${childManu}Submanu${reportsId}`}
																	aria-expanded={openManu1 ? 'true' : 'false'}
																>
																	{manu1.subSubManu.map(manu2 => {
																		return (
																			<li className="leaf-manu" key={manu2.name}>
																				{this.haveSubManu(manu2, false, '1')}
																			</li>
																		);
																	})}
																</ul>
															) : null}
														</li>
													);
												})}
											</ul>
										) : null}
									</li>
								</React.Fragment>
							);
						})}
					</ul>
				</div>
			</>
		);
	}
}

export default sidebar;
