import React from 'react';

export default function SupplierReturnTable({ supplierReasons, onReasonUpdateOrDelete }) {
	return (
		<div className="table-view table-responsive" id="setting-tables">
			<table id="supplier-return-reason-table" className="table">
				<thead>
					<tr>
						<th>Supplier Return Reason</th>
						<th>Actions</th>
					</tr>
				</thead>
				<tbody>
					{supplierReasons
						? supplierReasons.map(data => (
								<tr>
									<td>{data.reason}</td>
									<td>
										<i
											data-toggle="modal"
											data-target="#confirmationModal"
											className="fa fa-trash-o icon"
											onClick={() => onReasonUpdateOrDelete(data)}
										/>
										<i
											data-toggle="modal"
											data-target="#oneInputEditModal"
											className="fa fa-pencil-square-o icon"
											onClick={() => onReasonUpdateOrDelete(data)}
										/>
									</td>
								</tr>
						  ))
						: null}
				</tbody>
			</table>
		</div>
	);
}
