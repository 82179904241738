import React from 'react';
import SplitButton from '../../../common/splitButton';
import InlineInputForm from '../../../common/inlineInputForm';
import InlineDropdown from '../../../common/inlineDropDown';
import RunReport from '../../../common/runReport';
import StockOnHandTable from './stockOnHandTable';

export default function StockOnHandEnquiry() {
	return (
		<>
			<div className="comp-heading">
				<div className="row">
					<div className="col-lg-4">
						<label>Stock On Hand Enquiry</label>
					</div>
					<div className="col-lg-2"></div>
					<div className="col-lg-6" style={{ textAlign: 'right' }}>
						<SplitButton
							label="Export"
							type="light"
							data={['Export as PDF', 'Export as CSV', 'Export as XLSX', 'Export as XLS']}
						/>
						<button className="btn btn-success btn-sm">Run</button>
					</div>
				</div>
			</div>
			<br />
			<div className="panel panel-default">
				<div className="panel-body">
					<div className="row" id="inline-input-forms">
						<div className="col-sm-4">
							<InlineInputForm id="date-to" type="date" value="" name="Date To" />
							<InlineDropdown
								id="product-group"
								name="Product Group"
								values={['Product 1', 'Product 2', 'Product 3']}
							/>
							<InlineDropdown id="warehouse" name="Warehouse" values={['', 'All', 'Warehouse']} />
						</div>
						<div className="col-sm-4">
							<InlineInputForm id="product-code" value="" name="Product Code" />
							<InlineInputForm id="product-description" value="" name="Product Description" />
							<InlineDropdown
								id="show-product"
								name="Show Product"
								values={['All', 'Stock On Hand', 'Stock On Hand or Allocated']}
							/>
						</div>
						<div className="col-sm-4">
							<InlineInputForm id="supplier-code" value="" name="Supplier Code" />
							<InlineInputForm id="supplier-name" value="" name="Supplier Name" />
						</div>
					</div>
				</div>
			</div>
			<div className="panel panel-default">
				<div className="panel-body">
					<StockOnHandTable />
					<br />
					<RunReport />
				</div>
			</div>
			<div className="row">
				<div className="col-xs-6"></div>
				<div className="col-xs-6">
					<div className="panel panel-default" id="panel-margin-right">
						<div className="panel-body">
							<div className="stat-div">
								<small className="stat-label">ON PURCHASE</small>
								<div className="stat-value"></div>
								<small className="stat-label">QUANTITY ON HAND</small>
								<div className="stat-value"></div>
								<small className="stat-label">ALLOCATED QUANTITY</small>
								<div className="stat-value"></div>
								<small className="stat-label">AVAILABLE QUANTITY</small>
								<div className="stat-value"></div>
								<small className="stat-label">TOTAL COST</small>
								<div className="stat-value"></div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
