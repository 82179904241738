import React from 'react';
import Info from '../../common/info';
import StepsInfo from '../../common/stepsInfo';

export default function ImportSuppliers() {
	return (
		<>
			<div className="comp-heading">
				<div className="row">
					<div className="col-lg-4">
						<label>Import Suppliers</label>
					</div>
				</div>
			</div>
			<br />
			<div className="panel panel-default">
				<div className="panel-body">
					<Info
						heading=" Importing Suppliers"
						message="We recommend that you only import the data that you need to in limited batches, i.e. no more than 1000 rows at one time."
						link="Click for more information."
					/>
					<h2 className="stock-revaluation-steps">Import your Suppliers into Globuss in three easy steps</h2>
					<StepsInfo
						heading="Download your Suppliers template file"
						step="1"
						link="Download Suppliers template"
						message="Start by downloading our Suppliers Excel template file. This file has the correct column headings Globuss needs to import your suppliers data."
					/>
					<StepsInfo
						heading="Add your Suppliers to the template file"
						step="2"
						link="Click for Suppliers template (Field Definitions)"
						message="Export your data from your old system. Using Excel or another spreadsheet editor, copy and paste your data from the exported file into the Globuss template. Make sure the data you copy matches the column headings in the template. See the field definitions link below for details on what to enter in each column."
					/>
					<StepsInfo
						heading="Upload the updated template file"
						step="3"
						message="The file you import must be a valid Excel file in .CSV format."
					/>
					<button style={{ marginLeft: '5px' }} className="btn btn-success">
						Upload Suppliers
					</button>
				</div>
			</div>
		</>
	);
}
