import React from 'react';

export default function BatchNumberEnquiry() {
	return (
		<>
			<div className="comp-heading">
				<div className="row">
					<div className="col-lg-4">
						<label>Batch Number Enquiry</label>
					</div>
				</div>
			</div>
			<br />
			batchNumberEnquiry
		</>
	);
}
