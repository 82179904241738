import React, { Component } from 'react';
import ViewProductTable from './viewProductTable';
import SplitButton from '../../common/splitButton';
import Select from 'react-select';
import CloneModal from '../addProduct/cloneModal';

export default function ViewProducts({
	gotoProduct,
	products,
	onClickAction,
	onProductSelect,
	productGroups,
	handleChange,
	goto,
	selectedProduct,
	onCloneProduct,
	onChange,
}) {
	return (
		<>
			<div className="comp-heading">
				<div className="row">
					<div className="col-lg-4">
						<label>View Products</label>
					</div>
					<div className="col-lg-8" style={{ textAlign: 'right' }}>
						<button className="btn btn-light btn-sm">Import</button>
						<SplitButton
							label="Export"
							type="light"
							data={['Export to PDF', 'Export to XLS', 'Export to XLSX', 'Export to CSV']}
						/>
						<button
							onClick={() => goto('/inventory/products/add product')}
							className="btn btn-success btn-sm"
						>
							Add Product
						</button>
					</div>
				</div>
			</div>
			<br />
			<div className="panel panel-default">
				<div className="panel-body">
					<div className="row" id="settings-field">
						<div className="col-md-2 padding-right">
							<label className="input-label" htmlFor="product">
								Product
							</label>
							<input type="text" className="form-control" id="product" name="product" />
						</div>
						<div className="col-md-2 padding-0">
							<label className="input-label" htmlFor="productGroupId">
								Product Group
							</label>
							<Select
								name="productGroupId"
								id="productGroupId"
								isClearable
								onChange={handleChange}
								options={productGroups}
							/>
						</div>
						<div className="col-md-2 padding-0">
							<label className="input-label" htmlFor="supplier">
								Supplier
							</label>
							<input type="text" className="form-control" id="supplier" name="supplier" />
						</div>
						<div className="col-md-2 padding-0">
							<label className="input-label" htmlFor="supplier-product">
								Supplier Product
							</label>
							<input type="text" className="form-control" id="supplier-product" name="supplier-product" />
						</div>
						<div className="col-md-2 padding-0">
							<label className="input-label" htmlFor="bin-location">
								Bin Location
							</label>
							<input type="text" className="form-control" id="bin-location" name="bin-location" />
						</div>
						<div className="col-md-2 padding-0">
							<label className="input-label" htmlFor="barcode">
								Barcode
							</label>
							<input type="text" className="form-control" id="barcode" name="barcode" />
						</div>
					</div>
					<div className="row" id="settings-field" style={{ marginTop: '5px' }}>
						<div className="col-md-2 padding-right">
							<label className="input-label" htmlFor="obsolete">
								Obsolet
							</label>
							<Select
								name="obsolete"
								id="obsolete"
								isClearable
								onChange={handleChange}
								options={[
									{ value: '1', label: 'Yes' },
									{ value: '-1', label: 'No' },
								]}
							/>
						</div>
						<div className="col-md-2 padding-0">
							<label className="input-label" htmlFor="sellable">
								Sellable
							</label>
							<Select
								name="sellable"
								id="sellable"
								isClearable
								onChange={handleChange}
								options={[
									{ value: '1', label: 'Yes' },
									{ value: '-1', label: 'No' },
								]}
							/>
						</div>
					</div>
				</div>
			</div>
			<div className="panel panel-default">
				<div className="panel-body">
					<ViewProductTable
						gotoProduct={product => gotoProduct(product)}
						onProductSelect={product => onProductSelect(product)}
						products={products}
						onClickAction={action => onClickAction(action)}
					/>
				</div>
			</div>

			<CloneModal product={selectedProduct} onChange={e => onChange(e)} onCloneProduct={() => onCloneProduct()} />
		</>
	);
}
