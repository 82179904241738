import React from 'react';
import ReturnTable from './returnsTable';

export default function Returns() {
	return (
		<div id="Returns" className="tab-pane fade">
			<div className="row" id="settings-field">
				<div className="col-md-2">
					<label className="input-label" htmlFor="return-status">
						Return Status
					</label>
					<select className="form-control" id="return-status">
						<option>All</option>
						<option>Parked</option>
						<option>Completed</option>
					</select>
				</div>
			</div>
			<br />
			{/* <div className="panel panel-default">
				<div className="panel-body"> */}
			<ReturnTable />
			{/* </div>
			</div> */}
		</div>
	);
}
