import React from 'react';
import StockCountTable from './stockCountTable';

export default function StockCount({ goto }) {
	return (
		<>
			<div className="comp-heading">
				<div className="row">
					<div className="col-lg-4 col-md-7 col-8">
						<label>Stock Count</label>
					</div>
					<div className="col-lg-5 col-md-2 col-1"></div>
					<div className="col-lg-3 col-md-3 col-3" style={{ textAlign: 'right' }}>
						<button className="btn btn-default btn-sm">Import Count</button>
						<button onClick={() => goto('/stockTakes/createCount')} className="btn btn-success btn-sm">
							Add Count
						</button>
					</div>
				</div>
			</div>
			<br />
			<div className="panel panel-default">
				<div className="panel-body">
					<div className="row" id="stock-count">
						<div className="col-md-2 padding-right">
							<label className="input-label" htmlFor="stock-count-status">
								Status
							</label>
							<select className="form-control" id="stock-count-status">
								<option>All</option>
								<option>In Progress</option>
								<option>Completed</option>
								<option>Deleted</option>
							</select>
						</div>
						<div className="col-md-2 padding-0">
							<label className="input-label" htmlFor="stock-count-reason">
								Warehouse
							</label>
							<select className="form-control" id="stock-count-reason">
								<option></option>
								<option></option>
								<option>Head Office</option>
								<option>Main Warehouse</option>
							</select>
						</div>
					</div>
				</div>
			</div>
			<div className="panel panel-default">
				<div className="panel-body">
					<StockCountTable />
				</div>
			</div>
		</>
	);
}
