import React, { Component } from 'react';
import SalesOrdersComp from '../../../../components/setting/docDesigner/salesOrders/index';

export default class SalesOrders extends Component {
	state = {
		templates: ['Formal Order', 'Classic order'],
	};
	render() {
		const { templates } = this.state;
		return <SalesOrdersComp templates={templates} name="Sales Order" />;
	}
}
