import React from 'react';
import ShipmentsTable from './shipmentsTable';

export default function Shipments() {
	return (
		<div id="Shipments" className="tab-pane fade">
			<ShipmentsTable />
		</div>
	);
}
