import api from '../../api/api';
import { success, failed } from '../../constants/messages';
import { settings } from '../../constants/actionTypes';
import { loaderState } from '..';
import _ from 'lodash';
import swal from 'sweetalert';

export const getPriceTiers = () => dispatch => {
	api.get('/sellPriceTier')
		.then(res => {
			if (res.data.code === 200) {
				dispatch({ type: settings.SELL_PRICE_TIERS, payload: res.data.data });
			} else {
				swal('', res.data.message, 'error');
			}
			dispatch(loaderState(false));
		})
		.catch(err => {
			swal('', err.message, 'error');
			dispatch(loaderState(false));
		});
};

export const addTiers = ({ name, createdBy }) => (dispatch, getState) => {
	var allTiers = getState().system.sellPriceTiers.allTiers;
	var org = getState().users.organizationInfo;
	api.post('/sellPriceTier', { name, createdBy, updatedBy: createdBy, organizationId: org.id })
		.then(res => {
			if (res.data.code === 200) {
				dispatch({ type: settings.SELL_PRICE_TIERS, payload: [...allTiers, res.data.data] });
			} else {
				swal('', res.data.message, 'error');
			}
			dispatch(loaderState(false));
		})
		.catch(err => {
			swal('', err.message, 'error');
			dispatch(loaderState(false));
		});
};

export const updateTier = ({ name, id, updatedBy }) => (dispatch, getState) => {
	var allTiers = getState().system.sellPriceTiers.allTiers;
	api.post(`/sellPriceTier/update/${id}`, { name, updatedBy })
		.then(res => {
			if (Number(res.data.code) === 200) {
				let updatedTier = _.findLast(allTiers, tier => {
					return Number(tier.id) == Number(id);
				});
				updatedTier.name = name;
				let updatedTiers = _.map(allTiers, role => {
					return role.id === id ? updatedTier : role;
				});
				dispatch({ type: settings.ROLES, payload: updatedTiers });
			} else {
				swal('', res.data.message, 'error');
			}
			dispatch(loaderState(false));
		})
		.catch(err => {
			swal('', err.message, 'error');
			dispatch(loaderState(false));
		});
};
