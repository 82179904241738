import React, { Component } from 'react';
import SalesQuotesComp from '../../../../components/setting/docDesigner/salesQuotes/index';

export default class SalesQuotes extends Component {
	state = {
		templates: ['Default Sales Quote', 'NEW TEST'],
	};
	render() {
		const { templates } = this.state;
		return <SalesQuotesComp templates={templates} name="Sales Quote" />;
	}
}
