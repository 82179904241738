import React from 'react';

export default function ExportCredits() {
	return (
		<>
			<div className="comp-heading">
				<div className="row">
					<div className="col-lg-4">
						<label>Export Credits</label>
					</div>
				</div>
			</div>
			<br />
			ExportCredits
		</>
	);
}
