import React, { Component } from 'react';
import { initilizeDataTable, destroyDataTable, goto } from '../../../helpers';
import MarketPriceUpdateComp from '../../../components/inventory/marketPriceUpdate';

class MarketPriceUpdate extends Component {
	constructor(props) {
		super(props);
		this.state = {
			nav: ['Batch', 'Log'],
		};
	}

	componentDidMount() {
		initilizeDataTable('batch-table');
		initilizeDataTable('log-table');
	}

	componentDidUpdate() {
		initilizeDataTable('batch-table');
		initilizeDataTable('log-table');
	}

	render() {
		destroyDataTable('batch-table');
		destroyDataTable('log-table');

		let data = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];

		let priceTiers = [
			{ label: 'tier 1', value: 'value 1' },
			{ label: 'tier 2', value: 'value 2' },
			{ label: 'tier 3', value: 'value 3' },
			{ label: 'tier 4', value: 'value 4' },
		];
		return <MarketPriceUpdateComp priceTiers={priceTiers} data={data} nav={this.state.nav} />;
	}
}

export default MarketPriceUpdate;
