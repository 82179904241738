import React from 'react';
import TableActions from '../../common/tableActions';

export default function SuppplierTable() {
	return (
		<div className="table-view table-responsive">
			<table id="suppliers-table" className="table">
				<thead>
					<tr>
						{/* <th>
							<i className="fa fa-cog icon" aria-hidden="true" />
						</th> */}
						<th>Supplier Code</th>
						<th>Supplier Name</th>
						<th>City</th>
						<th>Country</th>
						<th>Currency</th>
						<th>Actions</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>asd</td>
						<td>asd</td>
						<td>asd</td>
						<td>asd</td>
						<td>asd</td>
						<td>
							<TableActions
								icon="fa fa-cog icon"
								data={['Edit', 'Add Purchase', 'View Transactions', 'Obsolete', 'Delete']}
							/>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	);
}
