import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
	loaderState,
	getProductGroups,
	getUnits,
	getAttributeSets,
	addProduct,
	updateProduct,
	getWarehouses,
	selectedNav,
} from '../../../actions';
import { initilizeDataTable, destroyDataTable, isEmptyOrNull, removeNullValues, goto } from '../../../helpers';
import _ from 'lodash';
import AddProductComp from '../../../components/inventory/addProduct';
import swal from 'sweetalert';

class AddProduct extends Component {
	constructor(props) {
		super(props);
		this.state = {
			nav: ['Details', 'Inventory', 'Purchase', 'Sale', 'Trasactions', 'References', 'Costs', 'Attachments'],
			productCode: null,
			productDescription: null,
			hideOrShow: false,
		};
	}

	componentDidMount() {
		const { units, productGroups, attributes, warehouses } = this.props;
		let id = this.props.match.params.id;
		const { products } = this.props;
		let selectedProduct = _.findLast(products, product => {
			return Number(product.id) === Number(id);
		});
		this.setState({ ...selectedProduct });
		if (!units) {
			this.props.loaderState(true);
			this.props.getUnits();
		}
		if (!productGroups) {
			this.props.loaderState(true);
			this.props.getProductGroups();
		}
		if (!attributes) {
			this.props.loaderState(true);
			this.props.getAttributeSets();
		}
		if (!warehouses) {
			this.props.loaderState(true);
			this.props.getWarehouses();
		}
		initilizeDataTable('inventory-table');
		initilizeDataTable('purchases-table');
		initilizeDataTable('sales-table');
		initilizeDataTable('transaction-table');
		initilizeDataTable('references-table');
		initilizeDataTable('cost-table');
		initilizeDataTable('attachments-table');
		initilizeDataTable('quantity-prices-table');
	}

	componentDidUpdate() {
		initilizeDataTable('inventory-table');
		initilizeDataTable('purchases-table');
		initilizeDataTable('sales-table');
		initilizeDataTable('transaction-table');
		initilizeDataTable('references-table');
		initilizeDataTable('cost-table');
		initilizeDataTable('attachments-table');
		initilizeDataTable('quantity-prices-table');
	}

	onFileDivClick = () => {
		document.getElementById('inputFile').click();
	};

	onClickHideOrView = () => {
		this.setState({
			hideOrShow: !this.state.hideOrShow,
		});
	};

	onChange = ({ target }) => {
		const { id, type } = target;
		if (type === 'checkbox') {
			this.setState({ [id]: target.checked ? 1 : -1 });
		} else {
			this.setState({ [id]: target.value });
		}
	};

	onSaveClick = () => {
		const { user, organization } = this.props;
		const { productCode, productDescription } = this.state;
		if (this.props.match.params.id) {
			this.onUpdateProduct();
		} else {
			if (isEmptyOrNull(productCode) && isEmptyOrNull(productDescription)) {
				swal('', 'Product Code and Product Description are required fields', 'info');
			} else {
				let state = { ...this.state };
				delete state.nav;
				delete state.hideOrShow;
				let data = removeNullValues(state);
				data.createdBy = user.id;
				data.updatedBy = user.id;
				data.organizationId = '1'; //organization.id;
				this.props.loaderState(true);
				this.props.addProduct(data);
			}
		}
	};

	onUpdateProduct = () => {
		let stateData = { ...this.state };
		if (isEmptyOrNull(stateData.productCode) && isEmptyOrNull(stateData.productDescription)) {
			swal('', 'Product Code and Product Description are required fields', 'info');
		} else {
			let data = removeNullValues(stateData);
			delete data.hideOrShow;
			delete data.nav;
			this.props.loaderState(true);
			this.props.updateProduct(data);
		}
	};

	unitsData = () => {
		const { units } = this.props;
		let data = [];
		_.forEach(units, unit => {
			data.push({
				value: unit.id,
				label: unit.unit_name,
			});
		});

		return data;
	};

	getSelectedUnit = name => {
		const { units } = this.props;
		let data = {};
		_.forEach(units, unit => {
			if (unit.id === name) {
				data.value = unit.id;
				data.label = unit.unit_name;
			}
		});

		return data;
	};

	getSelectedGroup = name => {
		const { productGroups } = this.props;
		let data = {};
		_.forEach(productGroups, group => {
			if (group.id === name) {
				data.value = group.id;
				data.label = group.groupName;
			}
		});

		return data;
	};

	productGroupsData = () => {
		const { productGroups } = this.props;
		let data = [];
		_.forEach(productGroups, group => {
			data.push({ value: group.id, label: group.groupName });
		});

		return data;
	};

	getSelectedAttribute = name => {
		const { attributes } = this.props;
		let data = {};
		_.forEach(attributes, attr => {
			if (attr.id === name) {
				data.value = attr.id;
				data.label = attr.attributeSetName;
			}
		});

		return data;
	};

	attributeSetsData = () => {
		const { attributes } = this.props;
		let data = [];
		_.forEach(attributes, attr => {
			data.push({
				value: attr.id,
				label: attr.attributeSetName,
			});
		});

		return data;
	};

	handleChange = (newValue, actionMeta) => {
		const { name } = actionMeta;
		if (newValue) {
			this.setState({ [name]: newValue.value });
		} else {
			this.setState({ [name]: newValue });
		}
	};

	goto = path => {
		this.props.selectedNav(path);
		goto(path);
	};

	render() {
		destroyDataTable('inventory-table');
		destroyDataTable('purchases-table');
		destroyDataTable('sales-table');
		destroyDataTable('transaction-table');
		destroyDataTable('references-table');
		destroyDataTable('cost-table');
		destroyDataTable('attachments-table');
		destroyDataTable('quantity-prices-table');

		const { nav, hideOrShow } = this.state;

		let units = this.unitsData();
		let productGroups = this.productGroupsData();
		let attributes = this.attributeSetsData();
		const { warehouses } = this.props;

		return (
			<div>
				<AddProductComp
					handleChange={(v, m) => this.handleChange(v, m)}
					units={units}
					productGroups={productGroups}
					attributes={attributes}
					onChange={e => this.onChange(e)}
					onSaveClick={() => this.onSaveClick()}
					goto={path => this.goto(path)}
					propsData={this.props}
					nav={nav}
					stateData={this.state}
					warehouses={warehouses}
					hideOrShow={hideOrShow}
					selectedUnit={name => this.getSelectedUnit(name)}
					selectedGroup={name => this.getSelectedGroup(name)}
					selectedAttribute={name => this.getSelectedAttribute(name)}
					onFileDivClick={() => this.onFileDivClick()}
					onClickHideOrView={() => this.onClickHideOrView()}
				/>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		user: state.users.loggedInUser,
		units: state.system.units.allUnits,
		productGroups: state.system.productGroups.allGroups,
		attributes: state.system.attributeSets.allAttributeSets,
		organization: state.users.organizationInfo,
		products: state.inventory.products.allProducts,
		warehouses: state.system.warehouses.allWarehouses,
	};
};

export default connect(mapStateToProps, {
	loaderState,
	getAttributeSets,
	getProductGroups,
	getUnits,
	addProduct,
	updateProduct,
	getWarehouses,
	selectedNav,
})(AddProduct);
