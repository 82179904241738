import React from 'react';

function Signup({ goto, onSignUp, onChange, first_name, last_name, password, confirm_password, email, phone_number }) {
	return (
		<div className="container register">
			<div className="row">
				<div className="col-md-3 register-left">
					<h3>Welcome To</h3>
					{/* <img src="images/logo.png" alt="" />
					<h2>Globuss</h2> */}
					<a onClick={() => goto('/')}>Already have an account</a>
					<br />
				</div>
				<div className="col-md-9 register-right">
					<div className="tab-content" id="myTabContent">
						<div className="tab-pane show active" id="home" role="tabpanel" aria-labelledby="home-tab">
							<h3 className="register-heading">Register here</h3>
							<div className="row register-form">
								<div className="col-md-6">
									<div className="form-group">
										<input
											type="text"
											id="first_name"
											className="form-control"
											placeholder="First Name *"
											value={first_name}
											onChange={e => onChange(e)}
										/>
									</div>
									<div className="form-group">
										<input
											type="text"
											id="last_name"
											className="form-control"
											placeholder="Last Name *"
											value={last_name}
											onChange={e => onChange(e)}
										/>
									</div>
									<div className="form-group">
										<input
											type="password"
											id="password"
											className="form-control"
											placeholder="Password *"
											value={password}
											onChange={e => onChange(e)}
										/>
									</div>
									<div className="form-group">
										<input
											type="password"
											id="confirm_password"
											className="form-control"
											placeholder="Confirm Password *"
											value={confirm_password}
											onChange={e => onChange(e)}
										/>
									</div>
								</div>
								<div className="col-md-6">
									<div className="form-group">
										<input
											type="email"
											id="email"
											className="form-control"
											placeholder="Your Email *"
											value={email}
											onChange={e => onChange(e)}
										/>
									</div>
									{/* <div className="form-group">
										<input
											type="text"
											minlength="10"
											maxlength="10"
											id="phone_number"
											className="form-control"
											placeholder="Your Phone *"
											value={phone_number}
											onChange={e => onChange(e)}
										/>
									</div> */}
									<input
										type="submit"
										onClick={() => onSignUp()}
										className="btnRegister"
										value="Next"
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Signup;
