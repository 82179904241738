import React, { Component } from 'react';
import { initilizeDataTable, destroyDataTable, warehousesData, goto } from '../../../helpers';
import { connect } from 'react-redux';
import _ from 'lodash';
import { getWarehouses, loaderState } from '../../../actions';
import StockAdjustmentsComp from '../../../components/inventory/stockAdjustments';

class StockAdjustments extends Component {
	componentDidMount() {
		const { warehouses } = this.props;
		if (warehouses.length === 0) {
			this.props.loaderState(true);
			this.props.getWarehouses();
		}
		initilizeDataTable('stock-adjustments-table');
	}

	componentDidUpdate() {
		initilizeDataTable('stock-adjustments-table');
	}

	render() {
		destroyDataTable('stock-adjustments-table');

		const { warehouses } = this.props;
		return <StockAdjustmentsComp warehouses={warehousesData(warehouses)} goto={path => goto(path)} />;
	}
}

const mapStateToProps = state => {
	return {
		warehouses: state.system.warehouses.allWarehouses,
	};
};

export default connect(mapStateToProps, { loaderState, getWarehouses })(StockAdjustments);
