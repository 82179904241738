import React from 'react';

export default function RoleTable({ roles, onRoleUpdateOrDelete }) {
	return (
		<div className="table-view table-responsive" id="setting-tables">
			<table id="roles-table" className="table">
				<thead>
					<tr>
						<th>Role Name</th>
						<th>Delete</th>
					</tr>
				</thead>
				<tbody>
					{roles
						? roles.map(role => (
								<tr key={role.roleName}>
									<td>{role.roleName}</td>
									<td>
										<i
											data-toggle="modal"
											data-target="#confirmationModal"
											className="fa fa-trash-o icon"
											onClick={() => onRoleUpdateOrDelete(role)}
										/>
										<i
											data-toggle="modal"
											data-target="#oneInputEditModal"
											className="fa fa-pencil-square-o icon"
											onClick={() => onRoleUpdateOrDelete(role)}
										/>
									</td>
								</tr>
						  ))
						: null}
				</tbody>
			</table>
		</div>
	);
}
