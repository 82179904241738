import React from 'react';
import CustomersTable from './customersTable';

export default function Contacts() {
	return (
		<div id="Contacts" className="tab-pane fade">
			<div className="row" id="settings-field">
				<div className="col-md-2 padding-right">
					<label className="input-label" htmlFor="first_name">
						First Name
					</label>
					<input disabled type="text" className="form-control" id="first_name" name="first_name" />
				</div>
				<div className="col-md-2 padding-0">
					<label className="input-label" htmlFor="last_name">
						Last Name
					</label>
					<input disabled type="text" className="form-control" id="last_name" name="last_name" />
				</div>
				<div className="col-md-2 padding-0">
					<label className="input-label" htmlFor="email">
						Email
					</label>
					<input disabled type="text" className="form-control" id="email" name="email" />
				</div>
				<div className="col-md-2 padding-0">
					<label className="input-label" htmlFor="delivery_address">
						Delivery Address
					</label>
					<select className="form-control" id="delivery_address">
						<option></option>
						<option>Add Delivery Address</option>
					</select>
				</div>
				<div className="col-md-2 padding-0">
					<label className="input-label" htmlFor="website">
						Website
					</label>
					<input disabled type="text" className="form-control" id="website" name="website" />
				</div>
				<div className="col-md-2 padding-0">
					<label className="input-label" htmlFor="toll_free_no">
						Toll Free No
					</label>
					<input disabled type="text" className="form-control" id="toll_free_no" name="toll_free_no" />
				</div>
				<div className="col-md-2 padding-right">
					<label className="input-label" htmlFor="phone">
						Phone
					</label>
					<input disabled type="text" className="form-control" id="phone" name="phone" />
				</div>
			</div>
			<br />
			<div className="row" id="settings-field">
				<div className="col-md-2 padding-right">
					<label className="input-label" htmlFor="fax">
						Fax
					</label>
					<input disabled type="text" className="form-control" id="fax" name="fax" />
				</div>
				<div className="col-md-2 padding-0">
					<label className="input-label" htmlFor="mobile">
						Mobile
					</label>
					<input disabled type="text" className="form-control" id="mobile" name="mobile" />
				</div>
				<div className="col-md-2 padding-0">
					<label className="input-label" htmlFor="ddi">
						DDI
					</label>
					<input disabled type="text" className="form-control" id="ddi" name="ddi" />
				</div>
				<div className="col-md-1  col-xs-4 padding-0">
					<label className="input-label" htmlFor="ordering">
						Ordering
					</label>
					<br />
					<input disabled type="checkbox" style={{ width: '18px', height: '18px' }} id="ordering" />
				</div>
				<div className="col-md-1  col-xs-4 padding-0">
					<label className="input-label" htmlFor="invoicing">
						Invoicing
					</label>
					<br />
					<input disabled type="checkbox" style={{ width: '18px', height: '18px' }} id="invoicing" />
				</div>
				<div className="col-md-1 col-xs-4 padding-0">
					<label className="input-label" htmlFor="shipping">
						Shipping
					</label>
					<br />
					<input disabled type="checkbox" style={{ width: '18px', height: '18px' }} id="shipping" />
				</div>
				<div className="col-md-2 padding-0" id="add-customer-btn">
					<label className="input-label">Add</label>
					<button disabled type="button" className="btn btn-success btn-sm">
						Add
					</button>
				</div>
			</div>
			<br />
			{/* <div className="panel panel-default">
				<div className="panel-body"> */}
			<CustomersTable />
			{/* </div>
			</div> */}
		</div>
	);
}
