import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { getWarehouses, loaderState } from '../../../../actions';
import { initilizeDataTable, destroyDataTable, warehousesData, goto } from '../../../../helpers';
import ViewCreditsComp from '../../../../components/sales/credit/view';

class ViewCredits extends Component {
	componentDidMount() {
		const { warehouses } = this.props;
		if (warehouses.length === 0) {
			this.props.loaderState(true);
			this.props.getWarehouses();
		}
		initilizeDataTable('credits-table');
	}
	componentDidUpdate() {
		initilizeDataTable('credits-table');
	}
	render() {
		destroyDataTable('credits-table');

		const { warehouses } = this.props;
		return <ViewCreditsComp goto={path => goto(path)} warehouses={warehousesData(warehouses)} />;
	}
}

const mapStateToProps = state => {
	return {
		warehouses: state.system.warehouses.allWarehouses,
	};
};

export default connect(mapStateToProps, { loaderState, getWarehouses })(ViewCredits);
