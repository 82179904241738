import React from 'react';

export default function AllTable() {
	return (
		<div id="All" className="tab-pane fade">
			<div className="table-view table-responsive">
				<table id="all-back-order-enquiry-table" className="table">
					<thead>
						<tr>
							<th>Customer Name</th>
							<th>Product Code</th>
							<th>Product Description</th>
							<th>Next Delivery</th>
							<th>Ordered</th>
							<th>To Ship</th>
							<th>Can Ship</th>
							<th>On Shipments</th>
							<th>Shipped</th>
						</tr>
					</thead>
					<tbody></tbody>
				</table>
			</div>
		</div>
	);
}
