import { suppliers } from '../../constants/actionTypes';
const initialState = {
	allSuppliers: null,
	selectedSupplier: null,
	codeChanges: null,
};

function suppliersReducer(state = initialState, action) {
	switch (action.type) {
		case suppliers.SUPPLIERS:
			return { ...state, allSuppliers: action.payload };
		case suppliers.SELECTED_SUPPLIER:
			return { ...state, selectedSupplier: action.payload };
		case suppliers.SUPPLIERS_CODE_CHANGES:
			return { ...state, codeChanges: action.payload };
		default:
			return state;
	}
}
export default suppliersReducer;
