export const common = {
	LOADER: 'LOADER',
	SELECTED_URL: 'SELECTED_URL',
	QUICK_LINKS: 'QUICK_LINKS',
	COUNTRIES_DATA: 'COUNTRIES_DATA',
};

export const users = {
	USER_INFO: 'USER_INFO',
	ORGANIZATION_INFO: 'ORGANIZATION_INFO',
	LOGGED_IN_USER_DATA: 'LOGGED_IN_USER_DATA',
	USERS: 'USERS',
	DELETE_USER: 'DELETE_USER',
};

export const settings = {
	ADJUSTMENT_REASONS: 'ADJUSTMENT_REASONS',
	UPDATE_OR_DELETE_ADJUSTMENT_REASON: 'UPDATE_OR_DELETE_ADJUSTMENT_REASON',
	CREDIT_REASONS: 'CREDIT_REASONS',
	UPDATE_OR_DELETE_CREDIT_REASON: 'UPDATE_OR_DELETE_CREDIT_REASON',
	ALL_UNITS: 'ALL_UNITS',
	SELECTED_UNIT: 'SELECTED_UNIT',
	CUSTOMER_TYPES: 'CUSTOMER_TYPES',
	SELECTED_CUSTOMER_TYPE: 'SELECTED_CUSTOMER_TYPE',
	ROLES: 'ROLES',
	SELECTED_ROLE: 'SELECTED_ROLE',
	DELIVERY_METHODS: 'DELIVERY_METHODS',
	SELECTED_DELIVERY_METHODS: 'SELECTED_DELIVERY_METHODS',
	SUPPLIER_RETURN_REASONS: 'SUPPLIER_RETURN_REASONS',
	SELECTED_SUPPLIER_RETURN_REASONS: 'SELECTED_SUPPLIER_RETURN_REASONS',
	WAREHOUSES: 'WAREHOUSES',
	SELECTED_WAREHOUSE: 'SELECTED_WAREHOUSE',
	SHIPPING_COMPANIES: 'SHIPPING_COMPANIES',
	SELECTED_SHIPPING_COMPANY: 'SELECTED_SHIPPING_COMPANY',
	SALES_GROUPS: 'SALES_GROUPS',
	SELECTED_SALES_GROUP: 'SELECTED_SALES_GROUP',
	SALES_PERSON: 'SALES_PERSON',
	SELECTED_SALES_PERSON: 'SELECTED_SALES_PERSON',
	ATTRIBUTE_SETS: 'ATTRIBUTE_SETS',
	SELECTED_ATTRIBUTE: 'SELECTED_ATTRIBUTE',
	PRODUCT_GROUPS: 'PRODUCT_GROUPS',
	SELECTED_PRODUCT_GROUP: 'SELECTED_PRODUCT_GROUP',
	PAYMENT_TERMS: 'PAYMENT_TERMS',
	SELECTED_PAYMENT_TERM: 'SELECTED_PAYMENT_TERM',
	SELL_PRICE_TIERS: 'SELL_PRICE_TIERS',
	SELECTED_SELL_PRICE_TIER: 'SELECTED_SELL_PRICE_TIER',
	PREFIXES: 'PREFIXES',
	CURRENCY_RATE: 'CURRENCY_RATE',
	SELECTED_CURRENCY_RATE: 'SELECTED_CURRENCY_RATE',
	TAXES: 'TAXES',
	SELECTED_TAXE: 'SELECTED_TAXE',
};

export const inventory = {
	PRODUCTS: 'PRODUCTS',
	SELECTED_PRODUCT: 'SELECTED_PRODUCT',
	STOCK_ADJUSTMENTS: 'STOCK_ADJUSTMENTS',
	SELECTED_STOCK_ADJUSTMENT: 'SELECTED_STOCK_ADJUSTMENT',
	WAREHOUSES_TRANSFERS: 'WAREHOUSES_TRANSFERS',
	SELECTED_WAREHOUSES_TRANSFER: 'SELECTED_WAREHOUSES_TRANSFER',
	STOCK_COUNTS: 'STOCK_COUNTS',
	SELECTED_STOCK_COUNT: 'SELECTED_STOCK_COUNT',
	PRODUCTS_CODE_CHANGES: 'CODE_CHANGES',
};

export const purchases = {
	PURCHASES: 'PURCHASES',
	SELECTED_PURCHASE: 'SELECTED_PURCHASE',
	SUPPLIER_RETURNS: 'SUPPLIER_RETURNS',
	SELECTED_SUPPLIER_RETURN: 'SELECTED_SUPPLIER_RETURN',
};

export const productions = {
	ASSEMBLIES: 'ASSEMBLIES',
	SELECTED_ASSEMBLY: 'SELECTED_ASSEMBLY',
	DISASSEMBLIES: 'DISASSEMBLIES',
	SELECTED_DISASSEMBLY: 'SELECTED_DISASSEMBLY',
	BILL_OF_MATERIALS: 'BILL_OF_MATERIALS',
	SELECTED_BILL_OF_MATERIAL: 'SELECTED_BILL_OF_MATERIAL',
};

export const sales = {
	SALES_QUOTES: 'SALES_QUOTES',
	SELECTED_SALES_QUOTE: 'SELECTED_SALES_QUOTE',
	SALES_ORDERS: 'SALES_ORDERS',
	SELECTED_SALES_ORDER: 'SELECTED_SALES_ORDER',
	SALES_ORDER_SHIPMENTS: 'SALES_ORDER_SHIPMENTS',
	SELECTED_SALES_ORDER_SHIPMENT: 'SELECTED_SALES_ORDER_SHIPMENT',
	SALES_CREDITS: 'SALES_CREDITS',
	SELECTED_SALES_CREDIT: 'SELECTED_SALES_CREDIT',
};

export const customers = {
	CUSTOMERS: 'CUSTOMERS',
	SELECTED_CUSTOMER: 'SELECTED_CUSTOMER',
	CUSTOMERS_CODE_CHANGES: 'CUSTOMERS_CODE_CHANGES',
};

export const suppliers = {
	SUPPLIERS: 'SUPPLIERS',
	SELECTED_SUPPLIER: 'SELECTED_SUPPLIER',
	SUPPLIERS_CODE_CHANGES: 'SUPPLIERS_CODE_CHANGES',
};

export const reports = {
	PURCHASE_EXNQUIRY: 'PURCHASE_EXNQUIRY',
};
