import React from 'react';

export default function Info({ label, percentage, number, icon, color }) {
	return (
		<div className="dashboard-chart-info">
			<div className="percentage">{percentage} %</div>
			<p className="dashboard-label">{label}</p>
			<p className="dashboard-number">{number}</p>
			<i className={`glyphicon glyphicon-arrow-${icon}`} style={{ color }} />
		</div>
	);
}
