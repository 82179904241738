import React from 'react';
import InlineInputForm from '../../common/inlineInputForm';

export default function Contact() {
	return (
		<div id="Contact" className="tab-pane fade">
			<div className="row" id="inline-input-forms">
				<div className="col-sm-4">
					<InlineInputForm id="firt-name" value="" name="First name" />
					<InlineInputForm id="last-name" value="" name="Last name" />
					<InlineInputForm id="email" value="" name="Email" />
					<InlineInputForm id="office-phone" value="" name="Office Phone" />
					<InlineInputForm id="website" value="" name="Website" />
				</div>
				<div className="col-sm-1"></div>
				<div className="col-sm-4">
					<InlineInputForm id="phone-number" value="" name="Phone Number" />
					<InlineInputForm id="fax-number" value="" name="Fax Number" />
					<InlineInputForm id="mobile-number" value="" name="Mobile Number" />
					<InlineInputForm id="ddi-number" value="" name="DDI Number" />
					<InlineInputForm id="toll-free-number" value="" name="Toll Free Number" />
				</div>
			</div>
		</div>
	);
}
