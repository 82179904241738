import React from 'react';

export default function QuicklinksDropdown({ quicklinks, goto }) {
	return (
		<div className="quicklinks-dropdown">
			<i className="fa fa-th" aria-hidden="true" />
			<div className="quicklinks-dropdown-content">
				<div className="row">
					{quicklinks
						? quicklinks.map((quicklink, index) =>
								index <= 9 ? (
									<div key={quicklink.name} className="col-xs-6">
										<a onClick={() => goto(quicklink.url)}>
											<i className={quicklink.icon} />
											{quicklink.name}
										</a>
									</div>
								) : (
									<React.Fragment key={quicklink.name}>
										{index === 10 ? <div className="col-xs-12 dropdown-divider"></div> : null}
										<div className="col-xs-6">
											<a onClick={() => goto(quicklink.url)}>
												<i className={quicklink.icon} />
												{quicklink.name}
											</a>
										</div>
									</React.Fragment>
								)
						  )
						: null}
				</div>
				<div className="link-to-quicklinks col-xs-12">
					<a onClick={() => goto('/account/customize quick links')}>
						<i className="fa fa-wrench" /> Customize shortcuts
					</a>
				</div>
			</div>
		</div>
	);
}
