import React from 'react';
import InlineInputForm from '../../../common/inlineInputForm';
import InLineCheckbox from '../../../common/inLineCheckbox';

export default function Purchasing() {
	return (
		<div id="Purchasing" className="tab-pane fade">
			<div className="row" id="inline-input-forms">
				<div className="col-sm-4">
					<div className="form-inline">
						<div className="form-group">
							<label htmlFor="footer-details">Footer Details</label>
							<textarea rows="7" type="text" className="form-control" id="footer-details" />
						</div>
					</div>
					<InlineInputForm id="purchase-order-print-name" value="" name="Purchase Order Report Print Name" />
					<InLineCheckbox id="enable" name="Enable" />
				</div>
			</div>
		</div>
	);
}
