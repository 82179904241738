import React from 'react';
import moment from 'moment';

export default function UsersTable({ users, onDeleteUser }) {
	return (
		<div className="table-view table-responsive">
			<table id="users-table" className="table">
				<thead>
					<tr>
						<th>Full Name</th>
						<th>Email</th>
						<th>Role</th>
						<th>Approved</th>
						<th>Last Login</th>
						<th>Action</th>
					</tr>
				</thead>
				<tbody>
					{users
						? users.map(user => (
								<tr key={user.id}>
									<td>
										{user.first_name} {user.last_name}
									</td>
									<td>{user.email}</td>
									<td>{user.role}</td>
									<td>
										{Number(user.isApproved) === 1 ? (
											<i className="fa fa-check" style={{ color: 'green' }} aria-hidden="true" />
										) : (
											<i className="fa fa-times" style={{ color: 'red' }} aria-hidden="true" />
										)}
									</td>
									<td>{moment(user.last_login).format('lll')}</td>
									<td>
										<span className="user-action-icons">
											<i
												className="fa fa-trash-o"
												data-toggle="modal"
												data-target="#confirmationModal"
												aria-hidden="true"
												onClick={() => onDeleteUser(user)}
											/>
											{/* <i
												className="fa fa-pencil-square-o"
												data-toggle="modal"
												data-target="#invite-user-modal"
												aria-hidden="true"
											/> */}
										</span>
									</td>
								</tr>
						  ))
						: null}
				</tbody>
			</table>
		</div>
	);
}
