import React from 'react';
import BatchCompleteTable from './batchCompleteTable';
import Stats from './stats';

export default function BatchComplete() {
	return (
		<>
			<div className="comp-heading">
				<div className="row">
					<div className="col-lg-4">
						<label>Batch Complete</label>
					</div>
					<div className="col-lg-2"></div>
					<div className="col-lg-6" style={{ textAlign: 'right' }}>
						<button className="btn btn-light btn-sm">Select / Unselect All</button>
						<button className="btn btn-success btn-sm">Batch Complete</button>
					</div>
				</div>
			</div>
			<br />
			<div className="panel panel-default">
				<div className="panel-body">
					<div className="row" id="settings-field">
						<div className="col-md-2 padding-right">
							<label className="input-label" htmlFor="status">
								Status
							</label>
							<select className="form-control" id="status">
								<option>Open</option>
								<option>Parked</option>
								<option>Placed</option>
								<option>Backorder</option>
							</select>
						</div>
						<div className="col-md-2 padding-0">
							<label className="input-label" htmlFor="customer-name">
								Customer Name
							</label>
							<input type="text" className="form-control" id="customer-name" name="customer-name" />
						</div>
						<div className="col-md-2 padding-0">
							<label className="input-label" htmlFor="delivery-address">
								Delivery Address
							</label>
							<input
								disabled
								type="text"
								className="form-control"
								id="delivery-address"
								name="delivery-address"
							/>
						</div>
					</div>
				</div>
			</div>
			<div className="panel panel-default">
				<div className="panel-body">
					<BatchCompleteTable />
				</div>
			</div>
			<div className="row">
				<div className="col-md-6"></div>
				<div className="col-md-6">
					<div className="panel panel-default" id="panel-margin-right">
						<div className="panel-body">
							<Stats />
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
