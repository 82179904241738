import api from '../../api/api';
import { success } from '../../constants/messages';
import { settings } from '../../constants/actionTypes';
import { loaderState } from '..';
import _ from 'lodash';
import swal from 'sweetalert';

export const getCurrencyRates = () => dispatch => {
	api.get('/currencyRates')
		.then(res => {
			if (res.data.code === 200) {
				dispatch({ type: settings.CURRENCY_RATE, payload: res.data.data });
			} else {
				swal('', res.data.message, 'error');
			}
			dispatch(loaderState(false));
		})
		.catch(err => {
			swal('', err.message, 'error');
			dispatch(loaderState(false));
		});
};

export const addCurrencyRate = data => (dispatch, getState) => {
	var allCurrencies = getState().system.currencyRates.allCurrencies;
	api.post('/currencyRates', { ...data })
		.then(res => {
			if (res.data.code === 200) {
				dispatch({ type: settings.CURRENCY_RATE, payload: [...allCurrencies, res.data.data] });
			} else {
				swal('', res.data.message, 'error');
			}
			dispatch(loaderState(false));
		})
		.catch(err => {
			swal('', err.message, 'error');
			dispatch(loaderState(false));
		});
};

export const deleteCurrency = id => (dispatch, getState) => {
	const { allCurrencies } = getState().system.currencyRates;
	api.delete(`/currencyRates/${id}`)
		.then(res => {
			if (Number(res.data.code) === 200) {
				_.remove(allCurrencies, term => {
					return Number(id) == Number(term.id);
				});
			} else {
				swal('', res.data.message, 'error');
			}
			dispatch(loaderState(false));
		})
		.catch(err => {
			swal('', err.message, 'error');
			dispatch(loaderState(false));
		});
};

export const updateCurrency = data => (dispatch, getState) => {
	const { allCurrencies } = getState().system.currencyRates;
	api.post(`/currencyRates/update/${data.id}`, { ...data })
		.then(res => {
			if (Number(res.data.code) === 200) {
				let updatedCurrency = _.findLast(allCurrencies, currency => {
					return Number(currency.id) == Number(data.id);
				});
				if (data.currencyCode) {
					updatedCurrency.currencyCode = data.currencyCode;
				}
				if (data.countryName) {
					updatedCurrency.countryName = data.countryName;
				}
				if (data.sellRate) {
					updatedCurrency.sellRate = data.sellRate;
				}
				if (data.buyRate) {
					updatedCurrency.buyRate = data.buyRate;
				}
				let updatedCurrencies = _.map(allCurrencies, currency => {
					return currency.id === data.id ? updatedCurrency : currency;
				});

				dispatch({ type: settings.PAYMENT_TERMS, payload: updatedCurrencies });
			} else {
				swal('', res.data.message, 'error');
			}
			dispatch(loaderState(false));
		})
		.catch(err => {
			swal('', err.message, 'error');
			dispatch(loaderState(false));
		});
};

export const onSelectCurrency = data => dispatch => {
	dispatch({ type: settings.SELECTED_CURRENCY_RATE, payload: data });
};
