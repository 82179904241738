import React from 'react';
import ProductGroupTable from './productGroupTable';
import ConfirmationModal from '../../../common/confirmationModal';
import EditModal from './editModal';
import Select from 'react-select';

export default function ProductGroups({
	groupName,
	onUpdate,
	onChange,
	attributes,
	allGroups,
	handleChange,
	onAdd,
	onProductGroupSelect,
	onDelete,
	handleEditChange,
	attrSet,
	editAttrSet,
	editGroupName,
}) {
	return (
		<>
			<div className="comp-heading">
				<div className="row">
					<div className="col-lg-4">
						<label>Product Groups</label>
					</div>
				</div>
			</div>
			<br />
			<div className="panel panel-default">
				<div className="panel-body">
					<div className="row" id="settings-field">
						<div className="col-md-2">
							<label className="input-label" htmlFor="product-group-name">
								* Product Group Name
							</label>
							<input
								value={groupName}
								onChange={e => onChange(e)}
								className="form-control"
								id="groupName"
								name="groupName"
							/>
						</div>
						<div className="col-md-2 col-xs-9">
							<label className="input-label" htmlFor="default-attribute-set">
								Default Attribute Set
							</label>
							<Select value={attrSet} isClearable onChange={handleChange} options={attributes} />
						</div>
						<div className="col-xs-2" id="system-add-btn">
							<button onClick={() => onAdd()} className="btn btn-success btn-sm">
								Add
							</button>
						</div>
					</div>
				</div>
			</div>
			<div className="panel panel-default">
				<div className="panel-body">
					<ProductGroupTable
						onProductGroupSelect={data => onProductGroupSelect(data)}
						allGroups={allGroups}
					/>
				</div>
			</div>
			<ConfirmationModal onDelete={() => onDelete()} item="Product Group" />
			<EditModal
				value={editGroupName}
				value1={editAttrSet}
				attributes={attributes}
				onChange={e => onChange(e)}
				handleChange={value => handleEditChange(value)}
				onUpdate={() => onUpdate()}
			/>
		</>
	);
}
