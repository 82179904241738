import React from 'react';
import { Permissions } from './permissions';

export default function RolePermissions({ roles }) {
	return (
		<>
			<div className="comp-heading">
				<div className="row">
					<div className="col-lg-4">
						<label>Role Permissions</label>
					</div>
				</div>
			</div>
			<br />
			<div className="panel panel-default">
				<div className="panel-body">
					<div className="container">
						<div className="row" id="role-permission">
							<div className="col-md-2 form-inline">
								<label className="input-label" htmlFor="roles">
									Role:
								</label>
								<select className="form-control" id="roles">
									<option></option>
									{roles
										? roles.map(role => <option key={role.roleName}>{role.roleName}</option>)
										: null}
								</select>
							</div>
						</div>
						<br />
						<Permissions />
					</div>
				</div>
			</div>
		</>
	);
}
