import React from 'react';
import SplitButton from '../../../common/splitButton';
import InlineInputForm from '../../../common/inlineInputForm';
import InlineDropdown from '../../../common/inlineDropDown';
import RunReport from '../../../common/runReport';
import PurchaseEnquiryTable from './purchaseEnquiryTable';

export default function PurchaseEnquiry() {
	return (
		<>
			<div className="comp-heading">
				<div className="row">
					<div className="col-lg-4">
						<label>Purchase Enquiry</label>
					</div>
					<div className="col-lg-2"></div>
					<div className="col-lg-6" style={{ textAlign: 'right' }}>
						<SplitButton
							label="Export"
							type="light"
							data={['Export as PDF', 'Export as CSV', 'Export as XLSX', 'Export as XLS']}
						/>
						<button className="btn btn-success btn-sm">Run</button>
					</div>
				</div>
			</div>
			<br />
			<div className="panel panel-default">
				<div className="panel-body">
					<div className="row" id="inline-input-forms">
						<div className="col-sm-4">
							<InlineInputForm id="date-from" type="date" value="" name="Date From" />
							<InlineInputForm id="date-to" type="date" value="" name="Date To" />
							<InlineDropdown
								id="transaction-date"
								name="Transaction Date"
								values={['Order Date', 'Delivery Date', 'Receipt Date']}
							/>
							<InlineInputForm id="order-number" value="" name="Order Number" />
						</div>
						<div className="col-sm-4">
							<InlineDropdown
								id="status"
								name="Status"
								values={[
									'All',
									'Unapproved',
									'Parked',
									'Placed',
									'Closed',
									'Costed',
									'Receipted',
									'Completed',
									'Deleted',
								]}
							/>

							<InlineDropdown
								id="warehouse"
								name="Warehouse"
								values={['Select All', 'Warehouse', 'Main Office']}
							/>
							<InlineInputForm id="product-code" value="" name="Product Code" />
							<InlineInputForm id="product-description" value="" name="Product Description" />
						</div>
						<div className="col-sm-4">
							<InlineInputForm id="sales-order-number" value="" name="Sales Order Number" />
							<InlineInputForm id="supplier-code" value="" name="Supplier Code" />
							<InlineInputForm id="supplier-name" value="" name="Supplier Name" />
							<InlineInputForm id="supplier-reference" value="" name="Supplier Reference" />
						</div>
					</div>
				</div>
			</div>
			<div className="panel panel-default">
				<div className="panel-body">
					<PurchaseEnquiryTable />
					<br />
					<RunReport />
				</div>
			</div>
		</>
	);
}
