import React, { Component } from 'react';
import { initilizeDataTable, destroyDataTable } from '../../../../helpers';
import { connect } from 'react-redux';
import CompanyComp from '../../../../components/setting/organization/company';

class Company extends Component {
	constructor(props) {
		super(props);
		this.state = {
			nav: ['Details', 'Configuration', 'Address', 'Contact', 'Images', 'Invoicing', 'Purchasing'],
		};
	}

	componentDidMount() {
		initilizeDataTable('upload-images-table');
	}

	componentDidUpdate() {
		initilizeDataTable('upload-images-table');
	}

	render() {
		destroyDataTable('upload-images-table');

		const { nav } = this.state;
		const { organization } = this.props;
		return <CompanyComp nav={nav} organization={organization} />;
	}
}

const mapStateToProps = state => {
	return {
		organization: state.users.organizationInfo,
		user: state.users.loggedInUser,
	};
};

export default connect(mapStateToProps, {})(Company);
