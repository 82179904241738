import React from 'react';
import QuantityPriceTable from './quantityPriceTable';

export default function QuantityPrices() {
	return (
		<div id="qualityprices" className="tab-pane fade">
			<div className="row">
				<div className="pull-right">
					<button className="btn btn-light btn-sm" style={{ marginRight: '5px' }}>
						Import
					</button>
					<button className="btn btn-light btn-sm">Export</button>
				</div>
			</div>
			<br />
			<div className="row" id="settings-field">
				<div className="col-md-1 padding-right">
					<label className="input-label" htmlFor="min-qty">
						Min. Qty
					</label>
					<input type="text" className="form-control" id="min-qty" name="min-qty" />
				</div>
				<div className="col-md-1 padding-0">
					<label className="input-label" htmlFor="valid-from">
						Valid From
					</label>
					<input type="date" className="form-control" id="valid-from" name="valid-from" />
				</div>
				<div className="col-md-1 padding-0">
					<label className="input-label" htmlFor="valid-to">
						Valid To
					</label>
					<input type="date" className="form-control" id="valid-to" name="valid-to" />
				</div>
				<div className="col-md-2 padding-0">
					<label className="input-label" htmlFor="priceDiscount">
						Price/Discount %
					</label>
					<input type="text" className="form-control" id="priceDiscount" name="priceDiscount" />
				</div>
				<div className="col-md-1 padding-0">
					<label className="input-label" htmlFor="price-type">
						Price Type
					</label>
					<select className="form-control" id="price-type" name="price-type">
						<option>Fixed Price</option>
						<option>Discount</option>
					</select>
				</div>
				<div className="col-md-1 padding-0">
					<label className="input-label" htmlFor="showDiscount">
						Show Discount
					</label>
					<br />
					<input
						type="checkbox"
						style={{ width: '18px', height: '18px' }}
						id="showDiscount"
						name="showDiscount"
					/>
				</div>
				<div className="col-md-1 padding-0">
					<label className="input-label" htmlFor="discountFrom">
						Discount From
					</label>
					<select className="form-control" id="discountFrom" name="discountFrom">
						<option>Customer Price Tier</option>
						<option>Default Sell Price</option>
					</select>
				</div>
				<div className="col-md-1 padding-0">
					<label className="input-label" htmlFor="margin">
						Margin %
					</label>
					<input type="text" className="form-control" id="margin" name="margin" />
				</div>
				<div className="col-md-1 padding-0">
					<label className="input-label" htmlFor="comments">
						Comments
					</label>
					<input type="text" className="form-control" id="comments" name="comments" />
				</div>
				<div className="col-md-1 padding-0" id="add-supplier-btn">
					<label className="input-label">Add button</label>
					<button type="button" className="btn btn-success btn-sm">
						Save
					</button>
				</div>
			</div>
			<br />
			<QuantityPriceTable />
		</div>
	);
}
