import React from 'react';

export default function Product() {
	return (
		<div className="row" id="settings-field">
			<div className="col-md-2 padding-right">
				<label className="input-label" htmlFor="product">
					Product
				</label>
				<input type="text" className="form-control" id="product" name="product" />
			</div>
			<div className="col-md-1 padding-0">
				<label className="input-label" htmlFor="availability">
					Availability
				</label>
				<input disabled type="text" className="form-control" id="availability" name="availability" />
			</div>
			<div className="col-md-1 padding-0">
				<label className="input-label" htmlFor="1uantity">
					* Quantity
				</label>
				<input type="text" className="form-control" id="1uantity" name="1uantity" />
			</div>
			<div className="col-md-7 padding-0">
				<label className="input-label" htmlFor="comments">
					Comments
				</label>
				<input type="text" className="form-control" id="comments" name="comments" />
			</div>

			<div className="col-md-1" id="add-customer-btn">
				<label className="input-label">Add</label>
				<button type="button" className="btn btn-success btn-sm">
					Add
				</button>
			</div>
		</div>
	);
}
