import React from 'react';

export default function CodesTable({ data }) {
	return (
		<table id="change-code-table" className="table">
			<thead>
				<tr>
					<th>Change Date</th>
					<th>Old Code</th>
					<th>New Code</th>
					<th>User</th>
				</tr>
			</thead>
			<tbody>
				{data.map(a => {
					return (
						<tr key={a}>
							<td>2019/04/08</td>
							<td>{a}</td>
							<td>123</td>
							<td>Shakir Ullah</td>
						</tr>
					);
				})}
			</tbody>
		</table>
	);
}
