import { customers } from '../../constants/actionTypes';
const initialState = {
	allCustomers: null,
	selectedCustomer: null,
	codeChanges: null,
};

function customersReducer(state = initialState, action) {
	switch (action.type) {
		case customers.CUSTOMERS:
			return { ...state, allCustomers: action.payload };
		case customers.SELECTED_CUSTOMER:
			return { ...state, selectedCustomer: action.payload };
		case customers.CUSTOMERS_CODE_CHANGES:
			return { ...state, codeChanges: action.payload };
		default:
			return state;
	}
}
export default customersReducer;
