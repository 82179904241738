import React, { Component } from 'react';
import { initilizeDataTable, destroyDataTable } from '../../../../helpers';
import UnitSalesComp from '../../../../components/reports/sales/unitSales';

export default class UnitSales extends Component {
	componentDidMount() {
		initilizeDataTable('sales-quotes-charge-table');
	}

	componentDidUpdate() {
		initilizeDataTable('sales-quotes-charge-table');
	}

	render() {
		destroyDataTable('sales-quotes-charge-table');
		return <UnitSalesComp />;
	}
}
