import React from 'react';
import SplitButton from '../../../common/splitButton';
import InlineInputForm from '../../../common/inlineInputForm';
import InlineDropdown from '../../../common/inlineDropDown';
import InLineCheckbox from '../../../common/inLineCheckbox';
import RunReport from '../../../common/runReport';
import InvoiceEnquiryTable from './invoiceEnquiryTable';
import Stats from './stats';

export default function InvoiceEnquiry() {
	return (
		<>
			<div className="comp-heading">
				<div className="row">
					<div className="col-lg-4">
						<label>Invoice Enquiry</label>
					</div>
					<div className="col-lg-2"></div>
					<div className="col-lg-6" style={{ textAlign: 'right' }}>
						<SplitButton
							label="Export"
							type="light"
							data={['Export as PDF', 'Export as CSV', 'Export as XLSX', 'Export as XLS']}
						/>
						<button className="btn btn-success btn-sm">Run</button>
					</div>
				</div>
			</div>
			<br />
			<div className="panel panel-default">
				<div className="panel-body">
					<div className="row" id="inline-input-forms">
						<div className="col-sm-4">
							<InlineInputForm id="date-from" type="date" value="" name="Date From" />
							<InlineInputForm id="date-to" type="date" value="" name="Date To" />
							<InlineDropdown
								id="transaction-date"
								name="Transaction Date"
								values={['', 'Invoice Date', 'Deu date', 'Completed Date']}
							/>
							<InlineDropdown
								id="sell-price-tier"
								name="Sell Price Tier"
								values={[
									'',
									'Sell Price Tier 1',
									'Sell Price Tier 2',
									'Sell Price Tier 3',
									'Sell Price Tier 4',
									'Sell Price Tier 5',
									'Sell Price Tier 6',
									'Sell Price Tier 7',
									'Sell Price Tier 8',
									'Sell Price Tier 9',
									'Sell Price Tier 10',
								]}
							/>
							<InlineDropdown id="printed" name="Printed" values={['', 'Yes', 'No']} />
						</div>
						<div className="col-sm-4">
							<InlineDropdown id="warehouse" name="Warehouse" values={['Select All', 'Warehouse']} />
							<InlineInputForm id="tracking-number" value="" name="Tracking Number" />
							<InlineInputForm id="sales-order-number" value="" name="Sales Order Number" />
							<InlineDropdown id="status" name="Status" values={['Parked', 'Completed', 'Deleted']} />
							<InlineDropdown id="sales-order-group" name="Sales Order Group" values={['']} />
						</div>
						<div className="col-sm-4">
							<InLineCheckbox id="include-credits" name="Include Credits" />
							<InlineDropdown id="customer-type" name="Customer Type" values={['', 'General']} />
							<InlineInputForm id="customer-code" value="" name="Customer Code" />
							<InlineInputForm
								disable="disabled"
								id="delivery-address"
								value=""
								name="Delivery Address"
							/>
							<InlineDropdown id="sales-person" name="Sales Person" values={['', 'name: email']} />
						</div>
					</div>
				</div>
			</div>
			<div className="panel panel-default">
				<div className="panel-body">
					<InvoiceEnquiryTable />
					<br />
					<RunReport />
				</div>
			</div>
			<Stats />
		</>
	);
}
