import React from 'react';
import Tabs from '../../common/tabs';
import Single from './single';
import Batch from './batch';
import ProductSearchModal from './productSearchModal';
import CodesTable from '../../common/codesTable';

export default function ChangeCodes({ nav, data }) {
	return (
		<>
			<div className="comp-heading">
				<div className="row">
					<div className="col-lg-4">
						<label>Change Codes</label>
					</div>
					<div className="col-md-5"></div>
					<div className="col-md-3 padding-0" style={{ textAlign: 'right' }}>
						<button className="btn btn-light btn-sm">Export</button>
					</div>
				</div>
			</div>
			<br />
			<div className="panel panel-default">
				<div className="panel-body">
					<Tabs nav={nav} />
					<br />
					<div className="tab-content">
						<Single />
						<Batch />
					</div>
					<ProductSearchModal />
				</div>
			</div>
			<div className="panel panel-default">
				<div className="panel-body">
					<div className="table-view table-responsive">
						<CodesTable data={data} />
					</div>
				</div>
			</div>
		</>
	);
}
