import React from 'react';

export default function CreditEnquiryTable() {
	return (
		<div id="SalesOrders" className="tab-pane active" >
			<div className="table-view table-responsive">
				<table id="sales-quotes-charge-table" className="table">
					<thead>
						<tr>
							<th>Credit Date</th>
							<th>Receipt Date</th>
							<th>Return</th>
							<th>Credit Number</th>
							<th>Customer</th>
							<th>Product</th>
							<th>Product Group</th>
							<th>Credit Reason</th>
							<th>Status</th>
							<th>Quantity</th>
							<th>Sub Total</th>
						</tr>
					</thead>
					<tbody></tbody>
				</table>
			</div>
		</div>
	);
}
