import React from 'react';
import ExportBtn from '../../../common/exportButton';
import QuotesTable from './quotesTable';

export default function ViewQuotes({ warehouses, goto }) {
	return (
		<>
			<div className="comp-heading">
				<div className="row">
					<div className="col-lg-4">
						<label>View Quotes</label>
					</div>
					<div className="col-lg-5 col-md-2 col-1"></div>
					<div className="col-lg-3 col-md-3 col-3" style={{ textAlign: 'right' }}>
						<ExportBtn />
						<button onClick={() => goto('/sales/quotes/add quote')} className="btn btn-success btn-sm">
							Add Quote
						</button>
					</div>
				</div>
			</div>
			<br />
			<div className="panel panel-default">
				<div className="panel-body">
					<div className="row" id="settings-field">
						<div className="col-md-2 padding-right">
							<label className="input-label" htmlFor="status">
								Status
							</label>
							<select className="form-control" id="status">
								<option>All</option>
								<option>Open</option>
								<option>Draft</option>
								<option>Pending</option>
								<option>Accepted</option>
								<option>Cancelled</option>
								<option>Deleted</option>
							</select>
						</div>
						<div className="col-md-2 padding-0">
							<label className="input-label" htmlFor="order_number">
								Order Number
							</label>
							<input type="text" className="form-control" id="order_number" name="order_number" />
						</div>
						<div className="col-md-2 padding-0">
							<label className="input-label" htmlFor="customer">
								Customer
							</label>
							<input type="text" className="form-control" id="customer" name="customer" />
						</div>
						<div className="col-md-2 padding-0">
							<label className="input-label" htmlFor="warehouse">
								Warehouse
							</label>
							<select className="form-control" id="warehouse">
								<option></option>
								{warehouses
									? warehouses.map(warehouse => <option key={warehouse}>{warehouse}</option>)
									: null}
							</select>
						</div>
						<div className="col-md-2 padding-0">
							<label className="input-label" htmlFor="printed">
								Printed
							</label>
							<select className="form-control" id="printed">
								<option></option>
								<option>Yes</option>
								<option>No</option>
							</select>
						</div>
					</div>
				</div>
			</div>
			<div className="panel panel-default">
				<div className="panel-body">
					<QuotesTable />
				</div>
			</div>
			<div className="row">
				<div className="col-md-6"></div>
				<div className="col-md-6">
					<div className="panel panel-default" id="panel-margin-right">
						<div className="panel-body">
							<div className="grid-container">
								<div className="grid-item">
									<div className="border-bottom">
										<span className="receipt-stat-label">Sales Quote Count</span>
										<span className="pull-right receipt-stat-value">0</span>
									</div>
									<div className="border-bottom">
										<span className="receipt-stat-label">Average Profit</span>
										<span className="pull-right receipt-stat-value">0</span>
									</div>
								</div>
								<div className="grid-item">
									<div className="border-bottom">
										<span className="receipt-stat-label">Total Profit</span>
										<span className="pull-right receipt-stat-value">0.00</span>
									</div>
									<div className="border-bottom">
										<span className="receipt-stat-label">Total Sales Quote Value</span>
										<span className="pull-right receipt-stat-value">0.00</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
