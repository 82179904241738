import history from '../history';
import _ from 'lodash';

export const paginationData = (dataSent, activePage, perPage) => {
	let actPage = activePage - 1;
	let pageStart = 0,
		pageEnd = perPage;
	if (activePage === 1) {
		pageStart = 0;
		pageEnd = perPage;
	} else {
		pageStart = actPage * perPage;
		pageEnd = pageStart + perPage;
	}

	let newData;
	newData = dataSent.slice(pageStart, pageEnd);

	return newData;
};

export const isEmptyOrNull = data => {
	let valid = false;
	if (!data || data === '') {
		valid = true;
	}
	return valid;
};

export const goto = path => {
	history.push(path);
	history.go(path);
};

export const removeNullValues = object => {
	let newObj = {};
	for (let key in object) {
		if (object.hasOwnProperty(key)) {
			if (!isEmptyOrNull(object[key])) {
				newObj[key] = object[key];
			}
		}
	}
	return newObj;
};

export const initilizeDataTable = id => {
	window.$(`#${id}`).DataTable({
		ordering: true,
		select: true,
		searching: true,
		pagingType: 'full_numbers',
		language: {
			paginate: {
				next: '&rsaquo;',
				previous: '&lsaquo;',
				first: '&laquo;',
				last: '&raquo;',
			},
		},
		sDom:
			'<"row view-filter"<"col-sm-12"<"pull-left"l><"pull-right"f><"clearfix">>>t<"row view-pager"<"col-sm-12"<"text-center"ip>>>',
	});
};

export const destroyDataTable = id => {
	window
		.$(`#${id}`)
		.DataTable()
		.destroy();
};

export const warehousesData = warehouses => {
	let data = [];
	_.forEach(warehouses, warehouse => {
		data.push(warehouse.warehouseName);
	});

	return data;
};

export const industryDropdownData = () => {
	let data = [
		{ value: 'Agriculture, Forestry, Fishing', label: 'Agriculture, Forestry, Fishing' },
		{ value: 'Animal Products', label: 'Animal Products' },
		{ value: 'Audio and Electronics', label: 'Audio and Electronics' },
		{ value: 'Automotive and Automotive Supplies', label: 'Automotive and Automotive Supplies' },
		{ value: 'Beverages (Alcoholic and Non-alcoholic)', label: 'Beverages (Alcoholic and Non-alcoholic)' },
		{ value: 'Building and Construction', label: 'Building and Construction' },
		{ value: 'Commercial Services', label: 'Commercial Services' },
		{ value: 'Education', label: 'Education' },
		{ value: 'Electrical and Electronic Components', label: 'Electrical and Electronic Components' },
		{ value: 'Energy, Chemicals and Petroleum', label: 'Energy, Chemicals and Petroleum' },
		{ value: 'Entertainment and Recreation', label: 'Entertainment and Recreation' },
		{ value: 'Fashion, Cosmetics and Accessories', label: 'Fashion, Cosmetics and Accessories' },
		{ value: 'Financial Services', label: 'Financial Services' },
		{ value: 'Food', label: 'Food' },
		{ value: 'Furniture and Fixtures', label: 'Furniture and Fixtures' },
		{ value: 'Health and Wellbeing', label: 'Health and Wellbeing' },
		{ value: 'Hotel, Restaurants and Bars', label: 'Hotel, Restaurants and Bars' },
		{ value: 'Industrial Equipment and Machinery', label: 'Industrial Equipment and Machinery' },
		{ value: 'IT Products and Services', label: 'IT Products and Services' },
		{ value: 'Jewellery', label: 'Jewellery' },
		{ value: 'Medical Supplies and Equipment', label: 'Medical Supplies and Equipment' },
		{ value: 'Metals and Fabrication', label: 'Metals and Fabrication' },
		{ value: 'Mining', label: 'Mining' },
		{ value: 'Office Equipment and Supplies', label: 'Office Equipment and Supplies' },
		{ value: 'Other', label: 'Other' },
		{ value: 'Pharmaceutical', label: 'Pharmaceutical' },
		{ value: 'Plastic and Rubber Products', label: 'Plastic and Rubber Products' },
		{ value: 'Printing and Publishing', label: 'Printing and Publishing' },
		{ value: 'Sport and Fitness', label: 'Sport and Fitness' },
		{ value: 'Textiles', label: 'Textiles' },
		{ value: 'Tobacco and other Horticulture Products', label: 'Tobacco and other Horticulture Products' },
		{ value: 'Transportation / Logistics', label: 'Transportation / Logistics' },
	];

	return data;
};
