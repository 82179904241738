import React, { Component } from 'react';
import { paginationData } from '../../../helpers';

import ChangeCodesComp from '../../../components/inventory/changeCode';
export default class ChangeCodes extends Component {
	constructor(props) {
		super(props);
		this.state = {
			nav: ['Single', 'Batch'],
		};
	}

	componentDidMount() {
		window.$(`#change-code-table`).DataTable({
			ordering: true,
			select: true,
			searching: true,
		});
	}

	componentDidUpdate() {
		window.$(`#change-code-table`).DataTable({
			ordering: true,
			select: true,
			searching: true,
		});
	}

	onSelectChange = event => {
		const { value } = event.target;
		if (!(value === 'show entries (default is 10)')) {
			this.setState({ perPage: parseInt(event.target.value) });
		}
	};

	render() {
		window
			.$(`#change-code-table`)
			.DataTable()
			.destroy();
		const { nav } = this.state;
		var arr = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26];
		return <ChangeCodesComp nav={nav} data={arr} />;
	}
}
