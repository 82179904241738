import React from 'react';
import ExportButton from '../../common/exportButton';
import BillOfMaterialTable from './billOfMaterialTable';

export default function viewBillOfMaterialProduction({ goto }) {
	return (
		<>
			<div className="comp-heading">
				<div className="row">
					<div className="col-lg-4">
						<label>View Bills Of Materials </label>
					</div>
					<div className="col-lg-3"></div>
					<div className="col-lg-5" style={{ textAlign: 'right' }}>
						<button className="btn btn-success btn-sm">Import</button>
						<ExportButton />
						<button
							onClick={() => goto('/productions/bill of materials/add bill of materials')}
							className="btn btn-success btn-sm"
						>
							Add Bill
						</button>
					</div>
				</div>
			</div>
			<br />
			<div className="panel panel-default">
				<div className="panel-body">
					<div className="row" id="settings-field">
						<div className="col-md-2 padding-right">
							<label className="input-label" htmlFor="bill_number">
								Bill Number
							</label>
							<input type="text" className="form-control" id="bill_number" name="bill_number" />
						</div>
						<div className="col-md-2 padding-0">
							<label className="input-label" htmlFor="product">
								Product
							</label>
							<input type="text" className="form-control" id="product" name="product" />
						</div>
						<div className="col-md-2 padding-0">
							<label className="input-label" htmlFor="obsolete">
								Obsolete
							</label>
							<select className="form-control" id="obsolete">
								<option>All</option>
								<option>Yes</option>
								<option>No</option>
							</select>
						</div>
						<div className="col-md-3 padding-0">
							<label className="input-label" htmlFor="display_all">
								Display Associated top-level BOM(s)
							</label>
							<br />
							<input type="checkbox" style={{ width: '18px', height: '18px' }} id="display_all" />
						</div>
					</div>
				</div>
			</div>
			<div className="panel panel-default">
				<div className="panel-body">
					<BillOfMaterialTable />
				</div>
			</div>
		</>
	);
}
