import React from 'react';
import Select from 'react-select';

export default function EditModal({ onUpdate, value, value1, onChange, handleChange, attributes }) {
	return (
		<div className="modal fade" id="editModal" role="dialog">
			<div className="modal-dialog">
				<div className="modal-content">
					<div className="modal-header">
						<button type="button" className="close" data-dismiss="modal">
							&times;
						</button>
						<h4 className="modal-title">Update Data</h4>
					</div>
					<div className="modal-body">
						<div id="settings-field">
							<label className="input-label" htmlFor="product-group-name">
								Product Group Name
							</label>
							<input
								type="text"
								value={value}
								onChange={e => onChange(e)}
								className="form-control"
								id="editGroupName"
								name="editGroupName"
							/>
						</div>
						<br />
						<div id="settings-field">
							<label className="input-label" htmlFor="default-attribute-set">
								Default Attribute Set
							</label>
							<Select value={value1} isClearable onChange={handleChange} options={attributes} />
						</div>
					</div>
					<div className="modal-footer">
						<button
							type="button"
							onClick={() => onUpdate()}
							className="btn btn-success"
							data-dismiss="modal"
						>
							Update
						</button>
						<button type="button" className="btn btn-default" data-dismiss="modal">
							Cancel
						</button>
					</div>
				</div>
			</div>
		</div>
	);
}
