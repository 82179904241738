import React from 'react';

export default function CreditsTable() {
	return (
		<div className="table-view table-responsive">
			<table id="credits-table" className="table">
				<thead>
					<tr>
						<th>Credit Note No.</th>
						<th>Credit Date</th>
						<th>Customer</th>
						<th>Status</th>
						<th>Currency</th>
						<th>Total</th>
					</tr>
				</thead>
				<tbody></tbody>
			</table>
		</div>
	);
}
