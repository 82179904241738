import React, { Component } from 'react';
import WarehouseTransfersComp from '../../../../components/setting/docDesigner/warehouseTransfers/index';

export default class WarehouseTransfers extends Component {
	state = {
		templates: ['Warehouse Transfer'],
	};
	render() {
		const { templates } = this.state;
		return <WarehouseTransfersComp templates={templates} name="Warehouse Transfer" />;
	}
}
