import React from 'react';
import CompaniesTable from './companiesTable';
import ConfirmationModal from '../../../common/confirmationModal';
import OneInputEditModal from '../../../common/OneInputEditModal';

export default function ShippingCompanies({
	addValue,
	editValue,
	onChange,
	onUpdate,
	onAdd,
	shippingCompanies,
	onCompanyUpdateOrDelete,
	onDelete,
	obsolete,
	onChangeEdit,
}) {
	return (
		<>
			<div className="comp-heading">
				<div className="row">
					<div className="col-lg-4">
						<label>Shipping Companies</label>
					</div>
				</div>
			</div>
			<br />
			<div className="panel panel-default">
				<div className="panel-body">
					<div className="row" id="settings-field">
						<div className="col-md-2 col-xs-9">
							<label className="input-label" htmlFor="company-name">
								* Company Name
							</label>
							<input
								value={addValue}
								onChange={e => onChange(e)}
								className="form-control"
								id="company-name"
							/>
						</div>
						<div className="col-xs-2" id="system-add-btn">
							<button onClick={() => onAdd()} className="btn btn-success btn-sm">
								Add
							</button>
						</div>
					</div>
				</div>
			</div>
			<div className="panel panel-default">
				<div className="panel-body">
					<CompaniesTable
						onCompanyUpdateOrDelete={data => onCompanyUpdateOrDelete(data)}
						shippingCompanies={shippingCompanies}
					/>
				</div>
			</div>
			<ConfirmationModal onDelete={() => onDelete()} item="Shipping Company" />
			<OneInputEditModal
				label="Company Name"
				value={editValue}
				onChange={e => onChangeEdit(e)}
				onUpdate={() => onUpdate()}
				checkbox={true}
				checkboxLabel="Obsolete"
				checkboxValue={obsolete}
			/>
		</>
	);
}
