import React, { Component } from 'react';
import DocTabHolder from './docTabHolder';
import Data from '../../../data';

export default function docDesignerHOC(MyComponent) {
	return class extends Component {
		constructor(props) {
			super(props);
			this.state = {
				nav: ['Document', 'Template', 'Toolbox', 'Preview'],
				newOrDuplicate: false,
			};
		}

		onClickNewOrDuplicate = () => {
			this.setState({ newOrDuplicate: !this.state.newOrDuplicate });
		};

		render() {
			const { onChange, templates, name } = this.props;

			return (
				<>
					<div className="comp-heading">
						<div className="row">
							<div className="col-lg-6">
								<label>Document Designer - {name}</label>
							</div>
						</div>
					</div>
					<br />
					<div className="row">
						<div className="col-md-5">
							<div className="panel panel-default">
								<div className="panel-body">
									<MyComponent {...this.props} />
								</div>
							</div>
						</div>
						<div className="col-md-7">
							<div className="panel panel-default">
								<div className="panel-body">
									<DocTabHolder
										toolBoxData={Data.docDesigner}
										nav={this.state.nav}
										onChange={e => onChange(e)}
										templates={templates}
										newOrDuplicate={this.state.newOrDuplicate}
										onClickNewOrDuplicate={() => this.onClickNewOrDuplicate()}
									/>
								</div>
							</div>
						</div>
					</div>
				</>
			);
		}
	};
}
