import React, { Component } from 'react';
import { goto, initilizeDataTable, destroyDataTable } from '../../../../helpers';
import { connect } from 'react-redux';
import swal from 'sweetalert';
import { loaderState, getUsers, onDeleteUser, deleteUser } from '../../../../actions';
import UsersComp from '../../../../components/setting/security/users/index';

class Users extends Component {
	constructor(props) {
		super(props);
		this.state = {
			user: null,
		};
	}

	componentDidMount() {
		initilizeDataTable('users-table');
		this.props.loaderState(true);
		this.props.getUsers();
	}

	componentDidUpdate() {
		initilizeDataTable('users-table');
	}

	onChange = user => {
		this.setState({ user });
	};

	onDelete = () => {
		const { selectedUser } = this.props;
		this.props.loaderState(true);
		this.props.deleteUser(selectedUser.id);
	};

	onDeleteUser = user => {
		this.setState({ user: user.first_name + ' ' + user.last_name });
		this.props.onDeleteUser(user);
	};

	render() {
		destroyDataTable('users-table');

		const { user } = this.state;
		const { users, userData } = this.props;
		return (
			<UsersComp
				user={user}
				users={users}
				userData={userData}
				onDeleteUser={user => this.onDeleteUser(user)}
				onChange={user => this.onChange(user)}
				onDelete={() => this.onDelete()}
			/>
		);
	}
}

const mapStateToProps = state => {
	return {
		users: state.users.allUsers,
		userData: state.users.loggedInUser,
		selectedUser: state.users.selectedUser,
	};
};

export default connect(mapStateToProps, { loaderState, getUsers, onDeleteUser, deleteUser })(Users);
