import React from 'react';
import SupplierReturnTable from './supplierReturnTable';
import ConfirmationModal from '../../../common/confirmationModal';
import OneInputEditModal from '../../../common/OneInputEditModal';

export default function SupplierReturnReason({
	addValue,
	editValue,
	onAdd,
	onChange,
	onUpdate,
	supplierReasons,
	onDelete,
	onReasonUpdateOrDelete,
	onChangeEdit,
}) {
	return (
		<>
			<div className="comp-heading">
				<div className="row">
					<div className="col-lg-4">
						<label>Supplier Return Reason</label>
					</div>
				</div>
			</div>
			<br />
			<div className="panel panel-default">
				<div className="panel-body">
					<div className="row" id="settings-field">
						<div className="col-md-2 col-xs-9">
							<label className="input-label" htmlFor="supplier-return-reason">
								* Supplier Return Reason
							</label>
							<input
								value={addValue}
								onChange={e => onChange(e)}
								className="form-control"
								id="supplier-return-reason"
							/>
						</div>
						<div className="col-xs-2" id="system-add-btn">
							<button onClick={() => onAdd()} className="btn btn-success btn-sm">
								Add
							</button>
						</div>
					</div>
				</div>
			</div>
			<div className="panel panel-default">
				<div className="panel-body">
					<SupplierReturnTable
						onReasonUpdateOrDelete={data => onReasonUpdateOrDelete(data)}
						supplierReasons={supplierReasons}
					/>
				</div>
			</div>
			<ConfirmationModal onDelete={() => onDelete()} item="Supplier Return" />
			<OneInputEditModal
				label="Company Name"
				value={editValue}
				onChange={e => onChangeEdit(e)}
				onUpdate={() => onUpdate()}
			/>
		</>
	);
}
