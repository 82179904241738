import React from 'react';

export default function Switch({ name, id, onChange, value, margin }) {
	return (
		<div className="form-inline" style={{ textAlign: margin ? 'center' : '' }}>
			<div className="form-group">
				<label htmlFor={id}>{name}</label>
				<label className="switch">
					<input type="checkbox" onChange={e => onChange(e)} checked={value} name={id} id={id} />
					<span className="slider round"></span>
				</label>
			</div>
		</div>
	);
}
