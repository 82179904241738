import React from 'react';

export default function RunReport({ onRunReport }) {
	return (
		<div className="container" id="run-report">
			<h5>Update your filters & run the report</h5>
			<button className="custom-btn">Run</button>
		</div>
	);
}
