import React from 'react';

export default function CostLinesTables() {
	return (
		<div className="table-view table-responsive">
			<table id="purchases-cost-lines-table" className="table">
				<thead>
					<tr>
						<th>Supplier </th>
						<th>Currency</th>
						<th>Cost</th>
						<th>Tax</th>
						<th>Ex. Rate</th>
						<th>Cost Date</th>
						<th>Reference</th>
						<th>Comments</th>
						<th>Delete</th>
					</tr>
				</thead>
				<tbody></tbody>
			</table>
		</div>
	);
}
