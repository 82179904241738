import React, { Component } from 'react';
import LabelsComp from '../../../../components/setting/docDesigner/labels/index';

export default class Labels extends Component {
	state = {
		templates: ['Default Label', 'Customer Label'],
	};
	render() {
		const { templates } = this.state;
		return <LabelsComp templates={templates} name="Labels" />;
	}
}
