import React from 'react';
import SplitButton from '../../../common/splitButton';
import InlineInputForm from '../../../common/inlineInputForm';
import InlineDropdown from '../../../common/inlineDropDown';
import RunReport from '../../../common/runReport';
import CreditEnquiryTable from './creditEnquiryTable';

export default function CreditEnquiry() {
	return (
		<>
			<div className="comp-heading">
				<div className="row">
					<div className="col-lg-4">
						<label>Credit Enquiry</label>
					</div>
					<div className="col-lg-2"></div>
					<div className="col-lg-6" style={{ textAlign: 'right' }}>
						<SplitButton
							label="Export"
							type="light"
							data={['Export as PDF', 'Export as CSV', 'Export as XLSX', 'Export as XLS']}
						/>
						<button className="btn btn-success btn-sm">Run</button>
					</div>
				</div>
			</div>
			<br />
			<div className="panel panel-default">
				<div className="panel-body">
					<div className="row" id="inline-input-forms">
						<div className="col-sm-4">
							<InlineInputForm id="date-from" type="date" value="" name="Date From" />
							<InlineInputForm id="date-to" type="date" value="" name="Date To" />
							<InlineDropdown
								id="transaction-date"
								name="Transaction Date"
								values={['Credit Date', 'Receipt date']}
							/>
							<InlineInputForm id="credit-number" value="" name="Credit Number" />
						</div>
						<div className="col-sm-4">
							<InlineInputForm id="credit-number" value="" name="Credit Number" />
							<InlineDropdown
								id="credit-status"
								name="Credit Status"
								values={['Parked', 'Completed', 'Deleted']}
							/>
							<InlineInputForm id="product-code" value="" name="Product Code" />
							<InlineInputForm id="product-description" value="" name="Product Description" />
						</div>
						<div className="col-sm-4">
							<InlineInputForm id="customer-code" value="" name="Customer Code" />
							<InlineInputForm id="customer-name" value="" name="Customer name" />
							<InlineDropdown id="product-group" name="Product Group" values={['', 'General']} />
							<InlineDropdown id="credit-reason" name="Credit Reason" values={['', 'Credit']} />
							<InlineDropdown
								id="warehouse"
								name="Warehouse"
								values={['Select All', 'Warehouse', 'Main Office']}
							/>
							<InlineDropdown id="sales-person" name="Sales Person" values={['', 'name: email']} />
						</div>
					</div>
				</div>
			</div>
			<div className="panel panel-default">
				<div className="panel-body">
					<CreditEnquiryTable />
					<br />
					<RunReport />
				</div>
			</div>
			<div className="row">
				<div className="col-md-6"></div>
				<div className="col-md-6">
					<div className="panel panel-default" id="panel-margin-right">
						<div className="panel-body">
							<div className="grid-container">
								<div className="grid-item">
									<div className="border-bottom">
										<span className="receipt-stat-label">Credit Count</span>
										<span className="pull-right receipt-stat-value"></span>
									</div>
								</div>
								<div className="grid-item">
									<div className="border-bottom">
										<span className="receipt-stat-label">Total Credit Value </span>
										<span className="pull-right receipt-stat-value"></span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
