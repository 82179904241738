import React from 'react';

import Tabs from '../../common/tabs';
import Products from './products';
import StockOnHand from './stockOnHand';
import SerialNumber from './serialNumber';
import BatchNumber from './batchNumber';
import ProductSupplier from './productSupplier';
import InventoryDetails from './inventoryDetails';
import ProductAttributes from './productAttributes';
import ProductPricing from './productPricing';
import SplitButton from '../../common/splitButton';

export default function ImportExport(props) {
	const {
		nav,
		onProductUpload,
		onProductAttributesUpload,
		onInventoryDetailsUpload,
		onProductSupplierUpload,
		onBatchNumberUpload,
		onSerialNumberUpload,
		onStockOnHandUpload,
		onProductPricingUpload,
	} = props;
	return (
		<div>
			<div className="comp-heading">
				<div className="row">
					<div className="col-lg-4 col-md-7 col-8">
						<label>Import/Export</label>
					</div>
					<div className="col-lg-5 col-md-2 col-1"></div>
					<div className="col-lg-3 col-md-3 col-3" style={{ textAlign: 'right' }}>
						<SplitButton
							type="success"
							label="Export"
							data={[
								'Products',
								'Stock On Hand',
								'Serial Number',
								'Batch Number',
								'Product Supplier',
								'Inventory Details',
								'Product Attributes',
								'Product Pricing',
							]}
						/>
					</div>
				</div>
			</div>
			<br />
			<Tabs nav={nav} />
			<div className="tab-content">
				<Products onProductUpload={() => onProductUpload()} />
				<StockOnHand onStockOnHandUpload={() => onStockOnHandUpload()} />
				<SerialNumber onSerialNumberUpload={() => onSerialNumberUpload()} />
				<BatchNumber onBatchNumberUpload={() => onBatchNumberUpload()} />
				<ProductSupplier onProductSupplierUpload={() => onProductSupplierUpload()} />
				<InventoryDetails onInventoryDetailsUpload={() => onInventoryDetailsUpload()} />
				<ProductAttributes onProductAttributesUpload={() => onProductAttributesUpload()} />
				<ProductPricing onProductPricingUpload={() => onProductPricingUpload()} />
			</div>
		</div>
	);
}
