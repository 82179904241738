import React from 'react';

import InlineInputForm from '../../../common/inlineInputForm';
import InLineCheckbox from '../../../common/inLineCheckbox';

export default function AddWarehouse({ onChange, data, onAdd, users, onUserSelect, edit }) {
	return (
		<>
			<div className="comp-heading">
				<div className="row">
					<div className="col-lg-4 col-md-7 col-8">
						<label> {edit ? <>Edit Warehouse ({data.code})</> : <>Add Warehouse</>} </label>
					</div>
					<div className="col-lg-5 col-md-2 col-1"></div>
					<div className="col-lg-3 col-md-3 col-3" style={{ textAlign: 'right' }}>
						<button onClick={() => onAdd()} className="btn btn-success btn-sm">
							Save
						</button>
					</div>
				</div>
			</div>
			<br />
			<div className="panel panel-default">
				<div className="panel-body">
					<div className="row" id="inline-input-forms">
						<div className="col-sm-4">
							<InlineInputForm
								disable={edit ? 'disabled' : ''}
								id="code"
								onChange={e => onChange(e)}
								value={data.code}
								name="* Warehouse Code"
							/>
							<InlineInputForm
								id="warehouseName"
								onChange={e => onChange(e)}
								value={data.warehouseName}
								name="* Warehouse Name"
							/>
							<InlineInputForm
								id="contactName"
								onChange={e => onChange(e)}
								value={data.contactName}
								name="Contact Number"
							/>
							<InlineInputForm id="email" onChange={e => onChange(e)} value={data.email} name="Email" />
							<InlineInputForm
								id="phoneNumber"
								onChange={e => onChange(e)}
								value={data.phoneNumber}
								name="Phone Number"
							/>
							<InlineInputForm
								id="mobileNumber"
								onChange={e => onChange(e)}
								value={data.mobileNumber}
								name="Mobile Number"
							/>
							<InlineInputForm
								id="DDInumber"
								onChange={e => onChange(e)}
								value={data.DDInumber}
								name="DDI Number"
							/>
							<InlineInputForm
								id="suburb"
								onChange={e => onChange(e)}
								value={data.suburb}
								name="Suburb"
							/>
							<InlineInputForm
								id="addressName"
								onChange={e => onChange(e)}
								value={data.addressName}
								name="Address Name"
							/>
						</div>
						<div className="col-sm-4">
							<InLineCheckbox
								id="defaultWarehouse"
								value={Number(data.defaultWarehouse) === 1 ? 'checked' : ''}
								onChange={e => onChange(e)}
								name="Default Warehouse"
							/>
							<InLineCheckbox
								id="obsolete"
								value={Number(data.obsolete) === 1 ? 'checked' : ''}
								onChange={e => onChange(e)}
								name="Obsolete"
							/>
							<InlineInputForm
								id="addressLine1"
								value={data.addressLine1}
								onChange={e => onChange(e)}
								name="Address Line 1"
							/>
							<InlineInputForm
								id="addressLine2"
								value={data.addressLine2}
								onChange={e => onChange(e)}
								name="Address Line 2"
							/>
							<InlineInputForm
								id="town"
								onChange={e => onChange(e)}
								value={data.town}
								name="Town / City"
							/>
							<InlineInputForm
								id="state"
								onChange={e => onChange(e)}
								value={data.state}
								name="State / Region"
							/>
							<InlineInputForm
								id="postalCode"
								onChange={e => onChange(e)}
								value={data.postalCode}
								name="Postal Code"
							/>
							<InlineInputForm
								id="country"
								onChange={e => onChange(e)}
								value={data.country}
								name="Country"
							/>
						</div>
						<div className="col-md-4" id="warehouse-user-access">
							<b>User Access</b>
							<hr className="user-access-hr" />
							{users
								? users.map(user => (
										<div className="form-inline" key={user.id}>
											<span className="input-label">
												{user.first_name} {user.last_name}
											</span>
											<span
												className="table-input"
												style={{ float: 'right', width: '70%', marginTop: '-5px' }}
											>
												<label className="switch">
													<input
														onChange={e => {
															onChange(e);
															setTimeout(() => {
																onUserSelect(user.id);
															}, 500);
														}}
														checked={
															data.userAccess
																? data.userAccess.includes(user.id)
																	? 'checked'
																	: ''
																: ''
														}
														type="checkbox"
														id="userCheck"
													/>
													<span className="slider round"></span>
												</label>
											</span>
										</div>
								  ))
								: null}
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
