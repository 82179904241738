import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { getWarehouses, loaderState } from '../../../../actions';
import { initilizeDataTable, destroyDataTable, warehousesData, goto } from '../../../../helpers';
import ViewSalesQuotesComp from '../../../../components/sales/quotes/view';

class ViewSalesQuotes extends Component {
	componentDidMount() {
		const { warehouses } = this.props;
		if (warehouses.length === 0) {
			this.props.loaderState(true);
			this.props.getWarehouses();
		}
		initilizeDataTable('quotes-table');
	}
	componentDidUpdate() {
		initilizeDataTable('quotes-table');
	}
	render() {
		destroyDataTable('quotes-table');

		const { warehouses } = this.props;
		return <ViewSalesQuotesComp goto={path => goto(path)} warehouses={warehousesData(warehouses)} />;
	}
}

const mapStateToProps = state => {
	return {
		warehouses: state.system.warehouses.allWarehouses,
	};
};

export default connect(mapStateToProps, { loaderState, getWarehouses })(ViewSalesQuotes);
