import React from 'react';

export default function References() {
	var arr = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
	return (
		<div id="References" className="tab-pane fade">
			{/* <div className="panel panel-default">
				<div className="panel-body"> */}
			<div className="table-view table-responsive">
				<table id="references-table" className="table">
					<thead className="thead-dark">
						<tr>
							<th>Trasaction Ref</th>
							<th>Trasaction Type</th>
							<th align="right">Quantity</th>
						</tr>
					</thead>
					<tbody>
						{arr.map((a, index) => {
							return (
								<tr key={index}>
									<td>6756</td>
									<td>type</td>
									<td align="right">{a}</td>
								</tr>
							);
						})}
					</tbody>
				</table>
			</div>
		</div>
		// 	</div>
		// </div>
	);
}
