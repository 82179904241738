import React, { Component } from 'react';
import ShipNotesComp from '../../../../components/setting/docDesigner/shipNotes/index';

export default class ShipNotes extends Component {
	state = {
		templates: ['Default Ship Note'],
	};
	render() {
		const { templates } = this.state;
		return <ShipNotesComp templates={templates} name="Ship Note" />;
	}
}
