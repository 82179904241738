import { settings } from '../../constants/actionTypes';
const initialState = {
	allSalesGroups: null,
	selectedSalesGroup: null,
};

function salesGroupsReducer(state = initialState, action) {
	switch (action.type) {
		case settings.SALES_GROUPS:
			return { ...state, allSalesGroups: action.payload };
		case settings.SELECTED_SALES_GROUP:
			return { ...state, selectedSalesGroup: action.payload };
		default:
			return state;
	}
}
export default salesGroupsReducer;
