import React, { Component } from 'react';
import { initilizeDataTable, destroyDataTable } from '../../../helpers';
import AddAdjustmentComp from '../../../components/inventory/stockAdjustments/addAdjustment';
export default class AddAdjustment extends Component {
	componentDidMount() {
		// initilizeDataTable('product-adjustments-table');
	}

	componentDidUpdate() {
		// initilizeDataTable('product-adjustments-table');
	}

	render() {
		// destroyDataTable('product-adjustments-table');

		return <AddAdjustmentComp />;
	}
}
