import React, { Component } from 'react';

import {
	loaderState,
	getShippingCompanies,
	addShippingCompany,
	deleteShippingCompany,
	onUpdateOrDeleteCompany,
	updateShippingCompany,
} from '../../../../actions';
import swal from 'sweetalert';
import { connect } from 'react-redux';
import { isEmptyOrNull, initilizeDataTable, destroyDataTable } from '../../../../helpers';
import ShippingCompaniesComp from '../../../../components/setting/system/shippingCompanies';
class ShippingCompanies extends Component {
	constructor(props) {
		super(props);
		this.state = {
			addValue: null,
			editValue: null,
			obsolete: null,
		};
	}

	componentDidMount() {
		initilizeDataTable('companies-table');
		this.props.loaderState(true);
		this.props.getShippingCompanies();
	}

	componentDidUpdate() {
		initilizeDataTable('companies-table');
	}

	onChange = ({ target }) => {
		const { value } = target;
		this.setState({ addValue: value });
	};

	onAdd = () => {
		const { user, organization } = this.props;
		const { addValue } = this.state;

		if (!isEmptyOrNull(addValue)) {
			this.props.loaderState(true);
			this.props.addShippingCompany({
				companyName: addValue,
				createdBy: user.id,
				organizationId: organization.id,
			});
		} else {
			swal('', 'Please enter a Company Name', 'info');
		}
		this.resetState();
	};

	resetState = () => {
		this.setState({ addValue: null, editValue: null, obsolete: null });
	};

	onUpdate = () => {
		const { selectedShippingCompany, user } = this.props;
		const { editValue, obsolete } = this.state;
		let data = {};
		if (isEmptyOrNull(editValue) && isEmptyOrNull(obsolete)) {
			this.setState({ editValue: null, obsolete: null });
		} else {
			if (!isEmptyOrNull(editValue)) {
				data.companyName = editValue;
			}
			if (!isEmptyOrNull(obsolete)) {
				data.obsolete = obsolete;
			}
			this.props.loaderState(true);
			this.props.updateShippingCompany({
				...data,
				id: selectedShippingCompany.id,
				updatedBy: user.id,
			});
			this.resetState();
		}
	};

	onDelete = () => {
		const { selectedShippingCompany } = this.props;
		this.props.loaderState(true);
		this.props.deleteShippingCompany(selectedShippingCompany.id);
	};

	onChangeEdit = ({ target }) => {
		const { type, value } = target;
		if (type === 'checkbox') {
			this.setState({ obsolete: target.checked ? '1' : '-1' });
		} else {
			this.setState({ editValue: value });
		}
	};

	onCompanyUpdateOrDelete = data => {
		this.props.onUpdateOrDeleteCompany(data);
	};

	render() {
		destroyDataTable('companies-table');

		const { addValue, editValue, obsolete } = this.state;
		const { allShippingCompanies, selectedShippingCompany } = this.props;
		return (
			<ShippingCompaniesComp
				addValue={addValue}
				editValue={editValue ? editValue : selectedShippingCompany ? selectedShippingCompany.companyName : null}
				onCompanyUpdateOrDelete={data => this.onCompanyUpdateOrDelete(data)}
				shippingCompanies={allShippingCompanies}
				onAdd={() => this.onAdd()}
				onChange={e => this.onChange(e)}
				onUpdate={() => this.onUpdate()}
				onDelete={() => this.onDelete()}
				onChangeEdit={e => this.onChangeEdit(e)}
				obsolete={obsolete}
			/>
		);
	}
}

const mapStateToProps = state => {
	return {
		allShippingCompanies: state.system.shippingCompanies.allShippingCompanies,
		user: state.users.loggedInUser,
		selectedShippingCompany: state.system.shippingCompanies.selectedShippingCompany,
		organization: state.users.organizationInfo,
	};
};

export default connect(mapStateToProps, {
	loaderState,
	getShippingCompanies,
	addShippingCompany,
	deleteShippingCompany,
	onUpdateOrDeleteCompany,
	updateShippingCompany,
})(ShippingCompanies);
