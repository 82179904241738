import React, { Component } from 'react';
import { connect } from 'react-redux';
import swal from 'sweetalert';
import { loaderState, addUnit, updateUnit, deleteUnit, onUpdateOrDeleteUnit, getUnits } from '../../../../actions';
import { isEmptyOrNull, initilizeDataTable, destroyDataTable } from '../../../../helpers';
import UnitsOfMeasureComp from '../../../../components/setting/system/unitOfMeasure';

class UnitsOfMeasure extends Component {
	constructor(props) {
		super(props);
		this.state = {
			addValue: null,
			editValue: null,
		};
	}

	componentDidMount() {
		initilizeDataTable('units-table-table');
		this.props.loaderState(true);
		this.props.getUnits();
	}

	componentDidUpdate() {
		initilizeDataTable('units-table-table');
	}
	resetState = () => {
		this.setState({ addValue: null, editValue: null });
	};

	onAdd = () => {
		const { user, organization } = this.props;
		const { addValue } = this.state;

		if (!isEmptyOrNull(addValue)) {
			this.props.loaderState(true);
			this.props.addUnit({ unit_name: addValue, createdBy: user.id, organizationId: organization.id });
		} else {
			swal('', 'Please enter a unit value', 'info');
		}
		this.resetState();
	};

	onChange = ({ target }) => {
		this.setState({ addValue: target.value });
	};

	onChangeEdit = ({ target }) => {
		this.setState({ editValue: target.value });
	};

	onUpdate = () => {
		const { selectedUnit, user } = this.props;
		const { editValue } = this.state;

		if (!isEmptyOrNull(editValue)) {
			this.props.loaderState(true);
			this.props.updateUnit({ unit_name: editValue, id: selectedUnit.id, updatedBy: user.id });
		}
		this.resetState();
	};

	onDelete = () => {
		const { selectedUnit } = this.props;
		this.props.loaderState(true);
		this.props.deleteUnit(selectedUnit.id);
		this.resetState();
	};

	onUnitUpdateOrDelete = data => {
		this.props.onUpdateOrDeleteUnit(data);
	};

	render() {
		destroyDataTable('units-table-table');

		const { addValue, editValue } = this.state;
		const { units, selectedUnit } = this.props;
		return (
			<UnitsOfMeasureComp
				units={units}
				addValue={addValue}
				editValue={editValue != null ? editValue : selectedUnit ? selectedUnit.unit_name : null}
				onUnitUpdateOrDelete={data => this.onUnitUpdateOrDelete(data)}
				onChange={e => this.onChange(e)}
				onChangeEdit={e => this.onChangeEdit(e)}
				onUpdate={() => this.onUpdate()}
				onAdd={() => this.onAdd()}
				onDelete={() => this.onDelete()}
			/>
		);
	}
}

const mapStateToProps = state => {
	return {
		units: state.system.units.allUnits,
		user: state.users.loggedInUser,
		selectedUnit: state.system.units.selectedUnit,
		organization: state.users.organizationInfo,
	};
};

export default connect(mapStateToProps, {
	loaderState,
	getUnits,
	deleteUnit,
	updateUnit,
	onUpdateOrDeleteUnit,
	addUnit,
})(UnitsOfMeasure);
