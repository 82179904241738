import React, { Component } from 'react';
import { loaderState, setSuperUserInfo } from '../../actions';
import { connect } from 'react-redux';
import swal from 'sweetalert';
import { EMAIL_FORMAT } from '../../constants/global-constant';
import { isEmptyOrNull, goto } from '../../helpers';
import SignupComp from '../../components/registration/signup';

class Signup extends Component {
	constructor(props) {
		super(props);
		this.state = {
			first_name: '',
			last_name: '',
			email: '',
			password: '',
			confirm_password: '',
		};
	}

	onChange = ({ target }) => {
		const { id, value } = target;
		this.setState({ [id]: value });
	};

	onSignUp = () => {
		const { last_name, first_name, password, email, confirm_password } = this.state;
		var email_format = EMAIL_FORMAT;
		if (
			isEmptyOrNull(last_name) ||
			isEmptyOrNull(first_name) ||
			isEmptyOrNull(password) ||
			isEmptyOrNull(confirm_password) ||
			isEmptyOrNull(email)
		) {
			swal('', 'All fields are required', 'info');
		} else if (!email.match(email_format)) {
			swal('', 'Email is not in correct format', 'error');
		} else if (password !== confirm_password) {
			swal('', "Password and confirm password doesn't match", 'error');
		} else {
			goto('/organization');
			let data = {
				first_name,
				last_name,
				email,
				password,
			};
			this.props.setSuperUserInfo(data);
		}
	};

	render() {
		const { first_name, last_name, password, confirm_password, email } = this.state;
		return (
			<div>
				<SignupComp
					goto={path => goto(path)}
					last_name={last_name}
					first_name={first_name}
					password={password}
					confirm_password={confirm_password}
					email={email}
					onChange={e => this.onChange(e)}
					onSignUp={() => this.onSignUp()}
				/>
			</div>
		);
	}
}

export default connect(null, { loaderState, setSuperUserInfo })(Signup);
