import api from '../../api/api';
import { success } from '../../constants/messages';
import { settings } from '../../constants/actionTypes';
import { loaderState } from '..';
import _ from 'lodash';
import swal from 'sweetalert';

export const getPaymentTerms = () => dispatch => {
	api.get('/paymentTerms')
		.then(res => {
			if (res.data.code === 200) {
				dispatch({ type: settings.PAYMENT_TERMS, payload: res.data.data });
			} else {
				swal('', res.data.message, 'error');
			}
			dispatch(loaderState(false));
		})
		.catch(err => {
			swal('', err.message, 'error');
			dispatch(loaderState(false));
		});
};

export const addPaymentTerms = data => (dispatch, getState) => {
	var allTerms = getState().system.paymentTerms.allTerms;
	api.post('/paymentTerms', { ...data })
		.then(res => {
			if (res.data.code === 200) {
				dispatch({ type: settings.PAYMENT_TERMS, payload: [...allTerms, res.data.data] });
			} else {
				swal('', res.data.message, 'error');
			}
			dispatch(loaderState(false));
		})
		.catch(err => {
			swal('', err.message, 'error');
			dispatch(loaderState(false));
		});
};

export const deletePaymentTerms = id => (dispatch, getState) => {
	const { allTerms } = getState().system.paymentTerms;
	api.delete(`/paymentTerms/${id}`)
		.then(res => {
			if (Number(res.data.code) === 200) {
				_.remove(allTerms, term => {
					return Number(id) == Number(term.id);
				});
				dispatch(loaderState(false));
			} else {
				swal('', res.data.message, 'error');
				dispatch(loaderState(false));
			}
		})
		.catch(err => {
			swal('', err.message, 'error');
			dispatch(loaderState(false));
		});
};

export const updatePaymentTerm = data => (dispatch, getState) => {
	const { allTerms } = getState().system.paymentTerms;
	api.post(`/productGroup/update/${data.id}`, { ...data })
		.then(res => {
			if (Number(res.data.code) === 200) {
				let updatedTerm = _.findLast(allTerms, term => {
					return Number(term.id) == Number(data.id);
				});
				if (data.name) {
					updatedTerm.name = data.name;
				}
				if (data.type) {
					updatedTerm.type = data.type;
				}
				if (data.days) {
					updatedTerm.days = data.days;
				}
				let updatedTerms = _.map(allTerms, term => {
					return term.id === data.id ? updatedTerm : term;
				});

				dispatch({ type: settings.PAYMENT_TERMS, payload: updatedTerms });
			} else {
				swal('', res.data.message, 'error');
			}
			dispatch(loaderState(false));
		})
		.catch(err => {
			swal('', err.message, 'error');
			dispatch(loaderState(false));
		});
};

export const onSelectPaymentTerm = data => dispatch => {
	dispatch({ type: settings.SELECTED_PAYMENT_TERM, payload: data });
};
