import React from 'react';

export default function Purchasestable() {
	return (
		<div className="table-view table-responsive">
			<table id="purchases-table" className="table">
				<thead>
					<tr>
						<th>
							<i className="fa fa-cog icon" aria-hidden="true" />
						</th>
						<th>Order No</th>
						<th>Order Date</th>
						<th>Delivery Date</th>
						<th>Supplier Name</th>
						<th>Supplier Reference</th>
						<th>Status</th>
						<th>Warehouse</th>
						<th>Currency</th>
						<th>Total(GBP)</th>
						<th>Actions</th>
					</tr>
				</thead>
				<tbody></tbody>
			</table>
		</div>
	);
}
