import React from 'react';
import InlineInputForm from '../../../common/inlineInputForm';
import InlineDropdown from '../../../common/inlineDropDown';
import InLineCheckbox from '../../../common/inLineCheckbox';

export default function ProductEnquiry() {
	return (
		<>
			<div className="comp-heading">
				<div className="row">
					<div className="col-lg-4">
						<label>Product Enquiry</label>
					</div>
					<div className="col-lg-2"></div>
					<div className="col-lg-6" style={{ textAlign: 'right' }}>
						<button className="btn btn-success btn-sm">Print</button>
					</div>
				</div>
			</div>
			<br />
			<div className="panel panel-default">
				<div className="panel-body">
					<div className="row" id="inline-input-forms">
						<div className="col-sm-4">
							<InlineDropdown
								link={true}
								path="/settings/system/warehouses"
								id="warehouse"
								name="Warehouse"
								values={['Select All', 'Warehouse', 'Main Office']}
							/>
							<InlineInputForm
								link={true}
								path="/inverntory/products/view products"
								id="product-code"
								value=""
								name="Product Code"
							/>
							<InlineInputForm
								link={true}
								path="/inverntory/products/view products"
								id="product-description"
								value=""
								name="Product Description"
							/>
							<InlineInputForm disable="disabled" id="bin-location" value="" name="Bin Location" />
							<InlineInputForm
								disable="disabled"
								link={true}
								path="/settings/system/units of measure"
								id="unit-of-measure"
								value=""
								name="Unit Of Measure"
							/>
							<InlineInputForm disable="disabled" id="barcode" value="" name="Barcode" />
						</div>
						<div className="col-sm-4">
							<InLineCheckbox disable="disabled" id="never-diminishing" name="Never Diminishing" />
							<InlineInputForm
								disable="disabled"
								link={true}
								path="/inverntory/products/view products"
								id="on-hand"
								value=""
								name="On Hand"
							/>
							<InlineInputForm
								disable="disabled"
								link={true}
								path="/inverntory/products/view products"
								id="on-purchase"
								value=""
								name="On Purchase"
							/>
							<InlineInputForm
								disable="disabled"
								link={true}
								path="/inverntory/products/view products"
								id="on-assembly"
								value=""
								name="On Assembly"
							/>
							<InlineInputForm
								disable="disabled"
								link={true}
								path="/inverntory/products/view products"
								id="on-order"
								value=""
								name="On Order"
							/>
							<InlineInputForm
								disable="disabled"
								link={true}
								path="/inverntory/products/view products"
								id="in-assembly"
								value=""
								name="In Assembly"
							/>
						</div>
						<div className="col-sm-4">
							<InlineInputForm
								disable="disabled"
								link={true}
								path="/inverntory/products/view products"
								id="on-transfer"
								value=""
								name="On Transfer"
							/>
							<InlineInputForm
								disable="disabled"
								link={true}
								path="/inverntory/products/view products"
								id="on-quote"
								value=""
								name="On Quote"
							/>
							<InlineInputForm disable="disabled" id="available" value="" name="Available" />
						</div>
					</div>
					<br />
				</div>
			</div>
		</>
	);
}
