import React from 'react';
import CanvasJSReact from '../../assets/canvasjs.react';
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

export default function Probablity({ options }) {
	return (
		<div>
			<CanvasJSChart
				options={options}
				/* onRef={ref => this.chart = ref} */
			/>
		</div>
	);
}
