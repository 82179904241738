import React from 'react';

export default function WarehouseTransferTable() {
	return (
		<div className="table-view table-responsive">
			<table id="add-warehouse-transfers-table" className="table">
				<thead>
					<tr>
						<th>Product Code</th>
						<th>Product Description</th>
						<th>Bin Location</th>
						<th>
							Volume(m<sup>3</sup>)
						</th>
						<th>Weight</th>
						<th>Pack Size</th>
						<th>Trasnfer Quantity</th>
						<th>Comments</th>
						<th>Action</th>
					</tr>
				</thead>
				<tbody></tbody>
			</table>
		</div>
	);
}
