import React from 'react';
import SplitButton from '../../../common/splitButton';
import InlineInputForm from '../../../common/inlineInputForm';
import InlineDropdown from '../../../common/inlineDropDown';
import RunReport from '../../../common/runReport';
import MarginEnquiryTable from './marginEnquiryTable';
import Stats from './stats';

export default function MarginEnquiry() {
	return (
		<>
			<div className="comp-heading">
				<div className="row">
					<div className="col-lg-4">
						<label>Margin Enquiry</label>
					</div>
					<div className="col-lg-2"></div>
					<div className="col-lg-6" style={{ textAlign: 'right' }}>
						<SplitButton
							label="Export"
							type="light"
							data={['Export as PDF', 'Export as CSV', 'Export as XLSX', 'Export as XLS']}
						/>
						<button className="btn btn-success btn-sm">Run</button>
					</div>
				</div>
			</div>
			<br />
			<div className="panel panel-default">
				<div className="panel-body">
					<div className="row" id="inline-input-forms">
						<div className="col-sm-4">
							<InlineInputForm id="date-from" type="date" value="" name="Date From" />
							<InlineInputForm id="date-to" type="date" value="" name="Date To" />
							<InlineInputForm id="sales-order-number" value="" name="Sales Order Number" />
							<InlineInputForm id="credit-note-number" value="" name="Credit Note Number" />
							<InlineDropdown
								id="sell-price-tier"
								name="Sell Price Tier"
								values={[
									'',
									'Sell Price Tier 1',
									'Sell Price Tier 2',
									'Sell Price Tier 3',
									'Sell Price Tier 4',
									'Sell Price Tier 5',
									'Sell Price Tier 6',
									'Sell Price Tier 7',
									'Sell Price Tier 8',
									'Sell Price Tier 9',
									'Sell Price Tier 10',
								]}
							/>
						</div>
						<div className="col-sm-4">
							<InlineDropdown
								id="warehouse"
								name="Warehouse"
								values={['Select All', 'Warehouse', 'Main Office']}
							/>
							<InlineDropdown
								id="product-group"
								name="Product Group"
								values={['', 'Product Group 1', 'Product Group 2', 'Product Group 3']}
							/>
							<InlineInputForm id="product-code" value="" name="Product Code" />
							<InlineInputForm id="product-description" value="" name="Product Description" />
							<InlineDropdown id="sales-order-group" name="Sales Order Group" values={['']} />
						</div>
						<div className="col-sm-4">
							<InlineDropdown id="customer-type" name="Customer Type" values={['', 'General']} />
							<InlineInputForm id="customer-code" value="" name="Customer Code" />
							<InlineInputForm id="customer-name" value="" name="Customer Name" />
							<InlineDropdown id="delivery-method" name="Delivery Method" values={['']} />
							<InlineInputForm
								id="delivery-address"
								value=""
								name="Delivery Address"
								disable="disabled"
							/>
							<InlineDropdown id="sales-persom" name="Sales Persom" values={['', 'name: email']} />
						</div>
					</div>
				</div>
			</div>
			<div className="panel panel-default">
				<div className="panel-body">
					<MarginEnquiryTable />
					<br />
					<RunReport />
				</div>
			</div>

			<Stats />
		</>
	);
}
