import React from 'react';

export default function AssembliesTable() {
	return (
		<div className="table-view table-responsive">
			<table id="view-assemblies-table" className="table">
				<thead>
					<tr>
						<th>
							<i style={{ fontSize: '1.5em' }} className="fa fa-cog icon" />
						</th>
						<th>Assembly Number</th>
						<th>Assembly Date</th>
						<th>Assemble By</th>
						<th>Assembly Status</th>
						<th>Product Code</th>
						<th>Product Description</th>
						<th>Source Warehouse</th>
						<th>Destination Warehouse</th>
						<th>Assembly Type</th>
						<th>Auto</th>
						<th>Assembled Quantity</th>
						<th>Total Cost</th>
						<th>Action</th>
					</tr>
				</thead>
				<tbody></tbody>
			</table>
		</div>
	);
}
