import React from 'react';
import SplitButton from '../../common/splitButton';
import WarehouseTable from './warehouseTable';

export default function WarehouseTransfers({ goto }) {
	return (
		<>
			<div className="comp-heading">
				<div className="row">
					<div className="col-lg-4">
						<label>Warehouse Transfers</label>
					</div>
					<div className="col-lg-5"></div>
					<div className="col-lg-3" style={{ textAlign: 'right' }}>
						<SplitButton
							label="Export"
							type="light"
							data={['Export to PDF', 'Export to CSV', 'Export to XLSX', 'Export to XLS']}
						/>
						<button className="btn btn-light btn-sm" style={{ marginRight: '5px' }}>
							Import
						</button>
						<button onClick={() => goto('/warehouse transfer/add')} className="btn btn-success btn-sm">
							Add
						</button>
					</div>
				</div>
			</div>
			<br />
			<div className="row" id="warehouse-transfers">
				<div className="col-md-2">
					<label className="input-label" htmlFor="adjustments-status">
						Status
					</label>
					<select className="form-control" id="adjustments-status">
						<option>Open</option>
						<option>Parked</option>
						<option>Completed</option>
						<option>All</option>
					</select>
				</div>
			</div>
			<br />
			{/* <div className="panel panel-default">
				<div className="panel-body"> */}
			<WarehouseTable />
			{/* </div>
			</div> */}
			<br />
			<div className="row">
				<div className="col-md-10"></div>
				<div className="col-md-2" id="stock-price-count" style={{ float: 'right' }}>
					<div className="transfers-label">STOCK PRICE COUNT</div>
					<br />
					<div className="transfers-count">0</div>
				</div>
			</div>
		</>
	);
}
