import { settings } from '../../constants/actionTypes';
const initialState = {
	allTiers: null,
	selectedTier: null,
};

function sellPriceTierReducer(state = initialState, action) {
	switch (action.type) {
		case settings.SELL_PRICE_TIERS:
			return { ...state, allTiers: action.payload };
		case settings.SELECTED_SELL_PRICE_TIER:
			return { ...state, selectedTier: action.payload };
		default:
			return state;
	}
}
export default sellPriceTierReducer;
