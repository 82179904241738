import React from 'react';
import Tabs from '../../../common/tabs';
import Details from './details';
import Configuration from './configuration';
import Address from '../../../customers/add/address';
import Contact from './contact';
import Images from './images';
import Invoicing from './invoicing';
import Purchasing from './purchasing';

export default function Company({ nav, organization }) {
	return (
		<>
			<div className="comp-heading">
				<div className="row">
					<div className="col-lg-4">
						<label>Company</label>
					</div>
					<div className="col-lg-2"></div>
					<div className="col-lg-6" style={{ textAlign: 'right' }}>
						<button className="btn btn-light btn-sm">Features</button>
						<button className="btn btn-success btn-sm">Save</button>
					</div>
				</div>
			</div>
			<br />
			<div className="panel panel-default">
				<div className="panel-body">
					<Tabs nav={nav} />
					<div className="tab-content">
						<Details organization={organization} />
						<Configuration />
						<Address />
						<Contact />
						<Images />
						<Invoicing />
						<Purchasing />
					</div>
				</div>
			</div>
		</>
	);
}
