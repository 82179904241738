import React from 'react';
import { goto } from '../../helpers';

export default function InlineInputForm({ id, name, value, onChange, disable, type, link, path }) {
	return (
		<>
			<div className="form-inline" id="form-inline-input">
				<div className="form-group">
					{link ? <a onClick={() => goto(path)}>{name}</a> : <label htmlFor={id}>{name}</label>}

					<input
						disabled={disable}
						onChange={e => onChange(e)}
						type={type ? type : 'text'}
						className="form-control"
						value={value}
						id={id}
					/>
				</div>
			</div>
		</>
	);
}
