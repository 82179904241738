import React, { Component } from 'react';
import { initilizeDataTable, destroyDataTable } from '../../../helpers';
import AddCustomerComp from '../../../components/customers/add';

export default class AddCustomer extends Component {
	constructor(props) {
		super(props);
		this.state = {
			nav: ['Details', 'Address', 'Contacts', 'Delivery', 'Quotes', 'Orders', 'Credits', 'Customer Pricing'],
		};
	}
	componentDidMount() {
		initilizeDataTable('customers-table');
		initilizeDataTable('delivery-address-table');
		initilizeDataTable('quotes-table');
		initilizeDataTable('orders-table');
		initilizeDataTable('credits-table');
	}
	componentDidUpdate() {
		initilizeDataTable('customers-table');
		initilizeDataTable('delivery-address-table');
		initilizeDataTable('quotes-table');
		initilizeDataTable('orders-table');
		initilizeDataTable('credits-table');
	}
	render() {
		destroyDataTable('customers-table');
		destroyDataTable('delivery-address-table');
		destroyDataTable('quotes-table');
		destroyDataTable('orders-table');
		destroyDataTable('credits-table');

		const { nav } = this.state;
		return <AddCustomerComp nav={nav} />;
	}
}
