import React from 'react';

export default function TermsTable({ terms, onPaymentTermSelect }) {
	return (
		<div className="table-view table-responsive" id="setting-tables">
			<table id="payment-terms-table" className="table">
				<thead>
					<tr>
						<th>Name</th>
						<th>Days</th>
						<th>Type</th>
						<th>Actions</th>
					</tr>
				</thead>
				<tbody>
					{terms
						? terms.map(term => (
								<tr key={term.id}>
									<td>{term.name}</td>
									<td>{term.days}</td>
									<td>{term.type}</td>
									<td>
										<i
											data-toggle="modal"
											data-target="#confirmationModal"
											className="fa fa-trash-o icon"
											onClick={() => onPaymentTermSelect(term)}
										/>
										<i
											data-toggle="modal"
											data-target="#paymentEditModal"
											className="fa fa-pencil-square-o icon"
											onClick={() => onPaymentTermSelect(term)}
										/>
									</td>
								</tr>
						  ))
						: null}
				</tbody>
			</table>
		</div>
	);
}
