import React, { Component } from 'react';
import { isEmptyOrNull } from '../../../../helpers';
import { connect } from 'react-redux';
import _ from 'lodash';
import swal from 'sweetalert';
import { loaderState, addWarehouse, getUsers } from '../../../../actions';
import AddWarehouseComp from '../../../../components/setting/system/warehouses/addOrEditWarehouse';

class Warehouses extends Component {
	constructor(props) {
		super(props);
		this.state = {
			code: null,
			warehouseName: null,
			contactName: null,
			email: null,
			phoneNumber: null,
			mobileNumber: null,
			DDInumber: null,
			suburb: null,
			addressName: null,
			defaultWarehouse: '-1',
			obsolete: '-1',
			addressLine1: null,
			addressLine2: null,
			town: null,
			state: null,
			postalCode: null,
			country: null,
			userCheck: false,
			userAccess: null,
			userAccesses: [],
		};
	}

	componentDidMount() {
		this.props.loaderState(true);
		this.props.getUsers();
	}

	onChange = ({ target }) => {
		const { id, value, type } = target;
		if (type === 'checkbox') {
			this.setState({ [id]: target.checked ? '1' : '-1' });
		} else {
			this.setState({ [id]: value });
		}
	};

	onAdd = () => {
		const { user, organization } = this.props;
		const { code, warehouseName } = this.state;
		if (!isEmptyOrNull(code) || !isEmptyOrNull(warehouseName)) {
			this.props.loaderState(true);
			let data = { ...this.state, createdBy: user.id, updatedBy: user.id, organizationId: 1 }; //todo: organization.id
			delete data.userCheck;
			delete data.userAccesses;
			this.props.addWarehouse(data);
		} else {
			swal('', 'Warehoues code and Warehouse name should not be empty', 'info');
		}
	};

	onUserSelect = id => {
		const { userCheck, userAccesses } = this.state;

		if (userCheck == true) {
			if (!userAccesses.includes(id)) {
				userAccesses.push(id);
			}
		} else {
			_.remove(userAccesses, access => {
				return access === id;
			});
		}
		this.setState({ userAccess: userAccesses.toString() });
	};

	render() {
		const { users } = this.props;
		return (
			<AddWarehouseComp
				onUserSelect={id => this.onUserSelect(id)}
				users={users}
				onAdd={() => this.onAdd()}
				data={this.state}
				onChange={e => this.onChange(e)}
			/>
		);
	}
}

const mapStateToProps = state => {
	return {
		users: state.users.allUsers,
		user: state.users.loggedInUser,
		organization: state.users.organizationInfo,
	};
};

export default connect(mapStateToProps, { loaderState, addWarehouse, getUsers })(Warehouses);
