import { inventory } from '../../constants/actionTypes';
const initialState = {
	allProducts: null,
	selectedProduct: null,
};

function productsReducer(state = initialState, action) {
	switch (action.type) {
		case inventory.PRODUCTS:
			return { ...state, allProducts: action.payload };
		case inventory.SELECTED_PRODUCT:
			return { ...state, selectedProduct: action.payload };
		default:
			return state;
	}
}
export default productsReducer;
