import React from 'react';
import CostingsTable from './costingsTable';

export default function Constings() {
	return (
		<div id="Constings" className="tab-pane fade">
			<div className="row" id="settings-field">
				<div className="col-md-2">
					<label className="input-label" htmlFor="order-status">
						Order Status
					</label>
					<select className="form-control" id="order-status">
						<option>All</option>
						<option>Open</option>
						<option>Parked</option>
						<option>Placed</option>
						<option>Costed</option>
						<option>Completed</option>
					</select>
				</div>
			</div>
			<br />
			{/* <div className="panel panel-default">
				<div className="panel-body"> */}
			<CostingsTable />
			{/* </div>
			</div> */}
		</div>
	);
}
