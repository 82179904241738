import React, { Component } from 'react';
import { initilizeDataTable, destroyDataTable, warehousesData } from '../../../helpers';
import { connect } from 'react-redux';
import _ from 'lodash';
import { getWarehouses, loaderState } from '../../../actions';
import AddSupplierReturnComp from '../../../components/purchases/addSupplierReturn/index';

class AddSupplierReturn extends Component {
	componentDidMount() {
		const { warehouses } = this.props;
		if (warehouses.length === 0) {
			this.props.loaderState(true);
			this.props.getWarehouses();
		}
		initilizeDataTable('add-supplier-return-table');
	}
	componentDidUpdate() {
		initilizeDataTable('add-supplier-return-table');
	}

	render() {
		destroyDataTable('add-supplier-return-table');

		const { warehouses } = this.props;
		return <AddSupplierReturnComp warehouses={warehousesData(warehouses)} />;
	}
}

const mapStateToProps = state => {
	return {
		warehouses: state.system.warehouses.allWarehouses,
	};
};

export default connect(mapStateToProps, { loaderState, getWarehouses })(AddSupplierReturn);
