import React from 'react';
import { goto } from '../../helpers';

export default function InlineDropDown({ id, name, values, value, onChange, disable, link, path, noEmpty }) {
	return (
		<div className="form-inline" id="form-inline">
			<div className="form-group">
				<label htmlFor={id} className="input-label">
					{link ? <a onClick={() => goto(path)}>{name}</a> : name}
				</label>
				<select disabled={disable} onChange={e => onChange(e)} value={value} className="form-control" id={id}>
					{!noEmpty ? <option></option> : null}
					{values ? values.map(value => <option key={value}>{value}</option>) : null}
				</select>
			</div>
		</div>
	);
}
