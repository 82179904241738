import React from 'react';

export default function inLineCheckbox({ id, name, value, onChange, disable }) {
	return (
		<div className="form-inline">
			<div className="form-group">
				<label htmlFor={id} className="input-label">
					{name}
				</label>
				<input
					disabled={disable ? 'disabled' : ''}
					onChange={e => onChange(e)}
					id={id}
					type="checkbox"
					style={{ width: '20px', height: '20px' }}
					checked={value}
				/>
			</div>
		</div>
	);
}
