import React from 'react';
import AddProducts from '../../quotes/add/product';
import OrderLinesTable from './orderLinesTable';

export default function OrderLines({ goto }) {
	return (
		<div id="OrderLines" className="tab-pane active" >
			<AddProducts goto={path => goto(path)} />
			<br />
			<OrderLinesTable />
		</div>
	);
}
