import React from 'react';
import Info from '../../common/info';
import UploadFiles from '../../common/uploadFiles';

export default function customers({ onCustomersUpload }) {
	return (
		<div id="Customers" className="tab-pane active">
			<br />
			<Info
				heading="Importing Customers"
				message="For further information on importing data, including required field definitions, please"
				link="visit our help pages."
			/>
			<UploadFiles onFileDivClick={() => onCustomersUpload()} id="Customers" />
		</div>
	);
}
