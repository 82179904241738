import React from 'react';

export default function PurchaseEnquiryTable() {
	return (
		<div className="table-view table-responsive">
			<table id="sales-quotes-charge-table" className="table">
				<thead>
					<tr>
						<th>Order Number</th>
						<th>Order Date</th>
						<th>Delivery Date</th>
						<th>Receipt Date</th>
						<th>Warehouse</th>
						<th>Supplier</th>
						<th>Product Code</th>
						<th>Product Description</th>
						<th>Product Group</th>
						<th>Sales Order Number</th>
						<th>Order Status</th>
						<th>Quantity</th>
						<th>Landed Cost</th>
					</tr>
				</thead>
				<tbody></tbody>
			</table>
		</div>
	);
}
