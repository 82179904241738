import api from '../../api/api';
import { success, failed } from '../../constants/messages';
import { settings } from '../../constants/actionTypes';
import { loaderState } from '..';
import _ from 'lodash';
import swal from 'sweetalert';

export const getSalesGroups = () => dispatch => {
	api.get('/salesGroup')
		.then(res => {
			if (res.data.code === 200) {
				dispatch({ type: settings.SALES_GROUPS, payload: res.data.data });
			} else {
				swal('', res.data.message, 'error');
			}
			dispatch(loaderState(false));
		})
		.catch(err => {
			swal('', err.message, 'error');
			dispatch(loaderState(false));
		});
};

export const addSalesGroup = ({ groupName, createdBy, organizationId }) => (dispatch, getState) => {
	var salesGroups = getState().system.salesGroups.allSalesGroups;
	api.post('/salesGroup', { groupName, createdBy, updatedBy: createdBy, organizationId })
		.then(res => {
			if (res.data.code === 200) {
				dispatch({ type: settings.SALES_GROUPS, payload: [...salesGroups, res.data.data] });
			} else {
				swal('', res.data.message, 'error');
			}
			dispatch(loaderState(false));
		})
		.catch(err => {
			swal('', err.message, 'error');
			dispatch(loaderState(false));
		});
};

export const deleteSalesGroup = id => (dispatch, getState) => {
	const { allSalesGroups } = getState().system.salesGroups;
	api.delete(`/salesGroup/${id}`)
		.then(res => {
			if (Number(res.data.code) === 200) {
				_.remove(allSalesGroups, group => {
					return Number(id) == Number(group.id);
				});
				dispatch(loaderState(false));
			} else {
				swal('', res.data.message, 'error');
				dispatch(loaderState(false));
			}
		})
		.catch(err => {
			swal('', err.message, 'error');
			dispatch(loaderState(false));
		});
};

export const updateSalesGroup = ({ groupName, obsolete, id, updatedBy }) => (dispatch, getState) => {
	const { allSalesGroups } = getState().system.salesGroups;
	api.post(`/salesGroup/update/${id}`, { groupName, obsolete, updatedBy })
		.then(res => {
			if (Number(res.data.code) === 200) {
				let updatedGroup = _.findLast(allSalesGroups, group => {
					return Number(group.id) == Number(id);
				});
				updatedGroup.groupName = groupName;
				updatedGroup.obsolete = obsolete;
				let updatedGroups = _.map(allSalesGroups, group => {
					return group.id === id ? updatedGroup : group;
				});

				dispatch({ type: settings.SHIPPING_COMPANIES, payload: updatedGroups });
				dispatch(loaderState(false));
			} else {
				swal('', res.data.message, 'error');
				dispatch(loaderState(false));
			}
		})
		.catch(err => {
			swal('', err.message, 'error');
			dispatch(loaderState(false));
		});
};

export const onUpdateOrDeleteSalesGroup = data => dispatch => {
	dispatch({ type: settings.SELECTED_SALES_GROUP, payload: data });
};
