import api from '../../api/api';
import { success, failed } from '../../constants/messages';
import { settings } from '../../constants/actionTypes';
import { loaderState } from '..';
import _ from 'lodash';
import swal from 'sweetalert';

export const getCustomerTypes = () => dispatch => {
	api.get('/customerTypes')
		.then(res => {
			if (res.data.code === 200) {
				dispatch({ type: settings.CUSTOMER_TYPES, payload: res.data.data });
			} else {
				swal('', res.data.message, 'error');
			}
			dispatch(loaderState(false));
		})
		.catch(err => {
			swal('', err.message, 'error');
			dispatch(loaderState(false));
		});
};

export const addCustomerType = ({ typeName, createdBy, organizationId }) => (dispatch, getState) => {
	var customerTypes = getState().system.customerTypes.allCustomerTypes;
	api.post('/customerTypes', { typeName, createdBy, updatedBy: createdBy, organizationId })
		.then(res => {
			if (res.data.code === 200) {
				dispatch({ type: settings.CUSTOMER_TYPES, payload: [...customerTypes, res.data.data] });
			} else {
				swal('', res.data.message, 'error');
			}
			dispatch(loaderState(false));
		})
		.catch(err => {
			swal('', err.message, 'error');
			dispatch(loaderState(false));
		});
};

export const deleteCustomerType = id => (dispatch, getState) => {
	const { allCustomerTypes } = getState().system.customerTypes;
	api.delete(`/customerTypes/${id}`)
		.then(res => {
			if (Number(res.data.code) === 200) {
				_.remove(allCustomerTypes, adj => {
					return Number(id) == Number(adj.id);
				});
				dispatch(loaderState(false));
			} else {
				swal('', res.data.message, 'error');
				dispatch(loaderState(false));
			}
		})
		.catch(err => {
			swal('', err.message, 'error');
			dispatch(loaderState(false));
		});
};

export const updateCustomerType = ({ typeName, id, updatedBy }) => (dispatch, getState) => {
	var customerTypes = getState().system.customerTypes.allCustomerTypes;
	api.post(`/customerTypes/update/${id}`, { typeName, updatedBy })
		.then(res => {
			if (Number(res.data.code) === 200) {
				let updatedType = _.findLast(customerTypes, type => {
					return Number(type.id) == Number(id);
				});
				updatedType.typeName = typeName;
				let updatedCustomerTypes = _.map(customerTypes, custType => {
					return custType.id === id ? updatedType : custType;
				});

				dispatch({ type: settings.CUSTOMER_TYPES, payload: updatedCustomerTypes });
				dispatch(loaderState(false));
			} else {
				swal('', res.data.message, 'error');
				dispatch(loaderState(false));
			}
		})
		.catch(err => {
			swal('', err.message, 'error');
			dispatch(loaderState(false));
		});
};

export const onUpdateOrDeleteCustomerType = data => dispatch => {
	dispatch({ type: settings.SELECTED_CUSTOMER_TYPE, payload: data });
};
