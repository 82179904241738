import React, { Component } from 'react';
import PickListComp from '../../../../components/setting/docDesigner/pickList/index';

export default class PickList extends Component {
	state = {
		templates: ['Default Picklist'],
	};
	render() {
		const { templates } = this.state;
		return <PickListComp templates={templates} name="Picklist" />;
	}
}
