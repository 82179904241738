import api from '../../api/api';
import { success } from '../../constants/messages';
import { inventory } from '../../constants/actionTypes';
import { loaderState } from '..';
import _ from 'lodash';
import swal from 'sweetalert';

export const getProducts = () => dispatch => {
	api.get('/products')
		.then(res => {
			if (res.data.code === 200) {
				dispatch({ type: inventory.PRODUCTS, payload: res.data.data });
			} else {
				swal('', res.data.message, 'error');
			}
			dispatch(loaderState(false));
		})
		.catch(err => {
			swal('', err.message, 'error');
			dispatch(loaderState(false));
		});
};

export const addProduct = data => (dispatch, getState) => {
	var allProducts = getState().inventory.products.allProducts;
	api.post('/products', { ...data })
		.then(res => {
			if (res.data.code === 200) {
				dispatch({ type: inventory.PRODUCTS, payload: [...allProducts, res.data.data] });
			} else {
				swal('', res.data.message, 'error');
			}
			dispatch(loaderState(false));
		})
		.catch(err => {
			swal('', err.message, 'error');
			dispatch(loaderState(false));
		});
};

export const updateProduct = data => (dispatch, getState) => {
	const { allProducts, selectedProduct } = getState().inventory.products;
	const { loggedInUser } = getState().users;
	api.post(`/products/update/${selectedProduct.id}`, { ...data, updatedBy: loggedInUser.id })
		.then(res => {
			if (Number(res.data.code) === 200) {
				let updatedProduct = { ...selectedProduct, ...data };
				let updatedProducts = _.map(allProducts, product => {
					return product.id === selectedProduct.id ? updatedProduct : product;
				});
				dispatch({ type: inventory.PRODUCTS, payload: updatedProducts });
			} else {
				swal('', res.data.message, 'error');
			}
			dispatch(loaderState(false));
		})
		.catch(err => {
			swal('', err.message, 'error');
		});
};

export const deleteProduct = id => (dispatch, getState) => {
	const { allProducts } = getState().inventory.products;
	api.delete(`/products/${id}`)
		.then(res => {
			if (res.data.code === 200) {
				_.remove(allProducts, product => {
					return product.id === id;
				});
			} else {
				swal('', res.data.message, 'error');
			}
			dispatch(loaderState(false));
		})
		.catch(err => {
			swal('', err.message, 'error');
			dispatch(loaderState(false));
		});
};

export const onSelectProduct = product => dispatch => {
	dispatch({ type: inventory.SELECTED_PRODUCT, payload: product });
};
