import React, { Component } from 'react';
import { goto, initilizeDataTable, destroyDataTable } from '../../../helpers';
import WarehouseTransfersComp from '../../../components/inventory/warehouseTransfers';

class WarehouseTransfers extends Component {
	componentDidMount() {
		initilizeDataTable('warehouse-transfers-table');
	}

	componentDidUpdate() {
		initilizeDataTable('warehouse-transfers-table');
	}

	render() {
		destroyDataTable('warehouse-transfers-table');

		return <WarehouseTransfersComp goto={path => goto(path)} />;
	}
}

export default WarehouseTransfers;
