import React from 'react';
import InlineInputForm from '../../common/inlineInputForm';

export default function Address() {
	return (
		<div id="Address" className="tab-pane fade">
			<div className="row" id="inline-input-forms">
				<div className="col-sm-4">
					<b>
						<h4>Postal Address</h4>
					</b>
					<InlineInputForm id="postal-address-name" value="" name="Address Name" />
					<InlineInputForm id="postal-address-line1" value="" name="Address Line 1" />
					<InlineInputForm id="postal-address-line2" value="" name="Address Line 2" />
					<InlineInputForm id="suburg" value="" name="Suburb" />
					<InlineInputForm id="postal-city" value="" name="City" />
					<InlineInputForm id="postal-state-region" value="" name="State/Region" />
					<InlineInputForm id="postal-code" value="" name="Postal Code" />
					<div className="form-inline">
						<div className="form-group">
							<label htmlFor="postal-country" className="input-label">
								Country
							</label>
							<select className="form-control" id="postal-country">
								<option>Pakistan</option>
								<option>United State</option>
								<option>United Kingdom</option>
							</select>
						</div>
					</div>
				</div>
				<div className="col-sm-1"></div>
				<div className="col-sm-4">
					<b>
						<h4>Physical Address</h4>
					</b>
					<InlineInputForm id="physical-address-name" value="" name="Address Name" />
					<InlineInputForm id="physical-address-line1" value="" name="Address Line 1" />
					<InlineInputForm id="physical-address-line2" value="" name="Address Line 2" />
					<InlineInputForm id="suburg" value="" name="Suburb" />
					<InlineInputForm id="physical-city" value="" name="City" />
					<InlineInputForm id="physical-state-region" value="" name="State/Region" />
					<InlineInputForm id="physical-code" value="" name="Postal Code" />
					<div className="form-inline">
						<div className="form-group">
							<label htmlFor="physical-country" className="input-label">
								Country
							</label>
							<select className="form-control" id="physical-country">
								<option>Pakistan</option>
								<option>United State</option>
								<option>United Kingdom</option>
							</select>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
