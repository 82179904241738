import React from 'react';
import docDesignerHOC from '../docDesigner';

function SalesQuotes() {
	return <div className="doc-designer">SalesQuotes Design</div>;
}

const designerHOC = docDesignerHOC(SalesQuotes);

export default designerHOC;
