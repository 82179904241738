import React from 'react';

export default function InvoiceEnquiryTable() {
	return (
		<div id="SalesOrders" className="tab-pane active" >
			<div className="table-view table-responsive">
				<table id="sales-quotes-charge-table" className="table">
					<thead>
						<tr>
							<th>Transaction No.</th>
							<th>Sales Order No.</th>
							<th>Invoice Date</th>
							<th>Due Date</th>
							<th>Completed Date</th>
							<th>Status</th>
							<th>Customer Code</th>
							<th>Customer Name</th>
							<th>Warehouse Name</th>
							<th>Margin</th>
							<th>Sub Total</th>
							<th>Action</th>
						</tr>
					</thead>
					<tbody></tbody>
				</table>
			</div>
		</div>
	);
}
