import React from 'react';
import Info from '../../common/info';
import UploadFiles from '../../common/uploadFiles';

export default function customerPricing({ onCustomerPricingUpload }) {
	return (
		<div id="CustomerPricing" className="tab-pane fade">
			<br />
			<Info
				heading="Importing Customer Pricing"
				message="For more information please"
				link="visit our help pages."
			/>
			<UploadFiles onFileDivClick={() => onCustomerPricingUpload()} id="Customer Pricing" />

			<div className="customer-pricing">
				<p>
					In order to make amendments to the Customer Pricing for customers, you will first need to delete all
					customer prices and then re-import.
				</p>
				<button className="btn btn-danger btn-sm">Delete All Customer Prices</button>
			</div>
		</div>
	);
}
