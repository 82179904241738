import { settings } from '../../constants/actionTypes';
const initialState = {
	allCreditReasons: null,
	selectedReason: null,
};

function adjustmentsReducer(state = initialState, action) {
	switch (action.type) {
		case settings.CREDIT_REASONS:
			return { ...state, allCreditReasons: action.payload };
		case settings.UPDATE_OR_DELETE_CREDIT_REASON:
			return { ...state, selectedReason: action.payload };
		default:
			return state;
	}
}
export default adjustmentsReducer;
