import React from 'react';

function Login({ email, password, onChange, onLogin, goto }) {
	return (
		// <div className="login-form">
		// 	<h1>Login</h1>
		// 	<div className="txtb">
		// 		<input type="text" onChange={e => onChange(e)} />
		// 		<span data-placeholder="Email"></span>
		// 	</div>
		// 	<div className="txtb">
		// 		<input type="password" onChange={e => onChange(e)} />
		// 		<span data-placeholder="Password"></span>
		// 	</div>
		// 	<input type="submit" onClick={() => onLogin()} className="logbtn" value="login" />
		// 	<div className="bottom-text">
		// 		<a onClick={() => goto('/signup')}> Register here </a>
		// 	</div>
		// </div>
		<div className="container register">
			<div className="row">
				<div className="col-md-3 register-left">
					<h3>Welcome To</h3>
					{/* <img src="images/logo.png" alt="" />
			<h2>Globuss</h2> */}
					<a onClick={() => goto('/signup')}>register here</a>
					<br />
				</div>
				<div className="col-md-9 register-right">
					<div className="tab-content" id="myTabContent">
						<div className="tab-pane show active" id="home" aria-labelledby="home-tab">
							<h3 className="register-heading">Login</h3>
							<div className="row register-form">
								<div className="col-md-12" style={{ marginBottom: '10px' }}>
									<div className="form-group">
										<input
											type="email"
											className="form-control"
											placeholder="username"
											id="email"
											onChange={e => onChange(e)}
											value={email}
										/>
									</div>
								</div>
								<div className="col-md-12">
									<div className="form-group">
										<input
											type="password"
											id="password"
											className="form-control"
											placeholder="password"
											onChange={e => onChange(e)}
											value={password}
										/>
									</div>
								</div>
								<input onClick={() => onLogin()} type="submit" className="btnLogin" value="Login" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Login;
