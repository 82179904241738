import React from 'react';
import Info from '../../common/info';
import StepsInfo from '../../common/stepsInfo';

export default function ImportBillOfMaterialProduction() {
	return (
		<>
			<div className="comp-heading">
				<div className="row">
					<div className="col-lg-4">
						<label>Import Bill Of Material</label>
					</div>
				</div>
			</div>
			<br />
			<div className="panel panel-default">
				<div className="panel-body">
					<Info
						heading=" Importing Bill of Materials"
						message="We recommend that you only import the data that you need to in batches, i.e. no more than 1000 rows at one time."
						link="Click for more information."
					/>
					<div className="row">
						<div className="col-md-7">
							<h2 className="bill-of-material-steps">
								Import your Bill of Materials into Unleashed in three easy steps
							</h2>
							<StepsInfo
								heading="Download the Bill of Materials template file"
								step="1"
								link="Download Bill of Materials template"
								message="Start by downloading our Bill of Materials Excel template file. This file has the correct column headings Unleashed needs to import your bill of materials data."
							/>
							<StepsInfo
								heading="Add your Bill of Materials to the template file"
								step="2"
								link="Click for Bill of Materials template (Field Definitions)"
								message="Export your data from your old system. Using Excel or another spreadsheet editor, copy and paste your data from the exported file into the Unleashed template. Make sure the data you copy matches the column headings in the template. See the field definitions link below for details on what to enter in each column."
							/>
							<StepsInfo
								heading="Upload the updated template file"
								step="3"
								message="The file you import must be a valid Excel file in .CSV format."
							/>
							<button style={{ marginLeft: '5px' }} className="btn btn-success">
								Upload Bills of Materials
							</button>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
