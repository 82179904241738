import React, { Component } from 'react';
import {
	loaderState,
	getAttributeSets,
	addAttributeSet,
	onSelectedAttribute,
	deleteAttribute,
	updateAttributeSet,
} from '../../../../actions';
import swal from 'sweetalert';
import { connect } from 'react-redux';
import { isEmptyOrNull, initilizeDataTable, destroyDataTable } from '../../../../helpers';
import AttributeSetsComp from '../../../../components/setting/system/attributeSets';
class AttributeSets extends Component {
	constructor(props) {
		super(props);
		this.state = {
			attributeSetName: null,
			type: null,
			editAttribute: null,
			editType: null,
		};
	}

	componentDidMount() {
		initilizeDataTable('attribute-set-table');
		this.props.loaderState(true);
		this.props.getAttributeSets();
	}

	componentDidUpdate() {
		initilizeDataTable('attribute-set-table');
	}
	resetState = () => {
		this.setState({ addAttributeSet: null, editAttribute: null, type: null, editType: null });
	};
	onAdd = () => {
		const { user, organization } = this.props;
		const { attributeSetName, type } = this.state;

		if (!isEmptyOrNull(attributeSetName) || !isEmptyOrNull(type)) {
			this.props.loaderState(true);
			this.props.addAttributeSet({ attributeSetName, type, createdBy: user.id, organizationId: organization.id });
		} else {
			swal('', 'Please enter Attribute Name and Type', 'info');
		}
		this.resetState();
	};

	onChange = ({ target }) => {
		const { name, value } = target;
		this.setState({ [name]: value });
	};

	onUpdate = () => {
		const { selectedAttribute, user } = this.props;
		const { editAttribute, editType } = this.state;

		if (isEmptyOrNull(editAttribute) && isEmptyOrNull(editType)) {
		} else {
			let data = {};
			if (!isEmptyOrNull(editAttribute)) {
				data.attributeSetName = editAttribute;
			}
			if (!isEmptyOrNull(editType)) {
				data.type = editType;
			}
			this.props.loaderState(true);
			this.props.updateAttributeSet({
				...data,
				id: selectedAttribute.id,
				updatedBy: user.id,
			});
		}
		this.resetState();
	};

	onDelete = () => {
		const { selectedAttribute } = this.props;
		this.props.loaderState(true);
		this.props.deleteAttribute(selectedAttribute.id);
		this.resetState();
	};

	onAttributeSelect = data => {
		this.props.onSelectedAttribute(data);
	};

	render() {
		destroyDataTable('attribute-set-table');

		const { attributeSetName, type, editAttribute, editType } = this.state;
		const { attributeSets, selectedAttribute } = this.props;
		return (
			<AttributeSetsComp
				attributeSets={attributeSets}
				attributeSetName={attributeSetName}
				type={type}
				name="editAttribute"
				name1="editType"
				editAttribute={
					editAttribute ? editAttribute : selectedAttribute ? selectedAttribute.attributeSetName : null
				}
				editType={editType ? editType : selectedAttribute ? selectedAttribute.type : null}
				onAdd={() => this.onAdd()}
				onChange={e => this.onChange(e)}
				onUpdate={() => this.onUpdate()}
				onDelete={() => this.onDelete()}
				onAttributeSelect={data => this.onAttributeSelect(data)}
			/>
		);
	}
}

const mapStateToProps = state => {
	return {
		attributeSets: state.system.attributeSets.allAttributeSets,
		user: state.users.loggedInUser,
		selectedAttribute: state.system.attributeSets.selectedAttribute,
		organization: state.users.organizationInfo,
	};
};

export default connect(mapStateToProps, {
	loaderState,
	getAttributeSets,
	addAttributeSet,
	onSelectedAttribute,
	deleteAttribute,
	updateAttributeSet,
})(AttributeSets);
