import { combineReducers } from 'redux';

import rolesReducer from './roles';
import adjustmentsReducer from './adjustmentReasons';
import creditReasonsReducer from './creditReasons';
import unitsOfMeasureReducer from './unitOfMeasure';
import customerTypesReducer from './customerTypes';
import deliveryMethodsReducer from './deliveryMethods';
import supplierReturnReasonsReducer from './supplierReturnReasons';
import warehousesReducer from './warehouses';
import shippingCompaniesReducer from './shippingCompanies';
import salesGroupsReducer from './salesGroup';
import salesPersonReducer from './salesPerson';
import attributeSetsReducer from './attributeSets';
import productGroupReducer from './productGroups';
import paymentTermsReducer from './paymentTerms';
import sellPriceTierReducer from './sellPriceTier';
import prefixesReducer from './prefixes';
import currencyRatesReducer from './currencyRates';
import taxesReducer from './taxes';

export default combineReducers({
	roles: rolesReducer,
	adjustments: adjustmentsReducer,
	creditReasons: creditReasonsReducer,
	units: unitsOfMeasureReducer,
	customerTypes: customerTypesReducer,
	deliveryMethods: deliveryMethodsReducer,
	supplierReturnReasons: supplierReturnReasonsReducer,
	warehouses: warehousesReducer,
	shippingCompanies: shippingCompaniesReducer,
	salesGroups: salesGroupsReducer,
	salesPerson: salesPersonReducer,
	attributeSets: attributeSetsReducer,
	productGroups: productGroupReducer,
	paymentTerms: paymentTermsReducer,
	sellPriceTiers: sellPriceTierReducer,
	prefixes: prefixesReducer,
	currencyRates: currencyRatesReducer,
	taxes: taxesReducer,
});
