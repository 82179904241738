import api from '../../api/api';
import { users } from '../../constants/actionTypes';
import { success, failed } from '../../constants/messages';
import { loaderState, selectedNav } from '../index';
import _ from 'lodash';
import history from '../../history';
import swal from 'sweetalert';

export const userSignIn = ({ email, password }) => dispatch => {
	api.post('/authentications/login', { email, password })
		.then(res => {
			if (Number(res.data.code) === 200) {
				dispatch({ type: users.LOGGED_IN_USER_DATA, payload: res.data.data });
				dispatch(selectedNav('/dashboard'));
				dispatch(loaderState(false));
				history.push('/dashboard');
				history.go('/dashboard');
			} else {
				swal('', res.data.message, 'error');
			}
			dispatch(loaderState(false));
		})
		.catch(err => {
			swal('', err.message, 'error');
			dispatch(loaderState(false));
		});
};

export const getUsers = () => (dispatch, getState) => {
	const { loggedInUser } = getState().users;
	api.get('/users')
		.then(res => {
			// let data = _.filter(res.data.data, user => {
			// 	return user.email != loggedInUser.email && Number(user.isActive) == 1;
			// });
			if (res.data.code === 200) {
				dispatch({
					type: users.USERS,
					payload: res.data.data,
				});
			} else {
				swal('', res.data.message, 'error');
			}
			dispatch(loaderState(false));
		})
		.catch(err => {
			swal('', err.message, 'error');
			dispatch(loaderState(false));
		});
};

export const setSuperUserInfo = data => {
	return {
		type: users.USER_INFO,
		payload: data,
	};
};

export const superUserSignUp = data => (dispatch, getState) => {
	const { superUserInfo } = getState().users;
	api.post('/authentications/signup', { ...superUserInfo, type: 'Super_User' })
		.then(res => {
			if (res.data.code == success) {
				dispatch({ type: users.LOGGED_IN_USER_DATA, payload: res.data.data });
				let orgData = {
					...data,
					superUserId: res.data.data.id,
					updatedBy: res.data.data.id,
					createdBy: res.data.data.id,
				};
				dispatch(AddOrganizationData(orgData));
			} else {
				swal('', res.data.message, 'error');
			}
			dispatch(loaderState(false));
		})
		.catch(err => {
			swal('', err.message, 'error');
			dispatch(loaderState(false));
		});
};

export const AddOrganizationData = data => dispatch => {
	api.post('/organizations', { ...data })
		.then(res => {
			if (res.data.code === 200) {
				dispatch({ type: users.ORGANIZATION_INFO, payload: res.data.data });
				dispatch(setSuperUserInfo(null));
				history.push('/dashboard');
				history.go('/dashboard');
			} else {
				swal('', res.data.message, 'error');
			}
			dispatch(loaderState(false));
		})
		.catch(err => {
			swal('', err.message, 'error');
			dispatch(loaderState(false));
		});
};

export const getOrganizationInfo = id => dispatch => {
	api.get('/organizations');
};

export const deleteUser = id => (dispatch, getState) => {
	const { allUsers } = getState().users;
	api.post(`/users/update/${id}`, { isActive: -1 })
		.then(res => {
			_.remove(allUsers, user => {
				return user.id === id;
			});
			dispatch({ type: users.USERS, payload: allUsers });
			dispatch(loaderState(false));
		})
		.catch(err => {
			swal('', err.message, 'error');
			dispatch(loaderState(false));
		});
};

export const onDeleteUser = user => dispatch => {
	dispatch({ type: users.DELETE_USER, payload: user });
};
