import React from 'react';

export default function SupplierReturnsTable() {
	return (
		<div className="table-view table-responsive">
			<table id="add-supplier-return-table" className="table">
				<thead>
					<tr>
						<th>
							<i style={{ fontSize: '1.5em' }} className="fa fa-cog icon" />
						</th>
						<th>Ln</th>
						<th>Image</th>
						<th>Product Code</th>
						<th>Product Description</th>
						<th>Units</th>
						<th>Order Qty</th>
						<th>Return Qty</th>
						<th>Order Price</th>
						<th>Return Price</th>
						<th>Return Cost</th>
						<th>Sub Total</th>
						<th>Tax Rate</th>
						<th>Reason</th>
						<th>Return</th>
						<th>
							<i style={{ fontSize: '1.5em' }} className="fa fa-comments-o" aria-hidden="true" />
						</th>
						<th>Delete</th>
					</tr>
				</thead>
				<tbody></tbody>
			</table>
		</div>
	);
}
