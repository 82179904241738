import React from 'react';
import TableActions from '../../common/tableActions';

export default function PurchasesTable() {
	return (
		<div className="table-view table-responsive">
			<table id="view-purchases-table" className="table">
				<thead>
					<tr>
						{/* <th>
							<i style={{ fontSize: '1.5em' }} className="fa fa-cog icon" />
						</th> */}
						<th>Order Number</th>
						<th>Order Date</th>
						<th>Delivery Date</th>
						<th>Supplier Name</th>
						<th>Supplier Reference</th>
						<th>Status</th>
						<th>Warehouse</th>
						<th>Currency</th>
						<th>Total (PKR)</th>
						<th>Action</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>asd</td>
						<td>asd</td>
						<td>asd</td>
						<td>asd</td>
						<td>asd</td>
						<td>asd</td>
						<td>asd</td>
						<td>asd</td>
						<td>asd</td>
						<td>
							<TableActions
								icon="fa fa-cog icon"
								data={['Edit', 'Print', 'Print Product Label', 'Email', 'Clone', 'Delete']}
							/>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	);
}
