import React from 'react';
import SplitButton from '../../../common/splitButton';
import InlineInputForm from '../../../common/inlineInputForm';
import InlineDropdown from '../../../common/inlineDropDown';
import RunReport from '../../../common/runReport';
import TransactionTable from './transactionTable';

export default function TransactionEnquiry() {
	return (
		<>
			<div className="comp-heading">
				<div className="row">
					<div className="col-lg-4">
						<label>Transaction Enquiry</label>
					</div>
					<div className="col-lg-2"></div>
					<div className="col-lg-6" style={{ textAlign: 'right' }}>
						<SplitButton
							label="Export"
							type="light"
							data={['Export as PDF', 'Export as CSV', 'Export as XLSX', 'Export as XLS']}
						/>
						<button className="btn btn-success btn-sm">Run</button>
					</div>
				</div>
			</div>
			<br />
			<div className="panel panel-default">
				<div className="panel-body">
					<div className="row" id="inline-input-forms">
						<div className="col-sm-4">
							<InlineInputForm id="date-from" type="date" value="" name="Date From" />
							<InlineInputForm id="date-to" type="date" value="" name="Date To" />
							<InlineDropdown
								id="transaction-type"
								name="Transaction Type"
								values={['Type 1', 'Type 2', 'Type 3']}
							/>
						</div>
						<div className="col-sm-4">
							<InlineInputForm id="product-code" value="" name="Product Code" />
							<InlineInputForm id="product-description" value="" name="Product Description" />
							<InlineDropdown id="warehouse" name="Warehouse" values={['Select All', 'Warehouse']} />
							<InlineInputForm id="reference" value="" name="Reference" />
						</div>
					</div>
				</div>
			</div>
			<div className="panel panel-default">
				<div className="panel-body">
					<TransactionTable />
					<br />
					<RunReport />
				</div>
			</div>
			<div className="row">
				<div className="col-md-6"></div>
				<div className="col-md-6">
					<div className="panel panel-default" id="panel-margin-right">
						<div className="panel-body">
							<div className="grid-container">
								<div className="grid-item">
									<div className="border-bottom">
										<span className="receipt-stat-label">Total Count</span>
										<span className="pull-right receipt-stat-value"></span>
									</div>
								</div>
								<div className="grid-item">
									<div className="border-bottom">
										<span className="receipt-stat-label">Total Value</span>
										<span className="pull-right receipt-stat-value"></span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
