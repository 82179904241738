import React from 'react';
import RoleTable from './roleTable';
import ConfirmationModal from '../../../common/confirmationModal';
import OneInputEditModal from '../../../common/OneInputEditModal';

export default function Roles({
	addValue,
	editValue,
	onDelete,
	onChange,
	onAdd,
	roles,
	onChangeEdit,
	onUpdate,
	onRoleUpdateOrDelete,
}) {
	return (
		<>
			<div className="comp-heading">
				<div className="row">
					<div className="col-lg-4">
						<label>Roles</label>
					</div>
				</div>
			</div>
			<br />
			<div className="panel panel-default">
				<div className="panel-body">
					<div className="row" id="settings-field">
						<div className="col-md-2 col-xs-8">
							<label className="input-label" htmlFor="role">
								* User Role
							</label>
							<input value={addValue} onChange={e => onChange(e)} className="form-control" id="role" />
						</div>
						<div className="col-xs-2" id="system-add-btn">
							<button onClick={() => onAdd()} className="btn btn-success">
								Add
							</button>
						</div>
					</div>
				</div>
			</div>
			<div className="panel panel-default">
				<div className="panel-body">
					<RoleTable onRoleUpdateOrDelete={data => onRoleUpdateOrDelete(data)} roles={roles} />
				</div>
			</div>
			<ConfirmationModal item={editValue} onDelete={() => onDelete()} />
			<OneInputEditModal
				value={editValue}
				label="Role"
				onChange={e => onChangeEdit(e)}
				onUpdate={() => onUpdate()}
			/>
		</>
	);
}
