import React from 'react';
import InlineInputForm from '../../common/inlineInputForm';
import InlineDropdown from '../../common/inlineDropDown';

export default function Address() {
	return (
		<div id="Address" className="tab-pane fade">
			<div className="row" id="inline-input-forms">
				<div className="col-sm-4">
					<b>
						<h4>Postal Address</h4>
					</b>
					<InlineInputForm id="postal-address-name" value="" name="Address Name" />
					<InlineInputForm id="postal-address-line1" value="" name="Address Line 1" />
					<InlineInputForm id="postal-address-line2" value="" name="Address Line 2" />
					<InlineInputForm id="suburg" value="" name="Suburb" />
					<InlineInputForm id="postal-city" value="" name="City" />
					<InlineInputForm id="postal-state-region" value="" name="State/Region" />
					<InlineInputForm id="postal-code" value="" name="Postal Code" />
					<InlineDropdown
						id="postal-country"
						name="Country"
						values={['Pakistan', 'United State', 'United Kingdom']}
					/>
				</div>
				<div className="col-sm-1"></div>
				<div className="col-sm-4">
					<b>
						<h4>Physical Address</h4>
					</b>
					<InlineInputForm id="physical-address-name" value="" name="Address Name" />
					<InlineInputForm id="physical-address-line1" value="" name="Address Line 1" />
					<InlineInputForm id="physical-address-line2" value="" name="Address Line 2" />
					<InlineInputForm id="suburg" value="" name="Suburb" />
					<InlineInputForm id="physical-city" value="" name="City" />
					<InlineInputForm id="physical-state-region" value="" name="State/Region" />
					<InlineInputForm id="physical-code" value="" name="Postal Code" />
					<InlineDropdown
						id="physical-country"
						name="Country"
						values={['Pakistan', 'United State', 'United Kingdom']}
					/>
				</div>
			</div>
		</div>
	);
}
