import React from 'react';
import QuickStockCountTable from './quickStockCountTable';

export default function QuickStockCount() {
	return (
		<>
			<div className="comp-heading">
				<div className="row">
					<div className="col-lg-4">
						<label>Quick Stock Count</label>
					</div>
					<div className="col-lg-2"></div>
					<div className="col-lg-6" style={{ textAlign: 'right' }}>
						<button className="btn btn-success btn-sm">Print</button>
					</div>
				</div>
			</div>
			<br />
			<div className="panel panel-default">
				<div className="panel-body">
					<div className="row" id="settings-field">
						<div className="col-md-2 padding-right">
							<label className="input-label" htmlFor="warehouse">
								Warehouse
							</label>
							<select className="form-control" id="warehouse" name="warehouse">
								<option></option>
								<option>Warehouse</option>
							</select>
						</div>
						<div className="col-md-2 padding-0">
							<label className="input-label" htmlFor="product-group">
								Product Group
							</label>
							<select className="form-control" id="product-group" name="product-group">
								<option></option>
								<option>Group 1</option>
								<option>Group 2</option>
								<option>Group 3</option>
								<option>Group 4</option>
							</select>
						</div>
					</div>
				</div>
			</div>
			<div className="panel panel-default">
				<div className="panel-body">
					<QuickStockCountTable />
				</div>
			</div>
		</>
	);
}
