import React from 'react';

export default function StockCountTable() {
	return (
		<div className="table-view table-responsive">
			<table id="stock-count-table" className="table">
				<thead>
					<tr>
						<th>Stock Count Number</th>
						<th>Stock Count Name</th>
						<th>Warehouse</th>
						<th>Status</th>
						<th>Start Date</th>
						<th>Number Of Products</th>
						<th>Count Progress</th>
					</tr>
				</thead>
				<tbody></tbody>
			</table>
		</div>
	);
}
