import React from 'react';

export default function ProductGroupTable({ allGroups, onProductGroupSelect }) {
	return (
		<div className="table-view table-responsive" id="setting-tables">
			<table id="product-groups-table" className="table">
				<thead>
					<tr>
						<th>Group Name</th>
						<th>Default Attribute Set</th>
						<th>Actions</th>
					</tr>
				</thead>
				<tbody>
					{allGroups
						? allGroups.map(group => (
								<tr key={group.id}>
									<td>{group.groupName}</td>
									<td>{group.attributeSetId}</td>
									<td>
										<i
											data-toggle="modal"
											data-target="#confirmationModal"
											className="fa fa-trash-o icon"
											onClick={() => onProductGroupSelect(group)}
										/>
										<i
											data-toggle="modal"
											data-target="#editModal"
											className="fa fa-pencil-square-o icon"
											onClick={() => onProductGroupSelect(group)}
										/>
									</td>
								</tr>
						  ))
						: null}
				</tbody>
			</table>
		</div>
	);
}
