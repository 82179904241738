import api from '../../api/api';
import { success, failed } from '../../constants/messages';
import { settings } from '../../constants/actionTypes';
import { loaderState } from '..';
import _ from 'lodash';
import swal from 'sweetalert';

export const getPrefixes = () => dispatch => {
	api.get('/prefixes')
		.then(res => {
			if (res.data.code === 200) {
				dispatch({ type: settings.PREFIXES, payload: res.data.data });
			} else {
				swal('', res.data.message, 'error');
			}
			dispatch(loaderState(false));
		})
		.catch(err => {
			swal('', err.message, 'error');
			dispatch(loaderState(false));
		});
};

export const addPrefixes = data => (dispatch, getState) => {
	var allPrefixes = getState().system.prefixes.allPrefixes;
	var org = getState().users.organizationInfo;
	api.post('/prefixes', { ...data, organizationId: org.id })
		.then(res => {
			if (res.data.code === 200) {
				dispatch({ type: settings.PREFIXES, payload: [...allPrefixes, res.data.data] });
			} else {
				swal('', res.data.message, 'error');
			}
			dispatch(loaderState(false));
		})
		.catch(err => {
			swal('', err.message, 'error');
			dispatch(loaderState(false));
		});
};

export const updatePrefixes = data => dispatch => {
	api.post(`/prefixes/update/1`, { ...data })
		.then(res => {
			if (Number(res.data.code) === 200) {
				dispatch(getPrefixes());
			} else {
				swal('', res.data.message, 'error');
			}
			dispatch(loaderState(false));
		})
		.catch(err => {
			swal('', err.message, 'error');
			dispatch(loaderState(false));
		});
};
