import React from 'react';

export default function EditModal({ data, onChange, onUpdate, selectedTax }) {
	return (
		<div className="modal fade" id="taxEditModal" role="dialog">
			<div className="modal-dialog">
				<div className="modal-content">
					<div className="modal-header">
						<button type="button" className="close" data-dismiss="modal">
							&times;
						</button>
						<h4 className="modal-title">Update Data</h4>
					</div>
					<div className="modal-body">
						{/* <div className="form-group">
							<label htmlFor="default_sales_tax">Default Sales Tax</label>
							<select
								onChange={e => onChange(e)}
								value={data.default_sales_tax}
								className="form-control"
								id="defaultSalesTax"
							>
								<option></option>
								<option>No Tax (0%)</option>
							</select>
						</div>
						<div className="form-group">
							<label htmlFor="default_purchase_tax">Default Purchase Tax</label>
							<select
								onChange={e => onChange(e)}
								value={data.default_purchase_tax}
								className="form-control"
								id="defaultPurchaseTax"
							>
								<option></option>
								<option>No Tax (0%)</option>
							</select>
						</div> */}
						<div className="form-group">
							<label htmlFor="editTaxName">Tax Description/Name</label>
							<input
								onChange={e => onChange(e)}
								value={
									data.editTaxName !== null
										? data.editTaxName
										: selectedTax
										? selectedTax.taxName
										: null
								}
								className="form-control"
								id="editTaxName"
							/>
						</div>
						<div className="form-group">
							<label htmlFor="editTaxCode">Tax Code</label>
							<input
								onChange={e => onChange(e)}
								value={
									data.editTaxCode !== null
										? data.editTaxCode
										: selectedTax
										? selectedTax.taxCode
										: null
								}
								className="form-control"
								id="editTaxCode"
							/>
						</div>
						<div className="form-group">
							<label htmlFor="editTaxRate">Tax Rate</label>
							<input
								onChange={e => onChange(e)}
								value={
									data.editTaxRate !== null
										? data.editTaxRate
										: selectedTax
										? selectedTax.taxRate
										: null
								}
								className="form-control"
								id="editTaxRate"
							/>
						</div>
						<div className="row">
							<div className="col-xs-4">
								<label className="input-label" htmlFor="editSalesTax">
									Sales Tax
								</label>
								<br />
								<input
									checked={
										data.editSalesTax
											? Number(data.editSalesTax) === 1
												? 'checked'
												: ''
											: selectedTax
											? Number(selectedTax.salesTax) === 1
												? 'checked'
												: ''
											: null
									}
									onChange={e => onChange(e)}
									type="checkbox"
									style={{ width: '18px', height: '18px' }}
									id="editSalesTax"
								/>
							</div>
							<div className="col-xs-4">
								<label className="input-label" htmlFor="purchaseTax">
									Purchase Tax
								</label>
								<br />
								<input
									checked={
										data.editPurchaseTax
											? Number(data.editPurchaseTax) === 1
												? 'checked'
												: ''
											: selectedTax
											? Number(selectedTax.purchaseTax) === 1
												? 'checked'
												: ''
											: null
									}
									onChange={e => onChange(e)}
									type="checkbox"
									style={{ width: '18px', height: '18px' }}
									id="editPurchaseTax"
								/>
							</div>
							<div className="col-xs-4">
								<label className="input-label" htmlFor="obsolete">
									Obsolete
								</label>
								<br />
								<input
									checked={
										data.obsolete
											? Number(data.obsolete) === 1
												? 'checked'
												: ''
											: selectedTax
											? Number(selectedTax.obsolete) === 1
												? 'checked'
												: ''
											: null
									}
									onChange={e => onChange(e)}
									type="checkbox"
									style={{ width: '18px', height: '18px' }}
									id="obsolete"
								/>
							</div>
						</div>
					</div>
					<div className="modal-footer">
						<button
							onClick={() => onUpdate()}
							type="button"
							className="btn btn-success"
							data-dismiss="modal"
						>
							Update
						</button>
						<button type="button" className="btn btn-default" data-dismiss="modal">
							Cancel
						</button>
					</div>
				</div>
			</div>
		</div>
	);
}
