import React from 'react';
import Info from '../../common/info';
import UploadFiles from '../../common/uploadFiles';

export default function ImportPurchases({ onCustomerContactsUpload }) {
	return (
		<>
			<div className="comp-heading">
				<div className="row">
					<div className="col-lg-4">
						<label>Import Purchases</label>
					</div>
				</div>
			</div>
			<br />
			<div className="panel panel-default">
				<div className="panel-body">
					<Info
						heading="Importing Purchase Orders"
						message="Purchase Orders can be created but not amended. They must be unique and the products need to exist in Unleashed first. For further information on importing data, including required field definitions, please"
						link="visit our help pages."
					/>
					<UploadFiles onFileDivClick={() => onCustomerContactsUpload()} id="Purchase Order" />
				</div>
			</div>
		</>
	);
}
