import React from 'react';

export default function QuotesEnquiryTable() {
	return (
		<div className="table-view table-responsive">
			<table id="sales-quotes-charge-table" className="table">
				<thead>
					<tr>
						<th>Quote No.</th>
						<th>Quote Date</th>
						<th>Expiry Date</th>
						<th>Accepted Date</th>
						<th>Warehouse</th>
						<th>Customer</th>
						<th>Customer Type</th>
						<th>Product</th>
						<th>Product Group</th>
						<th>Status</th>
						<th>Quantity</th>
						<th>Sub Total</th>
						<th>Product Code</th>
						<th>Customer Code</th>
						<th>Units</th>
						<th>Sales Group</th>
						<th>Sales Person</th>
						<th>Delivery Method</th>
					</tr>
				</thead>
				<tbody></tbody>
			</table>
		</div>
	);
}
