import React, { Component } from 'react';
import { goto, isEmptyOrNull, industryDropdownData } from '../../helpers';
import { loaderState, getCountriesData, superUserSignUp } from '../../actions';
import { connect } from 'react-redux';
import _ from 'lodash';
import OrganizationComp from '../../components/registration/organization';
import swal from 'sweetalert';

class Organization extends Component {
	componentDidMount() {
		const { countries } = this.props;
		if (!countries) {
			this.props.loaderState(true);
			this.props.getCountriesData();
		}
	}

	onCreateAccount = () => {
		this.props.loaderState(true);
		let stateData = { ...this.state };

		if (
			isEmptyOrNull(stateData.companyName) &&
			isEmptyOrNull(stateData.industry) &&
			isEmptyOrNull(stateData.tadingName) &&
			isEmptyOrNull(stateData.timezones) &&
			isEmptyOrNull(stateData.baseCurrency)
		) {
			swal('', 'Please fill all the fields', 'info');
		} else {
			this.props.superUserSignUp(stateData);
		}
	};

	onChange = ({ target }) => {
		const { id, value } = target;
		this.setState({ [id]: value });
	};

	handleChange = (newValue, actionMeta) => {
		const { name } = actionMeta;
		if (newValue) {
			this.setState({ [name]: newValue.value });
		} else {
			this.setState({ [name]: newValue });
		}
	};

	perpareTimeZoneData = () => {
		const { countries } = this.props;
		let data = [];
		_.forEach(countries, country => {
			data.push({ value: country.timezones[0], label: `(${country.timezones[0]}) ${country.name}` });
		});

		return data;
	};

	perpareCurrencyData = () => {
		const { countries } = this.props;
		let data = [];
		_.forEach(countries, country => {
			data.push({ label: `(${country.currencies[0].code}) ${country.name}`, value: country.currencies[0].name });
		});
		return data;
	};

	render() {
		let timezones = this.perpareTimeZoneData();
		let currencies = this.perpareCurrencyData();
		let industries = industryDropdownData();
		return (
			<OrganizationComp
				currencies={currencies}
				timezones={timezones}
				onCreateAccount={() => this.onCreateAccount()}
				onChange={e => this.onChange(e)}
				goto={path => goto(path)}
				industries={industries}
				handleChange={(v, m) => this.handleChange(v, m)}
			/>
		);
	}
}

const mapStateToProps = state => {
	return {
		countries: state.common.countriesData,
		user: state.users.userData,
	};
};

export default connect(mapStateToProps, { loaderState, getCountriesData, superUserSignUp })(Organization);
