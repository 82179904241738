import React, { Component } from 'react';
import { initilizeDataTable, destroyDataTable } from '../../../../helpers';
import ReOrderComp from '../../../../components/reports/purchase/reorder';

export default class ReOrder extends Component {
	componentDidMount() {
		initilizeDataTable('sales-quotes-charge-table');
	}

	componentDidUpdate() {
		initilizeDataTable('sales-quotes-charge-table');
	}

	render() {
		destroyDataTable('sales-quotes-charge-table');
		return <ReOrderComp />;
	}
}
