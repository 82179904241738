import React, { Component } from 'react';
import { goto, initilizeDataTable, destroyDataTable } from '../../../helpers';
import ViewSupplierComp from '../../../components/suppliers/view';

export default class ViewSupplier extends Component {
	componentDidMount() {
		initilizeDataTable('suppliers-table');
	}

	componentDidUpdate() {
		initilizeDataTable('suppliers-table');
	}

	render() {
		destroyDataTable('suppliers-table');

		return <ViewSupplierComp goto={path => goto(path)} />;
	}
}
