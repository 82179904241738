import React from 'react';

export default function AddCreditProductTable() {
	return (
		<div className="table-view table-responsive">
			<table id="add-credit-product-table" className="table">
				<thead>
					<tr>
						<th>
							<i style={{ fontSize: '1.5em' }} className="fa fa-cog icon" />
						</th>
						<th>Line</th>
						<th>Product Code </th>
						<th>Product Description</th>
						<th>Unit</th>
						<th>Qty</th>
						<th>Price</th>
						<th>Returnable</th>
						<th>Credit Qty</th>
						<th>Credit Price</th>
						<th>Line Total</th>
						<th>Tax Rate</th>
						<th>Reason</th>
						<th>Return</th>
						<th>
							<i style={{ fontSize: '1.5em' }} className="fa fa-comments-o" aria-hidden="true" />
						</th>
						<th>Del</th>
					</tr>
				</thead>
				<tbody></tbody>
			</table>
		</div>
	);
}
