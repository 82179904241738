import React from 'react';
import { render } from 'react-dom';
import './App.css';
import 'babel-polyfill';

import { Provider } from 'react-redux';
import { store, persistor } from './store/index';
import App from './components/App';
import { PersistGate } from 'redux-persist/integration/react';

render(
	<Provider store={store}>
		<PersistGate loading={null} persistor={persistor}>
			<App />
		</PersistGate>
	</Provider>,
	document.getElementById('root')
);
if (window.location.search === '?purge') {
	persistor.purge();
}
// comment out below code before deploying

// import React from 'react';
// import { render } from 'react-dom';
// import './App.css';
// import { Provider } from 'react-redux';
// import store from './store/index';
// import Home from './components/Home';

// render(
// 	<Provider store={store}>
// 		<Home />
// 	</Provider>,
// 	document.getElementById('root')
// );
