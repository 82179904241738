import React from 'react';

export default function SelectedManus({ SelectedLinks, onClickAddOrRemove }) {
	return (
		<div className="selected-quicklinks">
			<div className="selected-quicklinks-content">
				<div className="row">
					{SelectedLinks
						? SelectedLinks.map((quicklink, index) =>
								index <= 9 ? (
									<div key={quicklink.name} className="col-xs-6">
										<div className="item">
											<i className={quicklink.icon} /> {quicklink.name}{' '}
											<div className="quickLinks-dropdown">
												<i
													className="fa fa-times"
													onClick={() => onClickAddOrRemove(quicklink.id, -1, 'remove')}
												/>
											</div>
										</div>
									</div>
								) : (
									<>
										{index === 10 ? <div class="col-xs-12 dropdown-divider"></div> : null}
										<div key={quicklink.name} className="col-xs-6">
											<div className="item">
												<i className={quicklink.icon} /> {quicklink.name}
												<div className="quickLinks-dropdown">
													<i
														className="fa fa-times"
														onClick={() => onClickAddOrRemove(quicklink.id, -1, 'remove')}
													/>
												</div>
											</div>
										</div>
									</>
								)
						  )
						: null}
					{SelectedLinks ? (
						SelectedLinks.length === 0 ? (
							<div style={{ textAlign: 'center' }}>
								<i>
									<small>No data to display</small>
								</i>
							</div>
						) : null
					) : null}
				</div>
			</div>
		</div>
	);
}
