import React from 'react';

export default function PriceListTable() {
	return (
		<div className="table-view table-responsive">
			<table id="sales-quotes-charge-table" className="table">
				<thead>
					<tr>
						<th>Image</th>
						<th>Code</th>
						<th>Description</th>
						<th>Qty On Hand</th>
						<th>Purchase Price</th>
						<th>Sell Price</th>
						<th>Tier 1</th>
						<th>Tier 2</th>
						<th>Tier 3</th>
						<th>Tier 4</th>
						<th>Tier 5</th>
						<th>Tier 6</th>
						<th>Tier 7</th>
						<th>Tier 8</th>
						<th>Tier 9</th>
						<th>Tier 10</th>
					</tr>
				</thead>
				<tbody></tbody>
			</table>
		</div>
	);
}
