import React from 'react';

export default function ShipmenytEnquiryTable() {
	return (
		<div id="SalesOrders" className="tab-pane active" >
			<div className="table-view table-responsive">
				<table id="sales-quotes-charge-table" className="table">
					<thead>
						<tr>
							<th>Required Date</th>
							<th>Dispatch Date</th>
							<th>Shipment No.</th>
							<th>Sales Order No.</th>
							<th>Shipment Status</th>
							<th>Shipping Company</th>
							<th>Customer Ref</th>
							<th>Tracking Number</th>
							<th>Customer Code</th>
							<th>Customer Name</th>
							<th>Warehouse Name</th>
							<th>Action</th>
						</tr>
					</thead>
					<tbody></tbody>
				</table>
			</div>
		</div>
	);
}
