import React from 'react';
import docDesignerHOC from '../docDesigner';

function WarehouseTransfers() {
	return <div className="doc-designer">WarehouseTransfers Design</div>;
}

const designerHOC = docDesignerHOC(WarehouseTransfers);

export default designerHOC;
