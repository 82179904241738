import { sales } from '../../constants/actionTypes';
const initialState = {
	allSalesOrders: null,
	selectedSalesOrder: null,
	allSalesQuotes: null,
	selectedSalesQuote: null,
	salesCredits: null,
	selectedSalesCredit: null,
	salesOrderShipments: null,
	selectedSalesOrderShipment: null,
};

function salesReducer(state = initialState, action) {
	switch (action.type) {
		case sales.SALES_ORDERS:
			return { ...state, allSalesOrders: action.payload };
		case sales.SELECTED_SALES_ORDER:
			return { ...state, selectedSalesOrder: action.payload };
		case sales.SALES_QUOTES:
			return { ...state, allSalesQuotes: action.payload };
		case sales.SELECTED_SALES_QUOTE:
			return { ...state, selectedSalesQuote: action.payload };
		case sales.SALES_CREDITS:
			return { ...state, salesCredits: action.payload };
		case sales.SELECTED_SALES_CREDIT:
			return { ...state, selectedSalesCredit: action.payload };
		case sales.SALES_ORDER_SHIPMENTS:
			return { ...state, salesOrderShipments: action.payload };
		case sales.SELECTED_SALES_ORDER_SHIPMENT:
			return { ...state, selectedSalesOrderShipment: action.payload };
		default:
			return state;
	}
}
export default salesReducer;
