import React from 'react';

export default function QuoteSalesProductTable() {
	return (
		<div className="table-view table-responsive">
			<table id="add-quote-product-table" className="table">
				<thead>
					<tr>
						<th>
							<i style={{ fontSize: '1.5em' }} className="fa fa-cog icon" />
						</th>
						<th>Ln</th>
						<th>Image</th>
						<th>Product Code </th>
						<th>Product Description</th>
						<th>Quantity</th>
						<th>Unit Price</th>
						<th>Discount</th>
						<th>Disc. Price</th>
						<th>Line Tax Rate</th>
						<th>Line Total</th>
						<th>Margin</th>
						<th>
							<i style={{ fontSize: '1.5em' }} className="fa fa-comments-o" aria-hidden="true" />
						</th>
						<th>Delete</th>
					</tr>
				</thead>
				<tbody></tbody>
			</table>
		</div>
	);
}
