import React from 'react';

export default function CurrencyRates({ types, onUpdateOrDelete }) {
	return (
		<div className="table-view table-responsive" id="setting-tables">
			<table id="customer-type-table" className="table">
				<thead>
					<tr>
						<th> Type Name </th>
						<th> Actions </th>
					</tr>
				</thead>
				<tbody>
					{types
						? types.map(type => (
								<tr>
									<td>{type.typeName}</td>
									<td>
										<i
											data-toggle="modal"
											data-target="#confirmationModal"
											className="fa fa-trash-o icon"
											onClick={() => onUpdateOrDelete(type)}
										/>
										<i
											data-toggle="modal"
											data-target="#oneInputEditModal"
											className="fa fa-pencil-square-o icon"
											onClick={() => onUpdateOrDelete(type)}
										/>
									</td>
								</tr>
						  ))
						: null}
				</tbody>
			</table>
		</div>
	);
}
