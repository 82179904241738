import React from 'react';
import ViewProductTable from '../../../inventory/viewProduct/viewProductTable';

export default function Products() {
	return (
		<div id="Products" className="tab-pane fade">
			<ViewProductTable />
		</div>
	);
}
