import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getRolls, loaderState } from '../../../../actions';
import RolePermissonsComp from '../../../../components/setting/security/rolePermissions';

class RolePermissons extends Component {
	componentDidMount() {
		const { roles } = this.props;
		if (!roles) {
			this.props.loaderState(true);
			this.props.getRolls();
		}
	}
	render() {
		const { roles } = this.props;
		return <RolePermissonsComp roles={roles} />;
	}
}

const mapStateToProps = state => {
	return {
		roles: state.system.roles.allRoles,
	};
};

export default connect(mapStateToProps, { loaderState, getRolls })(RolePermissons);
