import React from 'react';
import InlineInputForm from '../../common/inlineInputForm';
import InlineDropdown from '../../common/inlineDropDown';
import CostLines from './costLines';
import PurchasesTable from './purchasesTable';
import CostLinesTables from './costLinesTables';
import Stats from './stats';
import Product from './product';

export default function AddPurchase({ goto, warehouses }) {
	return (
		<>
			<div className="comp-heading">
				<div className="row">
					<div className="col-lg-4">
						<label>Add Purchase Order</label>
					</div>
				</div>
			</div>
			<br />
			<div className="panel panel-default">
				<div className="panel-body">
					<div className="row" id="inline-input-forms">
						<div className="col-sm-4">
							<InlineInputForm
								link={true}
								path="/suppliers/view suppliers"
								id="supplier-code"
								value=""
								name="* Supplier Code"
							/>
							<InlineInputForm
								link={true}
								path="/suppliers/view suppliers"
								id="supplier-name"
								value=""
								name="* Supplier Name"
							/>
							<InlineInputForm
								disable="disabled"
								id="supplier-reference"
								value=""
								name="Supplier Reference"
							/>
							<InlineInputForm disable="disabled" id="discount" value="0.00" name="Discount (%)" />
							<InlineInputForm
								disable="disabled"
								id="supplier-invoice-date"
								type="date"
								value=""
								name="Supplier Invoice Date"
							/>
							<InlineDropdown disable="disabled" id="warehouse" name="Warehouse" values={warehouses} />
						</div>
						<div className="col-sm-4">
							<InlineInputForm disable="disabled" id="delivery-name" value="" name="Delivery Name" />
							<InlineInputForm disable="disabled" id="address-line-1" value="" name="Address Line 1" />
							<InlineInputForm disable="disabled" id="address-line-2" value="" name="Address Line 2" />
							<InlineInputForm disable="disabled" id="suburb" value="" name="Suburb" />
							<InlineInputForm disable="disabled" id="city" value="" name="City" />
							<InlineInputForm disable="disabled" id="state" value="" name="State/Region" />
						</div>
						<div className="col-sm-4">
							<InlineInputForm disable="disabled" id="postal-code" value="" name="Postal Code" />
							<InlineInputForm disable="disabled" id="country" value="" name="Country" />
							<InlineInputForm
								disable="disabled"
								id="order-date"
								value=""
								type="date"
								name="Order Date"
							/>
							<InlineInputForm
								disable="disabled"
								id="delivery-date"
								value=""
								type="date"
								name="* Delivery Date"
							/>
							<InlineDropdown
								disable="disabled"
								id="template"
								name="Template"
								values={['Globus Default', 'Formal Purchase', 'Classic Purchase']}
							/>
							<InlineInputForm disable="disabled" id="currency-code" value="" name="Currency Code" />
						</div>
					</div>
				</div>
			</div>
			<div className="panel panel-default">
				<div className="panel-body">
					<Product goto={path => goto(path)} />
					<br />
					<PurchasesTable />
				</div>
			</div>
			<div className="panel panel-default">
				<div className="panel-body">
					<CostLines />
					<br />
					<CostLinesTables />
				</div>
			</div>
			<div className="panel panel-default">
				<div className="panel-body">
					<div className="row">
						<div className="col-md-4">
							<label className="input-label" htmlFor="comments">
								Comments
							</label>
							<textarea disabled className="form-control" id="comments" rows="10" />
						</div>
						<div className="col-md-4"></div>
						<div className="col-md-4 stat-div">
							<Stats />
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
