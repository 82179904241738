import React from 'react';
import WarehousesTable from './warehousesTable';
import ConfirmationModal from '../../../common/confirmationModal';
export default function Warehouses({ goto, warehouses, onWarehouseUpdateOrDelete, onDelete }) {
	return (
		<>
			<div className="comp-heading">
				<div className="row">
					<div className="col-lg-4 col-md-7 col-8">
						<label>Warehouses</label>
					</div>
					<div className="col-lg-5 col-md-2 col-1"></div>
					<div className="col-lg-3 col-md-3 col-3" style={{ textAlign: 'right' }}>
						<button className="btn btn-light btn-sm" onClick={() => goto('/settings/system/warehouse/add')}>
							Add Warehouse
						</button>
					</div>
				</div>
			</div>
			<br />
			<div className="panel panel-default">
				<div className="panel-body">
					<WarehousesTable
						onWarehouseUpdateOrDelete={data => onWarehouseUpdateOrDelete(data)}
						warehouses={warehouses}
						goto={path => goto(path)}
					/>
				</div>
			</div>
			<ConfirmationModal onDelete={() => onDelete()} item="Warehouse" />
		</>
	);
}
