import React from 'react';
import Select from 'react-select';

export default function EditModal({ onUpdate, value, value1, value2, onChange, handleChangeEdit, countries }) {
	return (
		<div className="modal fade" id="currencyEditModal" role="dialog">
			<div className="modal-dialog">
				<div className="modal-content">
					<div className="modal-header">
						<button type="button" className="close" data-dismiss="modal">
							&times;
						</button>
						<h4 className="modal-title">Update Data</h4>
					</div>
					<div className="modal-body">
						<div className="form-group">
							<label htmlFor="default-attribute-set">Currency Selection</label>
							<Select value={value} isClearable onChange={handleChangeEdit} options={countries} />
							{/* <select
								onChange={e => onChange(e)}
								value={value}
								className="form-control"
								id="currencyselection"
							>
								<option></option>
								<option>AED</option>
								<option>AFN</option>
							</select> */}
						</div>
						<div className="form-group">
							<label htmlFor="text">Buy Rate</label>
							<input
								type="number"
								value={value1}
								onChange={e => onChange(e)}
								className="form-control"
								id="editBuyRate"
							/>
						</div>
						<div className="form-group">
							<label htmlFor="editSellRate">Sell Rate</label>
							<input
								type="number"
								value={value2}
								onChange={e => onChange(e)}
								className="form-control"
								id="editSellRate"
							/>
						</div>
					</div>
					<div className="modal-footer">
						<button
							type="button"
							onClick={() => onUpdate()}
							className="btn btn-success"
							data-dismiss="modal"
						>
							Update
						</button>
						<button type="button" className="btn btn-default" data-dismiss="modal">
							Cancel
						</button>
					</div>
				</div>
			</div>
		</div>
	);
}
