import React, { Component } from 'react';
import { isEmptyOrNull, removeNullValues } from '../../../../helpers';
import { connect } from 'react-redux';
import _ from 'lodash';
import swal from 'sweetalert';
import { loaderState, getUsers, onSelectWarehouse, updateWarehouse } from '../../../../actions';
import EditWarehouseComp from '../../../../components/setting/system/warehouses/addOrEditWarehouse';

class EditWarehouse extends Component {
	constructor(props) {
		super(props);
		this.state = {
			code: null,
			warehouseName: null,
			contactName: null,
			email: null,
			phoneNumber: null,
			mobileNumber: null,
			DDInumber: null,
			suburb: null,
			addressName: null,
			defaultWarehouse: '-1',
			obsolete: '-1',
			addressLine1: null,
			addressLine2: null,
			town: null,
			state: null,
			postalCode: null,
			country: null,
			userCheck: false,
			userAccess: null,
			userAccesses: [],
		};
	}

	componentDidMount() {
		this.props.loaderState(true);
		let id = this.props.match.params.id;
		const { warehouses } = this.props;
		let selectedWarehouse = _.findLast(warehouses, warehouse => {
			return Number(warehouse.id) === Number(id);
		});
		this.props.onSelectWarehouse(selectedWarehouse);
		this.props.getUsers();

		this.setState({ ...selectedWarehouse, userAccesses: selectedWarehouse.userAccess.split(',') });
	}

	onUpdate = () => {
		let updatedData = removeNullValues(this.state);
		let id = this.props.match.params.id;
		if (JSON.stringify(updatedData) !== '{}') {
			delete updatedData.userAccesses;
			delete updatedData.userCheck;
			delete updatedData.code;
			this.props.loaderState(true);
			this.props.updateWarehouse(id, updatedData);
		}
	};

	onChange = ({ target }) => {
		const { id, value, type } = target;
		if (type === 'checkbox') {
			this.setState({ [id]: target.checked ? '1' : '-1' });
		} else {
			this.setState({ [id]: value });
		}
	};

	onUserSelect = id => {
		const { userCheck, userAccesses } = this.state;

		if (userCheck == true) {
			if (!userAccesses.includes(id)) {
				userAccesses.push(id);
			}
		} else {
			_.remove(userAccesses, access => {
				return access === id;
			});
		}
		this.setState({ userAccess: userAccesses.toString() });
	};

	render() {
		const { users } = this.props;
		return (
			<EditWarehouseComp
				onUserSelect={id => this.onUserSelect(id)}
				users={users}
				data={this.state}
				onAdd={() => this.onUpdate()}
				edit={true}
				onChange={e => this.onChange(e)}
			/>
		);
	}
}

const mapStateToProps = state => {
	return {
		users: state.users.allUsers,
		user: state.users.loggedInUser,
		warehouses: state.system.warehouses.allWarehouses,
	};
};

export default connect(mapStateToProps, { loaderState, getUsers, onSelectWarehouse, updateWarehouse })(EditWarehouse);
