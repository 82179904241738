import React, { Component } from 'react';
import swal from 'sweetalert';
import { connect } from 'react-redux';
import { isEmptyOrNull, initilizeDataTable, destroyDataTable } from '../../../../helpers';
import {
	getAttributeSets,
	loaderState,
	getProductGroups,
	addProductGroup,
	deleteProductGroup,
	onSelectProductGroup,
	updateProductGroup,
} from '../../../../actions';
import _ from 'lodash';
import ProductGroupComp from '../../../../components/setting/system/productGroup';

class ProductGroup extends Component {
	constructor(props) {
		super(props);
		this.state = {
			groupName: null,
			attributeSetId: null,
			editGroupName: null,
			editAttrSetId: null,
		};
	}

	componentDidMount() {
		initilizeDataTable('product-groups-table');
		this.props.loaderState(true);
		this.props.getProductGroups();
		const { attributes } = this.props;
		if (attributes.length == 0) {
			this.props.loaderState(true);
			this.props.getAttributeSets();
		}
	}

	componentDidUpdate() {
		initilizeDataTable('product-groups-table');
	}

	resetState = () => {
		this.setState({ groupName: null, attributeSetId: null, editAttrSetId: null, editGroupName: null });
	};

	onChange = ({ target }) => {
		const { id, value } = target;
		this.setState({ [id]: value });
	};

	onDelete = () => {
		const { selectedGroup } = this.props;
		this.props.loaderState(true);
		this.props.deleteProductGroup(selectedGroup.id);
		this.resetState();
	};

	onAdd = () => {
		const { groupName, attributeSetId } = this.state;
		const { user, organization } = this.props;
		if (!isEmptyOrNull(groupName)) {
			let data = { createdBy: user.id, updatedBy: user.id, groupName, organizationId: organization.id };
			if (!isEmptyOrNull(attributeSetId)) {
				data.attributeSetId = attributeSetId;
			}
			this.props.loaderState(true);
			this.props.addProductGroup(data);
		} else {
			swal('', 'Please enter a Product Group Name!', 'info');
		}
		this.resetState();
	};

	onUpdate = () => {
		const { selectedGroup, user } = this.props;
		const { editGroupName, editAttrSetId } = this.state;

		if (isEmptyOrNull(editGroupName) && isEmptyOrNull(editAttrSetId)) {
		} else {
			let data = { id: selectedGroup.id, updatedBy: user.id };
			if (!isEmptyOrNull(editGroupName)) {
				data.groupName = editGroupName;
			}
			if (!isEmptyOrNull(editAttrSetId)) {
				data.attributeSetId = editAttrSetId;
			}
			this.props.loaderState(true);
			this.props.updateProductGroup({
				...data,
			});
		}
		this.resetState();
	};

	handleChange = newValue => {
		if (newValue) {
			this.setState({ attributeSetId: newValue.value });
		} else {
			this.setState({ attributeSetId: newValue });
		}
	};

	handleEditChange = newValue => {
		if (newValue) {
			this.setState({ editAttrSetId: newValue.value });
		} else {
			this.setState({ editAttrSetId: newValue });
		}
	};

	onProductGroupSelect = data => {
		this.props.onSelectProductGroup(data);
	};

	findAttribute = (attributes, id) => {
		let attrSet = {};
		let attrS = null;
		_.forEach(attributes, attr => {
			if (Number(attr.id) === Number(id)) {
				attrS = attr;
			}
		});
		if (attrS) {
			attrSet.label = attrS.attributeSetName;
			attrSet.value = attrS.id;
		} else {
			attrSet = null;
		}
		return attrSet;
	};

	render() {
		destroyDataTable('product-groups-table');

		const { groupName, editAttrSetId, editGroupName, attributeSetId } = this.state;
		const { attributes, allGroups, selectedGroup } = this.props;

		let attributesData = attributes
			? attributes.map(attr => {
					return { value: attr.id, label: attr.attributeSetName };
			  })
			: null;

		let id = editAttrSetId ? editAttrSetId : selectedGroup ? selectedGroup.attributeSetId : -1;
		let editAttrSet = attributes ? this.findAttribute(attributes, id) : null;
		let attrSet = attributes ? this.findAttribute(attributes, attributeSetId) : null;

		return (
			<ProductGroupComp
				groupName={groupName}
				attrSet={attrSet}
				editGroupName={editGroupName ? editGroupName : selectedGroup ? selectedGroup.groupName : null}
				editAttrSet={editAttrSet}
				attributes={attributesData}
				allGroups={allGroups}
				onAdd={() => this.onAdd()}
				handleChange={value => this.handleChange(value)}
				handleEditChange={value => this.handleEditChange(value)}
				onChange={e => this.onChange(e)}
				onUpdate={() => this.onUpdate()}
				onDelete={() => this.onDelete()}
				onProductGroupSelect={data => this.onProductGroupSelect(data)}
			/>
		);
	}
}

const mapStateToProps = state => {
	return {
		user: state.users.loggedInUser,
		attributes: state.system.attributeSets.allAttributeSets,
		allGroups: state.system.productGroups.allGroups,
		selectedGroup: state.system.productGroups.selectedGroup,
		organization: state.users.organizationInfo,
	};
};

export default connect(mapStateToProps, {
	getAttributeSets,
	loaderState,
	getProductGroups,
	addProductGroup,
	deleteProductGroup,
	onSelectProductGroup,
	updateProductGroup,
})(ProductGroup);
