import React from 'react';
import InvoicesTable from './invoicesTable';

export default function Invoices() {
	return (
		<div id="Invoices" className="tab-pane fade">
			<InvoicesTable />
		</div>
	);
}
