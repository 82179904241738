import React from 'react';
import InlineInputForm from '../../common/inlineInputForm';
import InlineDropdown from '../../common/inlineDropDown';
import Switch from '../../common/switch';
import BillOfMaterialTable from './billOfMaterialTable';

export default function AddBillOfMaterialProduction() {
	return (
		<>
			<div className="comp-heading">
				<div className="row">
					<div className="col-lg-4">
						<label>Add Bill Of Material</label>
					</div>
				</div>
			</div>
			<br />
			<div className="panel panel-default">
				<div className="panel-body">
					<div className="row" id="inline-input-forms">
						<div className="col-sm-4">
							<InlineInputForm id="product_code" value="" name="*Product Code" />
							<InlineInputForm id="product_description" value="" name="*Product Description" />
							<InlineDropdown
								disable="disabled"
								id="assembly_template"
								name="Assembly Template"
								values={['Globuss Default', 'Default Assembly']}
							/>
						</div>
						<div className="col-sm-3">
							<Switch id="can_auto_assemble" name="Can Auto Assemble" value="" />
							<Switch id="can_auto_disassemble" name="Can Auto Disassemble" value="" />
							<Switch id="sort_by_product_code" name="Sort By Product Code" value="" />
							<Switch id="obsolete" name="Obsolete" value="" />
						</div>
						<div className="col-sm-4">
							<div className="form-inline">
								<div className="form-group">
									<label htmlFor="comments">Comments</label>
									<textarea
										rows="7"
										style={{ width: '290px' }}
										type="text"
										className="form-control"
										id="comments"
									/>
								</div>
							</div>
							<small style={{ fontSize: '0.7em' }}>
								Note: Comments will be copied to each Assembly / Disassembly created from this BOM
							</small>
						</div>
					</div>
				</div>
			</div>
			<div className="panel panel-default">
				<div className="panel-body">
					<div className="row" id="settings-field">
						<div className="col-md-6 padding-right">
							<label className="input-label" htmlFor="component_product">
								*Component Product
							</label>
							<input
								disabled
								type="text"
								className="form-control"
								id="component_product"
								name="component_product"
							/>
						</div>
						<div className="col-md-2 padding-0">
							<label className="input-label" htmlFor="qauntity">
								*Quantity
							</label>
							<input disabled type="text" className="form-control" id="qauntity" name="qauntity" />
						</div>
						<div className="col-md-2 padding-0">
							<label className="input-label" htmlFor="wastage_qauntity">
								Wastage Qty
							</label>
							<input
								disabled
								type="text"
								className="form-control"
								id="wastage_qauntity"
								name="wastage_qauntity"
							/>
						</div>
						<div className="col-md-2" id="add-production-btn">
							<label className="input-label">Add</label>
							<button disabled type="button" className="btn btn-success btn-sm">
								Add
							</button>
						</div>
					</div>
					<br />
					<BillOfMaterialTable />
					<div className="row">
						<div className="col-md-10"></div>
						<div className="col-md-2" id="stock-price-count" style={{ float: 'right' }}>
							<div className="transfers-label">TOTAL COST</div>
							<br />
							<div className="transfers-count">0</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
