import React from 'react';
import TableActions from '../../common/tableActions';

export default function SellPriceTiers({ onClickHideOrView, hideOrShow }) {
	var hide = !hideOrShow ? 'hide-td' : '';
	var arr = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
	return (
		<div id="sellpricetiers" className="tab-pane active">
			<div className="markup-btn">
				{!hideOrShow ? (
					<button
						type="button"
						onClick={() => onClickHideOrView()}
						className="btn btn-default btn-sm"
						id="sale-markup-btn"
					>
						<i className="fa fa-angle-double-left icon" aria-hidden="true"></i>
						View Markup Prices
					</button>
				) : (
					<button
						type="button"
						onClick={() => onClickHideOrView()}
						className="btn btn-default btn-sm"
						id="sale-markup-btn"
					>
						<i className="fa fa-angle-double-right icon" aria-hidden="true"></i>
						Hide Markup Prices
					</button>
				)}
			</div>
			<div className="table-view table-responsive">
				<table id="sales-table" className="table" style={{ width: '100%' }}>
					<thead>
						<tr>
							<th>Tier</th>
							<th>Current Price</th>
							<th>Margin %</th>
							<th id={hide}>Markup Form</th>
							<th id={hide}>Markup %</th>
							<th id={hide}>Markup Price</th>
							<th id={hide}>Action</th>
						</tr>
					</thead>
					<tbody>
						{arr.map(a => {
							return (
								<tr key={a}>
									<td>
										<label className="input-label">
											<a href="#" target="_blank">
												Sell Price Tier 1
											</a>
										</label>
									</td>
									<td>0.00</td>
									<td>0.00</td>
									<td id={hide}>None</td>
									<td id={hide}>0.00</td>
									<td id={hide}>0.00</td>
									<td id={hide}>
										<TableActions
											data={['Edit', 'Delete']}
											icon="fa fa-cog"
											// onClick={action => {
											// 	onProductSelect(product);
											// 	onClickAction(action);
											// }}
										/>
										{/* <label className="input-label">
												<a href="#" target="_blank">
													Apply Markup Price
												</a>
											</label> */}
									</td>
								</tr>
							);
						})}
					</tbody>
				</table>
			</div>
		</div>
	);
}
