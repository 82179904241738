import { settings } from '../../constants/actionTypes';
const initialState = {
	allAttributeSets: null,
	selectedAttribute: null,
};

function attributeSetsReducer(state = initialState, action) {
	switch (action.type) {
		case settings.ATTRIBUTE_SETS:
			return { ...state, allAttributeSets: action.payload };
		case settings.SELECTED_ATTRIBUTE:
			return { ...state, selectedAttribute: action.payload };
		default:
			return state;
	}
}
export default attributeSetsReducer;
