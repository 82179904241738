import React from 'react';

export default function SalesEnquiryTable() {
	return (
		<div className="table-view table-responsive">
			<table id="sales-quotes-charge-table" className="table">
				<thead>
					<tr>
						<th>Order No.</th>
						<th>Order Date</th>
						<th>Required Date</th>
						<th>Completed Date</th>
						<th>Warehouse</th>
						<th>Customer</th>
						<th>Customer Type</th>
						<th>Product</th>
						<th>Product Group</th>
						<th>Status</th>
						<th>Quantity</th>
						<th>Sub Total</th>
					</tr>
				</thead>
				<tbody></tbody>
			</table>
		</div>
	);
}
