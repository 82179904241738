import React, { Component } from 'react';

export default class Costs extends Component {
	render() {
		return (
			<div id="Costs" className="tab-pane fade">
				<div className="panel panel-default">
					<div className="panel-body">
						<div className="row" id="settings-field">
							<div className="col-md-2 padding-right">
								<label className="input-label" htmlFor="purchase-price">
									<a href="#" target="_blank">
										Purchase Tax Rate
									</a>
								</label>
								<input type="text" className="form-control" id="purchase-price" name="purchase-price" />
							</div>
							<div className="col-md-2 padding-0">
								<label className="input-label" htmlFor="purchase-tax-rate">
									Purchase Tax Rate
								</label>
								<input type="text" className="form-control" id="purchase-price" name="purchase-price" />
							</div>
						</div>
					</div>
				</div>
				<div className="panel panel-default">
					<div className="panel-body">
						<div className="table-view table-responsive">
							<table id="cost-table" className="table">
								<thead>
									<tr>
										<th>Trasaction Date</th>
										<th>Product Code</th>
										<th>Product Description</th>
										<th>Trasaction Type</th>
										<th>Trasaction Ref</th>
										<th>Trans Qty</th>
										<th>Trans Unit Cost</th>
										<th>Pre Avg Cost</th>
										<th>Post Avg Cost</th>
										<th>Product Group</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>13/09/2017</td>
										<td>
											<label className="input-label">
												<a href="#" target="_blank">
													DINING
												</a>
											</label>
										</td>
										<td>
											<label className="input-label">
												<a href="#" target="_blank">
													Dining Table
												</a>
											</label>
										</td>
										<td>Purchase Order</td>
										<td>
											<label className="input-label">
												<a href="#" target="_blank">
													PO-000001
												</a>
											</label>
										</td>
										<td>60.00</td>
										<td>47.50</td>
										<td>47.50</td>
										<td>47.50</td>
										<td>
											<label className="input-label">
												<a href="#" target="_blank">
													Furniture
												</a>
											</label>
										</td>
									</tr>
									<tr>
										<td>13/09/2017</td>
										<td>
											<label className="input-label">
												<a href="#" target="_blank">
													COUCH2
												</a>
											</label>
										</td>
										<td>
											<label className="input-label">
												<a href="#" target="_blank">
													2-Seater Couch
												</a>
											</label>
										</td>
										<td>Purchase Order</td>
										<td>
											<label className="input-label">
												<a href="#" target="_blank">
													PO-000002
												</a>
											</label>
										</td>
										<td>50.00</td>
										<td>67.50</td>
										<td>67.50</td>
										<td>67.50</td>
										<td>
											<label className="input-label">
												<a href="#" target="_blank">
													Furniture
												</a>
											</label>
										</td>
									</tr>
									<tr>
										<td>13/09/2017</td>
										<td>
											<label className="input-label">
												<a href="#" target="_blank">
													STAIN
												</a>
											</label>
										</td>
										<td>
											<label className="input-label">
												<a href="#" target="_blank">
													Dark Wood Stain
												</a>
											</label>
										</td>
										<td>Purchase Order</td>
										<td>
											<label className="input-label">
												<a href="#" target="_blank">
													PO-000003
												</a>
											</label>
										</td>
										<td>28.00</td>
										<td>82.50</td>
										<td>78.70</td>
										<td>80.00</td>
										<td>
											<label className="input-label">
												<a href="#" target="_blank">
													Material
												</a>
											</label>
										</td>
									</tr>
									<tr>
										<td>13/09/2017</td>
										<td>
											<label className="input-label">
												<a href="#" target="_blank">
													NAIL-S
												</a>
											</label>
										</td>
										<td>
											<label className="input-label">
												<a href="#" target="_blank">
													Small Nails
												</a>
											</label>
										</td>
										<td>Purchase Order</td>
										<td>
											<label className="input-label">
												<a href="#" target="_blank">
													PO-000004
												</a>
											</label>
										</td>
										<td>100.00</td>
										<td>11.88</td>
										<td>7.99</td>
										<td>9.93</td>
										<td>
											<label className="input-label">
												<a href="#" target="_blank">
													Consumable
												</a>
											</label>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
