import React from 'react';

export default function StockMovementTable() {
	return (
		<div className="table-view table-responsive">
			<table id="sales-quotes-charge-table" className="table">
				<thead>
					<tr>
						<th>Year</th>
						<th>Month</th>
						<th>Opening SOH</th>
						<th>Plus Purchase</th>
						<th>Less Cost of Goods Sold</th>
						<th>Plus Credits</th>
						<th>Plus Stock Adjustments</th>
						<th>Plus Other Costs</th>
						<th>Closing SOH</th>
					</tr>
				</thead>
				<tbody></tbody>
			</table>
		</div>
	);
}
