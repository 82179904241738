import React, { Component } from 'react';
import swal from 'sweetalert';
import { connect } from 'react-redux';
import {
	loaderState,
	getCurrencyRates,
	addCurrencyRate,
	onSelectCurrency,
	deleteCurrency,
	updateCurrency,
} from '../../../../actions';
import _ from 'lodash';
import { isEmptyOrNull, initilizeDataTable, destroyDataTable, removeNullValues } from '../../../../helpers';
import CurrencyRateComp from '../../../../components/setting/system/currencyRates';

class CurrencyRate extends Component {
	constructor(props) {
		super(props);
		this.state = {
			baseCurrency: 'British pound',
			currencyselection: null,
			buyRate: null,
			sellRate: null,
			editBuyRate: null,
			editSellRate: null,
			editCurrencyselection: null,
		};
	}

	componentDidMount() {
		this.props.loaderState(true);
		this.props.getCurrencyRates();
		initilizeDataTable('currency-rates-table');
	}

	componentDidUpdate() {
		initilizeDataTable('currency-rates-table');
	}

	resetState = () => {
		this.setState({
			currencyselection: null,
			sellRate: null,
			sellRate: null,
			editBuyRate: null,
			editSellRate: null,
			editCurrencyselection: null,
		});
	};

	onChange = ({ target }) => {
		this.setState({ [target.id]: target.value });
	};

	onUpdate = () => {
		const { user, selectedCurrency } = this.props;
		const { editSellRate, editBuyRate, editCurrencyselection } = this.state;
		let data = {
			sellRate: editSellRate,
			buyRate: editBuyRate,
		};
		if (editCurrencyselection) {
			let currency = this.findSelectedCurrency(editCurrencyselection);
			data.currencyCode = currency.label;
			data.countryName = currency.value;
		}
		if (isEmptyOrNull(editSellRate) && isEmptyOrNull(editBuyRate) && isEmptyOrNull(editCurrencyselection)) {
		} else {
			let newData = removeNullValues(data);
			newData.createdBy = user.id;
			newData.updatedBy = user.id;
			newData.id = selectedCurrency.id;
			this.props.loaderState(true);
			this.props.updateCurrency(newData);
		}
	};

	prepareCountriesData = countries => {
		let data = [];
		_.forEach(countries, country => {
			data.push({ label: `(${country.currencies[0].code}) ${country.name}`, value: country.currencies[0].name });
		});
		return data;
	};

	handleChange = newValue => {
		if (newValue) {
			this.setState({ baseCurrency: newValue.value });
		} else {
			this.setState({ baseCurrency: newValue });
		}
	};

	handleChangeEdit = newValue => {
		if (newValue) {
			this.setState({ editCurrencyselection: newValue.value });
		} else {
			this.setState({ editCurrencyselection: newValue });
		}
	};

	handleChangeCurrencySelection = newValue => {
		if (newValue) {
			this.setState({ currencyselection: newValue.value });
		} else {
			this.setState({ currencyselection: newValue });
		}
	};

	findSelectedCurrency = currency => {
		const { countries } = this.props;
		let selected = {};
		_.forEach(countries, country => {
			if (currency === country.currencies[0].name) {
				selected.label = `(${country.currencies[0].code})${country.name}`;
				selected.value = country.currencies[0].name;
			}
		});

		return selected;
	};

	onAdd = () => {
		const { currencyselection, buyRate, sellRate } = this.state;
		const { user, organization } = this.props;
		if (!isEmptyOrNull(currencyselection) && !isEmptyOrNull(buyRate) && !isEmptyOrNull(sellRate)) {
			let selectedCurrency = this.findSelectedCurrency(currencyselection);
			let data = {
				currencyCode: selectedCurrency.label,
				countryName: selectedCurrency.value,
				sellRate,
				buyRate,
				createdBy: user.id,
				updatedBy: user.id,
				organizationId: organization.id,
			};
			this.props.loaderState(true);
			this.props.addCurrencyRate(data);
		} else {
			swal('', 'Please fill all the fields!', 'info');
		}
		this.resetState();
	};

	onDelete = () => {
		const { selectedCurrency } = this.props;
		this.props.loaderState(true);
		this.props.deleteCurrency(selectedCurrency.id);
		this.resetState();
	};

	onCurrencySelect = data => {
		this.props.onSelectCurrency(data);
	};

	render() {
		destroyDataTable('currency-rates-table');

		const {
			currencyselection,
			buyRate,
			sellRate,
			baseCurrency,
			editBuyRate,
			editSellRate,
			editCurrencyselection,
		} = this.state;
		const { countries, currencies, selectedCurrency } = this.props;
		let countriesData = this.prepareCountriesData(countries);
		let selectedBase = this.findSelectedCurrency(baseCurrency);
		let selectedSelection = this.findSelectedCurrency(currencyselection);
		let baseCur = selectedBase ? { label: selectedBase.label, value: selectedBase.value } : null;

		let Curr = editCurrencyselection
			? editCurrencyselection
			: selectedCurrency
			? selectedCurrency.countryName
			: null;
		let editCurr = Curr ? this.findSelectedCurrency(Curr) : null;

		return (
			<CurrencyRateComp
				value={currencyselection}
				buyRate={buyRate}
				sellRate={sellRate}
				editBuyRate={editBuyRate ? editBuyRate : selectedCurrency ? selectedCurrency.buyRate : null}
				editSellRate={editSellRate ? editSellRate : selectedCurrency ? selectedCurrency.sellRate : null}
				baseCur={baseCur}
				editCurr={editCurr}
				selectedBase={selectedBase}
				selectedSelection={selectedSelection}
				countries={countriesData}
				currencies={currencies}
				handleChange={value => this.handleChange(value)}
				handleChangeEdit={value => this.handleChangeEdit(value)}
				handleChangeCurrencySelection={value => this.handleChangeCurrencySelection(value)}
				onChange={e => this.onChange(e)}
				onUpdate={() => this.onUpdate()}
				onAdd={() => this.onAdd()}
				onDelete={() => this.onDelete()}
				onCurrencySelect={data => this.onCurrencySelect(data)}
			/>
		);
	}
}

const mapStateToProps = state => {
	return {
		user: state.users.loggedInUser,
		countries: state.common.countriesData,
		currencies: state.system.currencyRates.allCurrencies,
		selectedCurrency: state.system.currencyRates.selectedCurrency,
		organization: state.users.organizationInfo,
	};
};

export default connect(mapStateToProps, {
	loaderState,
	getCurrencyRates,
	addCurrencyRate,
	onSelectCurrency,
	deleteCurrency,
	updateCurrency,
})(CurrencyRate);
