import React, { Component } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import '../App.css';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';

import Sidebar from '../containers/sidebar';
import Header from '../containers/header';
import Routes from '../routes';
import Loader from '../components/common/loader';
import Signup from '../containers/registration/signup';
import Login from '../containers/registration/login';
import Organization from '../containers/registration/organization';

class App extends Component {
	render() {
		const { activeNav, onNavClick, sideManu, isLoading } = this.props;
		const pathname = window.location.pathname;

		let opacity = isLoading ? 0.5 : 1;
		let pointerEvents = isLoading ? 'none' : '';
		return (
			<BrowserRouter>
				<div style={{ pointerEvents, opacity }}>
					<Loader display={isLoading} />
					{pathname === '/signup' ? (
						<Signup />
					) : pathname === '/organization' ? (
						<Organization />
					) : pathname === '/' ? (
						<Login />
					) : (
						<div className="wrapper">
							<Sidebar manus={sideManu} onNavClick={nav => onNavClick(nav)} nav={activeNav} />
							<div id="content">
								<Header />
								<div className="main-body">
									<Routes />
								</div>
							</div>
						</div>
					)}
				</div>
			</BrowserRouter>
		);
	}
}

const mapStateToProps = state => {
	return {
		isLoading: state.common.isLoading,
	};
};

export default connect(mapStateToProps, {})(App);
