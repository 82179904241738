import React from 'react';
import InlineDropdown from '../../../components/common/inlineDropDown';
import InlineInputForm from '../../../components/common/inlineInputForm';

export default function Document({ templates, newOrDuplicate, onClickNewOrDuplicate }) {
	return (
		<div id="Document" className="tab-pane active">
			<div className="row" id="inline-input-forms">
				<div className="col-sm-12">
					<div className="template-forms">
						<InlineDropdown
							noEmpty={true}
							id="documentType"
							name="Document Type"
							values={[
								'Assemblies',
								'Credit Notes',
								'Invoices',
								'Labels',
								'Packing Slip',
								'Pick List',
								'Purchase Orders',
								'Sales Orders',
								'Sales Quotes',
								'Ship Notes',
								'Supplier Returns',
								'Warehouse Transfers',
							]}
						/>
					</div>
				</div>
			</div>
			<div className="row" id="inline-input-forms">
				<div className="col-md-6">
					<div className="template-forms">
						<InlineDropdown noEmpty={true} id="templateName" name="Template Name" values={templates} />
					</div>
				</div>
				<div className="col-md-6">
					<div className="form-inline" id="doc-checkbox">
						<div className="form-group">
							<input id="default" type="checkbox" />
							<label htmlFor="default" className="input-label">
								Default
							</label>
						</div>
					</div>
				</div>
			</div>
			<br />
			<div className="row" id="inline-input-forms" style={{ display: !newOrDuplicate ? 'none' : '' }}>
				<div className="col-sm-12">
					<InlineInputForm id="newTemplateName" name="New Template Name" />
				</div>
			</div>
			<br />
			<br />
			<div className="doc-designer-btns">
				<div className="defaults" style={{ display: newOrDuplicate ? 'none' : '' }}>
					<button className="btn btn-light btn-sm" onClick={() => onClickNewOrDuplicate()}>
						New
					</button>
					<button className="btn btn-light btn-sm" onClick={() => onClickNewOrDuplicate()}>
						Rename
					</button>
					<button className="btn btn-light btn-sm" onClick={() => onClickNewOrDuplicate()}>
						Duplicate
					</button>
					<button className="btn btn-light btn-sm">Export</button>
					<button className="btn btn-danger btn-sm">Delete</button>
				</div>
				<div className="on-create" style={{ display: !newOrDuplicate ? 'none' : '' }}>
					<button className="btn btn-light btn-sm">Create</button>
					<button className="btn btn-light btn-sm" onClick={() => onClickNewOrDuplicate()}>
						Cancel
					</button>
				</div>
			</div>
			<hr />
			<div className="doc-info">
				<i className="fa fa-info-circle" />
				<span>This is a read-only template. You must duplicate it to make modifications.</span>
			</div>
			<div className="template-import">
				<div className="template-import-heading">IMPORT TEMPLATE</div>
				<hr />
				<div className="doc-info">
					<i className="fa fa-info-circle" />
					<span>The file you import must be a valid JSON file in .json format</span>
					<div className="uploadFiles">
						<br />
						<div className="file-drop-area" id="template-upload">
							<input id={`inputFileTemplateUpload`} type="file" />
							<i className="fa fa-cloud-upload" aria-hidden="true"></i>
							<p className="file-name">click here to upload file.</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
