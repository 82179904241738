import React, { Component } from 'react';
import { initilizeDataTable, destroyDataTable } from '../../../helpers';
import AddBillOfMaterialComp from '../../../components/production/addBillOfMaterial';

class BillOfMaterial extends Component {
	componentDidMount() {
		initilizeDataTable('bill-of-material-table');
	}
	componentDidUpdate() {
		initilizeDataTable('bill-of-material-table');
	}
	render() {
		destroyDataTable('bill-of-material-table');

		return <AddBillOfMaterialComp />;
	}
}

export default BillOfMaterial;
