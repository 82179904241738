import { inventory } from '../../constants/actionTypes';
const initialState = {
	stockAdjustments: null,
	selectedstockAdjustment: null,
	warehouseTransfers: null,
	selectedWarehouseTransfer: null,
	stockCounts: null,
	selectedStockCount: null,
};

function transactionsReducer(state = initialState, action) {
	switch (action.type) {
		case inventory.STOCK_ADJUSTMENTS:
			return { ...state, stockAdjustments: action.payload };
		case inventory.SELECTED_STOCK_ADJUSTMENT:
			return { ...state, selectedstockAdjustment: action.payload };
		case inventory.WAREHOUSES_TRANSFERS:
			return { ...state, warehouseTransfers: action.payload };
		case inventory.SELECTED_WAREHOUSES_TRANSFER:
			return { ...state, selectedWarehouseTransfer: action.payload };
		case inventory.STOCK_COUNTS:
			return { ...state, stockCounts: action.payload };
		case inventory.SELECTED_STOCK_COUNT:
			return { ...state, selectedStockCount: action.payload };
		default:
			return state;
	}
}
export default transactionsReducer;
