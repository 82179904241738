let permissionsData = {
	permissions: [
		{
			main: 'Change Password',
		},
		{
			main: 'Dashboard',
		},
		{
			main: 'Data Explorer',
			subPermissions: [
				'Assembly Data Source',
				'Purchase Data Source',
				'Sales Order Data Source',
				'Sales Shipment Data Source',
				'Warehouse Transfer Data Source',
			],
		},
		{
			main: 'Inventory',
			subPermissions: [
				'Stock Adjustment',
				'Stock Adjustments Import',
				'Warehouse Stock Transfer',
				'Warehouse Stock Transfer Import',
				'Stock Count List',
				'Stock Revaluation',
				'Product',
				'View Products',
				'View Stock Adjustments',
				'Product Import',
				'Product Export',
				'Product Change Codes',
				'Markup Price Update',
			],
		},
		{
			main: 'Purchases',
			subPermissions: [
				'Costing Analysis Report',
				'Costing Analysis Report Export',
				'Purchase Enquiry',
				'Import Purchases',
				'Export Purchases',
				'Import Supplier',
				'Export Suppliers',
				'Purchase Order',
				'Purchase Order Approval',
				'Purchase Order Costing',
				'Receipt Purchase',
				'Reorder Report',
				'Supplier',
				'Supplier Change Code',
				'Supplier Return',
				'View Purchase',
				'View Supplier Return',
				'View Suppliers',
			],
		},
		{
			main: 'Production',
			subPermissions: [
				'Add Assembly',
				'Add Disassembly',
				'View Assemblies',
				'Add Bill Of Materials',
				'View Bills Of Materials',
				'Import Bills Of Materials',
				'Export Bills Of Materials',
			],
		},
		{
			main: 'Sales',
			subPermissions: [
				'Add Quote',
				'View Quotes',
				'Import Quotes',
				'Add Sales Order',
				'View Sales Orders',
				'View Shipments',
				'Batch Complete',
				'Import Sales Orders',
				'Export Sales Orders',
				'Add Credit',
				'View Credits',
				'Export Credits',
			],
		},
		{
			main: 'Customers',
			subPermissions: ['Add Customer', 'View Customers', 'ImportExport', 'Change Codes'],
		},
		{
			main: 'Suppliers',
			subPermissions: ['Add Supplier', 'View Suppliers', 'Import Suppliers', 'Export Suppliers', 'Change Codes'],
		},
		{
			main: 'Reports',
			subPermissions: [
				'Purchase Equiry',
				'Costing Analysis Report',
				'Reorder Report',
				'Product Enquiry',
				'Stock On Hand Equiry',
				'Batch Number Enquiry',
				'Serial Number Equiry',
				'Transaction Enquiry',
				'Product Allocations',
				'Quict Stock Count',
				'Stock Movement Enquiry',
				'Price List Enquiry',
				'Production Enquiry',
				'Margin Enquiry',
				'Unit Sales Enquiry',
				'Quote Enquiry',
				'Sales Enquiry',
				'Backorder Enquiry',
				'Shipment Enquiry',
				'Invoice Enquiry',
				'Credit Enquiry',
			],
		},
		{
			main: 'Settings',
			subPermissions: [
				'Company',
				'Billing',
				'Users',
				'Roles',
				'Role Permissions',
				'Email Logs',
				'Adjustment Reasons',
				'Attribute Sets',
				'Credit Reason',
				'Currency Rates',
				'Customer Types',
				'Delivery Methods',
				'Prefixes',
				'Product Groups',
				'Payment Terms',
				'Sales Groups',
				'Sales Persons',
				'Sell Price Tiers',
				'Shipping Companies',
				'Supplier Return Reason',
				'Taxes',
				'Units Of Measure',
				'Warehouses',
				'Purchase Orders',
				'Supplier Returns',
				'Warehouse Transfers',
				'Assemblies',
				'Sales Quotes',
				'Sales Orders',
				'Invoices',
				'Picklists',
				'Packing Slips',
				'Ship Notes',
				'Credit Notes',
				'Labels',
			],
		},
	],
};

export default permissionsData.permissions;
