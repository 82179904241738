import React from 'react';
import InlineInputForm from '../../../common/inlineInputForm';

export default function Contact() {
	return (
		<div id="Contact" className="tab-pane fade">
			<div className="row" id="inline-input-forms">
				<div className="col-sm-4">
					<InlineInputForm id="telephone" value="" name="Telephone" />
					<InlineInputForm id="fax" value="" name="Fax" />
					<InlineInputForm id="mobile" value="" name="Mobile" />
					<InlineInputForm id="DDI" value="" name="DDI" />
					<InlineInputForm id="toll-free" value="" name="TollFree" />
				</div>
				<div className="col-sm-1"></div>
				<div className="col-sm-4">
					<InlineInputForm id="purchase-email" value="" name="Purchase Email" />
					<div className="form-inline">
						<div className="form-group">
							<label htmlFor="purchase-email-comments">Purchase Email Comment</label>
							<textarea rows="7" type="text" className="form-control" id="purchase-email-comments" />
						</div>
					</div>
					<InlineInputForm id="sell-email" value="" name="Sell Email" />
					<div className="form-inline">
						<div className="form-group">
							<label htmlFor="sell-email-comments">Sell Email Comment</label>
							<textarea rows="7" type="text" className="form-control" id="sell-email-comments" />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
