import React from 'react';
import InlineInputForm from '../../common/inlineInputForm';
import InlineDropdown from '../../common/inlineDropDown';
import Product from './product';
import WarehouseTransferTable from './warehouseTransferTable';

export default function AddWarehouseTransfer({ warehouses }) {
	return (
		<>
			<div className="comp-heading">
				<div className="row">
					<div className="col-lg-4 col-md-7 col-8">
						<label>Add Warehouse Transfer</label>
					</div>
				</div>
			</div>
			<br />
			<div className="row" id="inline-input-forms">
				<div className="col-sm-4">
					<InlineDropdown id="source" name="Source" values={warehouses} />
					<InlineDropdown id="destination" name="Destination" values={warehouses} />
				</div>
				<div className="col-sm-4">
					<div className="form-inline">
						<div className="form-group">
							<label htmlFor="comments">Comments</label>
							<textarea rows="5" type="text" className="form-control" id="comments" />
						</div>
					</div>
				</div>
				<div className="col-sm-4">
					<InlineDropdown id="template" name="Template" values={warehouses} />
				</div>
			</div>
			<br />
			<Product />
			<br />
			<WarehouseTransferTable />
			<br /> <br />
			<div className="row">
				<div className="col-md-9 col-xs-6"></div>
				<div className="col-md-3 col-xs-6 stat-div">
					<small className="stat-label">TOTAL QUANTITY</small>
					<div className="stat-value">0</div>

					<small className="stat-label">
						SIZE (METRE<sup>3</sup>)
					</small>
					<div className="stat-value">0</div>

					<small className="stat-label">WEIGHT (KG)</small>
					<div className="stat-value">0</div>
				</div>
			</div>
			<br />
			<div className="row">
				<div className="col-xs-12" style={{ textAlign: 'right' }}>
					<button className="btn btn-light btn-sm">Email</button>
				</div>
			</div>
		</>
	);
}
