import React from 'react';

export default function Single({ supplers }) {
	return (
		<div id="Single" className="tab-pane active">
			<div className="row" id="settings-field">
				<div className="col-sm-2 padding-right">
					<label className="input-label" htmlFor="supplier">
						*Supplier
					</label>
					<input
						type="text"
						autoComplete="on"
						list="suppliers"
						className="form-control"
						id="supplier"
						name="supplier"
					/>
					<datalist id="suppliers">
						<option></option>
						{supplers ? supplers.map(suppler => <option value={suppler.name}></option>) : null}
						<option value="q"></option>
						<option value="q"></option>
						<option value="q"></option>
						<option value="q"></option>
						<option value="q"></option>
						<option value="q"></option>
						<option value="q"></option>
						<option value="q"></option>
						<option value="q"></option>
						<option value="q"></option>
						<option value="q"></option>
						<option value="q"></option>
						<option value="q"></option>
						<option value="q"></option>
						<option value="q"></option>
						<option value="q"></option>
						<option value="q"></option>
						<option value="q"></option>
						<option value="q"></option>
						<option value="q"></option>
						<option value="q"></option>
						<option value="q"></option>
						<option value="q"></option>
						<option value="q"></option>
						<option value="q"></option>
						<option value="q"></option>
						<option value="q"></option>
						<option value="q"></option>
						<option value="q"></option>
					</datalist>
					{/* <input type="text" className="form-control" id="supplier" name="supplier" /> */}
				</div>
				<div className="col-sm-2 padding-0">
					<label className="input-label" htmlFor="new-code">
						*New Supplier Code
					</label>
					<input type="text" list="supplier-codes" className="form-control" id="new-code" name="new-code" />
					<datalist id="supplier-codes">
						<option></option>
						{supplers ? supplers.map(suppler => <option value={suppler.name}></option>) : null}
					</datalist>
				</div>
				<div className="col-lg-3 col-md-3 col-sm-3 col-2 padding-0" id="system-add-btn">
					<button className="btn btn-success btn-sm">Save</button>
				</div>
			</div>
		</div>
	);
}
