import React from 'react';

export default function WarehouseTable() {
	return (
		<div className="table-view table-responsive">
			<table id="warehouse-transfers-table" className="table">
				<thead>
					<tr>
						<th>Transfer Number</th>
						<th>Transfer Date</th>
						<th>Source Warehouse</th>
						<th>Destination Warehouse</th>
						<th>Comments</th>
						<th>Transfer Status</th>
						<th>Action</th>
					</tr>
				</thead>
				<tbody></tbody>
			</table>
		</div>
	);
}
