import React, { Component } from 'react';
import { initilizeDataTable, destroyDataTable } from '../../../../helpers';
import BackOrderComp from '../../../../components/reports/sales/backOrder';

export default class BackOrder extends Component {
	constructor(props) {
		super(props);
		this.state = {
			nav: ['Sales Orders', 'All'],
		};
	}
	componentDidMount() {
		initilizeDataTable('sales-quotes-charge-table');
		initilizeDataTable('all-back-order-enquiry-table');
	}

	componentDidUpdate() {
		initilizeDataTable('sales-quotes-charge-table');
		initilizeDataTable('all-back-order-enquiry-table');
	}

	render() {
		destroyDataTable('sales-quotes-charge-table');
		destroyDataTable('all-back-order-enquiry-table');

		const { nav } = this.state;
		return <BackOrderComp nav={nav} />;
	}
}
