import api from '../../api/api';
import { success, failed } from '../../constants/messages';
import { settings } from '../../constants/actionTypes';
import { loaderState } from '..';
import _ from 'lodash';
import swal from 'sweetalert';

export const getSupplierReturnReasons = () => dispatch => {
	api.get('/supplierReturnReasons')
		.then(res => {
			if (res.data.code === 200) {
				dispatch({ type: settings.SUPPLIER_RETURN_REASONS, payload: res.data.data });
			} else {
				swal('', res.data.message, 'error');
			}
			dispatch(loaderState(false));
		})
		.catch(err => {
			swal('', err.message, 'error');
			dispatch(loaderState(false));
		});
};

export const addSupplierReturnReason = ({ reason, createdBy, organizationId }) => (dispatch, getState) => {
	var supplierReturnReasons = getState().system.supplierReturnReasons.allReturnReason;
	api.post('/supplierReturnReasons', { reason, createdBy, updatedBy: createdBy, organizationId })
		.then(res => {
			if (Number(res.data.code) === 200) {
				dispatch({
					type: settings.SUPPLIER_RETURN_REASONS,
					payload: [...supplierReturnReasons, res.data.data],
				});
				dispatch(loaderState(false));
			} else {
				swal('', res.data.message, 'error');
				dispatch(loaderState(false));
			}
		})
		.catch(err => {
			swal('', err.message, 'error');
			dispatch(loaderState(false));
		});
};

export const deleteSupplierReturnReason = id => (dispatch, getState) => {
	const { allReturnReason } = getState().system.supplierReturnReasons;
	api.delete(`/supplierReturnReasons/${id}`)
		.then(res => {
			if (Number(res.data.code) === 200) {
				_.remove(allReturnReason, reason => {
					return Number(id) == Number(reason.id);
				});
				dispatch(loaderState(false));
			} else {
				swal('', res.data.message, 'error');
				dispatch(loaderState(false));
			}
		})
		.catch(err => {
			swal('', err.message, 'error');
			dispatch(loaderState(false));
		});
};

export const updateSupplierReturnReason = ({ reason, id, updatedBy }) => (dispatch, getState) => {
	var supplierReturnReasons = getState().system.supplierReturnReasons.allReturnReason;
	api.post(`/supplierReturnReasons/update/${id}`, { reason, updatedBy })
		.then(res => {
			let updatedReason = _.findLast(supplierReturnReasons, reason => {
				return Number(reason.id) == Number(id);
			});
			updatedReason.reason = reason;
			let updatedReasons = _.map(supplierReturnReasons, reason => {
				return reason.id === id ? updatedReason : reason;
			});

			dispatch({ type: settings.SUPPLIER_RETURN_REASONS, payload: updatedReasons });
			dispatch(loaderState(false));
		})
		.catch(err => {
			swal('', err.message, 'error');
			dispatch(loaderState(false));
		});
};

export const onUpdateOrDeleteSupplierReturnReason = data => dispatch => {
	dispatch({ type: settings.SELECTED_SUPPLIER_RETURN_REASONS, payload: data });
};
