import React from 'react';
import CustomersTable from './customersTable';

export default function ViewCustomers({ goto }) {
	return (
		<>
			<div className="comp-heading">
				<div className="row">
					<div className="col-md-4">
						<label>View Customers</label>
					</div>
					<div className="col-md-5"></div>
					<div className="col-md-3 padding-0" style={{ textAlign: 'right' }}>
						<button className="btn btn-light btn-sm">Import</button>
						<button className="btn btn-light btn-sm">Export</button>
						<button onClick={() => goto('/customers/add customers')} className="btn btn-success btn-sm">
							Add Customer
						</button>
					</div>
				</div>
			</div>
			<br />
			<div className="panel panel-default">
				<div className="panel-body">
					<div className="row" id="settings-field">
						<div className="col-md-2 padding-right">
							<label className="input-label" htmlFor="search-customer">
								Customer
							</label>
							<input type="text" className="form-control" id="search-customer" name="search-customer" />
						</div>
						<div className="col-md-2 padding-0">
							<label className="input-label" htmlFor="search-customer-type">
								Customer Type
							</label>
							<select className="form-control" id="search-customer-type">
								<option>All</option>
								<option>Cash</option>
								<option>General</option>
								<option>On Account</option>
								<option>Wholesale</option>
							</select>
						</div>
						<div className="col-md-2 padding-0">
							<label className="input-label" htmlFor="search-obsolete">
								Obsolete
							</label>
							<select className="form-control" id="search-obsolete">
								<option>All</option>
								<option>Yes</option>
								<option>No</option>
							</select>
						</div>
					</div>
				</div>
			</div>
			<div className="panel panel-default">
				<div className="panel-body">
					<CustomersTable />
				</div>
			</div>
		</>
	);
}
