import React from 'react';
import Info from '../../common/info';
import UploadFiles from '../../common/uploadFiles';

export default function deliveryAddresses({ onDeliveryAddressUpload }) {
	return (
		<div id="DeliveryAddresses" className="tab-pane fade">
			<br />
			<Info
				heading="Importing Delivery Addresses"
				message="You can upload Delivery Addresses if they are available and applicable to your requirements. For more information please"
				link="visit our help pages."
			/>
			<UploadFiles onFileDivClick={() => onDeliveryAddressUpload()} id="Delivery Address" />
		</div>
	);
}
