import React from 'react';
import TableActions from '../../common/tableActions';

export default function ViewProductTable({ products, gotoProduct, onClickAction, onProductSelect }) {
	return (
		<div className="table-view table-responsive">
			<table id="view-product-table" className="table">
				<thead>
					<tr>
						{/* <th>
							<i style={{ fontSize: '1.7em' }} className="fa fa-cog icon" />
						</th> */}
						<th>Image</th>
						<th>Product Code</th>
						<th>Product Description</th>
						<th>Product Group</th>
						<th>Pack</th>
						<th>Allocated</th>
						<th>Qty On Hand</th>
						<th>Units</th>
						<th>Action</th>
					</tr>
				</thead>
				<tbody>
					{products
						? products.map(product => (
								<tr key={product.id}>
									{/* <td>
										<input type="checkbox" />
									</td> */}
									<td>{product.image}</td>
									<td>
										<a onClick={() => gotoProduct(product)}>{product.productCode}</a>
									</td>
									<td>
										<a onClick={() => gotoProduct(product)}>{product.productDescription}</a>
									</td>
									<td>{product.productGroupId}</td>
									<td>{product.packSize}</td>
									<td></td>
									<td></td>
									<td>{product.unitId}</td>
									<th onMouseEnter={() => onProductSelect(product)}>
										<TableActions
											data={[
												'Edit',
												'Clone',
												'Adjust',
												'Order',
												'Purchase',
												'View Transaction',
												'Obsolete',
												'Print Product Label',
												'Delete',
											]}
											icon="fa fa-cog"
											onClick={action => onClickAction(action)}
										/>
									</th>
								</tr>
						  ))
						: null}
				</tbody>
			</table>
		</div>
	);
}
