import React from 'react';

export default function UnitSalesTable() {
	return (
		<div className="table-view table-responsive">
			<table id="sales-quotes-charge-table" className="table">
				<thead>
					<tr>
						<th>Product Code</th>
						<th>Product Description</th>
						<th>Pref Supplier</th>
						<th>Warehouse</th>
						<th>JAN</th>
						<th>FEB</th>
						<th>MAR</th>
						<th>APR</th>
						<th>MAY</th>
						<th>JUN</th>
						<th>JUL</th>
						<th>AUG</th>
						<th>SEP</th>
						<th>OCT</th>
						<th>NOV</th>
						<th>DEC</th>
						<th>Total</th>
						<th>Stock On Hand</th>
					</tr>
				</thead>
				<tbody></tbody>
			</table>
		</div>
	);
}
