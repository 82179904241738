import React, { Component } from 'react';
import { loaderState, getCountriesData, getWarehouses, getProducts, addQuicklLinks } from '../../actions';
import { connect } from 'react-redux';
import DashboardComp from '../../components/dashboard';

class Dashboard extends Component {
	componentDidMount() {
		// this.props.loaderState(true);
		// this.props.addQuicklLinks();
		const { countries, warehouses, products } = this.props;
		if (!countries) {
			this.props.loaderState(true);
			this.props.getCountriesData();
		}
		if (!warehouses) {
			this.props.loaderState(true);
			this.props.getWarehouses();
		}
		if (!products) {
			this.props.getProducts();
		}
	}

	render() {
		const probablityOptions = {
			//exportEnabled: true,
			animationEnabled: true,
			// title: {
			// 	text: '',
			// },
			width: '250',
			height: '250',
			data: [
				{
					type: 'pie',
					//startAngle: 75,
					toolTipContent: '<b>{label}</b>: {y}%',
					//showInLegend: 'true',
					legendText: '{label}',
					indexLabelFontSize: 12,
					indexLabel: '{label} - {y}%',
					dataPoints: [
						{ y: 74, label: 'label 1' },
						{ y: 26, label: 'label 2' },
					],
				},
			],
		};

		const activityOptions = {
			//exportEnabled: true,
			animationEnabled: true,
			// title: {
			// 	text: '',
			// },
			width: '250',
			height: '250',
			data: [
				{
					type: 'pie',
					//startAngle: 75,
					toolTipContent: '<b>{label}</b>: {y}%',
					//showInLegend: 'true',
					legendText: '{label}',
					indexLabelFontSize: 12,
					indexLabel: '{label} - {y}%',
					dataPoints: [
						{ y: 40, label: 'label 1' },
						{ y: 40, label: 'label 2' },
						{ y: 20, label: 'label 3' },
					],
				},
			],
		};

		const marginOptions = {
			theme: 'light2',
			animationEnabled: true,
			axisY: {
				includeZero: false,
				prefix: '$',
			},
			toolTip: {
				shared: true,
			},
			height: '250',
			data: [
				{
					type: 'area',
					name: 'Revenue',
					showInLegend: true,
					xValueFormatString: 'MMM YYYY',
					yValueFormatString: '$#,##0.##',
					dataPoints: [
						{ x: new Date('2017- 01- 01'), y: 5000.927 },
						{ x: new Date('2017- 02- 01'), y: 4000.609 },
						{ x: new Date('2017- 03- 01'), y: 8000.428 },
						{ x: new Date('2017- 04- 01'), y: 1000.259 },
						{ x: new Date('2017- 05- 01'), y: 1400.153 },
						{ x: new Date('2017- 06- 01'), y: 24000.18 },
						{ x: new Date('2017- 07- 01'), y: 34000.84 },
						{ x: new Date('2017- 08- 01'), y: 40000.671 },
						{ x: new Date('2017- 09- 01'), y: 67000.496 },
						{ x: new Date('2017- 10- 01'), y: 76000.007 },
						{ x: new Date('2017- 11- 01'), y: 87000.233 },
						{ x: new Date('2017- 12- 01'), y: 86000.276 },
					],
				},
				{
					type: 'area',
					name: 'Profit',
					showInLegend: true,
					xValueFormatString: 'MMM YYYY',
					yValueFormatString: '$#,##0.##',
					dataPoints: [
						{ x: new Date('2017- 01- 01'), y: 6000.515 },
						{ x: new Date('2017- 02- 01'), y: 5000.725 },
						{ x: new Date('2017- 03- 01'), y: 7000.86 },
						{ x: new Date('2017- 04- 01'), y: 11000.29 },
						{ x: new Date('2017- 05- 01'), y: 15000.51 },
						{ x: new Date('2017- 06- 01'), y: 25000.62 },
						{ x: new Date('2017- 07- 01'), y: 32000.2 },
						{ x: new Date('2017- 08- 01'), y: 45000.935 },
						{ x: new Date('2017- 09- 01'), y: 60000.31 },
						{ x: new Date('2017- 10- 01'), y: 70000.75 },
						{ x: new Date('2017- 11- 01'), y: 75000.49 },
						{ x: new Date('2017- 12- 01'), y: 83000.84 },
					],
				},
			],
		};

		const { warehouses, products } = this.props;
		return (
			<DashboardComp
				probablityOptions={probablityOptions}
				activityOptions={activityOptions}
				marginOptions={marginOptions}
				warehouses={warehouses}
				products={products}
			/>
		);
	}
}

const mapStateToProps = state => {
	return {
		countries: state.common.countriesData,
		warehouses: state.system.warehouses.allWarehouses,
		products: state.inventory.products.allProducts,
	};
};

export default connect(mapStateToProps, { loaderState, getCountriesData, getWarehouses, getProducts, addQuicklLinks })(
	Dashboard
);
