import React, { Component } from 'react';
import { goto, initilizeDataTable, destroyDataTable } from '../../../../helpers';
import EmailLogsComp from '../../../../components/setting/security/emailLogs';

export default class EmailLogs extends Component {
	componentDidMount() {
		initilizeDataTable('email-log-table');
	}

	componentDidUpdate() {
		initilizeDataTable('email-log-table');
	}
	render() {
		destroyDataTable('email-log-table');

		return <EmailLogsComp />;
	}
}
