import React from 'react';

export default function StepsInfo(props) {
	const { heading, message, link, step, button } = props;
	return (
		<div className="stepsInfo">
			<label className="label">
				<span className="info-icon">{step}</span>
				<span className="heading">{heading}</span>
			</label>
			<span className="span">
				{message}
				<a href="#">{link}</a>
				{button ? (
					<span id="info-btn" className="btn btn-success btn-file btn-sm">
						Upload
						<input type="file" style={{ visibility: 'hidden' }} />
					</span>
				) : null}
			</span>
		</div>
	);
}
