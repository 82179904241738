import React, { Component } from 'react';
import ImportExportComp from '../../../components/customers/importExport';

export default class ImportExport extends Component {
	constructor(props) {
		super(props);
		this.state = {
			nav: ['Customers', 'Delivery Addresses', 'Customer Contacts', 'Customer Pricing'],
		};
	}
	onCustomersUpload = () => {
		document.getElementById('inputFileCustomers').click();
	};
	onDeliveryAddressUpload = () => {
		document.getElementById('inputFileDeliveryAddress').click();
	};
	onCustomerPricingUpload = () => {
		document.getElementById('inputFileCustomerPricing').click();
	};
	onCustomerContactsUpload = () => {
		document.getElementById('inputFileCustomerContacts').click();
	};
	render() {
		const { nav } = this.state;
		return (
			<ImportExportComp
				nav={nav}
				onCustomersUpload={() => this.onCustomersUpload()}
				onCustomerContactsUpload={() => this.onCustomerContactsUpload()}
				onCustomerPricingUpload={() => this.onCustomerPricingUpload()}
				onDeliveryAddressUpload={() => this.onDeliveryAddressUpload()}
			/>
		);
	}
}
