import React from 'react';

export default function CustomersTable() {
	return (
		<div className="table-view table-responsive">
			<table id="customers-table" className="table">
				<thead>
					<tr>
						<th>First Name</th>
						<th>Last Name</th>
						<th>Email</th>
						<th>Delivery Address</th>
						<th>Website</th>
						<th>Default</th>
						<th>Ordering</th>
						<th>Invoicing</th>
						<th>Shipping</th>
						<th>Toll Free No</th>
						<th>Phone</th>
						<th>Fax</th>
						<th>Mobile</th>
						<th>DDI</th>
						<th>
							<i className="fa fa-comments-o icon" aria-hidden="true" />
						</th>
						<th>Action</th>
					</tr>
				</thead>
				<tbody></tbody>
			</table>
			<small>Please Save your Customer before adding contacts</small>
		</div>
	);
}
