import React from 'react';
import DeliveryAddressesTable from './deliveryAddressesTable';

export default function Delivery() {
	return (
		<div id="Delivery" className="tab-pane fade">
			<div className="row" id="settings-field">
				<div className="col-md-2 padding-right">
					<label className="input-label" htmlFor="address_name">
						Address Name
					</label>
					<input disabled type="text" className="form-control" id="address_name" name="address_name" />
				</div>
				<div className="col-md-2 padding-0">
					<label className="input-label" htmlFor="address_line_1">
						Address Line 1
					</label>
					<input disabled type="text" className="form-control" id="address_line_1" name="address_line_1" />
				</div>
				<div className="col-md-2 padding-0">
					<label className="input-label" htmlFor="address_line_2">
						Address Line 2
					</label>
					<input disabled type="text" className="form-control" id="address_line_2" name="address_line_2" />
				</div>
				<div className="col-md-1 padding-0">
					<label className="input-label" htmlFor="suburb">
						Suburb
					</label>
					<input disabled type="text" className="form-control" id="suburb" name="suburb" />
				</div>
				<div className="col-md-1 padding-0">
					<label className="input-label" htmlFor="town_city">
						Town/City
					</label>
					<input disabled type="text" className="form-control" id="town_city" name="town_city" />
				</div>
				<div className="col-md-2 padding-0">
					<label className="input-label" htmlFor="state_region">
						State/Region
					</label>
					<input disabled type="text" className="form-control" id="state_region" name="state_region" />
				</div>
				<div className="col-md-2 padding-0">
					<label className="input-label" htmlFor="postal_code">
						Postal Code
					</label>
					<input disabled type="text" className="form-control" id="postal_code" name="postal_code" />
				</div>
			</div>
			<br />
			<div className="row" id="settings-field">
				<div className="col-md-2 padding-right">
					<label className="input-label" htmlFor="country">
						Country
					</label>
					<select disabled className="form-control" id="country">
						<option></option>
						<option>Pakistan</option>
					</select>
				</div>
				<div className="col-md-6 padding-0">
					<label className="input-label" htmlFor="delivery_instruction">
						Delivery Instruction
					</label>
					<input
						disabled
						type="text"
						className="form-control"
						id="delivery_instruction"
						name="delivery_instruction"
					/>
				</div>
				<div className="col-md-2 padding-0" id="add-customer-btn">
					<label className="input-label">Add</label>
					<button disabled type="button" className="btn btn-success btn-sm">
						Add
					</button>
				</div>
			</div>
			<br />
			{/* <div className="panel panel-default">
				<div className="panel-body"> */}
			<DeliveryAddressesTable />
			{/* </div>
			</div> */}
		</div>
	);
}
