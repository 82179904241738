import React from 'react';

export default function AdjustmentTable() {
	return (
		<div className="table-view table-responsive">
			<table id="product-adjustments-table" className="table">
				<thead>
					<tr>
						<th style={{ textAlign: 'center' }} colSpan="4">
							Adjustment
						</th>
						<th style={{ textAlign: 'center' }} colSpan="3">
							Warehouse
						</th>
						<th style={{ textAlign: 'center' }} colSpan="3">
							Global Values
						</th>
						<th></th>
					</tr>
				</thead>
				<thead>
					<tr>
						<th>Product</th>
						<th>Qty</th>
						<th>Value</th>
						<th>Comments</th>
						<th>On Hand</th>
						<th>On Hand Value</th>
						<th>Available</th>
						<th>Qty</th>
						<th>Value</th>
						<th>Avg. Land Cost</th>
						<th>Actions</th>
					</tr>
				</thead>
				<tbody></tbody>
			</table>
		</div>
	);
}
