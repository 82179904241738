import { settings } from '../../constants/actionTypes';
const initialState = {
	allGroups: null,
	selectedGroup: null,
};

function productGroupReducer(state = initialState, action) {
	switch (action.type) {
		case settings.PRODUCT_GROUPS:
			return { ...state, allGroups: action.payload };
		case settings.SELECTED_PRODUCT_GROUP:
			return { ...state, selectedGroup: action.payload };
		default:
			return state;
	}
}
export default productGroupReducer;
