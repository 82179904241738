import React from 'react';

export default function MethodsTable({ methods, onMethodUpdateOrDelete }) {
	return (
		<div className="table-view table-responsive" id="setting-tables">
			<table id="delivery-methods-table" className="table">
				<thead>
					<tr>
						<th> Name </th>
						<th> Actions </th>
					</tr>
				</thead>
				<tbody>
					{methods
						? methods.map(method => (
								<tr>
									<td>{method.methodName}</td>
									<td>
										<i
											data-toggle="modal"
											data-target="#confirmationModal"
											className="fa fa-trash-o icon"
											onClick={() => onMethodUpdateOrDelete(method)}
										/>
										<i
											data-toggle="modal"
											data-target="#oneInputEditModal"
											className="fa fa-pencil-square-o icon"
											onClick={() => onMethodUpdateOrDelete(method)}
										/>
									</td>
								</tr>
						  ))
						: null}
				</tbody>
			</table>
		</div>
	);
}
