import React from 'react';

export default function Stats() {
	return (
		<div className="row">
			<div className="col-md-1"></div>
			<div className="col-md-6 col-xs-6">
				<div className="border-bottom">
					<span className="receipt-stat-label">Sub Total ()</span>
					<span className="pull-right receipt-stat-value">0</span>
				</div>
				<div className="border-bottom">
					<span className="receipt-stat-label">Sub Total (GBP)</span>
					<span className="pull-right receipt-stat-value">0</span>
				</div>
				<div className="border-bottom">
					<span className="receipt-stat-label">Charge Sub Total ()</span>
					<span className="pull-right receipt-stat-value">0</span>
				</div>
				<div className="border-bottom">
					<span className="receipt-stat-label">Charge Sub Total (GBP)</span>
					<span className="pull-right receipt-stat-value">0</span>
				</div>
				<div className="border-bottom">
					<span className="receipt-stat-label">Tax Total (GBP)</span>
					<span className="pull-right receipt-stat-value"></span>
				</div>
			</div>
			<div className="col-md-5 col-xs-6">
				<div className="border-bottom">
					<span className="receipt-stat-label">Total (GBP) </span>
					<span className="pull-right receipt-stat-value">0</span>
				</div>
			</div>
		</div>
	);
}
